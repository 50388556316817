// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdBannersTable_tableContainer__6uSA1 {\n  width: 100%;\n  max-width: 1000px;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n  /* background-color: red; */\n}\n\n.AdBannersTable_tableContainer__6uSA1 th,\n.AdBannersTable_tableContainer__6uSA1 td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.AdBannersTable_tableContainer__6uSA1 th {\n  background-color: #f2f2f2;\n}\n\n.AdBannersTable_tableBodyContainer__7V\\+0s tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n\n\n.AdBannersTable_imgPreviewBox__jtKSe {\n  width: 100px;\n}\n\n.AdBannersTable_imgPreview__6oHGr {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/UploadBanners/AdBannersTable/AdBannersTable.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;;;AAIA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb","sourcesContent":[".tableContainer {\n  width: 100%;\n  max-width: 1000px;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n  /* background-color: red; */\n}\n\n.tableContainer th,\n.tableContainer td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.tableContainer th {\n  background-color: #f2f2f2;\n}\n\n.tableBodyContainer tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n\n\n.imgPreviewBox {\n  width: 100px;\n}\n\n.imgPreview {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "AdBannersTable_tableContainer__6uSA1",
	"tableBodyContainer": "AdBannersTable_tableBodyContainer__7V+0s",
	"imgPreviewBox": "AdBannersTable_imgPreviewBox__jtKSe",
	"imgPreview": "AdBannersTable_imgPreview__6oHGr"
};
export default ___CSS_LOADER_EXPORT___;
