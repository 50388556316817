import React, { useRef, useState } from "react";
import MuiModal from "../../../muiComps/MuiModal";
import TinyMCEEditorCommon from "../../../common/TinyMCEEditorCommon";
import TinyMCEDataTable from "./TinyMCEDataTable";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";

import styles from "./MainTable.module.css";
import ReactHotToaster from "../../../common/ReactHotToaster";

const MainTable = () => {
  const [openTinyEditor, setOpenTinyEditor] = useState(false);
  const [tinyMCEDataList, setTinyMCEDataList] = useState([]);
  const [activeTinyDataItem, setActiveTinyDataItem] = useState({});
  const editorRef = useRef(null);

  const initialContent = tinyMCEDataList[0]?.symbol || null;
  // console.log("tinyMCEDataList: ", tinyMCEDataList)
  console.log(editorRef?.current?.getContent() === initialContent)

  // POST TINYMCE TEXT TO BACKEND [CREATE]
  const postTinyMCETable = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "/home/stock_screen",
        data: {
          // userid: userID,
          symbol: editorRef.current.getContent(),
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      // console.log('tiny data: ', data)
      setTinyMCEDataList(data?.response);
      // setOpenTinyEditor(false);
    },
    onError: (error) => {
      console.log(error);
      // toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const publishTinyMCETableHandler = () => {
    const consent = window.confirm("Are you sure you want to publish?");

    if (!consent) return;

    // If consent is true, send tinymce text
    postTinyMCETable.mutate();
  };

  // FETCHING ALL SYMBOLS DATA
  const homeTableSymbolsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["home-table-stocks"], // dependency
    queryFn: () => fetchHomeSymbols(),
    onSuccess: (data) => {
      // console.log("home table data: ", data);
      setTinyMCEDataList(data?.response);
    },
    onError: (error) => {
      console.log("home table error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // DELETE TINYMCE TABLE ITEM
  const deleteTinyMCETableItem = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "delete",
        url: `/home/stock_screen?id=${tinyMCEDataList[0]?.id}`,
        data: {},
      });
      return res.data;
    },
    onSuccess: (data) => {
      console.log("delete tiny data: ", data);
      setTinyMCEDataList([]);
    },
    onError: (error) => {
      console.log(error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const clearTinyMCEContent = () => {
    const consent = window.confirm(
      "Are you sure you want to clear all content?"
    );

    if (!consent) return;

    setTinyMCEDataList([{ symbol: "" }]);

    // If consent is true, send tinymce text
    // deleteTinyMCETableItem.mutate();
  };

  return (
    <div>
      {/* <button onClick={() => setOpenTinyEditor(true) } >Create TinyMCE Table</button> */}

      <div className={styles.tinyMCEContainer}>
        <div className={styles.tinyMCEPreviewContainer}>
          <h2>Mobile Screen Preview</h2>
          <TinyMCEEditorCommon
            editorRef={editorRef}
            initialContent={initialContent}
            editable={false}
          />
        </div>

        {/* tinyMCE Edit Update PUblish */}
        {/* <TinyMCEDataTable tableRowData={tinyMCEDataList} setActiveTinyDataItem={setActiveTinyDataItem} deleteTinyItem={deleteTinyItem} /> */}
        <div className={styles.tinyMCEEditor}>
          <h2>Create TinyMCE Table on HomeScreen</h2>
          <TinyMCEEditorCommon
            editorRef={editorRef}
            initialContent={initialContent}
          />

          <div className={styles.btnContainer} >
            <button className={styles.clearBtn} onClick={clearTinyMCEContent}>
              clear
            </button>
            <button className={styles.publishBtn} onClick={publishTinyMCETableHandler}>publish to app</button>
          </div>
        </div>
      </div>

      {/* TinyMCEEditor common popup */}
      {/* <MuiModal open={openTinyEditor} setOpen={setOpenTinyEditor} >
        <div>
          <TinyMCEEditorCommon editorRef={editorRef} />

          <button onClick={publishTinyMCETableHandler} >publish</button>
        </div>
        
      </MuiModal> */}

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default MainTable;

// utility functions
export const fetchHomeSymbols = async () => {
  // if (!) return;

  const res = await axiosConfig({
    method: "get",
    url: "/home/stock_screen",
    data: {},
  });

  return res.data;
};
