import React from 'react';
import mobileImage from '../../../assets/mobile-app-screen.png'

import styles from './MobileAppPitch.module.css';

const MobileAppPitch = () => {
  return (
    <div className={styles.mainContainer} >
      <div className={styles.innerContainer}>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>Get Started With Mobile App</h2>
          <p className={styles.description}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quis quidem ab suscipit facere quod, cum dignissimos nihil voluptas pariatur doloribus sunt dolorum natus labore sequi! Omnis quos nobis at!
          </p>

          <button className={styles.ctaBtn} >Get Demo</button>
        </div>

        <div className={styles.imageContainer}>
          <img className={styles.image} src={mobileImage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default MobileAppPitch