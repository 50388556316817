// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditableTable_user_Type_Select_Input__OcTLr {\n  outline: none;\n  padding: 4px;\n  text-align: left;\n  width: 140px;\n  /* border: 2px solid red; */\n  border-radius: 4px;\n}\n\nuser_Type_Select_Input:hover,\nuser_Type_Select_Input:focus,\nuser_Type_Select_Input:active,\nuser_Type_Select_Input:checked {\n  background: linear-gradient(#5A2569, #5A2569);\n  color: red;\n  border: 1px solid red;\n}\n\n\n.EditableTable_html_btn__pyWCl {\n  text-align: center;\n  height: 25px;\n  border-radius: 4px;\n  outline: none;\n  border: none;\n\n  cursor: pointer;\n  background-color: red;\n  color: white;\n}", "",{"version":3,"sources":["webpack://./src/User/EditableTable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;;;;EAIE,6CAA6C;EAC7C,UAAU;EACV,qBAAqB;AACvB;;;AAGA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,YAAY;;EAEZ,eAAe;EACf,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".user_Type_Select_Input {\n  outline: none;\n  padding: 4px;\n  text-align: left;\n  width: 140px;\n  /* border: 2px solid red; */\n  border-radius: 4px;\n}\n\nuser_Type_Select_Input:hover,\nuser_Type_Select_Input:focus,\nuser_Type_Select_Input:active,\nuser_Type_Select_Input:checked {\n  background: linear-gradient(#5A2569, #5A2569);\n  color: red;\n  border: 1px solid red;\n}\n\n\n.html_btn {\n  text-align: center;\n  height: 25px;\n  border-radius: 4px;\n  outline: none;\n  border: none;\n\n  cursor: pointer;\n  background-color: red;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user_Type_Select_Input": "EditableTable_user_Type_Select_Input__OcTLr",
	"html_btn": "EditableTable_html_btn__pyWCl"
};
export default ___CSS_LOADER_EXPORT___;
