// import { useEffect } from "react";
// import {
//     PayPalScriptProvider,
//     PayPalButtons,
//     usePayPalScriptReducer
// } from "@paypal/react-paypal-js";

// // This values are the props in the UI
// const amount = "2";
// const currency = "USD";
// const style = {"layout":"vertical"};

// // Custom component to wrap the PayPalButtons and handle currency changes
// const ButtonWrapper = ({ currency, showSpinner }) => {
//     // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
//     // This is the main reason to wrap the PayPalButtons in a new component
//     const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

//     useEffect(() => {
//         dispatch({
//             type: "resetOptions",
//             value: {
//                 ...options,
//                 currency: currency,
//             },
//         });
//     }, [currency, showSpinner]);


//     return (<>
//             { (showSpinner && isPending) && <div className="spinner">Loading...</div> }
//             <PayPalButtons
//                 style={style}
//                 disabled={false}
//                 forceReRender={[amount, currency, style]}
//                 fundingSource={undefined}
//                 createOrder={(data, actions) => {
//                   console.log("createOrder data: ", data)
//                     // return actions.order
//                     //     .create({
//                     //         purchase_units: [
//                     //             {
//                     //                 amount: {
//                     //                     currency_code: currency,
//                     //                     value: amount,
//                     //                 },
//                     //             },
//                     //         ],
//                     //     })
//                     //     .then((orderId) => {
//                     //         // Your code here after create the order
//                     //         return orderId;
//                     //     });
//                 }}
//                 onApprove={function (data, actions) {
//                     console.log("onApprove data: ", data)
//                 }}
//             />
//         </>
//     );
// }

// export default function PayPal() {
// 	return (
// 		<div style={{ maxWidth: "750px", minHeight: "200px" }}>
//             <PayPalScriptProvider
//                 options={{
//                     "client-id": "AbREbWOUjv29kyQ5RQn9qpv1bZD9ENMn_HLvfd05hR9QGPhLssv5-TSOAuIAQ8kjyhPVj1nuovjTCrgz",
//                     components: "buttons",
//                     currency: "USD"
//                 }}
//             >
// 				<ButtonWrapper
//                     currency={currency}
//                     showSpinner={false}
//                 />
// 			</PayPalScriptProvider>
// 		</div>
// 	);
// }


import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

const initialOptions = {
  "client-id":
    // "AbREbWOUjv29kyQ5RQn9qpv1bZD9ENMn_HLvfd05hR9QGPhLssv5-TSOAuIAQ8kjyhPVj1nuovjTCrgz",
    "AXVICBXNgELKJQpFpUdxO5Zi13J-rbA3Z7bVIXWS9uXUNVvVIYmPSrCjK0Y0SVngysgDpCcYtpIz1g3_",
  currency: "USD",
  intent: "capture",
  // "data-client-token": "abc123xyz==",
};

export default function PayPal() {
  return (
    <PayPalScriptProvider options={initialOptions}>
      <ButtonWrapper />
      {/* <PayPalButtons /> */}
    </PayPalScriptProvider>
  );
}

const ButtonWrapper = () => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  return (
    <>
      {isPending ? <h1>lOADING...</h1> : null}

      {/* <PayPalButtons style={{ layout: "vertical" }} /> */}

      <PayPalButtons />
    </>
  );
};
