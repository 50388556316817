import React, { useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';

// Default <Toaster /> component for react-hot-toast;

/*
  How to use react-hot-toast
  1) import toast from "react-hot-toast";
  2) import ReactHotToaster from "../../components/common/ReactHotToaster";
  3) Add <ReactHotToaster /> at the bottom jsx
  4) https://react-hot-toast.com/docs/toaster
  5) toast.error(error?.response?.data?.response || "Something went wrong");
  toast.success(TOAST_MSG, {
          duration: 6000,
          position: "top-center",
        });
*/

const ReactHotToaster = () => {
  useEffect(() => {
    

    return () => {
      toast.dismiss();
    }

  }, [])

  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        duration: 8000,
        style: {
          background: "#363636",
          color: "#fff",
        },

        // Default options for specific types
        success: {
          duration: 8000,
          theme: {
            primary: "green",
            secondary: "black",
          },
        },
      }}
    />
  );
};

export default ReactHotToaster;
