import { Box } from "@mui/material";
import React from "react";

const ColorSignalCircle = ({ bgColor = "green", width = 10}) => {
  return (
    <Box
      sx={{
        width: width,
        height: width,
        borderRadius: "50%",
        backgroundColor: bgColor,
      }}
    />
  );
};

export default ColorSignalCircle;
