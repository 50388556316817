// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.UserCallback_tableContainer__DxBIF {\n  width: 100%;\n  max-width: 900px;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n  /* background-color: red; */\n}\n\n.UserCallback_tableContainer__DxBIF th,\n.UserCallback_tableContainer__DxBIF td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.UserCallback_tableContainer__DxBIF th {\n  background-color: #f2f2f2;\n}\n\n.UserCallback_tableBodyContainer__BYlIF tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.UserCallback_btn__-G\\+GI {\n  outline: none;\n  border: none;\n  padding: 0.3rem 0.6rem;\n  border-radius: 0.2rem;\n  /* font-size: 1.2rem; */\n  text-transform: uppercase;\n  background-color: #333;\n  color: #fff;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/HomeScreenContent/UserCallback/UserCallback.module.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,qBAAqB;EACrB,uBAAuB;EACvB,yBAAyB;EACzB,sBAAsB;EACtB,WAAW;EACX,eAAe;AACjB","sourcesContent":["\n.tableContainer {\n  width: 100%;\n  max-width: 900px;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n  /* background-color: red; */\n}\n\n.tableContainer th,\n.tableContainer td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.tableContainer th {\n  background-color: #f2f2f2;\n}\n\n.tableBodyContainer tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.btn {\n  outline: none;\n  border: none;\n  padding: 0.3rem 0.6rem;\n  border-radius: 0.2rem;\n  /* font-size: 1.2rem; */\n  text-transform: uppercase;\n  background-color: #333;\n  color: #fff;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "UserCallback_tableContainer__DxBIF",
	"tableBodyContainer": "UserCallback_tableBodyContainer__BYlIF",
	"btn": "UserCallback_btn__-G+GI"
};
export default ___CSS_LOADER_EXPORT___;
