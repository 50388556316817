import React from "react";

import styles from "./CallToActionBanner.module.css";
import { Button, Stack, Typography } from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

const CallToActionBanner = () => {
  return (
    <Stack
      direction={{ sm: "column", md: "row" }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
      padding={{ xs: '1rem', md: '3rem 8rem' }}
      sx={{
        // height: 200,
        // padding: '3rem 8rem',
        bgcolor: '#CAFAD7',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Stack
        padding={{ xs: '1rem', md: '0 4rem' }}
        sx={{
        // flex: 2,
        // padding: '0 4rem'
        textAlign: 'center'
      }} >
        <Typography variant="h4" textAlign={{ sm: "center", md: "left" }} color="#083A5E" >
          <Typography variant="h4" component="span" color="#339502" >Let Me Help </Typography>
          You Overshoot <br/>
          Your Goals In The Right Ways.
        </Typography>
      </Stack>
       
      <Stack sx={{
        minWidth: 140
      }}>
        <Button variant="contained" color="success" endIcon={<TrendingFlatIcon sx={{
          
        }} />}>
          Start Now
        </Button>
      </Stack>
      
    </Stack>
  );
};

export default CallToActionBanner;
