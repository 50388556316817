import axiosConfig from "../axios/axiosConfig";

// function to send deviceID to backend server
export async function sendToken(USERID) {
  if (!USERID) return;

  // first check for browser notifications
  const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      alert("Permission Denied. Please unblock push notifications from the browser!");
      return;
    }
  
  const oneSignalUserId = window.localStorage.getItem("one_signal_userid");
  console.log("one_signal_userid: ", oneSignalUserId);
  
    // check for OneSignal PlayerID
  if (!oneSignalUserId || oneSignalUserId === "") {
    alert(
      "Push notifications are disabled, please subscribe using the bell icon at the bottom right to receive real time alerts."
    );
    return;
  }

  
  // Send the token to your server and update the UI if necessary
  try {
    const res = await axiosConfig({
      method: "patch",
      url: `/tnibroapp/one_signal_token?userid=${USERID}`,
      data: {
        notify_token: oneSignalUserId,
        userid: USERID,
        device_name: navigator.userAgentData.platform,
        device_type: navigator.userAgentData.mobile ? "mobile" : "pc",
        device_id: getUniqueID(),
      },
    });

    // console.log("res: ", res);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }

  // IMP: commented out else if block, alert message was showing on every request.
  // else if (permission === "denied") {
  //   alert(
  //     "Permission denied for push notification, you wont be able to set real time alerts."
  //   );
  // }
}

// SENDING DEVICE ID TO BACKEND SERVER, WHENEVER USER ON SIGN UP AND LOGIN
export const sendDeviceID = async (user_id) => {
  try {
    const token_res = await sendToken(user_id);
    // console.log("token_res: ", token_res)
    
    if (!token_res) return;
  } catch (error) {
    console.log(error);
    alert("Could not capture Device ID, you may not receive alerts.")
    
  }
}

// Utility function, get uniqueID
const getUniqueID = () => {
  const uniqueID = localStorage.getItem("uniqueID");

  if (!uniqueID) {
    const newUniqueID = crypto.randomUUID();
    localStorage.setItem("uniqueID", newUniqueID);
    return newUniqueID;
  } else {
    return uniqueID;
  }
};
