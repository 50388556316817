import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function MuiAutocomplete({
  label,
  options,
  value,
  setSelectedOptions,
  stateName,
}) {
  // const [value, setValue] = useState(options[0]);  // actual state that is selected
  const [inputValue, setInputValue] = useState(""); // what user types manually

  return (
    <Autocomplete
      size="small"
      value={value}
      onChange={(event, newValue) => {
        setSelectedOptions((prev) => {
          return { ...prev, [stateName]: newValue };
        });
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="mui-autocomplete"
      options={options}
      sx={{ width: 200 }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
