import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import axiosConfig from "../../axios/axiosConfig";
import MuiLinearProgressLoader from "../../components/muiComps/MuiLinearProgressLoader";
import DenseTable from "../../components/Tables/Tables";
import { Box, Stack, Typography } from "@mui/material";
import NoResultsToDisplay from "../../components/common/NoResultsToDisplay";
import SearchBox from "../../components/SearchBox";
import HistoricalRecommendationTable from "../../components/searchResults/HistoricalRecommendationTable";
import VerifyEmailPrompt from "../../components/VerifyEmailPrompt";

import styles from "./SearchResultsPage.module.css";

// url for this page: /search-results?symbol=reliance&segment=cash

const SearchResultsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const symbol = searchParams.get("symbol");
  const segment = searchParams.get("segment");
  const searchDate = searchParams.get("date");

  // Date in the format: 'Monday, Aug 7, 2023'
  const FORMATTED_DATE = new Date(searchDate)?.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const [recommendationDetails, setRecommendationDetails] = useState({});
  const [stockTableData, setStockTableData] = useState({});
  const stockTableDataArray = [stockTableData];
  const [historicalRecomData, setHistoricalRecomData] = useState([]);

  const filteredSectors = stockTableDataArray[0]?.category_indices?.filter(
    (item) => item !== "all"
  );

  
  // const CURRENT_RECOMMENDATION = stockTableData?.recommended?.recommendation;
  const CURRENT_RECOMMENDATION =
    recommendationDetails?.recommendation_signal ||
    recommendationDetails?.recommendation;

  const PREV_RECOM_DATE = new Date(
    recommendationDetails?.date
  )?.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  // Stock category filter component
  const [selectedStockCategory, setSelectedStockCategory] = useState("");

  const stockData = useMutation({
    mutationFn: async () => getStockData(symbol, segment, searchDate),
    onSuccess: (data) => {
      setRecommendationDetails(data.response.recommendation);
      // year_high, year_low fields missing for option chain. Getting undefined error.
      const STOCKS_DATA_OBJ = data.response.data;
      if (
        segment === "optionchain" &&
        Object.keys(STOCKS_DATA_OBJ).length !== 0
      ) {
        const newStockData = {
          ...STOCKS_DATA_OBJ,
          year_high: '{"year_high": 0, "year_high_gap_pcnt": 0}',
          year_low: '{"year_low": 0, "year_low_gap_pcnt": 0}',
        };
        setStockTableData(newStockData);
      } else {
        setStockTableData(STOCKS_DATA_OBJ);
      }

      setHistoricalRecomData(data.response.historical_performance);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    stockData.mutate();
  }, [symbol, segment, searchDate]);

  // const CURRENT_RECOMMENDATION =

  if (!symbol) {
    return <h1>Seach Input is empty</h1>;
  }

  return (
    <Box sx={{}}>
      {/* <SearchBox /> */}

      <Typography
        variant="h4"
        sx={{
          margin: "2rem 0 0",
          border: "1px solid",
          display: "inline-block",
          padding: "2px 8px",
          borderRadius: 1,
        }}
      >
        {symbol.trim().toUpperCase()}
      </Typography>

      {/* SECTORS & CATEGORIES */}
      <div className={styles.sectorTagWrapper}>
              {filteredSectors?.map((item, index) => {
                return (
                  <span key={index} className={styles.sectorTagItem}>
                    {item}
                  </span>
                );
              })}
            </div>

      {/* SCREENER TABLE HERE */}
      <Box sx={{ margin: "1rem 0 4rem" }}>
        {stockData.isLoading ? (
          <MuiLinearProgressLoader />
        ) : Object.keys(stockTableData).length !== 0 ? (
          <div
            style={{
              overflowX: "auto",
            }}
          >
            <Box
              sx={{
                padding: "0.2rem 0",
              }}
            >
              <Stack direction="row" spacing={2} mb={1}>
                <p>
                  Our Recommendation <em>On or Before</em>{" "}
                  <strong className={styles.date}>{FORMATTED_DATE}</strong>:{" "}
                </p>
                {/* <span>Our Recommendation On or before {FORMATTED_DATE}: </span> */}
                <h4
                  style={{
                    textTransform: "uppercase",
                    color: recommendationDetails?.color || "gray",
                  }}
                >
                  {CURRENT_RECOMMENDATION}
                </h4>
              </Stack>

              <p>
                We're recommending{" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: recommendationDetails?.color || "gray",
                  }}
                >
                  {CURRENT_RECOMMENDATION}
                </span>{" "}
                Signal since:{" "}
                <strong className={styles.date}>{PREV_RECOM_DATE}</strong>
              </p>
            </Box>

            {/* Previous Performance button */}
            <div className={styles.btnContainer}>
              <button className={styles.previousRecommendationsBtn} onClick={(event) => {
                navigate(`/previous-trades-performance?symbol=${stockTableData?.symbol}`)
              }} >Previous Recommendations</button>
            </div>

            <DenseTable
              tableData={stockTableDataArray}
              maxHeight="600px"
              selectedStockCategory={selectedStockCategory}
              setSelectedStockCategory={setSelectedStockCategory}
              disableFiltering={true}
              disableSorting={true}
              clearFilters={true}
            />
          </div>
        ) : (
          <NoResultsToDisplay />
        )}
      </Box>

      {/* DISPLAY HISTORICAL DATA TABLE HERE */}
      <Typography
        variant="h4"
        mb={2}
        sx={{
          textTransform: "uppercase",
          border: "1px solid",
          borderRadius: 2,
          display: "inline-block",
          padding: "2px 8px",
        }}
      >
        Historical Data
      </Typography>

      <div style={{ overflowX: "auto" }}>
        <HistoricalRecommendationTable
          rows={historicalRecomData}
          symbol={symbol}
          // CURRENT_RECOMMENDATION={CURRENT_RECOMMENDATION}
        />
      </div>

      {/* TOP STOCKS TABLES */}
      <Box sx={{ marginBottom: "2rem" }}>{/* <TopStocksHomeTables /> */}</Box>
    </Box>
  );
};

export default SearchResultsPage;

// Utility Functions
const getStockData = async (SYMBOL, SEGMENT, DATE) => {
  if (!SYMBOL || !SEGMENT) return;

  const res = await axiosConfig({
    method: "post",
    url: "/home/searching_stocks",
    data: { symbol: SYMBOL, segment: SEGMENT, timeframe: DATE },
  });

  return res.data;
};
