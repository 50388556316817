import React, { useState } from "react";

import styles from "./UploadBannerForm.module.css";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { axiosConfig } from "../../../axios/axiosConfig";
import ReactHotToaster from "../ReactHotToaster";

// Screen List for Publishing content
// const screenListOptions = [
//   { id: 1, label: "Home", value: "home" },
//   { id: 2, label: "Dashboard", value: "dashboard" },
//   { id: 3, label: "Watchlist", value: "watchlist" },
//   { id: 4, label: "Alert", value: "alert" },
//   { id: 5, label: "New Listing", value: "new_listing" },
//   { id: 6, label: "Categories", value: "categories" },
//   { id: 7, label: "Options", value: "options" },
//   { id: 11, label: "Intraday Nifty/BankNifty", value: "NiftyBanknifty" },
// ];

const UploadBannerForm = ({
  imgPreview,
  formInput,
  handleFormInputOnChange,
  handleImageFileChange,
  handleSubmit,
  handleUpdate,
  isEditing,
  setIsEditing
}) => {
  const [bannerScreensList, setBannerScreensList] = useState([]);
  const screenListOptions = bannerScreensList?.map((item) => {
    return {
      id: item.id,
      label: item.screen_name,
      value: item.screen_name,
    };
  });

  // FETCH BANNER Screens
  const bannerScreensQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["banner-screens"],
    queryFn: () => fetchBannerScreensList(),
    onSuccess: (data) => {
      // console.log("screens list data: ", data.response)
      setBannerScreensList(data.response);
    },
    onError: (error) => {
      console.log("error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // HANDLE CANCEL EDIT 
  const handleCancelEdit = (event) => {
    event.preventDefaut();
    setIsEditing(false);
    
  };

  return (
    <div>
      <h2>Upload Banner Form</h2>

      {/* Banner form */}
      {/* name?, description?, image url / upload, deeplink / Launch URL */}
      <div className={styles.formPreviewContainer}>
        <form className={styles.bannerForm}>
          <h2>Create New Banner</h2>

          <div className="inputBox">
            <select
              className={styles.selectScreenInput}
              name="selectedScreen"
              value={formInput.selectedScreen}
              onChange={handleFormInputOnChange}
            >
              {screenListOptions?.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>

          {/* <div className={styles.inputBox}>
            <label htmlFor="title">Title</label>
            <input
              disabled
              className={styles.inputControl}
              type="text"
              name="title"
              id="title"
              value={formInput.title}
              onChange={handleFormInputOnChange}
            />
          </div>

          <div className={styles.inputBox}>
            <label htmlFor="description">description</label>
            <input
              disabled
              className={styles.inputControl}
              type="text"
              name="description"
              id="description"
              value={formInput.description}
              onChange={handleFormInputOnChange}
            />
          </div> */}

          <div className={styles.inputBox}>
            <label htmlFor="title">Image</label>

            <div className={styles.imgUploadContainer}>
              <input
                className={styles.imgUrlInput}
                type="text"
                name="imgUrl"
                id="imgUrl"
                value={formInput.imgUrl}
                onChange={handleFormInputOnChange}
              />
              <label htmlFor="imageUpload">
                {/* <UploadIcon /> */}
                <span className={styles.uploadBtn}>upload</span>
                <input
                  onChange={handleImageFileChange}
                  className={styles.imgFileInput}
                  type="file"
                  name="imageUpload"
                  id="imageUpload"
                />
              </label>
            </div>
          </div>

          {/* target url */}
          <div className={styles.inputBox}>
            <label htmlFor="targetUrl">Target Link</label>
            <input
              disabled
              className={styles.inputControl}
              type="text"
              name="targetUrl"
              id="targetUrl"
              value={formInput.targetUrl}
              onChange={handleFormInputOnChange}
            />
          </div>

          {/* FROM DATE PICKER */}
          <div className={styles.inputBox}>
            <label htmlFor="startDate">Start date: </label>
            <input
              className={styles.inputControl}
              type="date"
              name="startDate"
              id="startDate"
              min={new Date().toISOString().slice(0, 10)}
              value={formInput.startDate}
              onChange={handleFormInputOnChange}
            />
          </div>

          {/* TO DATE PICKER */}
          <div className={styles.inputBox}>
            <label htmlFor="endDate">End date: </label>
            <input
              className={styles.inputControl}
              type="date"
              name="endDate"
              id="endDate"
              value={formInput.endDate}
              onChange={handleFormInputOnChange}
            />
          </div>

          <div className={styles.btnContainer}>
            {isEditing ? (
              <div className="btnContainer">
                <button className={styles.submitBtn} onClick={handleUpdate}>
                  update
                </button>
                <button className={styles.submitBtn} onClick={() => setIsEditing(false)}>
                  cancel
                </button>
              </div>
            ) : (
              <button className={styles.submitBtn} onClick={handleSubmit}>
                create
              </button>
            )}
          </div>
        </form>

        {/* Image Preview Container */}
        <div className={styles.imgPreviewContainer}>
          <h2>Image Preview</h2>
          <div className={styles.imgContainer}>
            <img className={styles.imgPreview} src={imgPreview} alt="" />
          </div>
        </div>
      </div>

      {/* REACT HOT TOAST COMP */}
      <ReactHotToaster />
    </div>
  );
};

export default UploadBannerForm;

// UTILITIES
const fetchBannerScreensList = async () => {
  // if (!watchlistId) return;

  const res = await axiosConfig({
    method: "get",
    url: "/advertisement/screens/list",
    data: {},
  });

  return res.data;
};
