// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.HtmlSelectDropdown_select_Input__km8kP {\n  text-align: center;\n  height: 35px;\n  padding: 0 0.2rem;\n  border-radius: 4px;\n  outline: none;\n\n  text-align: left;\n  /* width: 120px; */\n  border: 1px solid #ccc;\n  /* margin-bottom: 4px; */\n}\n\n\n.HtmlSelectDropdown_select_Title__sXg2l {\n  margin-bottom: 8px;\n}", "",{"version":3,"sources":["webpack://./src/components/common/HtmlSelectDropdown/HtmlSelectDropdown.module.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;;EAEb,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,wBAAwB;AAC1B;;;AAGA;EACE,kBAAkB;AACpB","sourcesContent":["\n.select_Input {\n  text-align: center;\n  height: 35px;\n  padding: 0 0.2rem;\n  border-radius: 4px;\n  outline: none;\n\n  text-align: left;\n  /* width: 120px; */\n  border: 1px solid #ccc;\n  /* margin-bottom: 4px; */\n}\n\n\n.select_Title {\n  margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_Input": "HtmlSelectDropdown_select_Input__km8kP",
	"select_Title": "HtmlSelectDropdown_select_Title__sXg2l"
};
export default ___CSS_LOADER_EXPORT___;
