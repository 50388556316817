import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import DashboardItem from "../DashboardItem";

import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";

import DashboardSelectModal from "./DashboardSelectModal";

import MuiModal from "../../muiComps/MuiModal";
import styles from "./DashboardList.module.css";
import CreateDashboardModal from "./CreateDashboardModal";

import axiosConfig from "../../../axios/axiosConfig";
import { useSelector } from "react-redux";

const dashboardListDemo = [
  {
    id: 1,
    name: "all time high",
    screener_list: ["a123"],
    private: true,
  },
  {
    id: 2,
    name: "intraday",
    screener_list: ["a123", "b966"],
    private: false,
  },
  {
    id: 3,
    name: "secret strategy",
    screener_list: ["a653", "b435", "c408"],
    private: false,
  },
];

const screenerList = [
  {
    screenerID: "a123",
    symbols: [
      "AAPL",
      "MSFT",
      "GOOGL",
      "FACB",
      "REL",
      "TATA",
      "OPPO",
      "MAR",
      "YAMA",
    ],
  },
];

const DashboardList = () => {
  const [userDashboards, setUserDashboards] = useState([]);
  const userDashboardList = userDashboards?.map((item) => {
    return {
      label: item.dashboard_name,
      id: item.id,
    };
  });
  const [openDashboardSelectModal, setOpenDashboardSelectModal] =
    useState(true);
  const [openCreateDashboardModal, setOpenCreateDashboardModal] =
    useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // If routing from /saved-scan, there will be screenerId
  const location = useLocation();
  const screenerId = location.state?.screenerId;

  const { user: currentUser } = useSelector((state) => state.auth);
  const USER_ID = currentUser.userDetails.id;
  

  useEffect(() => {
    const fetchAllDashboards = async () => {
      try {
        const res = await axiosConfig({
          method: "get",
          url: `/user/dashboard?userid=${USER_ID}`,
          data: {},
        });
        // console.log(res.data.response)

        setUserDashboards(res.data.response);
      } catch (error) {
        console.log(error.response);
      }
    };

    fetchAllDashboards();
  }, [USER_ID]);

  // HANDLE save screener, patch screenerID to dashboard api
  const handleSaveScreener = async (dashboardID, screener_ID) => {
    // patch screenerid to dashboard here
    try {
      const res = await axiosConfig({
        method: "patch",
        url: "/user/dashboard",
        data: { dashboard_id: dashboardID, screener_id: screener_ID },
      });

      if (res.data.status === "success") {
        let newScreener_List;

        const newUserDashboards = userDashboards.map((item) => {
          if (item.id === dashboardID) {
            newScreener_List = [...item.screener_list, screener_ID];
            return {
              ...item,
              screener_list: newScreener_List,
            };
          }
          return item;
        });

        setUserDashboards(newUserDashboards);

        navigate(`/dashboards/${dashboardID}`, {
          state: { screener_list: newScreener_List },
        });
      } else {
        console.log("something went wrong...");
      }
    } catch (error) {
      console.log(error.response);
    }
    //  finally {
    //   setOpenModal(false);
    // }
  };

  return (
    <Box sx={{ p: 2, position: "relative" }}>
      {/* <h1>DashboardList Monitor Screeners</h1> */}
      <Typography variant="h3" sx={{
        textTransform: 'uppercase',
        border: '1px solid',
        borderRadius: '0.5rem',
        display: 'inline-block',
        padding: '4px'
      }} >Monitor Screeners</Typography>

      {/* CREATE NEW DASHBOARD */}
      <Stack
        direction="row"
        spacing={2}
        sx={{ position: "absolute", top: 15, right: 20 }}
      >
        <Button
          variant="contained"
          onClick={(e) => setOpenCreateDashboardModal(true)}
          startIcon={<DashboardCustomizeIcon />}
        >
          Create New Dashboard
        </Button>
      </Stack>

      {/* Show DashboardSelectModal if url contains '?openModal=true' */}
      {searchParams.get("openModal") === "true" ? (
        <MuiModal
          open={openDashboardSelectModal}
          setOpen={setOpenDashboardSelectModal}
        >
          <DashboardSelectModal
            setOpenModal={setOpenDashboardSelectModal}
            userDashboards={userDashboards}
            setUserDashboards={setUserDashboards}
            userDashboardList={userDashboardList}
            screenerId={screenerId}
            setOpenCreateDashboardModal={setOpenCreateDashboardModal}
            handleSaveScreener={handleSaveScreener}
          />
        </MuiModal>
      ) : null}

      {/* IMP: create dashboards container below if needed */}
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {userDashboards?.map((dashboard) => {
          return (
            <DashboardItem
              key={dashboard.id}
              {...dashboard}
              setUserDashboards={setUserDashboards}
            />
          );
        })}
      </Box>

      {/* CREATE NEW DASHBOARD MODAL */}
      <MuiModal
        open={openCreateDashboardModal}
        setOpen={setOpenCreateDashboardModal}
      >
        <CreateDashboardModal
          setOpenModal={setOpenCreateDashboardModal}
          setUserDashboards={setUserDashboards}
          currentUser={currentUser}
          screenerId={screenerId}
          handleSaveScreener={handleSaveScreener}
        />
      </MuiModal>
    </Box>
  );
};

export default DashboardList;
