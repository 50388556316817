import React from "react";
import UserPaymentsDashboardTable from "./UserPaymentsDashboardTable";

const styles = {
  pageContainer: {
    margin: "2rem",
  },
  pageTitle: {
    marginBottom: "2rem",
  },
};

const PaymentsDashboard = () => {
  // const [paidUsersData, setPaidUsersData] = useState();

  return (
    <div style={styles.pageContainer}>
      <h1 style={styles.pageTitle}>Payments Dashboard</h1>

      {/* USER PAYMENTS DASHBOARD TABLE */}
      <UserPaymentsDashboardTable />
    </div>
  );
};

export default PaymentsDashboard;
