import React, { useState } from 'react'
import MobileOTPInputBox from '../MobileOTPInputBox'
import toast from 'react-hot-toast';
import { getOTP, verifyOTP } from '../utilFunctions';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ActivateAccountModal = ({ setOpen }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileOTP, setMobileOTP] = useState("");
  const [sessionID, setSessionID] = useState("");

  // GET OTP HANDLER
  const handleGetOTP = async () => {
    const res = await getOTP(USERID, mobileNumber);

    // If successful
    if (res.status === 200 || res.statusText === "OK") {
      setSessionID(res.data.Details);
      const TOAST_MSG =
        "OTP sent to mobile, please type OTP and click Verify OTP";
      toast.success(TOAST_MSG, {
        duration: 6000,
        position: "top-center",
      });
    } else {
      console.log("handleGetOTP error: ", res.response);
      const ERROR_MSG = res.response.data.response;
      toast.error(ERROR_MSG || "Something went wrong", {
        duration: 6000,
        position: "top-center",
      });
    }
  };

  // VERIFY / SUBMIT OTP / ACTIVATE HANDLER, ACTIVATE USER ACCOUNT
  const handleVerifyOTP = async () => {
    const res = await verifyOTP(mobileNumber, sessionID, mobileOTP, USERID, "activate");

    if (res.status === 200 || res.statusText === "OK") {
      // Indian User Authorized
      toast.success("OTP verified successfully! Please login.", {
        duration: 6000,
        position: "top-center",
      });

      navigate('/login');

    } else {
      console.log("handleVerifyOTP error: ", res);
      const ERROR_MSG = res.response.data.response;
      toast.error(ERROR_MSG || "Something went wrong", {
        duration: 6000,
        position: "top-center",
      });
    }

    setOpen(false);
};

  return (
    <Box sx={{
      maxWidth: '450px'
    }}>
      {/* <h1>ActivateAccountModal</h1> */}
      <Typography variant='h4' sx={{
        textAlign: 'center',
        textTransform: 'uppercase',
        margin: '1rem 0',
        border: '1px solid #ccc',
        padding: '0.2rem',
        borderRadius: '0.4rem'
      }} >Activate My Account</Typography>

      {/* mobile number input, otp  */}
      {/* MOBILE PHONE INPUT */}
      <MobileOTPInputBox
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
        mobileOTP={mobileOTP}
        setMobileOTP={setMobileOTP}
        onGetOTP={handleGetOTP}
        onVerifyOTP={handleVerifyOTP}
        actionType="activate"
        // selectedOptionsArray={['This msg string is to Avoid disabled button bug in GET OTP button']}
      />
    </Box>
  )
}

export default ActivateAccountModal