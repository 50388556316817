import React from "react";

import styles from "./AdvisoryDetailsTable.module.css";
import ColorSignalCircle from "../../../common/ColorSignalCircle";

const AdvisoryDetailsTable = ({ advisoryDetailsList }) => {
  return (
    <div className={styles.AdvisoryDetailsTable} >
      <h2>Stock Advisory History</h2>

      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>#</th>
            <th>Symbol</th>
            <th>Description</th>
            <th>Entry</th>
            <th>Target</th>
            <th>Stop Loss</th>
            <th>Type</th>
            <th>Duration</th>

            <th>Status</th>
            <th>Created</th>
            <th>Modified</th>
            <th>End Date</th>
            <th>Target hit?</th>
            <th>Target hit date</th>

            <th>File</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {advisoryDetailsList?.map((item, index) => {
            // const createdDate = new Date(item.created_at).toDateString();
            // const modifiedDate = new Date(item.modified_at).toDateString();

            return (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.symbol_name}</td>
                <td>{item.description}</td>
                <td>{item.entry_price}</td>
                <td>{item.target_price}</td>
                <td>{item.stoploss_price}</td>

                <td>{item.adviosry_type}</td>
                <td>{item.adviosry_duration}</td>
                <td>{item.adviosry_status}</td>

                <td>{item.created_at}</td>
                <td>{item.modified_at}</td>
                <td>{item.advisoury_end_date}</td>

                <td>
                  <div className={styles.colorCircleContainer} >
                    {item.is_target_hit ? (
                      <ColorSignalCircle bgColor="green" />
                    ) : (
                      <ColorSignalCircle bgColor="red" />
                    )}
                  </div>
                </td>
                <td>{item.target_hit_date ? item.target_hit_date : "N/A"}</td>

                <td>
                  <div>
                    <a className={styles.fileDownloadLink} href={item?.file} >download</a>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdvisoryDetailsTable;
