import React from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import styled from "@mui/system/styled";
import { useAxios } from "../../hooks/useAxios";
import { Typography } from "@mui/material";
import MuiLinearProgressLoader from "../../components/muiComps/MuiLinearProgressLoader";

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  padding: theme.spacing(1),
  borderRadius: "4px",
  textAlign: "center",
}));

export default function BackTestTiles({
  setTimeframeDate,
  setShowBackTestTiles,
  screenerId,
}) {
  const { response, loading, error } = useAxios({
    method: "GET",
    url: `/backtest?date=true&segment=cash&screenerid=${screenerId}`,
    headers: {
      accept: "*/*",
    },
    data: null,
  });
  const backtestDates = response?.response;

  const handleTileClick = (date) => {
    setTimeframeDate(date);
    setShowBackTestTiles(false);
    // fetchBacktestSymbols();
  };

  return (
    <>
      <Box m={8} p={2} sx={{ maxWidth: 1200, border: "1px solid #ccc" }}>
        <Typography align="center" variant="h5">
          Backtest History
        </Typography>
        {loading ? (
          <MuiLinearProgressLoader />
        ) : (
          <Box sx={{ flexGrow: 1 }} m={4}>
            <Grid container spacing={2}>
              {backtestDates?.map((date) => {
                // const trimmedDate = date.timeframe.slice(0, 10);
                const trimmedDate = new Date(date.timeframe).toDateString();
                return (
                  <Grid
                    key={date.id}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    sx={{ cursor: "pointer" }}
                  >
                    <Item
                      sx={{
                        "&:hover": {
                          backgroundColor: "#ddd",
                        },
                      }}
                      onClick={() => handleTileClick(date.timeframe)}
                    >
                      {trimmedDate}
                    </Item>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
}
