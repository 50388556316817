import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Typography } from "@mui/material";

import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import Stack from "@mui/material/Stack";

const DashboardSelectModal = ({
  setOpenModal,
  userDashboards,
  setUserDashboards,
  userDashboardList,
  screenerId,
  setOpenDashboardSelectModal,
  setOpenCreateDashboardModal,
  handleSaveScreener,
}) => {
  // FORMAT: dashboardValue: { label: <DASHBOARDNAME>, id: DASHBOARD_ID }
  const [dashboardValue, setDashboardValue] = React.useState(
    userDashboardList[0]
  );

  const handleCreateNewDashboard = () => {
    setOpenModal(false);
    setOpenCreateDashboardModal(true);
  };

  return (
    <Box>
      <Typography variant="h5" mb={2}>
        {userDashboardList.length === 0 ? "Create" : "Select"} Dashboard
      </Typography>

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        value={dashboardValue}
        onChange={(event, newValue) => {
          setDashboardValue(newValue);
        }}
        options={userDashboardList}
        disabled={userDashboardList.length === 0}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              userDashboardList.length === 0
                ? "No Dashboards"
                : "Choose a Dashboard"
            }
          />
        )}
      />

      {/* DASHBOARD SAVE BUTTON */}
      <Stack direction="row" spacing={2} mt={2} >
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={userDashboardList.length === 0 || !dashboardValue}
          onClick={() => {
            handleSaveScreener(dashboardValue.id, screenerId).then(() =>
              setOpenModal(false)
            );
          }}
        >
          SAVE
        </Button>
          <span>OR</span>
        <Button
          disabled={dashboardValue}
          variant="contained"
          startIcon={<DashboardCustomizeIcon />}
          onClick={handleCreateNewDashboard}
        >
          CREATE NEW
        </Button>
      </Stack>
    </Box>
  );
};

export default DashboardSelectModal;
// check auto running savescan in screener
