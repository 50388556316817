// usePagination.jsx
/* 
  check this link for details:
  https://aaronshivers.com/pagination-with-react-hooks-and-material-ui
*/

import { useState } from "react";
import PropTypes from "prop-types";

const usePagination = (data = [], itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemCount = data?.length; // total no of rows or symbols

  // itemsPerPage is rowsPerPage, 
  const getCurrentData = () => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    // making new array of data
    const newData = [...data];

    return newData.slice(start, end);
  };

  // pageCount is total number of pages (buttons)
  const pageCount = Math.ceil(itemCount / itemsPerPage);

  return {
    currentPage,
    getCurrentData,
    setCurrentPage,
    pageCount,
  };
};

usePagination.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

export default usePagination;
