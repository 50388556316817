
// COMMON INTRADAY OPTIONS LIST
export const intraDayOptions = [
  { id: 1, value: "1-min", label: "1-min" },
  { id: 2, value: "5-min", label: "5-min" },
  { id: 3, value: "15-min", label: "15-min" },
  { id: 4, value: "30-min", label: "30-min" },
  { id: 5, value: "45-min", label: "45-min" },
  { id: 6, value: "60-min", label: "60-min" },
  { id: 7, value: "1-d", label: "1-day" },
];


// ALL OPTIONS SYMBOLS
export const allOptionSymbols = ['TATASTEEL', 'NATIONALUM', 'IGL', 'INDIGO', 'LUPIN', 'PVR', 'MGL', 'BALRAMCHIN', 'RAIN', 'HDFCAMC', 'NMDC', 'HAVELLS', 'IBULHSGFIN', 'ABCAPITAL', 'GODREJPROP', 'MUTHOOTFIN', 'TATAPOWER', 'METROPOLIS', 'CROMPTON', 'GAIL', 'MARICO', 'UBL', 'VOLTAS', 'DABUR', 'TVSMOTOR', 'IDFC', 'RAMCOCEM', 'GODREJCP', 'INDIAMART', 'BERGEPAINT', 'BHARATFORG', 'CUMMINSIND', 'TATACHEM', 'MRF', 'IEX', 'NAVINFLUOR', 'GLENMARK', 'IDEA', 'TATACOMM', 'JKCEMENT', 'ICICIPRULI', 'MPHASIS', 'DIXON', 'SYNGENE', 'IRCTC', 'WHIRLPOOL', 'ESCORTS', 'CHAMBLFERT', 'PAGEIND', 'LTTS', 'LAURUSLABS']