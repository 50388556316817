import React from "react";
import AllUsersTicketHistoryTable from "./AllUsersTicketHistoryTable";
import BackButton from "../../common/BackButton";


const UserSupportPanel = () => {
  
  return (
    <div style={{
      // margin: '2rem'
    }} >
      
      <BackButton />
      {/* users tickets table */}
      <AllUsersTicketHistoryTable title="Admin Support Panel" userType="admin" />
    </div>
  );
};

export default UserSupportPanel;

