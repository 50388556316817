import React from "react";
import PlayStore from "../../../assets/icons/googleplay.png";
import AppleStore from "../../../assets/icons/ios.png";
import YoutubeEnglish from "../../../assets/icons/youtubeenglish.png";
import YoutubeHindi from "../../../assets/icons/youtubehindi.png";

import styles from "./StepsBanner.module.css";

const STEPS_CONTENT = [
  {
    id: 1,
    title: "Steps 1",
    text: "Choose a good quality stock or create a watchlist in TNIBRO Web/App",
  },
  {
    id: 2,
    title: "Steps 2",
    text: "Set an alert for buying and selling on your selected stocks.",
  },
  {
    id: 3,
    title: "Steps 3",
    text: "Once the alert is triggered, you will a notification.",
  },
];

const ICONS = [
  { id: 1, img_source: PlayStore, alt_text: "Play Store Icon" },
  { id: 2, img_source: AppleStore, alt_text: "Apple Store Icon" },
  { id: 3, img_source: YoutubeEnglish, alt_text: " Youtube English Icon" },
  { id: 4, img_source: YoutubeHindi, alt_text: "Youtube Hindi Icon" },
];

const StepsBanner = () => {
  return (
    <>
      <h3 className={styles.upper_caption}>
        If you work on reducing the losses, you are actually maximizing your
        profits.
      </h3>
      <div className={styles.outer_container}>
        <h3 className={styles.main_title}>
          Start Profitable Trading | No Experience Required
        </h3>
        <h3 className={styles.subtitle}>3 Simple Steps</h3>
        <div className={styles.bottom_border}></div>

        <div className={styles.steps_container}>
          {STEPS_CONTENT.map((item) => {
            return (
              <div key={item.id} className={styles.step_container}>
                <h3 className={styles.step_title}>{item.title}</h3>

                <p className={styles.steps_content}>{item.text}</p>
              </div>
            );
          })}
        </div>

        {/* ICONS IMAGE CONTAINER */}
        <div className={styles.icons_container}>
          <img className={styles.icon_image} src={PlayStore} alt="" />
          <img className={styles.icon_image} src={AppleStore} alt="" />
          <img
            className={`${styles.icon_image} ${styles.youtube_icons}`}
            src={YoutubeEnglish}
            alt=""
          />
          <img
            className={`${styles.icon_image} ${styles.youtube_icons}`}
            src={YoutubeHindi}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default StepsBanner;
