// New razorpay functions made for testing zoho subscriptions on 27/02/2024

import { axiosConfig } from "../../axios/axiosConfig";
import { loadScript } from "./common";
import userDetailsLocalStorage from "../userDetailsLocalStorage";

const userName = userDetailsLocalStorage?.username;
const userEmail = userDetailsLocalStorage?.email;
const userPhone = userDetailsLocalStorage?.phoneno;

// CHECKOUT FUNCTION FOR RAZORPAY PAYMENT
// function loadScript(src) {
//   return new Promise((resolve) => {
//     const script = document.createElement("script");
//     script.src = src;
//     script.onload = () => {
//       resolve(true);
//     };
//     script.onerror = () => {
//       resolve(false);
//     };
//     document.body.appendChild(script);
//   });
// }

// RAZORPAY CHECKOUT AND PAYMENT ORDER TO BACKEND
export async function razorpayCheckout(USERID, AMOUNT, PAYMENT_OBJ) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  try {
    const res = await axiosConfig({
      method: "post",
      url: "/payment/payment_order",
      data: {
        userid: USERID,
        amount: AMOUNT,
        plan_type: PAYMENT_OBJ?.plan_code,
        paymentmode: "online",
        currency: "INR",
      },
    });

    console.log("paymentOrderData response: ", res);
    const paymentOrderData = res.data.response;
    console.log("payment order res.data: ", res.data);

    const transaction_id = res.data.user_payment_details.id;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      // currency: data.currency,
      currency: "INR",
      amount: paymentOrderData.amount.toString(), // amount in 
      order_id: paymentOrderData.id,
      name: "StockYaari",
      // callback_url: res.data.callback_url,
      callback_url: '/thanks-for-paying',
      description: "Thanks for paying",
      image: "",
      handler: function (response) {
        console.log("razorpay handler res: ", response);
        // const payment_id = response.razorpay_payment_id;

        const data = {
          userid: USERID,
          transaction_id: transaction_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          // customer_id: "",
          // payment_mode: "",
          // amount: "",
          // invoice_id: "child_invoice_id"
        };

        paymentVerify(data, PAYMENT_OBJ);
      },
      prefill: {
        name: userName,
        email: userEmail,
        phone_number: userPhone,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  } catch (error) {
    console.log(error);
  }
}

// PAYMENT VERIFICATION FUNCTION FOR RAZORPAY
// below function is being used above in line 57
async function paymentVerify(DATA, sub_object) {
  
  try {
    const res = await axiosConfig({
      method: "post",
      url: "/payment/payment_verify",
      data: { ...DATA, ...sub_object }
    });

    console.log("payment_verify res: ", res.data);
    // IMP: UNCOMMENT IT LATER, PAYMENT SUCCESS URL.
    // window.location.replace(res.data.callback_url);
    window.location.replace('/thanks-for-paying');
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
}
