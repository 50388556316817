import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function BasicButton({
  title,
  variant = "outlined",
  startIcon,
  callback,
  color = "primary",
  onKeyDown,
  disabled,
  
}) {
  const handleButtonValue = () => {
    callback(true);
  };

  return (
    <div
      style={
        {
          // marginLeft: "10px"
        }
      }
    >
      {/* <Stack spacing={2} direction="row"> */}
      {/* <Button type='button' variant="contained" onKeyDown={onKeyDown} onClick={handleButtonValue}>{title}</Button> */}
      <Button
        type="button"
        size="small"
        color={color}
        disabled={disabled}
        variant={variant}
        onClick={callback}
        startIcon={startIcon}
      >
        {title}
      </Button>
      {/* </Stack> */}
    </div>
  );
}
