import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 2
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 2
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


// columns / heading format
const columns = [
  { id: 1, label: "S.No.", align: "left" },
  { id: 2, label: "Symbol", align: "right" },
  { id: 3, label: "Date", align: "right" },
  { id: 4, label: "Trigger Price", align: "right" },
  // { id: 5, label: "High Price", align: "right" },
  // { id: 6, label: "% Change", align: "right" },
];

export default function SymbolTable({ symbolsData }) {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: 700 }}  >
      <Table sx={{ maxWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((col) => {
              return (
                <StyledTableCell key={col.id} align={col.align}>
                  {col.label}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {symbolsData?.map((row, index) => (
            <StyledTableRow key={row.symbol}>
              <StyledTableCell component="th" scope="row">
                {index + 1}
              </StyledTableCell>
              <StyledTableCell align="right">{row.symbol}</StyledTableCell>
              <StyledTableCell align="right">{new Date(row.timeframe).toDateString()}</StyledTableCell>
              <StyledTableCell align="right">{row.CMP}</StyledTableCell>
              {/* <StyledTableCell align="right">{row.latest_cmp}</StyledTableCell>
              <StyledTableCell align="right">
                <span style={{
                  color: row.pcnt >= 0 ? 'green' : 'red'
                }} >
                  {row.pcnt}
                </span>
              </StyledTableCell> */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
