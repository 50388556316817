import React from 'react';

import styles from './TopBanner.module.css';
import image1 from '../../../assets/no-background/1.png';
import image2 from '../../../assets/no-background/2.png';
import image3 from '../../../assets/no-background/3.png';
import image4 from '../../../assets/no-background/4.png';
import image5 from '../../../assets/no-background/1.png';
import image6 from '../../../assets/no-background/2.png';
import { useState } from 'react';
import { useEffect } from 'react';

import leftImage1 from '../../../assets/homebanners/btc.png';
import leftImage2 from '../../../assets/homebanners/ethusdt.png';
import leftImage3 from '../../../assets/homebanners/google.png';
import leftImage4 from '../../../assets/homebanners/nifty.png';
import leftImage5 from '../../../assets/homebanners/tataelexi.png';
import leftImage6 from '../../../assets/homebanners/tesla.png';

import rightImage1 from '../../../assets/people/p1.jpg';
import rightImage2 from '../../../assets/people/p4.jpg';
import rightImage3 from '../../../assets/people/p6.jpg';
import rightImage4 from '../../../assets/people/p7.jpg';
import rightImage5 from '../../../assets/people/p9.jpg';
import rightImage6 from '../../../assets/people/p4.jpg';
import IconBadges from '../IconBadges';

const leftImagesArray = [leftImage1, leftImage2, leftImage3, leftImage4, leftImage5, leftImage6 ]
const rightImagesArray = [rightImage1, rightImage2, rightImage3, rightImage4, rightImage5, rightImage6 ]
const imagesArray = [image1, image2, image3, image4, image5, image6]

const TopBanner = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const activeImageSourceLeft = leftImagesArray[imageIndex];
  const activeImageSourceRight = rightImagesArray[imageIndex];
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (imageIndex < leftImagesArray.length - 1 || imageIndex < rightImagesArray.length - 1) {
        setImageIndex(prevState => prevState + 1);
      } else {
        setImageIndex(0)
      }
      
    }, 2000)

    return () => clearInterval(interval);
  }, [imageIndex])

  return (
    <div className={styles.container}>
      {/* LEFT DIV */}
      <div className={styles.leftImageContainer}>
          <img className={styles.leftImage} src={activeImageSourceLeft} alt="" />
      </div>

      {/* MIDDLE CONTENT DIV */}
      <div className={styles.middleContentContainer}>
        <h2 className={styles.text_title} >TNIBRO - A Smart Tool to Check Buy / Sell Signals</h2>

        <ul className={styles.topBanner_List}  >
          <li>It's very simple to use.</li>
          <li>It's accurate than any other.</li>
          <li>It works on stocks, commodity, crypto, forex for trading.</li>
        </ul>

        <p>If you want to buy or sell any stock, commodity, crypto and forex, check with
          TNIBRO app for the signal that has been given.
        </p>

        <br />
        <p>But before you do, check the previous performance of our smart tool.</p>
        <br />
        
        <p className={styles.subtitle}>How to check part performance?</p>

        <p>Search any stock in the search bar and you will see previous buy / sell 
          signals with profit and loss summary.</p>

        <IconBadges justifyContent='flex-end' />  
      </div>

      {/* RIGHT DIV */}
      <div className={styles.rightImageContainer}>
        <img className={styles.rightImage} src={activeImageSourceRight} alt="" />
      </div>
    </div>
  )
}

export default TopBanner