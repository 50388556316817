import React from "react";
import { useState } from "react";

import styles from "./ImageTextCarousel.module.css";
import Slider from "react-slick";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import Image1 from "../../../assets/people/p1.jpg";
import Image2 from "../../../assets/people/p2.jpeg";
import Image3 from "../../../assets/people/p3.jpg";
import Image4 from "../../../assets/people/p4.jpg";
import Image5 from "../../../assets/people/p5.jpg";
import Image6 from "../../../assets/people/p6.jpg";
import Image7 from "../../../assets/people/p7.jpg";
import Image8 from "../../../assets/people/p8.jpg";
import Image9 from "../../../assets/people/p9.jpg";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/material";

const words = [
  { id: 0, imageSource: Image1, text: "Apple", value: "Apple" },
  { id: 1, imageSource: Image2, text: "Apple", value: "Banana" },
  { id: 2, imageSource: Image3, text: "Apple", value: "Grapes" },
  { id: 3, imageSource: Image4, text: "Apple", value: "fOnion" },
  { id: 4, imageSource: Image5, text: "Apple", value: "Potato" },
  { id: 5, imageSource: Image6, text: "Apple", value: "Tomato" },
  { id: 6, imageSource: Image7, text: "Apple", value: "Tomato" },
  { id: 7, imageSource: Image8, text: "Apple", value: "Tomato" },
  { id: 8, imageSource: Image9, text: "Apple", value: "Tomato" },
  { id: 9, imageSource: Image6, text: "Apple", value: "Tomato" },
];

const ImageTextCarousel = () => {
  const [wordData, setWordData] = useState(words[0].value);
  const [translateWidth, setTranslateWidth] = useState(0); // width in px

  const handleClick = (index) => {
    setWordData(words[index].value);
  };

  // default is 0px

  const handleNextClick = (array_index) => {
    // 0 - 0 x 200, 1 - 1 x 200
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    // autoplaySpeed: 2000,
    // centerPadding: "60px",
    nextArrow: <ArrowForwardIosIcon sx={{ color: "#000" }} />,
    prevArrow: <ArrowBackIosIcon sx={{ color: "#000" }} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box>
      {/* carousel item */}

      <h2 className={styles.title}>
        It is so easy to trade with the help of TNIBRO, anyone can earn money.
        ITS FREE.
      </h2>
      <div className={styles.carouselContainer}>
        <Slider {...settings}>
          {words.map((item, index) => {
            return (
              <div className={styles.carouselItem}>
                <div className={styles.imageContainer}>
                  <img className={styles.image} src={item.imageSource} alt="" />

                  {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id
                  maiores voluptatum odio incidunt non consequatur tempora
                  ducimus aut necessitatibus itaque nisi, quod perspiciatis.
                </p> */}
                </div>
              </div>
            );
          })}
        </Slider>

        <div className={styles.subtitleText}>
          Thousands of traders who lost their hard earn money in trading are now
          booking profits everyday.
        </div>

        <div className={styles.sliderCaption}>
          <span className={styles.textStrikethrough}>
            chart reading, technical analysis
          </span>
          &nbsp;NOT REQUIRED
        </div>
      </div>
    </Box>
  );
};

export default ImageTextCarousel;
