import React from 'react'

const AdvisorDashboard = () => {
  return (
    <div>
        <h2>Advisor Dashboard</h2>

        <p>Click on Advisory Form or User Support</p>
    </div>
  )
}

export default AdvisorDashboard