export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const UPDATE_EMAIL_VERIFICATION = "UPDATE_EMAIL_VERIFICATION";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const STORE_USER_DETAILS = "STORE_USER_DETAILS";

export const SET_SELECTED_STOCK_CATEGORY = "SET_SELECTED_STOCK_CATEGORY";


// GLOBAL SEARCH
export const SET_SEARCH_DATE = "SET_SEARCH_DATE";
export const SET_SEARCH_INPUT = "SET_SEARCH_INPUT";
export const SET_SEARCH_SYMBOL = "SET_SEARCH_SYMBOL";
export const SET_SEARCH_SEGMENT = "SET_SEARCH_SEGMENT";


// PAGINATION
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";   // NUMBER OF SYMBOLS / STOCKS LIST
export const SET_TOTAL_STOCKS = "SET_TOTAL_STOCKS";
