import React from "react";

import styles from "./BannerImageTable.module.css";
import toast from "react-hot-toast";
import { axiosConfig } from "../../../../../axios/axiosConfig";
import { useMutation } from "@tanstack/react-query";

const BannerImageTable = ({ bannerImagesList }) => {
  // POST TINYMCE TEXT TO BACKEND [CREATE]
  const deleteBannerMutation = useMutation({
    mutationFn: async (banner_id) => {
      const res = await axiosConfig({
        method: "delete",
        url: "/home/banner",
        data: {
          id: banner_id,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      //   setPopupContentList((prevState) => {
      //     return [...prevState, data.response];
      //   });
      //   setOpen(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const handleDeleteBanner = (BannerID) => {
    const consent = window.confirm("Are you sure?");

    if (!consent) return;

    // If consent is true, send tinymce text
    deleteBannerMutation.mutate(BannerID);
  };

  return (
    <div className={styles.mainContainer}>
      <h2>BannerImageTable</h2>

      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>#</th>
            <th>Image Link</th>
            <th>URL</th>
            <th>Preview</th>
            {/* <th>Preview</th> */}
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {bannerImagesList?.map((item, index) => {
            const imgPreview = item?.image ? item?.image : item?.redirect_url;

            return (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item?.image}</td>
                <td>{item?.redirect_url}</td>

                {/* IMAGE PREVIEW */}
                <td>
                  <img
                    src={imgPreview}
                    alt="Banner Preview"
                    style={{ width: "200px", height: "125px" }}
                  />
                </td>

                <td>
                  <div className={styles.deleteBtnContainer}>
                    <button
                      className={styles.deleteBtn}
                      onClick={() => handleDeleteBanner(item?.id)}
                    >
                      delete
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BannerImageTable;
