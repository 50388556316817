import { useMutation } from "@tanstack/react-query";
import React, { useRef, useState } from "react";

import { axiosConfig } from "../../../../axios/axiosConfig";

import styles from './RaiseTicketModal.module.css';

const RaiseTicketModal = ({ setOpen, handleAddTicketItem }) => {
  
  // const [lastName, setLastName] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [emailInput, setEmailInput] = useState("");
  // const [subject, setSubject] = useState("");
  // const descRefValue = descRef.current?.value;
  // const [userQueryDetails, setUserQueryDetails] = useState({
    //   lastName: "",
    //   firstName: "",
    //   emailInput: "",
    //   subject: "",
    // });
    // const { lastName, firstName, emailInput, subject } = userQueryDetails;
    const descRef = useRef("");
  const [title, setTitle] = useState("");
  const [fileInput, setFileInput] = useState();
  

  // const userQueryInputOnChange = (event) => {
  //   setUserQueryDetails((prevState) => {
  //     return {
  //       ...prevState,
  //       [event.target.name]: event.target.value,
  //     };
  //   });
  // };

  // raise ticket
  const raiseTicketMutation = useMutation({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    mutationFn: () => raiseUserTicket(descRef, title, fileInput),
    onSuccess: (data) => {
      
      handleAddTicketItem(data?.response[0]);
      setOpen(false);
    },
    onError: (error) => {
      console.log("raise ticket error: ", error);
    },
  });

  const handleSubmitTicket = () => {
    raiseTicketMutation.mutate();
  };

  return (
    <div className={styles.userSupportBox}>
      <h2>Raise Ticket</h2>

      <div className={styles.titleInputBox}>
        <p htmlFor="title" className={styles.subjectLabel}>
          Title
        </p>
        <input
          className={styles.titleInput}
          type="text"
          name="title"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      {/* TEXT AREA, DESCRIPTION */}
      <div className={styles.descriptionInputBox}>
        <p className={styles.descriptionLabel}>Description</p>
        {/* <label htmlFor="" className="subjectLabel">Subject: </label> */}
        <textarea className={styles.descInput} name="" id="" cols="30" rows="5" ref={descRef}></textarea>
      </div>

      {/* FILE UPLOAD */}
      <div className={styles.fileInputBox}>
        <p htmlFor="fileInput" className={styles.subjectLabel}>
          File Upload (if any)
        </p>
        <input
          className={styles.fileInput}
          type="file"
          name="fileInput"
          id="fileInput"
          // value={fileInput}
          onChange={(e) => setFileInput(e.target.files[0])}
        />
      </div>

      <div className={styles.submitButtonContainer}>
        <button disabled={!title} className={styles.submitButton} onClick={handleSubmitTicket}>
          submit
        </button>
      </div>
    </div>
  );
};

export default RaiseTicketModal;

// UTILITY FUNCTIONS, userQueryDetails, descRefValue, USERID, userContactID
const raiseUserTicket = async (desc_ref, title, file_input) => {
  const descRefValue = desc_ref.current?.value;

  const formData = new FormData();
  formData.append('title', title);
  formData.append('description', descRefValue);
  formData.append('priority', "Low");
  formData.append('status', "Open");
  formData.append('file', file_input);

  const res = await axiosConfig({
    method: "post",
    url: "/support/raise_request",
    data: formData,
  });

  return res.data;
};
