import React, { useState } from "react";
import SignInPage from "./SignIn";
import SignUpPage from "./SignUpPage";

import toast from "react-hot-toast";
import ReactHotToaster from "../../components/common/ReactHotToaster";

import axiosConfig from "../../axios/axiosConfig";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import HomePage from "../../pages/HomePage";
import HomePageLayout from "../../Layout/HomePageLayout";

const LoginScreen = () => {
  const userState = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const [displaySignUpPage, setDisplaySignUpPage] = useState(false);

  const [userFullName, setUserFullName] = useState("");
  // const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [reUserPassword, setReUserPassword] = useState("");

  const [mobileOTP, setMobileOTP] = useState("");
  const [sessionID, setSessionID] = useState("");

  const [userDetails, setUserDetails] = useState({
    phone: "91",
    country: "India",
    countryCode: "in",
  });

  const routeToHome = () => {
    // navigate('/home');

    if (location.pathname === "/login" || location.pathname === "/register") {
      window.location.assign("/");
    } else {
      window.location.reload();
    }
  };

  // onchange handler for phone, country
  const phoneInputOnChange = (value, data, event, formattedValue) => {
    // console.log({value, data, event, formattedValue })

    setUserDetails((prev) => {
      return {
        ...prev,
        phone: value,
        country: data.name,
        countryCode: data.countryCode,
      };
    });
  };

  // SETTING TOKEN AND USER DETAILS IN LOCAL STORAGE
  const setLocalStorage = (responseData) => {
    if (responseData.access_token) {
      const allUserDetails = {
        ...responseData,
        userDetails: responseData.response,
      };
      localStorage.setItem("user", JSON.stringify(allUserDetails));

      // window.location.reload();
      // window.location.assign("/");
      routeToHome();
    }
  };

  // GET OTP and LOGIN for Indian users ONLY, send all details (phone, email and password) to avoid erros
  // difference function in 'SignIn' for international users (redux dispatch)
  const getOTPLogin = async () => {
    try {
      const res = await axiosConfig({
        method: "post",
        url: "/enroll/login",
        data: {
          // phoneno: parseInt(userDetails.phone.split('91')[1]),
          phoneno: parseInt(userDetails.phone),
          // email: userEmail,
          password: userPassword,
        },
      });

      // If successful
      if (res.status === 200 || res.statusText === "OK") {
        setSessionID(res.data.Details);
        const TOAST_MSG =
          "OTP sent to mobile, please type OTP and click Verify OTP";
        toast.success(TOAST_MSG, {
          duration: 6000,
          position: "top-center",
        });
      }
    } catch (error) {
      console.log(error.response);
      const ERROR_MSG = error.response.data.response;
      toast.error(ERROR_MSG || "Something went wrong", {
        duration: 6000,
        position: "top-center",
      });

      if (ERROR_MSG.toLowerCase() === "user is deactivated") {
        navigate('/activate-account');
      }
    }
  };

  // Verify OTP and Login (FOR INDIAN USERS ONLY)
  const verifyOTPLogin = async () => {
    try {
      const res = await axiosConfig({
        method: "post",
        url: "/enroll/verifyotp",
        data: {
          session_id: sessionID,
          otp: parseInt(mobileOTP),
          phoneno: parseInt(userDetails.phone),
          country: userDetails?.country
        },
      });

      if (res.status === 200 || res.statusText === "OK") {
        // Indian User Authorized
        setLocalStorage(res.data);
        toast.success("OTP verified successfully!", {
          duration: 6000,
          position: "top-center",
        });
        
      }
    } catch (error) {
      console.log("error: ", error);
      const ERROR_MSG = error.response.data.response;
      toast.error(ERROR_MSG || "Something went wrong", {
        duration: 6000,
        position: "top-center",
      });
    }
  };

  // If user is already logged in
  if (userState?.isLoggedIn) {

    return (
      <>
      <h1>You are logged In</h1>
      {/* <button onClick={() => navigate(-1)}>go back</button> */}
      <button onClick={() => navigate('/home')}>home</button>
      </>
    )
  }

  

  if (displaySignUpPage) {
    return (
      <>
        <SignUpPage
          // displaySignUpPage={() => setDisplaySignUpPage(false)}
          setDisplaySignUpPage={setDisplaySignUpPage}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          phoneInputOnChange={phoneInputOnChange}
          routeToHome={routeToHome}
        />
      </>
    );
  }

  return (
    <>
      <SignInPage
        // displaySignUpPage={() => setDisplaySignUpPage(true)}
        setDisplaySignUpPage={setDisplaySignUpPage}
        getOTPLogin={getOTPLogin}
        verifyOTPLogin={verifyOTPLogin}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        userPassword={userPassword}
        setUserPassword={setUserPassword}
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        mobileOTP={mobileOTP}
        setMobileOTP={setMobileOTP}
        phoneInputOnChange={phoneInputOnChange}
        routeToHome={routeToHome}
      />

      {/* React Hot Toast */}
      <ReactHotToaster />
    </>
  );
};

export default LoginScreen;
