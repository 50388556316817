import React, { useState } from 'react';
import UserPaymentsDashboardTable from './UserPaymentsDashboardTable';
import { useQuery } from '@tanstack/react-query';

import { axiosConfig } from '../../../axios/axiosConfig';

const styles = {
  pageContainer: {
    margin: '2rem'
  },
  pageTitle: {
    marginBottom: '2rem'
  }
}

const PaymentsDashboard = () => {
  const [paidUsersData, setPaidUsersData] = useState();

  // FETCH USING useQUERY
const fetchPaidUsersQuery = useQuery({
  queryKey: ["paid-user-payment-details"], // dependency
  queryFn: () => fetchPaidUserDetails(),
  onSuccess: (data) => {
    // setUserPaymentDetails(data.response);
  },
  onError: (error) => {
    console.log("user payments error: ", error);
  },
});

  return (
    <div style={styles.pageContainer} >
      <h1 style={styles.pageTitle} >Payments Dashboard</h1>

      {/* USER PAYMENTS DASHBOARD TABLE */}
      <UserPaymentsDashboardTable />
    </div>
  )
}

export default PaymentsDashboard;


// FETCHER FUNCTION SAMPLE
export const fetchPaidUserDetails = async () => {
  // if (!watchlistId) return;
  return;

  const res = await axiosConfig({
    method: "get",
    url: ``,
    data: {},
  });

  return res.data;
};