import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import styles from "./UserSubscriptionDetails.module.css";
import { useMutation } from "@tanstack/react-query";
import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";
import ReactHotToaster from "../../../common/ReactHotToaster";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UserSubscriptionDetails = ({
  handleRenewPlan,
  userSubscriptionDetails,
  selectedPlan,
  setSelectedPlan,
  planPeriod,
  setPlanPeriod,
}) => {
  const {
    plan_type,
    add_on_list,
    remaining_days,
    subscription_alerts_left,
    topup_alerts_left,
    expiry_date,
    subscription_date,
    active: isSubscriptionActive,
    next_billing_date,
    subscription_consume_alert
  } = userSubscriptionDetails;
  const formattedDate = new Date(subscription_date).toDateString();
  const formattedNextBillDate = new Date(next_billing_date).toDateString();
  const formattedExpiryDate = new Date(expiry_date).toDateString();

  console.log("userSubscriptionDetails: ", userSubscriptionDetails["active"])

  // IMP: The below two lines of code is checking if the latest bill has generated and if payment has been made.
  const isTodayValid = isTodayGreaterThanOrEqualToInvoiceDate(next_billing_date)
  const isCurrentBillPaid = userSubscriptionDetails?.payment_id?.payment_status && isTodayValid

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  // Below are static plan details.
  const {
    id,
    name,
    plan_code,
    description,
    plan_description,
    price,
    interval,
    interval_unit,
    alert: totalAlertCount,
    
  } = userSubscriptionDetails?.plan_id;

  
  const activePlanDetails = {
    name: name,
    price: price,
    id: id,
    plan_code: plan_code,
  }

  const cancelSubscription = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "/subscription/cancel_subscription",
        data: {
          userid: USERID,
          immediate: false,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      // console.log(data)
      toast.success(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const cancelSubscriptionHandler = (event) => {
    const consent = window.confirm(
      "Are you sure you want to cancel the subscription?"
    );
    if (!consent) return;

    cancelSubscription.mutate();
  };

  // change plan, route to pricing plans
  const routeToPricingPlans = (upgradeType) => {
    navigate(`/pricing?planType=${upgradeType}`);
  };

  // routing to cart directly, when user wants to renew the same plan
  const routeToCart = (upgradeType) => {
    const state = { planType: upgradeType, selectedPlan: activePlanDetails };
    navigate("/cart", { state });
  };

  return (
    <div>
      {/* <Box sx={{}}>
        <Stack direction="row" spacing={2}>
          <Typography>Plan Type:</Typography>
          <Typography>{plan_type}</Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Typography>Add Ons:</Typography>
          {add_on_list?.map((item, index) => {
            return <span>{item}</span>;
          })}
        </Stack>

        <Stack direction="row" spacing={2}>
          <Typography>Subscribed / Renewed On:</Typography>
          <Typography>{formattedDate}</Typography>
        </Stack>
      </Box> */}

      <div className="paymentWarning">
        <p>Your current subscription has ended. Please renew subscription and make payment to continue using our services.</p>
        
      </div>

      {/* USER SUBSCRIPTION DETAILS */}
      {userSubscriptionDetails?.id ? (
        <div className={styles.userSubscriptionDetailsContainer}>
          <h2>Subscription Details</h2>

          <div className={styles.detailsList}>
            <DetailItem label="Plan Name" value={name} />
            <DetailItem label="Description" value={description} />
            <DetailItem label="Price" value={`${price}/-`} />
            <DetailItem label="Subscribed On" value={formattedDate} />
            <DetailItem label="Next Bill Date" value={formattedNextBillDate} />
            <DetailItem label="Expiry Date" value={formattedExpiryDate} />
            <DetailItem label="Days Remaining" value={remaining_days} highlightRed={true} />
            <DetailItem label="Payment" value={!userSubscriptionDetails?.payment_id ? "not paid" : `paid ${userSubscriptionDetails?.payment_id?.amount}`} highlightRed={true} />
            <DetailItem
              label="Plan Period"
              value={`${interval} ${interval_unit}`}
            />
            <DetailItem label="(Consumed / Total) Alerts" value={`${subscription_consume_alert} / ${totalAlertCount}`} />
            <DetailItem
              label="Alerts Left"
              value={`${subscription_alerts_left}`}
            />
          </div>

          <div className={styles.btnContainer}>
            <button
              className={`${styles.btn} ${styles.renewBtn}`}
              onClick={() => routeToCart("renew")}
            >
              make payment / renew
            </button>

            <button
              className={`${styles.btn} ${styles.changePlanBtn}`}
              onClick={() => routeToPricingPlans("update")}
            >
              Change Plan
            </button>

            <button
              className={`${styles.btn} ${styles.cancelBtn}`}
              onClick={cancelSubscriptionHandler}
            >
              Cancel subscription
            </button>
          </div>
        </div>
      ) : null}

      {/* ACTIVE ALERT DETAILS */}

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default UserSubscriptionDetails;

function DetailItem({ label, value, highlightRed = false }) {
  return (
    <div className={`${styles.detailItem} ${highlightRed ? styles.highlightRedBg : ""}`}>
      <h3>{label}</h3>
      <p>{value}</p>
    </div>
  );
}

// function to check if today's date is greater than or equal to invoice date.
function isTodayGreaterThanOrEqualToInvoiceDate(invoiceDate) {
  // Create a Date object for the invoice date
  const invoice = new Date(invoiceDate);

  // Get today's date without time (set hours, minutes, etc. to 0)
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Compare the timestamps (milliseconds since epoch)
  return today.getTime() >= invoice.getTime();
}