import { Container } from '@mui/material'
import React from 'react'

import styles from '../terms-conditions/TermsAndConditions.module.css';

const PrivacyPolicy = () => {
  // const styles = {
  //   headMargin: {
  //     marginTop: '1em'
  //   }
  // }

  return (
    <Container maxWidth="lg">
      <div className={styles.PrivacyPolicy}>
      <h1>Privacy Policy for TNIBRO Technologies LLP</h1>

      <p>At TNI BRO, accessible from www.tnibro.com, one of our main priorities is the privacy of our visitors.
This Privacy Policy document contains types of information that is collected and recorded by TNI
BRO and how we use it.</p>

      <p>If you have additional questions or require more information about our Privacy Policy, do not
hesitate to contact us.</p>

      <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website about
the information that they shared and/or collect in TNI BRO. This policy is not applicable to any
information collected offline or via channels other than this website.</p>

      {/* <h2 style={styles.headMargin} >Consent</h2> */}
      <h2 >Consent</h2>

      <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.
Information we collect.</p>

      <p>The personal information that you are asked to provide, and the reasons why you are asked to
provide it, will be made clear to you at the point we ask you to provide your personal information.

</p>

      <p>If you contact us directly, we may receive additional information about you such as your name, email
address, phone number, the contents of the message and/or attachments you may send us, and any
other information you may choose to provide.</p>

      <p>When you register for an Account, we may ask for your contact information, including items such as
name, company name, address, email address, and telephone number.</p>

      <h2 style={styles.headMargin}>How we use your information</h2>

      <p style={{ lineHeight: '2em'}}>
      We use the information we collect in various ways, including:
      <ul style={{ listStyle: "none"}}>
        <li style={{textIndent: "20px", lineHeight: '2em' }} >Provide, operate, and maintain our website.</li>
        <li style={{textIndent: "20px", lineHeight: '2em' }} >Improve, personalize, and expand our website.</li>
        <li style={{textIndent: "20px", lineHeight: '2em' }} >Understand and analyse how you use our website.</li>
        <li style={{textIndent: "20px", lineHeight: '2em' }} >Develop new products, services, features, and functionality.</li>
        <li style={{textIndent: "20px", lineHeight: '2em' }} >Communicate with you, either directly or through one of our partners, including for customer
          service, to provide you with updates and other information relating to the website, and for
          marketing and promotional purposes.</li>
        <li style={{textIndent: "20px", lineHeight: '2em' }} >Send you emails.</li>
        <li style={{textIndent: "20px", lineHeight: '2em' }} >Find and prevent fraud.</li>
      </ul>
          
      </p>

      <h2 style={styles.headMargin}>Log Files</h2>
      <p>TNI BRO follows a standard procedure of using log files. These files log visitors when they visit
      websites. All hosting companies do this and a part of hosting services&#39; analytics. The information
collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider
(ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not
linked to any information that is personally identifiable. The purpose of the information is for

analysing trends, administer the site, track users&#39; movement on the website, and gather
demographic information.</p>

<h2>Cookies and Web Beacons</h2>
<p>Like any other website, TNI BRO uses &quot;cookies&quot;. These cookies are used to store information
including visitors&#39; preferences, and the pages on the website that the visitor accessed or visited. The
information is used to optimize the users&#39; experience by customizing our web page content based on
visitor’s browser type and/or other information.</p>


<h2 style={styles.headMargin}>Advertising Partners Privacy Policies</h2>
<p>You may consult this list to find the Privacy Policy for each of the advertising partners of TNI BRO.</p>
<p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons
that are used in their respective advertisements and links that appear on TNI BRO, which are sent
directly to users&#39; browser. They automatically receive your IP address when this occurs. These
technologies are used to measure the effectiveness of their advertising campaigns and/or to
personalize the advertising content that you see on websites that you visit.</p>

<p>Note that TNI BRO has no access to or control over these cookies that are used by third-party
advertisers.</p>

<h2 style={styles.headMargin}>Third-Party Privacy Policies</h2>
<p>TNI BRO&#39;s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you
to consult the respective Privacy Policies of these third-party ad servers for more detailed
information. It may include their practices and instructions about how to opt out of certain options.</p>

<p>You can choose to disable cookies through your individual browser options. To know more detailed
information about cookie management with specific web browsers, it can be found at the browsers&#39;
respective websites.</p>

<ul style={{paddingLeft: "3em"}} >
  <li>CCPA Privacy Rights (Do Not Sell My Personal Information)</li>
  <li>Under the CCPA, among other rights, California consumers have the right to:</li>
  <li>Request that a business that collects a consumer&#39;s personal data disclose the categories and
specific pieces of personal data that a business has collected about consumers.</li>
  <li>Request that a business delete any personal data about the consumer that a business has
collected.</li>
  <li>Request that a business that sells a consumer&#39;s personal data, not sell the consumer&#39;s
personal data.</li>
  <li>If you make a request, we have one month to respond to you. If you would like to exercise
any of these rights, please contact us.</li>
  
</ul>


<h2 style={styles.headMargin}>GDPR Data Protection Rights</h2>
<p>We would like to make sure you are fully aware of all of your data protection rights. Every user is
entitled to the following:</p>

<p>The right to access – You have the right to request copies of your personal data. We may charge you
a small fee for this service.</p>

<p>The right to rectification – You has the right to request that we correct any information you believe
is inaccurate. You also have the right to request that we complete the information you believe is
incomplete.</p>

<p>The right to erasure – You have the right to request that we erase your personal data, under certain
conditions.</p>

<p>The right to restrict processing – You have the right to request that we restrict the processing of your
personal data, under certain conditions.</p>

<p>The right to object to processing – You have the right to object to our processing of your personal
data, under certain conditions.</p>

<p>The right to data portability – You have the right to request that we transfer the data that we have
collected to another organization, or directly to you, under certain conditions</p>

<p>If you make a request, we have one month to respond to you. If you would like to exercise any of
these rights, please contact us.</p>

<h2 style={styles.headMargin}>Children&#39;s Information</h2>
<p>Another part of our priority is adding protection for children while using the internet. We encourage
parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

<p>TNI BRO does not knowingly collect any Personal Identifiable Information from children under the
age of 13. If you think that your child provided this kind of information on our website, we strongly
encourage you to contact us immediately and we will do our best efforts to promptly remove such
information from our records.</p>

<h2 style={styles.headMargin}>Changes to This Privacy Policy</h2>
<p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page
periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on
this page. These changes are effective immediately after they are posted on this page.</p>

<p>Our Privacy Policy was created with the help of the Privacy Policy Generator.</p>


<h2 style={styles.headMargin}>Contact Us</h2>
<p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
</p>

</div>
</Container>
  )
}

export default PrivacyPolicy