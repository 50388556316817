import React from "react";

import styles from "./UserPaymentsDashboardTable.module.css";
import { Link } from "react-router-dom";

const columnHeaders = [
  { col_id: "id", name: "ID", col_width: 30 },
  { col_id: "name", name: "Name", col_width: 100 },
  { col_id: "mobile", name: "Mobile", col_width: 100 },
  { col_id: "email", name: "Email", col_width: 150 },
  { col_id: "subscription", name: "Subscription", col_width: 50 },
  { col_id: "userID", name: "User Details" },
];

const tableRowData = [
  {
    id: 1,
    name: "John Wick",
    mobile: "1234567890",
    email: "example@email.com",
    subscription: "free",
    userID: "21",
  },
  {
    id: 2,
    name: "Neo Anderson",
    mobile: "1234567890",
    email: "example@email.com",
    subscription: "premium",
    userID: "22",
  },
  {
    id: 3,
    name: "Jane Doe",
    mobile: "1234567890",
    email: "example@email.com",
    subscription: "basic",
    userID: "23",
  },
  {
    id: 4,
    name: "Samantha P.",
    mobile: "1234567890",
    email: "example@email.com",
    subscription: "premium",
    userID: "24",
  },
];

const UserPaymentsDashboardTable = () => {
  return (
    <div>
      <h2 className={styles.tableTitle} >List of premium users</h2>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              {columnHeaders.map((column) => (
                <th
                  key={column.col_id}
                  style={{
                    width: column?.col_width,
                  }}
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableRowData.map((row) => (
              <tr key={row.id}>
                {columnHeaders.map((column) => {
                  const dataItem =
                    column.col_id === "created_at"
                      ? new Date(row[column.col_id]).toDateString()
                      : row[column.col_id];

                  if (column.col_id === "userID") {
                    return <td>
                      <Link to={`user/${dataItem}`} >
                        view 
                      </Link>
                    </td>;
                  }

                  return <td key={column.col_id}>{dataItem}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserPaymentsDashboardTable;
