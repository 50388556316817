import React from 'react';
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const ReferralTable = ({ userReferralData }) => {

  const tableCellStyles = {
    padding: "4px 6px",
    // border: '1px solid'
  };
  return (
    <TableContainer
        sx={{ maxWidth: 1400 }}
        component={Paper}
                >
        <Table size="small" aria-label="a dense table">
          <TableHead sx={{ backgroundColor: "#e2e2e2" }}>
            <TableRow>
              <TableCell sx={{ ...tableCellStyles, fontWeight: "bold" }}>
                NAME
              </TableCell>
              <TableCell sx={{ ...tableCellStyles, fontWeight: "bold" }}>
                REFERRAL CODE
              </TableCell>
              <TableCell sx={{ ...tableCellStyles, fontWeight: "bold" }}>
              REFERRAL COMPLETED
              </TableCell>

              <TableCell
                sx={{ ...tableCellStyles, fontWeight: "bold" }}
                align="center"
              >
                USER TYPE
                <Stack direction="row" spacing={0.5}>
                  
                </Stack>
              </TableCell>

              <TableCell
                sx={{ ...tableCellStyles, fontWeight: "bold" }}
                align="center"
              >
                JOINING DATE
                
              </TableCell>

              <TableCell sx={{ fontWeight: "bold" }}>
                SUBSCRIPTION DATE
              </TableCell>

              <TableCell sx={{ fontWeight: "bold" }}>
                PLAN TYPE
              </TableCell>

              <TableCell sx={{ fontWeight: "bold" }}>
                ADD ONs
              </TableCell>

              <TableCell
                sx={{ ...tableCellStyles, fontWeight: "bold" }}
                align="left"
              >
                EMAIL
              </TableCell>

              <TableCell
                sx={{ ...tableCellStyles, fontWeight: "bold" }}
                align="left"
              >
                PHONE
              </TableCell>
              <TableCell
                sx={{ ...tableCellStyles, fontWeight: "bold" }}
                align="center"
              >
                COUNTRY
                
              </TableCell>
              
            </TableRow>
          </TableHead>

          {/* TABLE BODY STARTS HERE */}
          <TableBody sx={{ backgroundColor: "white" }}>
            {userReferralData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:nth-of-type(odd)": {
                    backgroundColor: "#f9f9f9",
                  },
                }}
              >
                <TableCell sx={tableCellStyles} component="th" scope="row">
                  {row.username}
                </TableCell>
                <TableCell sx={tableCellStyles} component="th" scope="row">
                  {row.referralcode}
                </TableCell>
                <TableCell sx={tableCellStyles} component="th" scope="row" align="center">
                  {row.referral_completed}
                </TableCell>

                <TableCell sx={tableCellStyles} align="center">
                  {row.user_type}
                </TableCell>
                <TableCell sx={tableCellStyles} align="center">
                  {/* {row.date_joined.slice(0, 10)} */}
                  {new Date(row.date_joined).toDateString()}
                </TableCell>

                <TableCell sx={tableCellStyles} align="center"  >
                  {row?.user_subscription ? (
                    <span>{new Date(row.user_subscription?.subscription_date).toDateString()}</span>
                  ) : "n/a"}
                  {/* {new Date(row.user_subscription?.subscription_date).toDateString()} */}
                </TableCell>

                <TableCell sx={tableCellStyles}>
                  {row?.user_subscription ? (
                    <span>{row.user_subscription?.plan_type}</span>
                  ) : "n/a"}
                </TableCell>
                
                <TableCell sx={tableCellStyles}>
                  {row?.user_subscription ? (
                    // <span>{row.user_subscription?.add_on}</span>
                    <span>{row.user_subscription?.add_on.slice(1, -1).replaceAll("'", "").toUpperCase()}</span>
                  ) : "n/a"}
                  
                </TableCell>

                <TableCell sx={tableCellStyles} align="left">
                  {row.email}
                </TableCell>
                <TableCell sx={tableCellStyles} align="left">
                  {row.phoneno}
                </TableCell>
                <TableCell sx={tableCellStyles} align="center">
                  {row.country}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* ERROR MSG IF EMPTY */}
        {userReferralData?.length === 0 ? (
              <Typography variant="h4" sx={{
                p: 2,
                textAlign: 'center',
                width: '100%'
              }} >NO DATA AVAILABLE</Typography>
            ) : null}
      </TableContainer>
  )
}

export default ReferralTable