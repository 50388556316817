import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Box,
  Typography,
  Stack
} from "@mui/material";
import toast from "react-hot-toast";
import { setWatchlistAlert } from "../watchlist_utils";
import { useSelector } from "react-redux";
import { intraDayOptions } from "../../../data/selectOptionsList";
import { sendToken } from "../../../utils/notifications";
// import ReactHotToaster from "../../common/ReactHotToaster";


const AddWatchlistAlertModal = ({ watchlistDetails, setUserWatchlists, setUserWatchlistDetails, setOpenAddWatchlistAlertModal }) => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  
  const [alertType, setAlertType] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [selectedInterval, setSelectedInterval] = useState({id: "", label: "", value: "" });
  const [intervalInput, setIntervalInput] = useState("");
  
  // ADD OR REMOVE BUY/SELL WATCHLIST ALERT
  // IMP: THIS IS A COMMON FUNCTION THAT CAN CREATE OR REMOVE BOTH BUY AND SELL ALERT
  const handleWatchlistAlert = async (requestType) => {
    // below code moved to 'sendToken'.
    // const permission = await Notification.requestPermission();

    // if (permission !== "granted") {
    //   alert("Permission Denied. Please unblock push notifications from the browser!");
    //   return;
    // }

    if (requestType === "add") {
      try {
        const token_res = await sendToken(userDetails.id);
        
        if (!token_res) {
          // alert message already provided in 'sendToken'.
          return;
        };
      } catch (error) {
        console.log(error);
        return;
      }
    }

    const res = await setWatchlistAlert(requestType, watchlistDetails.id, alertType, selectedInterval?.value, userDetails.id);
    
    if (res.status === 200 || res.statusText === "OK") {
      // if setUserWatchlists is not undefined.
      if (setUserWatchlists) {
        setUserWatchlists(prevState => {
          return prevState.map(item => {
            if (item.id === watchlistDetails.id) {
              return res.data.watchlist_data;
            }
            return item;
          })
        })
      }      

      // if setUserWatchlistDetails is not undefined (coming from single watchlist page)
      if (setUserWatchlistDetails) {
        setUserWatchlistDetails(res.data.watchlist_data)
      }
      
      // Toast Notifications according to requestType (Add or Remove)
      if (requestType === "add") {
        toast.success(
          `${alertType.toUpperCase()} for ${selectedInterval?.value} interval created successfully for ${watchlistDetails.name}!`);
      } else if (requestType === "remove") {
        toast.success(
          `${alertType.toUpperCase()} for ${watchlistDetails.name} removed successfully!`);
      }
      
    } else {
      toast.error(res.response.data.response, { position: "top-right", hideAfter: 5 });
    }

    setOpenAddWatchlistAlertModal(false);
  };

  return (
    <Box>
      <Typography variant="h5">Watchlist Alert for {watchlistDetails.name}</Typography>

      <Autocomplete
        size="small"
        value={alertType}
        onChange={(event, newValue) => {
          setAlertType(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="watchlist-alert"
        options={["Buy Alert", "Sell Alert"]}
        sx={{ width: 200, mt: 2, mb: 3 }}
        renderInput={(params) => (
          <TextField {...params} label="Select Alert Type" />
        )}
      />

      <Autocomplete
        size="small"
        value={selectedInterval}
        onChange={(event, newValue) => {
          setSelectedInterval(newValue);
        }}
        inputValue={intervalInput}
        onInputChange={(event, newInputValue) => {
          setIntervalInput(newInputValue);
        }}
        id="select-alert-type"
        options={intraDayOptions}
        sx={{ width: 200, mb: 3 }}
        renderInput={(params) => (
          <TextField {...params} label="Select Interval" />
        )}
      />

      <Stack direction="row" spacing={1} >
      <Button disabled={(!alertType || !selectedInterval?.value) || (watchlistDetails.buy_alert && watchlistDetails.sell_alert)} variant="contained" size="small" onClick={() => handleWatchlistAlert("add")}>
        Set Alert
      </Button>

      <Button disabled={!alertType && !(watchlistDetails.buy_alert || watchlistDetails.sell_alert)} variant="contained" size="small" onClick={() => handleWatchlistAlert("remove")} >
        Remove Alert
      </Button>
      </Stack>
      
    {/* REACT TOAST */}
    {/* <ReactHotToaster /> */}
    </Box>
  );
};

export default AddWatchlistAlertModal;
