// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.AdvisoryDetailsTable_AdvisoryDetailsTable__oZ5Mq {\n  margin: 2rem;\n  \n}\n\n.AdvisoryDetailsTable_tableContainer__kRA4Q {\n    width: 100%;\n    max-width: 1300px;\n    border-collapse: collapse;\n    border: 1px solid #ccc;\n    /* background-color: red; */\n  }\n  \n  .AdvisoryDetailsTable_tableContainer__kRA4Q th,\n  .AdvisoryDetailsTable_tableContainer__kRA4Q td {\n    padding: 8px;\n    text-align: left;\n    border: 1px solid #ccc;\n  }\n  \n  .AdvisoryDetailsTable_tableContainer__kRA4Q th {\n    background-color: #f2f2f2;\n  }\n  \n  .AdvisoryDetailsTable_tableBodyContainer__ETr1s tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n  \n  .AdvisoryDetailsTable_colorCircleContainer__JEI7K {\n    /* border: 1px solid red; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }", "",{"version":3,"sources":["webpack://./src/components/AdvisorDashboard/AdvisorHome/AdvisoryDetailsTable/AdvisoryDetailsTable.module.css"],"names":[],"mappings":";AACA;EACE,YAAY;;AAEd;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,sBAAsB;IACtB,2BAA2B;EAC7B;;EAEA;;IAEE,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB","sourcesContent":["\n.AdvisoryDetailsTable {\n  margin: 2rem;\n  \n}\n\n.tableContainer {\n    width: 100%;\n    max-width: 1300px;\n    border-collapse: collapse;\n    border: 1px solid #ccc;\n    /* background-color: red; */\n  }\n  \n  .tableContainer th,\n  .tableContainer td {\n    padding: 8px;\n    text-align: left;\n    border: 1px solid #ccc;\n  }\n  \n  .tableContainer th {\n    background-color: #f2f2f2;\n  }\n  \n  .tableBodyContainer tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n  \n  .colorCircleContainer {\n    /* border: 1px solid red; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdvisoryDetailsTable": "AdvisoryDetailsTable_AdvisoryDetailsTable__oZ5Mq",
	"tableContainer": "AdvisoryDetailsTable_tableContainer__kRA4Q",
	"tableBodyContainer": "AdvisoryDetailsTable_tableBodyContainer__ETr1s",
	"colorCircleContainer": "AdvisoryDetailsTable_colorCircleContainer__JEI7K"
};
export default ___CSS_LOADER_EXPORT___;
