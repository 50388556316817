import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import axiosConfig from "../../../../axios/axiosConfig";

import SaveIcon from "@mui/icons-material/Save";

const getRandom = () => {
  return Math.round(Math.random() * 1000000000);
};

const CreateDashboardModal = ({
  setOpenModal,
  setUserDashboards,
  currentUser,
  screenerId,
  handleSaveScreener,
}) => {
  const [error, setError] = useState(false);
  const [dashboardName, setDashboardName] = useState("");
  const [privateChecked, setPrivateChecked] = useState(false);

  const handlePrivateToggle = (event) => {
    setPrivateChecked(event.target.checked);
  };

  const handleDashboardNameChange = (e) => {
    setDashboardName(e.target.value);
  };

  // handle CREATE NEW dashboard
  const handleCreateDashboard = async () => {
    try {
      const res = await axiosConfig({
        method: "post",
        url: "/user/dashboard",
        data: {
          userid: currentUser.userDetails.id,
          dashboard_name: dashboardName,
          dashboard_private: privateChecked,
        },
      });

      // IF dashboard create SUCCESSFULL
      if (res.status === 200 || res.statusText === "OK") {
        setUserDashboards((prev) => {
          return [...prev, res.data.response];
        });

        // If screenerId exits, add it to the newly created dashboard
        if (screenerId) {
          handleSaveScreener(res.data.response.id, screenerId);
        }
      }
    } catch (error) {
      console.log(error.response);
    } finally {
      setOpenModal(false);
    }
  };

  // Disable Save Dashboard Button if 'dashboardName' is falsy
  const disableSaveBtn = !dashboardName || dashboardName.trim() === "";

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        Create New Dashboard
      </Typography>

      <TextField
        error={error}
        id="outlined-error-helper-text"
        label="Dashboard Name"
        placeholder="ie. top gainers"
        helperText={error && "Duplicate or Incorrect name."}
        value={dashboardName}
        onChange={handleDashboardNameChange}
      />

      <Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
        <FormGroup>
          <FormControlLabel
            disabled={false}
            control={
              <Checkbox
                size="small"
                checked={privateChecked}
                onChange={handlePrivateToggle}
              />
            }
            // label="Make Private (Only visible to you)"
          />
        </FormGroup>
        <Typography sx={{ ml: -2, fontSize: 13 }} variant="span">
          Make Private?
        </Typography>
      </Stack>

      <Button
        sx={{ mt: 2 }}
        size="small"
        variant="contained"
        startIcon={<SaveIcon />}
        disabled={disableSaveBtn}
        onClick={handleCreateDashboard}
      >
        Save
      </Button>
    </Box>
  );
};

export default CreateDashboardModal;
