import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import MuiModal from '../../components/muiComps/MuiModal'
import ActivateAccountModal from '../../components/UserDashboard/Settings/ActivateAccountModal'

const ActivateUserAccount = () => {
  const [showActivateAccountModal, setShowActivateAccountModal] = useState(false);

  const handleActivate = () => {
    setShowActivateAccountModal(true)
  }

  return (
    <div>
      {/* ACTIVATE ACCOUNT BOX */}
      <Box sx={{
        width: '400px',
        margin: '2rem',
        padding: '2rem',
        backgroundColor: '#fff',
        // border: '1px solid red',
        borderRadius: '0.3rem',
        boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.8)'
      }} >
        <Typography variant='subtitle1' >
          Activate your account by clicking below.
        </Typography>

        <Stack direction='row' sx={{
        // margin: '2rem',
        marginTop: '1rem'
      }} >
        <Button onClick={handleActivate} size='small' variant='contained' >Activate Account</Button>
      </Stack>
      </Box>


      {/* ACTIVATE ACCOUNT MODAL */}
      <MuiModal open={showActivateAccountModal} setOpen={setShowActivateAccountModal} >
        <ActivateAccountModal setOpen={setShowActivateAccountModal} />
      </MuiModal>
    </div>
  )
}

export default ActivateUserAccount