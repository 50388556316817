import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import NorthIcon from "@mui/icons-material/North";
import { axiosConfig } from "../../axios/axiosConfig";

import styles from "./PreviousTradesPerformance.module.css";
import ReactHotToaster from "../../components/common/ReactHotToaster";

// const columnHeaders = [
//   { col_id: "id", name: "ID", col_width: 30 },
//   { col_id: "buy_price", name: "Buy Price", col_width: 100 },
//   { col_id: "max_high", name: "High", col_width: 100 },
//   { col_id: "max_high_date", name: "High Date", col_width: 150 },
//   { col_id: "min_low", name: "Low", col_width: 50 },
//   { col_id: "profit/loss", name: "Profit/Loss" },
//   { col_id: "recommendation", name: "Signal" },
//   { col_id: "recommendation_date", name: "Signal Date" },
//   { col_id: "sell_price", name: "Sell Price" },
//   { col_id: "sell_price_date", name: "Sell Price Date" },
// ];

const PreviousTradesPerformance = () => {
  const [searchParams] = useSearchParams();

  // const symbolID = searchParams.get("symbol_id");
  const symbolName = searchParams.get("symbol");

  const [topPerformanceList, setTopPerformanceList] = useState([]);
  console.log("topPerformanceList: ", topPerformanceList);

  // FETCHING ALL SYMBOLS
  const symbolsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["top-performance"],
    queryFn: () => fetchTopPerformances(symbolName),
    onSuccess: (data) => {
      setTopPerformanceList(data.response.data);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  if (!symbolName || symbolName.trim() === "") {
    return (
      <div>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Symbol Name Empty.
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.outerContainer}>
      <h1 className={styles.tableTitle}>{symbolName.toUpperCase()}</h1>
      <h2 className={styles.subTitle}>Recent trade Performance</h2>

      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>#</th>
            <th>Green Signal</th>
            <th>
              <div className={styles.highLowHeader}>
                <p>High</p>
                <p>Low</p>
              </div>
            </th>
            {/* <th>High</th>
            <th>Low</th> */}
            {/* <th>Red Signal</th> */}
            <th>% Gain / Loss</th>

            {/* <th>% Gain / Loss</th> */}

            {/* <th>Created On</th> */}
            {/* <th>Active</th>
            <th>Actions</th> */}
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {topPerformanceList.map((item, index) => {
            // const createdDate = new Date(item.created_at).toDateString();

            return (
              <tr key={item.id}>
                <td>{index + 1}</td>
                {/* GREEN SIGNAL PRICE & DATE */}
                <td>
                  <div>
                    <div className={styles.iconContainer}>
                      <NorthIcon sx={{ color: "green", fontSize: "1rem" }} />
                      <p className={styles.greenFontColor}>{item.buy_price}</p>
                    </div>

                    <p className={styles.date}>
                      {formatDate(item.recommendation_date)}
                    </p>
                  </div>
                </td>

                {/* HIGH, LOW AND DATES */}
                <td>
                  <div className={styles.highLowDataContainer}>
                    <div className={styles.highDataContainer}>
                      <p>{item.max_high}</p>
                      <p className={styles.date}>
                        {formatDate(item.max_high_date)}
                      </p>
                    </div>

                    <div className={styles.lowDataContainer}>
                      <p>{item.min_low}</p>
                      <p className={styles.date}>
                        {formatDate(item.min_low_date)}
                      </p>
                    </div>
                  </div>
                </td>

                {/* HIGH PRICE & DATE */}
                {/* <td>
                  <p>{item.max_high}</p>
                  <p className={styles.date}>
                    {formatDate(item.max_high_date)}
                  </p>
                </td> */}

                {/* LOW PRICE & DATE */}
                {/* <td>
                  <p>{item.min_low}</p>
                  <p className={styles.date}>{formatDate(item.min_low_date)}</p>
                </td> */}

                {/* RED SIGNAL PRICE & DATE */}
                {/* <td>
                  {item.sell_price_date ? (
                    <div>
                      <div className={styles.iconContainer}>
                        <SouthIcon sx={{ color: "red", fontSize: "1rem" }} />
                        <p className={styles.redFontColor}>{item.sell_price}</p>
                      </div>

                      <p className={styles.date}>
                        {formatDate(item.sell_price_date)}
                      </p>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td> */}

                {/* GAIN & LOSS % */}
                <td>
                  <p
                    className={
                      styles[
                        item?.recommendation === "Buy"
                          ? "greenFontColor"
                          : "redFontColor"
                      ]
                    }
                  >
                    {/* {item["profit/loss"]} */}
                    {item?.recommendation === "Buy" ? item?.profit_loss_asper_high_alert : item?.profit_loss_asper_red_alert}
                    <span>%</span>
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default PreviousTradesPerformance;

// FETCHER FUNCTION SAMPLE
export const fetchTopPerformances = async (symbol_name) => {
  if (!symbol_name) return;

  const res = await axiosConfig({
    method: "get",
    // url: `/appdashboard/stocks_predictions?stock_id=${symbol_id}`,
    url: `/appdashboard/stocks_predictions?name=${symbol_name}`,
    data: {},
  });

  return res.data;
};

const formatDate = (date) => {
  return new Date(date).toDateString();
};
