import React, { useEffect, useState } from "react";

import axios from "axios";
import toast from "react-hot-toast";

import BasicButton from "../components/Button/BasicButton";
import MembersEditableTable from "./MembersTable";
import AddTeamMember from "./AddTeamMember";
import Loader from "../components/Loading/Loading";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import "./TeamMembers.css";
import BasicTextFields from "../components/Modal/Input";
import BasicPagination from "../components/Pagination/Pagination";
import { useSelector } from "react-redux";
import AccessDenied from "../components/common/AccessDenied";
import ReactHotToaster from "../components/common/ReactHotToaster";

const TeamMembers = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  const [showAddMemberScreen, setShowAddMemberScreen] = useState(false);
  const [memberList, setmemberList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [itemsLimit, setItemsLimit] = useState(10);

  const limit = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
  ];

  const handleShowUserFields = () => {
    setShowAddMemberScreen(!showAddMemberScreen);
  };

  const handleCreateMember = (memDetail) => {
    axios
      .post("/tnibroadmin/department", {
        ...memDetail,
      })
      .then((response) => {
        toast.success(response.data.response);
        setShowAddMemberScreen(false);
        handleFetchMembersList();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      });
  };

  const handleEditedMember = (memberObj) => {
    const memberIdToEdit = memberObj.id;

    axios
      .patch(`/tnibroadmin/department?id=${memberIdToEdit}`, {
        ...memberObj,
      })
      .then((response) => {
        toast.success(response.data.response);
        handleFetchMembersList();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      });
  };

  // FETCH MEMBERS LIST
  const handleFetchMembersList = () => {
    setIsLoading(true);

    const param = {
      limit: itemsLimit,
      offset: (currentPage - 1) * itemsLimit,
    };

    if (searchText !== "") {
      param.search = searchText;
    }

    axios
      .get("/tnibroadmin/department", {
        params: {
          userid: userDetails.id,
          department: true,
          ...param,
        },
      })
      .then((response) => {
        setmemberList(response.data.response);
        setIsLoading(false);
        const totalPage = Math.ceil(response.data.total_count / itemsLimit);
        setTotalPages(totalPage);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
        setIsLoading(false);
      });
  };

  const handleDeleteMember = (member) => {
    const memberToDelete = member.id;

    axios
      .delete(`/tnibroadmin/department?id=${memberToDelete}`)
      .then((response) => {
        toast.success(response.data.response);
        handleFetchMembersList();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      });
  };

  const handleSearchText = (field, text) => {
    setSearchText(text);
  };

  const handlePagechange = (val) => {
    setCurrentPage(val);
  };

  const handleItemsLimitChange = (event) => {
    setItemsLimit(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchOnEnter = () => {
    setCurrentPage(1);
    handleFetchMembersList();
  };

  useEffect(() => {
    handleFetchMembersList();
  }, [currentPage, itemsLimit]);

  // ACCESS DENIED, NOT AUTHORIZED FOR NON-ADMIN
  if (userDetails?.user_type !== "Admin") {
    return <AccessDenied />
  }

  return (
    <>
      <div className="member-wrapper">
        <span>
          <h2>My Team</h2>
        </span>
        <div className="member-details-container">
          <BasicButton title="Add Member" callback={handleShowUserFields} />
          {showAddMemberScreen && (
            <AddTeamMember handleCreateMember={handleCreateMember} />
          )}

          {isLoading ? (
            <div style={{ marginTop: "20px" }}>
              <Loader />
            </div>
          ) : (
            memberList &&
            memberList.length > 0 && (
              <div style={{ width: "90%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <BasicTextFields
                    label="Search member"
                    value={searchText}
                    callback={handleSearchText}
                    onEnter={handleSearchOnEnter}
                  />
                </div>
                <div className="member-list">
                  <MembersEditableTable
                    tableData={memberList}
                    editedFieldData={handleEditedMember}
                    deleteMember={handleDeleteMember}
                  />

                  <div
                    style={{
                      display: "flex",
                      margin: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BasicPagination
                      count={totalPages}
                      currentPage={currentPage}
                      handlePagechange={handlePagechange}
                    />

                    <div style={{ marginLeft: "20px" }}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Limit
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={itemsLimit}
                            label="Limit"
                            onChange={handleItemsLimitChange}
                          >
                            {limit.map((num) => (
                              <MenuItem value={num.value}>{num.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

      {/* REACT TOAST */}
      <ReactHotToaster />
      </div>
    </>
  );
};

export default TeamMembers;