import React, { useState } from "react";

import styles from "./UserRefundsTable.module.css";
import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";

const UserRefundsTable = ({ userRefundsData, setUserRefundsData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleRefund = async (userItem) => {
    setIsLoading(true);

    let isCurrentRefundConfirmed = userItem.is_confirmed;

    const consent = window.confirm("Are you sure you want to continue?");

    if (!consent) return;

    try {
      const res = await axiosConfig({
        method: "patch",
        url: "/payment/moneyback",
        data: {
          id: userItem.id,
          is_confirmed: !isCurrentRefundConfirmed,
        },
      });

      const updatedItem = res.data.response[0];

      setUserRefundsData((prevState) => {
        return prevState.map((state) => {
          if (state.id === userItem.id) {
            return updatedItem;
          }
          return state;
        });
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>UserRefundsTable</h2>

      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Contact</th>
            <th>Refund ID</th>
            <th>Refund Status</th>
            <th>Refund Initiated Date</th>
            <th>Confirmed?</th>
            <th>Created On</th>
            <th>Modified On</th>

            {/* <th>Actions</th> */}
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {userRefundsData.map((item) => {
            // const createdDate = new Date(item.created_at).toDateString();
            // const modifiedDate = new Date(item.modified_at).toDateString();

            return (
              <tr key={item.id}>
                <td>{item.email}</td>
                <td>{item.contact}</td>
                <td>{item.refund_id ? item.refund_id : "N/A"}</td>
                <td>{item.refund_status ? item.refund_status : "N/A"}</td>
                <td>
                  {item.refund_initiated_date
                    ? item.refund_initiated_date
                    : "N/A"}
                </td>
                <td>{item.is_confirmed ? "true" : "false"}</td>
                <td>{item.created_at}</td>
                <td>{item.modified_at}</td>
                {/* <td>
                  <div className={styles.viewBtnContainer}>
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      <button
                        className="editBtn"
                        onClick={() => handleToggleRefund(item)}
                      >
                        toggle confirm
                      </button>
                    )}
                  </div>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UserRefundsTable;
