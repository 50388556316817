import React, { useState, useEffect } from 'react';
import './ImageSlider.css'; // Assuming separate CSS file for styling

const ImageSlider = ({ imageList }) => {
  const [currentImage, setCurrentImage] = useState(0);
  

  const prevImage = () => {
    setCurrentImage((prev) => (prev === 0 ? imageList.length - 1 : prev - 1));
  };

  const nextImage = () => {
    setCurrentImage((prev) => (prev === imageList.length - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev === imageList.length - 1 ? 0 : prev + 1));
    }, 5000); // Change the interval duration as needed (here, it's set to 5 seconds)

    return () => clearInterval(interval);
  }, [imageList.length]);

  return (
    <div className="image-slider">
      <button onClick={prevImage} className="slider-btn prev-btn">Prev</button>
      <img src={imageList[currentImage]} alt={`${currentImage + 1}`} className="slider-image" />
      <button onClick={nextImage} className="slider-btn next-btn">Next</button>
    </div>
  );
};

export default ImageSlider;
