import { SET_SELECTED_STOCK_CATEGORY } from "../actions/types";

const initialState = {
  selectedStockCategory: ""
};

// function messageReducer (state = initialState, action) {
//   const { type, payload } = action;

//   switch (type) {
//     case SET_MESSAGE:
//       return { message: payload };

//     case CLEAR_MESSAGE:
//       return { message: "" };

//     default:
//       return state;
//   }
// }


export default function stockCategoryReducer (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SELECTED_STOCK_CATEGORY:
      return { selectedStockCategory: payload };

    default:
      return state;
  }
}