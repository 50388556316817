import React from "react";
import styles from "./PaymentFailure.module.css";

const PaymentFailure = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Payment Failed!</h1>
      <p className={styles.message}>
        We're sorry, but your payment could not be processed at this time. Please try again later.
      </p>
    </div>
  );
};

export default PaymentFailure;