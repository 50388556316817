import React, { useState } from "react";

import styles from "./HtmlSelectDropdown.module.css";
import { Label } from "@mui/icons-material";

/*
  OPTIONS ARRAY FORMAT
  const optionsArray = [
    { id: 1, label: <Label1>, value: <Value1> },
    { id: 2, label: <Label2>, value: <Value2> },
  ]
*/

const HtmlSelectDropdown = ({
  title = "",
  placeholder = "",
  selectName = "",
  defaultOption = "",
  optionsArray = [{ id: 1, label: 'Label1', value: 'Value1' }],
  selectedOption,
  setSelectedOption,
  defaultOptionVisible = true,
}) => {
  // const [selectedOption, setSelectedOption] = useState("");

  return (
    <>
      <h3 className={styles.select_Title}>{title}</h3>
      {/* <label htmlFor={selectName}>slect label</label> */}

      <select
        className={styles.select_Input}
        name={selectName}
        placeholder={placeholder}
        value={selectedOption}
        onChange={(e) => {
          setSelectedOption(e.target.value);
        }}
      >
        {defaultOptionVisible ? (
          <option value="">{defaultOption}</option>
        ) : null}

        {optionsArray.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default HtmlSelectDropdown;
