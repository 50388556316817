export const measureRootOptions = [
  {
    label: "Number",
    value: "number",
    defaultValue: "20",
    group: "",
  },
  // {
  //   label: "Pattern",
  //   value: "pattern",
  //   defaultValue: "",
  //   group: "",
  // },
  // {
  //   label: "Since",
  //   value: "since",
  //   defaultValue: "",
  //   group: "",
  // },
  {
    label: "Open",
    value: "open",
    group: "Stock attributes",
  },

  {
    label: "High",
    value: "high",
    group: "Stock attributes",
  },

  {
    label: "Low",
    value: "low",
    group: "Stock attributes",
  },

  {
    label: "Close",
    value: "close",
    group: "Stock attributes",
  },

  {
    label: "Volume",
    value: "volume",
    group: "Stock attributes",
  },

  {
    label: "Open %",
    value: "open_pct",
    defaultValue: "0",
    group: "Stock attributes",
  },

  {
    label: "High %",
    value: "high_pct",
    defaultValue: "0",
    group: "Stock attributes",
  },

  {
    label: "Low %",
    value: "low_pct",
    defaultValue: "0",
    group: "Stock attributes",
  },

  {
    label: "Close %",
    value: "close_pct",
    defaultValue: "0",
    group: "Stock attributes",
  },

  {
    label: "Volume %",
    value: "volume_pct",
    defaultValue: "0",
    group: "Stock attributes",
  },

  {
    label: "HA-Open (Heikin-Ashi)",
    value: "heikin_ashi_open",
    group: "Stock attributes",
  },
  {
    label: "HA-High (Heikin-Ashi)",
    value: "heikin_ashi_high",
    group: "Stock attributes",
  },
  {
    label: "HA-Low (Heikin-Ashi)",
    value: "heikin_ashi_low",
    group: "Stock attributes",
  },
  {
    label: "HA-Close (Heikin-Ashi)",
    value: "heikin_ashi_close",
    group: "Stock attributes",
  },

  {
    label: "Macd Line",
    value: "macd-line",
    defaultValue: "26,12,9",
    group: "Indicators",
  },

  {
    label: "Macd Histogram",
    value: "macd-histogram",
    defaultValue: "26,12,9",
    group: "Indicators",
  },

  {
    label: "Macd Signal",
    value: "macd-signal",
    defaultValue: "26,12,9",
    group: "Indicators",
  },

  {
    label: "Supertrend",
    value: "supertrend",
    defaultValue: "7,3",
    group: "Indicators",
  },

  // IMPORTANT: COMMENTED OUT FOR TIME BEING (on 07-02-2023)
  // {
  //   label: "VWAP",
  //   value: "vwap",
  //   group: "Indicators",
  // },

  {
    label: "RSI",
    value: "rsi",
    defaultValue: "14",
    group: "Indicators",
  },

  {
    label: "UpperBollingerBand",
    value: "upper-bollinger-band",
    defaultValue: "20,2",
    group: "Indicators",
  },
  {
    label: "LowerBollingerBand",
    value: "lower-bollinger-band",
    defaultValue: "20,2",
    group: "Indicators",
  },

  {
    label: "EMA",
    value: "ema",
    group: "Indicators",
  },

  {
    label: "ATR (SMA)",
    value: "sma_atr",
    defaultValue: "7",
    group: "Indicators",
  },
  {
    label: "ATR (RMA)",
    value: "rma_atr",
    defaultValue: "7",
    group: "Indicators",
  },
  {
    label: "VWAP",
    value: "vwap",
    defaultValue: "20",
    group: "Indicators",
  },

  // COMMENTED OUT DUE TO BUGS
  // {
  //   label: "Since day frame",
  //   value: "since_day",
  //   group: "xyz",
  // },
  // {
  //   label: "Greatest",
  //   value: "greatest",
  //   group: "xyz",
  // },
  // {
  //   label: "Lowest",
  //   value: "lowest",
  //   group: "xyz",
  // },
  {
    label: "Max (52 week high)",
    value: "max",
    defaultValue: "0",
    group: "Math function",
  },
  {
    label: "Min (52 week low)",
    value: "min",
    defaultValue: "0",
    group: "Math function",
  },
];

// FILTERING BY CATEGORIES / SECTORS ARRAY
// export const STOCK_CATEGORIES = [
//   'auto',
//   'bank',
//   'commodities',
//   'ethanol',
//   'finance',
//   'navratna',
//   'maharatna',
//   'miniratna',
//   'fmcg',
//   'healthcare',
//   'IT',
// 'metal',
// 'oil&gas',
// 'sugar',
// 'miscellaneous',
// 'niftymidcap100',
// 'niftymidcap150',
// 'niftylargemidcap250',
// 'nifty100',
// 'nifty200',
// 'nifty50',
// 'niftysmallcap50',
// 'niftysmallcap100',
// 'niftysmallcap250',
// 'niftysmallcap400',
// 'niftynext50',
// ]

// SECTORS / CATEGORIES OF RECOMMENDATIONS DASHBOARD
export const STOCK_CATEGORIES2 = [
  { id: 1, label: "PSU", value: "psu" },
  { id: 2, label: "NAVRATNA", value: "navratna" },
  { id: 3, label: "MAHARATNA", value: "maharatna" },
  { id: 4, label: "MINIRATNA", value: "miniratna" },
  { id: 5, label: "AUTO", value: "auto" },
  { id: 6, label: "BANK", value: "bank" },
  { id: 7, label: "COMMODITIES", value: "commodities" },
  { id: 8, label: "ETHANOL", value: "ethanol" },
  { id: 9, label: "FINANCE", value: "finance" },
  { id: 10, label: "FMCG", value: "fmcg" },
  { id: 11, label: "HEALTHCARE", value: "healthcare" },
  { id: 12, label: "IT", value: "it" },
  { id: 13, label: "METAL", value: "metal" },
  { id: 14, label: "OIL & GAS", value: "oil&gas" },
  { id: 15, label: "SUGAR", value: "sugar" },
  { id: 16, label: "MISCELLANEOUS", value: "miscellaneous" },
  { id: 17, label: "NIFTYMIDCAP100", value: "niftymidcap100" },
  { id: 18, label: "NIFTYMIDCAP150", value: "niftymidcap150" },
  { id: 19, label: "NIFTYLARGEMIDCAP250", value: "niftylargemidcap250" },
  { id: 20, label: "NIFTY100", value: "nifty100" },
  { id: 21, label: "NIFTY200", value: "nifty200" },
  { id: 22, label: "NIFTY50", value: "nifty50" },
  { id: 23, label: "NIFTYSMALLCAP50", value: "niftysmallcap50" },
  { id: 24, label: "NIFTYSMALLCAP100", value: "niftysmallcap100" },
  { id: 25, label: "NIFTYSMALLCAP250", value: "niftysmallcap250" },
  { id: 26, label: "NIFTYSMALLCAP400", value: "niftysmallcap400" },
  { id: 27, label: "NIFTYNEXT50", value: "niftynext50" },
  { id: 28, label: "TEXTILES", value: "textiles" },
  { id: 29, label: "TELECOM", value: "telecom" },
  { id: 30, label: "AGROCHEMICALS", value: "agrochemicals" },
  { id: 31, label: "PAINTS", value: "paints" },
  { id: 32, label: "JEWELLERY", value: "jewellery" },
  { id: 33, label: "PAINTS", value: "paints" },
  { id: 34, label: "POWER", value: "power" },
  { id: 35, label: "CHEMICALS", value: "chemicals" },
  { id: 36, label: "MATERIALS", value: "materials" },
  { id: 37, label: "TYRES", value: "tyres" },
  { id: 38, label: "HOTELS", value: "hotels" },
  { id: 39, label: "EDUCATION", value: "education" },
  { id: 40, label: "PACKAGING", value: "packaging" },
  { id: 41, label: "HEALTHCARE", value: "healthcare" },
  { id: 42, label: "GLASS", value: "glass" },
  { id: 43, label: "BEVERAGES", value: "beverages" },
  { id: 44, label: "ELECTRONICS", value: "electronics" },
  { id: 45, label: "ELECTRICALS", value: "electricals" },
  { id: 46, label: "MEDIA", value: "media" },
  { id: 47, label: "APPAREL", value: "apparel" },
  { id: 48, label: "AGROCHEMICALS", value: "agrochemicals" },
  { id: 49, label: "DIVERSIFIED", value: "diversified" },
  { id: 50, label: "RENEWABLE", value: "renewable" },
  { id: 51, label: "CEMENT", value: "cement" },
  { id: 52, label: "MACHINERY", value: "machinery" },
  { id: 53, label: "RATINGS", value: "ratings" },
  { id: 54, label: "SHIPBUILDING", value: "shipbuilding" },
  { id: 55, label: "PETROCHEMICALS", value: "petrochemicals" },
  { id: 56, label: "REAL ESTATE", value: "real&estate" },
  { id: 57, label: "RETAIL", value: "retail" },
  { id: 58, label: "PUBLISHING", value: "publishing" },
  { id: 59, label: "UTILITIES", value: "utilities" },
  { id: 60, label: "RUBBER", value: "rubber" },
  { id: 61, label: "LOGISTICS", value: "logistics" },
  { id: 62, label: "BATTERIES", value: "batteries" },
  { id: 63, label: "AGRO", value: "agro" },
  { id: 64, label: "JEWELLERY", value: "jewellery" },
  { id: 65, label: "TRADING", value: "trading" },
  { id: 66, label: "TYRES", value: "tyres" },
  { id: 67, label: "BREWERIES", value: "breweries" },
  { id: 68, label: "SUGAR", value: "sugar" },
  { id: 69, label: "ETHANOL", value: "ethanol" },
];

// PATTERNS
export const patternsList = [
  {
    label: "three outside up",
    value: "three outside up",
    group: "Bullish",
  },
  {
    label: "rising three method",
    value: "rising three method",
    group: "Bullish",
  },
  {
    label: "three white soldiers",
    value: "three white soldiers",
    group: "Bullish",
  },
  {
    label: "belt hold bullish",
    value: "belt hold bullish",
    group: "Bullish",
  },
  {
    label: "abandoned baby bullish",
    value: "abandoned baby bullish",
    group: "Bullish",
  },
  {
    label: "Falling three method",
    value: "Falling three method",
    group: "Bullish",
  },
  {
    label: "three inside up",
    value: "three inside up",
    group: "Bullish",
  },
  {
    label: "morning star",
    value: "morning star",
    group: "Bullish",
  },
  {
    label: "bullish doji",
    value: "bullish doji",
    group: "Bullish",
  },
  {
    label: "engulfing_bullish",
    value: "engulfing_bullish",
    group: "Bullish",
  },
  {
    label: "harami bullish",
    value: "harami bullish",
    group: "Bullish",
  },
  {
    label: "hammer",
    value: "hammer",
    group: "Bullish",
  },
  {
    label: "morning star doji",
    value: "morning star doji",
    group: "Bullish",
  },
  {
    label: "DragonFly Doji",
    value: "DragonFly Doji",
    group: "Bullish",
  },
  {
    label: "Inverted Hammer",
    value: "Inverted Hammer",
    group: "Bullish",
  },
  {
    label: "white marubozu",
    value: "white marubozu",
    group: "Bullish",
  },

  {
    label: "belt hold bearish",
    value: "belt hold bearish",
    group: "Bearish",
  },
  {
    label: "three outside down",
    value: "three outside down",
    group: "Bearish",
  },
  {
    label: "three inside down",
    value: "three inside down",
    group: "Bearish",
  },
  {
    label: "deliberation bearish",
    value: "deliberation bearish",
    group: "Bearish",
  },
  {
    label: "darkcloud ever pattern",
    value: "darkcloud ever pattern",
    group: "Bearish",
  },
  {
    label: "shooting star",
    value: "shooting star",
    group: "Bearish",
  },
  {
    label: "bearish doji",
    value: "bearish doji",
    group: "Bearish",
  },
  {
    label: "engulfing_bearish",
    value: "engulfing_bearish",
    group: "Bearish",
  },
  {
    label: "harami bearish",
    value: "harami bearish",
    group: "Bearish",
  },
  {
    label: "hanging man",
    value: "hanging man",
    group: "Bearish",
  },
  {
    label: "piercing pattern",
    value: "piercing pattern",
    group: "Bearish",
  },
  {
    label: "evening star",
    value: "evening star",
    group: "Bearish",
  },
  {
    label: "three black crow",
    value: "three black crow",
    group: "Bearish",
  },
  {
    label: "black marubozu",
    value: "black marubozu",
    group: "Bearish",
  },
];

export const offsetOptions = [
  {
    label: "5 min",
    value: "5-min",
    group: "Intraday Candles",
  },
  {
    label: "10 min",
    value: "10-min",
    group: "Intraday Candles",
  },
  {
    label: "15 min",
    value: "15-min",
    group: "Intraday Candles",
  },
  {
    label: "30 min",
    value: "30-min",
    group: "Intraday Candles",
  },
  // {
  //   label: "45 min",
  //   value: "45-min",
  //   group: "Intraday Candles"
  // },
  {
    label: "1 hour",
    value: "60-min",
    group: "Intraday Candles",
  },

  {
    label: "Latest",
    value: "latest",
    group: "daily-candles",
  },

  {
    label: "1 Day ago",
    value: "1-day-ago",
    group: "daily-candles",
  },

  {
    label: "2 Day ago",
    value: "2-day-ago",
    group: "daily-candles",
  },
  {
    label: "3 Day ago",
    value: "3-day-ago",
    group: "daily-candles",
  },
  {
    label: "n Day ago",
    value: "n-day-ago",
    group: "daily-candles",
  },

  {
    label: "Weekly",
    value: "weekly",
    group: "weekly-candles",
  },
  {
    label: "1 Week ago",
    value: "1-Week-ago",
    group: "weekly-candles",
  },
  {
    label: "2 Week ago",
    value: "2-week-ago",
    group: "weekly-candles",
  },
  {
    label: "3 Week ago",
    value: "3-week-ago",
    group: "weekly-candles",
  },
  {
    label: "52 Weeks",
    value: "52-weeks",
    group: "weekly-candles",
  },
  {
    label: "n Week ago",
    value: "n-week-ago",
    group: "weekly-candles",
  },
  {
    label: "Monthly",
    value: "monthly",
    group: "monthly-candles",
  },
  {
    label: "1 Month ago",
    value: "1-month-ago",
    group: "monthly-candles",
  },
  {
    label: "2 Month ago",
    value: "2-month-ago",
    group: "monthly-candles",
  },
  {
    label: "3 Month ago",
    value: "3-month-ago",
    group: "monthly-candles",
  },
  {
    label: "n Month ago",
    value: "n-month-ago",
    group: "monthly-candles",
  },
  {
    label: "Quarterly",
    value: "quarterly",
    group: "quarterly-candles",
  },
  {
    label: "1 Quarter ago",
    value: "1-quarter-ago",
    group: "quarterly-candles",
  },
  {
    label: "2 Quarter ago",
    value: "2-quarter-ago",
    group: "quarterly-candles",
  },
  {
    label: "3 Quarter ago",
    value: "3-quarter-ago",
    group: "quarterly-candles",
  },
  {
    label: "n Quarter ago",
    value: "n-quarter-ago",
    group: "quarterly-candles",
  },
  {
    label: "Yearly",
    value: "yearly",
    group: "yearly-candles",
  },
  {
    label: "1 Year ago",
    value: "1-year-ago",
    group: "yearly-candles",
  },
  {
    label: "2 Year ago",
    value: "2-year-ago",
    group: "yearly-candles",
  },
  {
    label: "3 Year ago",
    value: "3-year-ago",
    group: "yearly-candles",
  },
  {
    label: "n Year ago",
    value: "n-year-ago",
    group: "yearly-candles",
  },
];

export const intraDayTimeFrame = [
  {
    label: "0(Latest)",
    value: "0",
    group: "Latest candle",
  },
  {
    label: "-1(Previous)",
    value: "-1",
    group: "Latest candle",
  },
  {
    label: "-2",
    value: "-2",
    group: "Latest candle",
  },
  {
    label: "-3",
    value: "-3",
    group: "Latest candle",
  },
  {
    label: "-4",
    value: "-4",
    group: "Latest candle",
  },
  {
    label: "-5",
    value: "-5",
    group: "Latest candle",
  },
  {
    label: "-6",
    value: "-6",
    group: "Latest candle",
  },
  {
    label: "-7",
    value: "-7",
    group: "Latest candle",
  },
  {
    label: "-8",
    value: "-8",
    group: "Latest candle",
  },

  {
    label: "=1 (first candle)",
    // value: "1candle",
    value: "1",
    group: "Days nth Candles",
  },
  {
    label: "=2 (second candle)",
    value: "2candle",
    group: "Days nth Candles",
  },
  {
    label: "=3",
    value: "3candle",
    group: "Days nth Candles",
  },
  {
    label: "=4",
    value: "4candle",
    group: "Days nth Candles",
  },
  {
    label: "=5",
    value: "5candle",
    group: "Days nth Candles",
  },
  {
    label: "nth candle of the day",
    value: "nth-candle",
    group: "Days nth Candles",
  },

  {
    label: "=-1 (previous days last candle)",
    value: "-1",
    group: "Prior day Candle",
  },
  {
    label: "=-2 (previous days last candle)",
    value: "-2 (previous day second last candle)",
    group: "Prior day Candle",
  },
  {
    label: "=-3",
    value: "-3",
    group: "Prior day Candle",
  },
  {
    label: "=-4",
    value: "-4",
    group: "Prior day Candle",
  },
  {
    label: "=-5",
    value: "-5",
    group: "Prior day Candle",
  },
];

export const operatorOptions = [
  {
    label: "Equal",
    value: "equal",
  },

  {
    label: "Less than",
    value: "less_than",
  },

  {
    label: "Greater than",
    value: "greater_than",
  },
  {
    label: "Greater than equal to",
    value: "greater_than_equal_to",
  },
  {
    label: "Less than equal to",
    value: "less_than_equal_to",
  },

  {
    label: "Gap between",
    value: "gap_between",
  },
  {
    label: "Greatest",
    value: "greatest",
  },
];

export const combination = [
  {
    label: "Passes all",
    value: "pass-all",
  },

  {
    label: "failed",
    value: "failed",
  },
];

export const segment = [
  {
    label: "Cash",
    value: "cash",
    group: "Segment",
  },
  {
    label: "Crypto",
    value: "crypto",
    group: "Segment",
  },
  {
    label: "Nifty 50",
    value: "nifty_50",
    group: "Segment",
  },
  {
    label: "MCX",
    value: "mcx",
    group: "Segment",
  },
  // {
  //   label: "OPTIONS",
  //   value: "optionchain",
  //   group: "Segment",
  // },
  {
    label: "FOREX",
    value: "forex",
    group: "Segment",
  },
  {
    label: "F&O",
    value: "f&o",
    group: "Segment",
  },
];

export const refreshTimeOptions = [
  // {
  //   title: "1 min",
  //   value: 60000,
  // },
  {
    title: "5 min",
    value: 300000,
  },
  // {
  //   title: "15 min",
  //   value: 900000,
  // },
  // {
  //   title: "30 min",
  //   value: 1800000,
  // },
  // {
  //   title: "60 min",
  //   value: 3600000,
  // },
];

export const rowsPerPageOptions = [
  {
    title: "25",
    value: 25,
  },

  {
    title: "50",
    value: 50,
  },

  {
    title: "100",
    value: 100,
  },
  {
    title: "200",
    value: 200,
  },
  {
    title: "500",
    value: 500,
  },
  {
    title: "1000",
    value: 1000,
  },
];
