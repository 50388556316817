import React from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import TtyOutlinedIcon from "@mui/icons-material/TtyOutlined";
import { Link } from "react-router-dom";

import styles from "./Footer.module.css";

const linksArray = [
  { id: 1, label: "Home", link: "/home" },
  { id: 2, label: "Screener", link: "/screener" },
  { id: 3, label: "Saved Scans", link: "/savedscan" },
  { id: 4, label: "Options", link: "/nse-option-chain" },
  {
    id: 5,
    label: "Recommended Stocks",
    link: "/recommended-stocks?tab=nifty50",
  },
  { id: 6, label: "Dashboards", link: "/dashboards" },
  // { id: 7, label: "Watchlists", link: "/watchlist" },
  // { id: 8, label: "Alerts", link: "/alerts" },
  // { id: 9, label: "Pricing", link: "/pricing" },
];

const linksArray2 = [
  { id: 7, label: "Watchlists", link: "/watchlist" },
  { id: 8, label: "Alerts", link: "/alerts" },
  { id: 1, label: "Privacy Policy", link: "/privacy-policy" },
  // { id: 2, label: "Refunds & Cancellation Policy", link: "/refunds-cancellation" },
  { id: 3, label: "Terms & Conditions", link: "/terms-conditions" },
  { id: 4, label: "Contact Us", link: "/contact-us" },
];

const contactData = [
  {
    id: 1,
    icon: <LocationOnOutlinedIcon />,
    title: "Location",
    text: "2nd F, Plot 756, Udyog Vihar Phase V, Gurugram, Haryana 122016",
  },
  {
    id: 2,
    icon: <EmailOutlinedIcon />,
    title: "Mail Us",
    text: "support@stockyaari.com",
  },
  {
    id: 3,
    icon: <AccessTimeOutlinedIcon />,
    title: "Timings",
    text: "10.00 - 6.00 (Mon-Fri)",
  },
];

const Footer = () => {
  return (
    <div className={styles.Footer}>
      {/* SUBSCRIBE NEWSLETTER SECTION */}
      {/* IMPORTANT: DISPLAY NONE */}
      <div className={styles.subscribeContainer}>
        <h3 className={styles.title}>
          Subscribe to Our Newsletter & Get Updates
        </h3>

        <div className={styles.emailContainer}>
          <input
            className={styles.emailInput}
            type="email"
            placeholder="Your Email"
          />
          <button className={styles.subscribeBtn}>Subscribe</button>
        </div>
      </div>

      <div className={styles.topContainer}>
        {/* SOCIAL LINKS SECTION */}
        <section
          className={`${styles.socialLinksContainer} ${styles.footerSection}`}
        >
          <h1>TNIBRO TECHNOLOGIES LLP</h1>
          <p>
            We aim to be an innovator in financial technology, dedicated to
            empowering investors with cutting-edge tools and insights.
          </p>

          <div className={styles.phoneContainer}>
            <div className={styles.phoneIconContainer}>
              <TtyOutlinedIcon fontSize="large" />
            </div>
            <div className={styles.phoneText}>
              {/* <p> */}
              <span>Make a Call </span>
              <br />
              +91 9999 9999
              {/* </p> */}
            </div>
          </div>
        </section>

        {/* CONTACT US SECTION */}
        <section
          className={`${styles.usefulLinksContainer} ${styles.footerSection}`}
        >
          <h2 className={styles.sectionTitle}>Contact Us</h2>

          {/* Contact Details */}
          <div className={styles.contactWrapper}>
            {contactData.map((item) => {
              return (
                <div className={styles.contactItem}>
                  <div className={styles.iconContainer}>
                    {item.icon}
                    <h3 className={styles.title}>{item.title}</h3>
                  </div>

                  <p className={styles.text}>{item.text}</p>
                </div>
              );
            })}
          </div>
        </section>

        {/* USEFUL LINKS SECTION */}
        <section
          className={`${styles.usefulLinksContainer} ${styles.footerSection}`}
        >
          <h2 className={styles.sectionTitle}>Useful Links</h2>

          {/* Links */}
          <div className={styles.listWrapper}>
            <ul className={styles.linksList}>
              {linksArray.map((item) => {
                return (
                  <Link className={styles.link} key={item.id} to={item.link}>
                    <li className={styles.linkItem}>{item.label}</li>
                  </Link>
                );
              })}
            </ul>

            <ul className={styles.linksList}>
              {linksArray2.map((item) => {
                return (
                  <Link className={styles.link} key={item.id} to={item.link}>
                    <li className={styles.linkItem}>{item.label}</li>
                  </Link>
                );
              })}
            </ul>
          </div>
        </section>
      </div>

      {/* COPYRIGHT */}
      <div className={styles.copyrightContainer}>
        <p>TNIBRO TECHNOLOGIES LLP &copy; All Rights Reserved. 2023</p>
      </div>
    </div>
  );
};

export default Footer;
