import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Stack, Typography } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
// import CircularProgress from "@mui/material/CircularProgress";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";

import UserEditModal from "./UserEditModal";
import styles from "./EditableTable.module.css";
import { country_list } from "../data/countryList";

import { useMutation } from "@tanstack/react-query";
import axiosConfig from "../axios/axiosConfig";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import ReactHotToaster from "../components/common/ReactHotToaster";
import { Link } from "react-router-dom";
import ColorSignalCircle from "../components/common/ColorSignalCircle";
import MuiModal from "../components/muiComps/MuiModal";

const USER_STATUS_OPTIONS = [
  { id: 1, label: "Active", value: "True" },
  { id: 2, label: "Inactive", value: "False" },
];

export default function EditableTable({
  userData,
  editedFieldData,
  deleteUser,
  userInfo,
  setUserInfo,
  joinedDates,
  fetchUserData,
  sortUserData,
  // filterDates,
}) {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const admin_user_id = userDetails?.id;
  // const [userData, setUserData] = useState(tableData);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [userAlertDetails, setUserAlertDetails] = useState({
    user_id: null,
    alert_type: "",
  });

  const [userToEdit, setUserToEdit] = useState({});
  const uniqueDatesArray = getUniqueDates(joinedDates); // Unique dates array for Autocomplete List for filtering

  const handleEditedFieldData = (editedObj) => {
    editedFieldData(editedObj);
  };

  // Set Alert mutation
  const setAlerttMutation = useMutation({
    mutationFn: async () => postUserAlerts(userAlertDetails, admin_user_id),
    onSuccess: (data) => {
      toast.success("Alerts set successfully!!");
    },
    onError: (error) => {
      console.log(error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const handleSetUserAlertDetails = (ALERT_TYPE, USERID) => {
    setUserAlertDetails((currentState) => {
      return {
        ...currentState,
        user_id: USERID,
        alert_type: ALERT_TYPE,
      };
    });

    // confirm from user to set alerts....
    const consent = window.confirm(
      `Are you sure you want to set ${ALERT_TYPE}s for this user?`
    );
    if (consent) {
      setAlerttMutation.mutate();
    }
  };

  const tableCellStyles = {
    padding: "2px 6px",
    // border: '1px solid'
  };

  // backgroundColor: '#FFF5EE' - table heading
  return (
    <>
      <TableContainer
        sx={{ maxWidth: 1400, margin: "0 2rem" }}
        component={Paper}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead sx={{ backgroundColor: "#e2e2e2" }}>
            <TableRow>
              <TableCell sx={{ ...tableCellStyles, fontWeight: "bold" }}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <span>NAME</span>
                  <SortByAlphaIcon
                    onClick={() => sortUserData("username")}
                    fontSize="10px"
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                </Stack>
              </TableCell>
              <TableCell sx={{ ...tableCellStyles, fontWeight: "bold" }}>
                REFERRAL CODE
              </TableCell>
              <TableCell sx={{ ...tableCellStyles, fontWeight: "bold" }}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <span>REFERRAL COMPLETED</span>

                  <SortByAlphaIcon
                    onClick={() => sortUserData("referral_completed")}
                    fontSize="10px"
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                </Stack>
              </TableCell>

              <TableCell
                sx={{ ...tableCellStyles, fontWeight: "bold" }}
                align="left"
              >
                <span>EMAIL</span>
                <SortByAlphaIcon
                  onClick={() => sortUserData("email")}
                  fontSize="10px"
                  sx={{
                    cursor: "pointer",
                  }}
                />
              </TableCell>

              <TableCell
                sx={{ ...tableCellStyles, fontWeight: "bold" }}
                align="left"
              >
                PHONE
              </TableCell>
              <TableCell
                sx={{ ...tableCellStyles, fontWeight: "bold" }}
                align="center"
              >
                COUNTRY
                <Stack direction="row" spacing={0.5}>
                  <select
                    className={styles.user_Type_Select_Input}
                    name="country"
                    placeholder="Filter by Country"
                    value={userInfo?.country}
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return { ...prevState, country: e.target.value };
                      });
                    }}
                  >
                    <option value="">Filter by Country</option>
                    {country_list.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                  {userInfo.country ? (
                    <button
                      className={styles.html_btn}
                      onClick={() => {
                        setUserInfo((prevState) => {
                          return { ...prevState, country: undefined };
                        });
                      }}
                    >
                      clear
                    </button>
                  ) : null}
                </Stack>
              </TableCell>

              <TableCell
                sx={{ ...tableCellStyles, fontWeight: "bold" }}
                align="center"
              >
                USER TYPE
                <Stack direction="row" spacing={0.5}>
                  <select
                    className={styles.user_Type_Select_Input}
                    name="user-type"
                    placeholder="Filter by User-Type"
                    value={userInfo?.userType}
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return { ...prevState, userType: e.target.value };
                      });
                    }}
                  >
                    <option value="">Filter by User-Type</option>

                    {["Admin", "Premium", "Trial", "Free"].map(
                      (item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    )}
                  </select>

                  {userInfo.userType ? (
                    <button
                      className={styles.html_btn}
                      onClick={() => {
                        setUserInfo((prevState) => {
                          return { ...prevState, userType: undefined };
                        });
                      }}
                    >
                      clear
                    </button>
                  ) : null}
                </Stack>
              </TableCell>

              <TableCell>
                USER STATUS
                <Stack direction="row" spacing={0.5}>
                  <select
                    className={styles.user_Type_Select_Input}
                    name="user-status"
                    placeholder="Filter by User-Status"
                    value={userInfo?.userStatus}
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return { ...prevState, userStatus: e.target.value };
                      });
                    }}
                  >
                    <option value="">Filter by Active</option>

                    {USER_STATUS_OPTIONS.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>

                  {userInfo.userStatus ? (
                    <button
                      className={styles.html_btn}
                      onClick={() => {
                        setUserInfo((prevState) => {
                          return { ...prevState, userStatus: undefined };
                        });
                      }}
                    >
                      clear
                    </button>
                  ) : null}
                </Stack>
              </TableCell>

              <TableCell
                sx={{ ...tableCellStyles, fontWeight: "bold" }}
                align="center"
              >
                JOINING DATE
                <Stack direction="column" spacing={0.5}>
                  {/* <select
                    className={styles.user_Type_Select_Input}
                    name="user-type"
                    placeholder="Filter by User-Type"
                    value={userInfo?.joinDate}
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return { ...prevState, joinDate: e.target.value };
                      });
                    }}
                  >
                    <option value="">Filter by Join Date</option>
                    {uniqueDatesArray?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select> */}

                  <Stack direction="row" spacing={0.5}>
                    <label>From: </label>
                    <input
                      style={{ marginLeft: "auto" }}
                      type="date"
                      name="fromDate"
                      id="fromDate"
                      value={userInfo.fromDate}
                      onChange={(e) => {
                        setUserInfo((prev) => {
                          return { ...prev, fromDate: e.target.value };
                        });
                      }}
                    />
                    <button onClick={fetchUserData}>ok</button>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <label>To: </label>
                    <input
                      style={{ marginLeft: "auto" }}
                      type="date"
                      name="toDate"
                      id="toDate"
                      value={userInfo.toDate}
                      onChange={(e) => {
                        setUserInfo((prev) => {
                          return { ...prev, toDate: e.target.value };
                        });
                      }}
                    />

                    <button
                      title="Clear Date Filter"
                      className={styles.html_btn}
                      disabled={!(userInfo.fromDate && userInfo.toDate)}
                      // style={{ backgroundColor: userInfo.fromDate && userInfo.toDate ? "red" : "#FCFCFC"}}
                      onClick={() => {
                        setUserInfo((prevState) => {
                          return { ...prevState, fromDate: "", toDate: "" };
                        });

                        // fetchUserData()
                      }}
                    >
                      clr
                    </button>
                  </Stack>
                </Stack>
              </TableCell>

              {/* ACTION BUTTONS (DELETE, EDIT) NOT NEEDED */}
              <TableCell sx={{ fontWeight: "bold" }}>ACTIONS</TableCell>

              {/* SET ALERTS */}
              <TableCell sx={{ fontWeight: "bold" }}>
                <Stack direction="row" spacing={1}>
                  <span>SET BULK ALERT</span>
                  <NotificationsIcon />
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* TABLE BODY STARTS HERE */}
          <TableBody sx={{ backgroundColor: "white" }}>
            {userData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:nth-of-type(odd)": {
                    backgroundColor: "#f9f9f9",
                  },
                }}
              >
                <TableCell sx={tableCellStyles} component="th" scope="row">
                  {row.username}
                </TableCell>
                <TableCell sx={tableCellStyles} component="th" scope="row">
                  {row.referralcode}
                </TableCell>
                <TableCell
                  sx={tableCellStyles}
                  component="th"
                  scope="row"
                  align="center"
                >
                  <Link to={`/admin-dashboard/users/${row.referralcode}`}>
                    <span>{row.referral_completed}</span>
                  </Link>
                </TableCell>
                <TableCell sx={tableCellStyles} align="left">
                  {row.email}
                </TableCell>
                <TableCell sx={tableCellStyles} align="right">
                  {row.phoneno}
                </TableCell>
                <TableCell sx={tableCellStyles} align="center">
                  {row.country}
                </TableCell>
                <TableCell sx={tableCellStyles} align="center">
                  {row.user_type}
                </TableCell>
                <TableCell sx={tableCellStyles} align="center">
                  {row.is_active ? (
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <ColorSignalCircle bgColor="green" />
                      <Typography variant="subtitle1" fontSize={10}>
                        ACTIVE
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <ColorSignalCircle bgColor="red" />
                      <Typography variant="subtitle1" fontSize={10}>
                        INACTIVE
                      </Typography>
                    </Stack>
                  )}
                </TableCell>
                <TableCell sx={tableCellStyles} align="center">
                  {/* {row.date_joined.slice(0, 10)} */}
                  {new Date(row.date_joined).toDateString()}
                </TableCell>

                <TableCell sx={tableCellStyles}>
                  <Stack direction="row" spacing={1}>
                    <EditOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenEditModal(true);
                        setUserToEdit(row);
                      }}
                    />

                    <DeleteIcon
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        const consent = window.confirm(
                          "Are you sure you want to delete this user?"
                        );
                        if (consent) {
                          deleteUser(row);
                        }
                      }}
                      color="error"
                    />
                  </Stack>
                </TableCell>
                {/* <button onClick={(e) => { e.stopPropagation(); deleteUser(row) }}>delete</button> */}

                <TableCell sx={tableCellStyles}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      disabled={setAlerttMutation.isLoading}
                      variant="contained"
                      size="small"
                      // color="success"
                      endIcon={<NotificationAddIcon />}
                      onClick={() =>
                        handleSetUserAlertDetails("Buy Alert", row?.id)
                      }
                    >
                      buy
                    </Button>

                    <Button
                      disabled={setAlerttMutation.isLoading}
                      variant="contained"
                      size="small"
                      color="warning"
                      endIcon={<NotificationAddIcon />}
                      onClick={() =>
                        handleSetUserAlertDetails("Sell Alert", row?.id)
                      }
                    >
                      sell
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {openEditModal && (
        <UserEditModal
          userFields={userToEdit}
          isOpen={openEditModal}
          action={(val) => setOpenEditModal(val)}
          // callback={handleEditedFieldData}
          onValidate={handleEditedFieldData}
        />
      )} */}

      <MuiModal open={openEditModal} setOpen={setOpenEditModal}>
        <UserEditModal
          userFields={userToEdit}
          isOpen={openEditModal}
          action={(val) => setOpenEditModal(val)}
          // callback={handleEditedFieldData}
          onValidate={handleEditedFieldData}
        />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </>
  );
}

// UTILITY FUNCTIONS
// function to get unique joined dates (with no duplicate dates)
function getUniqueDates(joined_dates) {
  let uniqueJoinedDates = [];

  joined_dates.forEach((date) => {
    if (!uniqueJoinedDates.includes(date)) {
      uniqueJoinedDates.push(date);
    }
  });

  return uniqueJoinedDates;
}

// set user alerts, post request
const postUserAlerts = async (userAlertObj, adminUserId) => {
  const CURRENT_TIME = new Date().toLocaleString().replace(",", "");

  const { user_id, alert_type } = userAlertObj;
  if (!user_id || !alert_type) return;

  const res = await axiosConfig({
    method: "post",
    url: "/user/admin/createalert",
    data: {
      admin_userid: adminUserId,
      userid: user_id,
      alert_type: alert_type,
      set_alert_time: CURRENT_TIME,
    },
  });

  return res.data;
};
