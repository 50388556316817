import React, { useState } from 'react';

import styles from './AddUserTopUp.module.css';
import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosConfig } from '../../../../axios/axiosConfig';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import AllTopUpsTable from './AllTopUpsTable';

const AddUserTopUp = ({ userSubscriptionDetails }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const USERID = currentUser.userDetails.id;

  

  
  // fetch all available topups
  const [allTopupsList, setAllTopupsList] = useState([]);

  // Fetching all available top ups
  const fetchAllTopUpsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["all-topups"],
    queryFn: () => fetchAllTopups(USERID),
    onSuccess: (data) => {
      // console.log("all topups: ", data)
      setAllTopupsList(data.response);
    },
    onError: (error) => {
      console.log("error: ", error.response);
      toast.error(
        error?.response?.data?.response || "Something went wrong!"
      );
    },
  });


  


  

  return (
    <div>
      <h1>AddUserTopUp</h1>

      {/* List of Available Top Ups Table */}
      <AllTopUpsTable allTopUpsData={allTopupsList} userSubscriptionDetails={userSubscriptionDetails} />

    </div>
  )
}

export default AddUserTopUp;


// utilities
// fetch all topups available
const fetchAllTopups = async (user_id) => {
  // return;
  if (!user_id) {
    return;
  }

  const res = await axiosConfig({
    method: "get",
    url: `/subscription/topup_plan?userid=${user_id}`,
    data: {},
  });

  return res.data;
};