import React, { useEffect } from "react";

import styles from "./ThanksForPaying.module.css";
import { useNavigate } from "react-router-dom";

const ThanksForPaying = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/user-dashboard/subscription");
    }, 5000);
  }, []);

  return (
    <div className={styles.thanksContainer}>
      <div className={styles.thanksIcon}>
        <svg width="100" height="100" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="40" fill="#4CAF50" />
          <path
            d="M40 60l20 20L70 40"
            stroke="#fff"
            strokeWidth="5"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <h1 className={styles.thanksHeading}>Thanks for Paying!</h1>
      <p className={styles.thanksMessage}>
        Your payment has been successfully processed. We appreciate your
        business.
      </p>
    </div>
  );
};

export default ThanksForPaying;
