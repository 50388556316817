import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import axiosConfig from "../../../axios/axiosConfig";
import { useSelector } from "react-redux";
import { getUserWatchlists } from "../watchlist_utils";
import MuiModal from "../../muiComps/MuiModal";
import AddWatchlistAlertModal from "../AddWatchlistAlertModal";
import { useQuery } from "@tanstack/react-query";
import MuiLinearProgressLoader from "../../muiComps/MuiLinearProgressLoader";
import ReactHotToaster from "../../common/ReactHotToaster";

// for All user watchlists page. import this to pages/watchlists

const UserWatchlistTable = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const USERID = userDetails.id;

  const navigate = useNavigate();
  const [userWatchlists, setUserWatchlists] = useState([]);

  const [openAddWatchlistAlertModal, setOpenAddWatchlistAlertModal] =
    useState(false);
  const [watchlistDetails, setWatchlistDetails] = useState({});

  // FETCH USER WATCHLISTS USING useQUERY
  const watchlistQuery = useQuery({
    queryKey: ["user-watchlists"],
    queryFn: () => getUserWatchlists(userDetails.id),
    onSuccess: (data) => {
      setUserWatchlists(data.response);
    },
    onError: (error) => {
      toast.error(error.response.data.response || "Something went wrong");
    },
  });

  // DELETE WATCHLIST HANDLER
  const handleDeleteWatchlist = async (watchlistObj) => {
    const { id, name } = watchlistObj;
    const consent = window.confirm(
      "Are you sure you want to delete this watchlist?"
    );
    if (!consent) return;

    try {
      const res = await axiosConfig({
        method: "delete",
        url: `/watchlist/userwatchlist?watchlist_id=${id}&userid=${USERID}`,
        data: {},
      });

      if (res.status === 200 || res.statusText === "OK") {
        setUserWatchlists((prev) => {
          return prev.filter((item) => item.id !== id);
        });
        toast.success(`Watchlist: ${name} deleted successfully!`);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.response || "Something went wrong");
    }
  };

  const headerStyles = {
    fontWeight: "bold",
    padding: "2px 2px",
  };

  const rowTableCellStyles = {
    padding: "4px 2px",
  };

  if (watchlistQuery.isLoading) {
    return <MuiLinearProgressLoader />;
  }

  return (
    <div>
      {/* <h1>UserWatchlistTable</h1> */}

      {/* table here */}
      <TableContainer
        component={Paper}
        sx={{ minWidth: 500, maxWidth: 1100, mb: 4, p: "4px" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={headerStyles}>Watchlist Name</TableCell>
              <TableCell sx={headerStyles}>Description</TableCell>
              <TableCell sx={headerStyles} align="center">
                Total Stocks
              </TableCell>
              <TableCell sx={headerStyles} align="center">
                Buy Alert
              </TableCell>
              <TableCell sx={headerStyles} align="center">
                Sell Alert
              </TableCell>
              <TableCell sx={headerStyles} align="center">
                Alert
              </TableCell>

              <TableCell sx={headerStyles} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userWatchlists.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={rowTableCellStyles} component="th" scope="row">
                  <Link to={`/watchlist/${row.id}`}>
                    <Typography
                      variant="subtitle1"
                      sx={{ "&:hover": { textDecoration: "underline" } }}
                    >
                      {row.name}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell sx={{ ...rowTableCellStyles, maxWidth: 300 }}>
                  {row.description}
                </TableCell>
                <TableCell sx={rowTableCellStyles} align="center">
                  {row.symbol.length}
                </TableCell>
                <TableCell sx={rowTableCellStyles} align="center">
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {row?.buy_alert ? (
                      <Tooltip title="Buy Alert is Active">
                        <Box
                          sx={{
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            backgroundColor: "green",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={rowTableCellStyles} align="center">
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {row?.sell_alert ? (
                      <Tooltip title="Sell Alert is Active">
                        <Box
                          sx={{
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            backgroundColor: "green",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </Box>
                </TableCell>

                <TableCell sx={rowTableCellStyles} align="center">
                  <Button
                    color="info"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      if (row.symbol.length === 0) {
                        toast.success("Watchlist Empty, please add stocks");
                      } else {
                        setOpenAddWatchlistAlertModal(true);
                        setWatchlistDetails(row);
                      }
                    }}
                  >
                    add/remove alert
                  </Button>
                </TableCell>

                <TableCell sx={rowTableCellStyles} align="center">
                  <ButtonGroup
                    variant="contained"
                    size="small"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      color="warning"
                      onClick={() =>
                        navigate(`/watchlist/edit/${row.id}`, {
                          state: { userWatchlistDetails: row },
                        })
                      }
                    >
                      edit
                    </Button>
                    <Button onClick={() => navigate(`/watchlist/${row.id}`)}>
                      add/view stocks
                    </Button>
                    <Button
                      color="error"
                      onClick={() => handleDeleteWatchlist(row)}
                    >
                      delete
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* create new watchlist button */}
      <Button variant="contained" onClick={() => navigate("/watchlist/create")}>
        Create New Watchlist
      </Button>

      {/* CREATE NEW WATCHLIST ALERT */}
      <MuiModal
        open={openAddWatchlistAlertModal}
        setOpen={setOpenAddWatchlistAlertModal}
      >
        <AddWatchlistAlertModal
          setOpenAlertTypeModal={setOpenAddWatchlistAlertModal}
          watchlistDetails={watchlistDetails}
          setUserWatchlists={setUserWatchlists}
          setOpenAddWatchlistAlertModal={setOpenAddWatchlistAlertModal}
        />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default UserWatchlistTable;
