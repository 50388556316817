import React from "react";

import styles from "./YoutubeTutorial.module.css";

const YoutubeTutorial = () => {
  return (
    <div className={styles.outer_container}>
      <div className={styles.inner_container}>
        <div className={styles.video_caption_container}>
          <p className={styles.video_caption}>
            A Smart Tool to check before buy / sell any stock / crypto /
            commodities. It's so simple that anyone can use it. We don't do
            anything manual. It's all done by our smart tool "TNIBRO". We are
            not a tip provider.
          </p>
        </div>

        <div className={styles.youtube_video_container}>
          <iframe
            width="100%"
            height={250}
            // height="200"
            title="Video Title"
            src="https://www.youtube.com/embed/tgbNymZ7vqY"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default YoutubeTutorial;
