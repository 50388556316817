import React from "react";
// import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "./styles/globalStyles.css"
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HelmetProvider } from "react-helmet-async";

import { Provider } from "react-redux";
import store from "./store";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import axios from "axios";
const AUTH_TOKEN = JSON.parse(localStorage.getItem("user"))?.access_token;

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
// axios.defaults.headers.post['Content-Type'] = 'application/json';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true, // default is true
      refetchOnmount: true, // default is true
      refetchOnReconnect: true, // default is true
      retry: 2,
      // staleTime: 5*1000,  // default is 0
    },
  },
});

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_ID;

const root = createRoot(document.getElementById("root"));
root.render(
  // <StrictMode>
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </Provider>
    {/* <ReactQueryDevtools /> */}
  </QueryClientProvider>
  // </StrictMode>
);

// reportWebVitals();
