// MuiAlertToast
import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

// const severity = ["error", "warning", "info", "success"];
// const variant = ["contained", "filled", "outlined"];

/*
      INSIDE CLIENT
  // Mui Toast Alert Notification
  const [showAlert, setShowAlert] = useState({
    isVisible: false,
    msg: "",
    severity: ""
  });

  setShowAlert({ isVisible: true, msg: "Something went wrong", severity: "error"})

    ALERT MESSAGE TOAST
  {showAlert.isVisible && (<MuiAlertToast severity={showAlert.severity} msg={showAlert.msg} setShowAlert={setShowAlert} />)}
*/


export default function MuiAlertToast({
  severity = "info",
  msg = "Toast Message",
  setShowAlert,
}) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };


  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert variant="filled" severity={severity} onClose={handleClose}  sx={{ width: "100%" }}>
        {msg}
      </Alert>
    </Snackbar>
  );
}