import React from "react";

import styles from "./TinyMCEDataTable.module.css";
import { axiosConfig } from "../../../../../axios/axiosConfig";

const TinyMCEDataTable = ({ tableRowData = [], setActiveTinyDataItem, deleteTinyItem }) => {
  return (
    <div>
      TinyMCEDataTable
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>ID</th>
            <th>View/Edit</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {tableRowData.map((item, index) => {
            return (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <div className={styles.actionBtnContainer}>
                    <button
                      onClick={() => {
                        // setOpenTinyEditor(false);
                        // setfetchedContent(item); // for sending this content to tinymCe editor
                        // setIsEditing(true);
                      }}
                    >
                      view/edit
                    </button>
                  </div>
                </td>

                <td>
                  <div className={styles.actionBtnContainer}>
                    <button
                    onClick={() => {
                      setActiveTinyDataItem(item);
                      deleteTinyItem();
                    }}
                    >
                      delete
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TinyMCEDataTable;


