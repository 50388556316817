// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* .tab {\n  border: 1px solid black;\n} */\n\n.RecommendedStocksPage_disclaimerWrapper__moE94 {\n  /* border: 1px solid gray; */\n  /* padding: 0.5rem; */\n  border-radius: 0.4rem;\n}\n\n.RecommendedStocksPage_disclaimerWrapper__moE94 h2 {\n  text-align: center;\n}\n\n.RecommendedStocksPage_disclaimerText__cVLUN {\n  text-align: justify;\n  \n}\n\n\n\n\n/* MEDIA QUERIES */\n@media screen and (max-width: 480px) {\n  .RecommendedStocksPage_disclaimerText__cVLUN { font-size: 0.8rem; }\n}", "",{"version":3,"sources":["webpack://./src/pages/recommended-stocks/RecommendedStocksPage.module.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;EACE,4BAA4B;EAC5B,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;;AAErB;;;;;AAKA,kBAAkB;AAClB;EACE,+CAAkB,iBAAiB,EAAE;AACvC","sourcesContent":["/* .tab {\n  border: 1px solid black;\n} */\n\n.disclaimerWrapper {\n  /* border: 1px solid gray; */\n  /* padding: 0.5rem; */\n  border-radius: 0.4rem;\n}\n\n.disclaimerWrapper h2 {\n  text-align: center;\n}\n\n.disclaimerText {\n  text-align: justify;\n  \n}\n\n\n\n\n/* MEDIA QUERIES */\n@media screen and (max-width: 480px) {\n  .disclaimerText { font-size: 0.8rem; }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimerWrapper": "RecommendedStocksPage_disclaimerWrapper__moE94",
	"disclaimerText": "RecommendedStocksPage_disclaimerText__cVLUN"
};
export default ___CSS_LOADER_EXPORT___;
