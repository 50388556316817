import { Stack } from "@mui/material";

import styles from "./HtmlSelectInputFilter.module.css";

const operatorsArray = [
  { id: 6, value: "", label: "" },
  { id: 1, value: "gt", label: ">" },
  { id: 2, value: "lt", label: "<" },
  { id: 3, value: "gte", label: ">=" },
  { id: 4, value: "lte", label: "<=" },
  { id: 5, value: "eq", label: "=" },
];

const HtmlSelectInputFilter = ({
  selectValue,
  selectOnChange,
  inputValue,
  inputOnChange,
  onClick,
}) => {
  return (
    <Stack direction="row" spacing={1} justifyContent="flex-end">
      {/* Select Operator: */}
      <select
        className={styles.select_Input_Control}
        name="operator"
        value={selectValue}
        onChange={selectOnChange}
      >
        {operatorsArray.map((item, index) => (
          <option key={index} value={item.value}>{item.label}</option>
        ))}
      </select>

      <input
        className={styles.number_Input_Control}
        type="number"
        placeholder="0"
        name=""
        id=""
        maxLength={100}
        // style={{ backgroundColor: !!selectValue && !!inputValue ? "red" : "" }}
        value={inputValue}
        onChange={inputOnChange}
      />

      {/* COMMENTING OUT CLEAR BTN AS ADVISED */}
      {/* {!!selectValue || !!inputValue ? (
        <button
          // style={{ backgroundColor: !!selectValue && !!inputValue ? "red" : "", color: !!selectValue && !!inputValue ? "white" : "black" }}
          className={styles.html_btn}
          onClick={onClick}
        >
          clear
        </button>
      ) : null} */}
    </Stack>
  );
};

export default HtmlSelectInputFilter;
