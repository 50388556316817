import React, { useEffect, useCallback } from "react";
import Layout from "./Layout/Layout";
import { createTheme, ThemeProvider } from "@mui/material";
import runOneSignal from "./utils/oneSignal/oneSignalInit";
// import "./styles/global.css";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { logout } from "./actions/auth";
import { logout } from "./reduxStore/actions/auth";

import { parseJwt } from "./utils/common/commonUtilityFunctions";

import OneSignal from "react-onesignal";
import { sendToken } from "./utils/notifications";

function App() {
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // window.alert("test success!!")

  useEffect(() => {
    const checkAppInstalled = async () => {
      // alert("kem cho, maja maa..")
      // const relatedApps = await navigator.getInstalledRelatedApps();
      // // if (true) {
      // //   window.alert("test success!!")
      // // }
      // console.log("related apps: ", relatedApps)
      // window.alert("alert....")
      // relatedApps.forEach((app) => {
      //   console.log("print...")
      //   console.log(app.id, app.platform, app.url);
      //   alert(app.platform)
      // });
      navigator.getInstalledRelatedApps().then((relatedApps) => {
        // console.log("relatedApps: ", relatedApps)
        if (relatedApps.length > 0) {
          alert("yes..");
        }
        for (let app of relatedApps) {
          console.log(app.platform);
          console.log(app.url);
          console.log(app.id);
          alert(app.id);
          window.alert(app.id);
        }
      });
    };

    checkAppInstalled();
  }, []);

  useEffect(() => {
    window.addEventListener("load", (e) => {
      if (navigator.getInstalledRelatedApps) {
        navigator.getInstalledRelatedApps().then((apps) => {
          if (apps.length > 0) {
            console.log("app is installed, success/?!!");
          }
        });
      }
    });
  }, []);

  // useEffect(() => {
  //   if (["/login", "/register"].includes(location.pathname)) {
  //     dispatch(clearMessage()); // clear message when changing location
  //   }
  // }, [dispatch, location]);

  useEffect(() => {
    if (USERID) {
      runOneSignal();
    }
  }, [USERID]);

  const theme = createTheme({
    typography: {
      fontFamily: ["Poppins", "sans-serif", "cursive"].join(","),
    },
  });

  // useEffect(() => {
  //   if (!USERID) return;

  //   OneSignal.getUserId(function (userId) {
  //     // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
  //     localStorage.setItem("one_signal_userid", userId);
  //   });

  //   OneSignal.isPushNotificationsEnabled(function (isEnabled) {
  //     if (isEnabled) {
  //       console.log("Push notifications are enabled!");
  //     } else {
  //       console.log("Push notifications are not enabled yet.");
  //       alert(
  //         "Push notifications are disabled. Please enable it on your browser to receive real time Alerts and recommendations."
  //       );
  //     }
  //   });

  //   sendToken(USERID)
  // }, [USERID]);

  // const logOut = useCallback(() => {
  //   dispatch(logout());
  // }, [dispatch]);

  // LOGOUT IF TOKEN IS EXPIRED
  if (!!USERID) {
    const decodedJwt = parseJwt(user.access_token);

    if (decodedJwt.exp * 1000 < Date.now()) {
      dispatch(logout());
      navigate("/");
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout />
    </ThemeProvider>
  );
}

export default App;
