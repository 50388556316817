import React, { useState } from "react";

import styles from "./AllTopUpsTable.module.css";
import ReactHotToaster from "../../../../common/ReactHotToaster";
import ColorSignalCircle from "../../../../common/ColorSignalCircle";
import { useMutation } from "@tanstack/react-query";
import { axiosConfig } from "../../../../../axios/axiosConfig";
import { useSelector } from "react-redux";
import { razorpayCheckoutAddTopup } from "../../../../../utils/razorpay/addTopUpUtils";

const AllTopUpsTable = ({ allTopUpsData, userSubscriptionDetails }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const USERID = currentUser.userDetails.id;

  const [activeTopupItem, setActiveTopupItem] = useState({});
  const userSubscriptionID = userSubscriptionDetails?.id;
  
  
  // ADD USER TOP UP
  const addUserTopUp = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "/subscription/user_topup",
        data: {
          userid: USERID,
          user_subscription_id: userSubscriptionID,
          topup_plan_id: activeTopupItem.id,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      // console.log("add user topup res: ", data);
      const { customer_id, invoice_id } = data.response.invoice;
      
      const paymentObj = {
        customer_id: customer_id,
        payment_mode: "online",
        topup_price: activeTopupItem?.price,
        invoice_id: invoice_id,
        topup_plan_id: activeTopupItem?.id,
        user_topup_id: data.data.id,
        USERID: USERID
      };

      // RAZORPAY DISPLAY AFTER SUBSCRIPTION COMPLETE

      razorpayCheckoutAddTopup(paymentObj);
    },
    onError: (error) => {
      console.log("create subscription error: ", error);
      // toast.error(error.message || "Something went wrong");
    },
  });

  const handleAddTopUp = (topup_item) => {
    setActiveTopupItem(topup_item);

    const consent = window.confirm(
      `Are you sure you want to add ${topup_item.topup_code} top-up?`
    );
    if (!consent) {
      return;
    }
    addUserTopUp.mutate();
  };

  

  return (
    <div className={styles.outerContainer}>
      <h1 className={styles.tableTitle}>Buy TopUp</h1>
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Title</th>
            <th>Code</th>
            <th>Description</th>
            <th>Features</th>
            <th>Quantity</th>
            <th>Price</th>

            {/* <th>Created On</th> */}
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {allTopUpsData.map((item, index) => {
            // const createdDate = new Date(item.created_at).toDateString();

            return (
              <tr key={item.id}>
                <td>{item.title}</td>
                <td>{item.topup_code}</td>
                <td>{item.description}</td>
                <td>{item.topup_description}</td>
                <td>{item.quantity}</td>
                <td>{item.price}</td>
                {/* <td>{`${item.interval} ${item.interval_unit}`}</td> */}
                {/* <td>{createdDate}</td> */}
                <td>
                  {item.active ? (
                    <ColorSignalCircle bgColor="green" />
                  ) : (
                    <ColorSignalCircle bgColor="red" />
                  )}
                </td>
                <td>
                  <div className={styles.actionBtnContainer}>
                    <button
                      className={styles.addTopupBtn}
                      onClick={() => handleAddTopUp(item)}
                    >
                      add topup
                    </button>
                    {/* <button onClick={() => {}} >delete</button> */}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default AllTopUpsTable;
