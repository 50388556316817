import React from "react";
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinIcon
} from "react-share";

import styles from "./SocialShare.module.css";

const SocialShare = ({ linkTo }) => {
  const shareUrl = "https://faircopy.tnibro.com" + linkTo;
  const title = "TNIBro";

  return (
    <div className={styles.outer_container}>
      {/* <h4 className={styles.share_text}>
        Share with your friends and family
      </h4> */}

      {/* SOCIAL SHARE ICONS CONTAINER */}
    <div className={styles.container}>
      <div className={styles.shareIcon}>
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>

      <div className={styles.shareIcon}>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="Demo__some-network__share-button"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>

      <div className={styles.shareIcon}>
        <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      <div className={styles.shareIcon}>
        <TwitterShareButton url={shareUrl} className="Demo__some-network__share-button">
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>
    </div>
    </div>
  );
};

export default SocialShare;
