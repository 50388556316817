import React, { useState, useEffect } from "react";
import AutoComplete from "../autoComplete/AutoComplete";

import { combination, segment } from "../../utils/screener-dropdown-options";
import { useSelector } from "react-redux";
import { getUserWatchlists } from "../watchlist/watchlist_utils";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import ReactHotToaster from "../common/ReactHotToaster";
import { Stack } from "@mui/material";

const ScanHeader = ({
  handleCombinationValue,
  handleSegmentValue,
  segmentValue,
  combinationValue,
}) => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  // const DEFAULT_SEGMENT = [{label: 'Cash', value: 'cash', group: 'Segment'}];  // All users get 'cash' as default.
  const ALL_SEGMENTS_FOR_TRIAL = [
    {label: 'Cash', value: 'cash', group: 'Segment'},
    {label: 'Nifty50', value: 'nifty50', group: 'Segment'},
    {label: 'Nifty100', value: 'nifty100', group: 'Segment'},
    {label: 'Nifty200', value: 'nifty200', group: 'Segment'},
    {label: 'Nifty500', value: 'nifty500', group: 'Segment'},
    {label: 'MCX', value: 'mcx', group: 'Segment'},
    {label: 'F&O', value: 'fo', group: 'Segment'},
    {label: 'Futures', value: 'futures', group: 'Segment'},
  ]

  // converting User subscribed AddOns string from backend to array
  const userAddOnArray = userDetails.user_subscription ? userDetails.user_subscription?.add_on?.replace(/'/g, '').slice(1, -1).split(', ') : [];
  const userSegments = segment.filter(item => userAddOnArray?.includes(item.value));  // filtering only user subscribed addOns
  
  const [watchlistOptions, setWatchlistOptions] = useState([]);
  // const allSegmentOptions = [ ...userSegments, ...watchlistOptions, ...DEFAULT_SEGMENT]  // USER ADD-ONS + USER WATCHLISTS
  const allSegmentOptions = [ ...ALL_SEGMENTS_FOR_TRIAL, ...watchlistOptions]  // USER ADD-ONS + USER WATCHLISTS
  

  // FETCH USER WATCHLISTS USING useQUERY
  const watchlistQuery = useQuery({
    queryKey: ["user-watchlists"],
    queryFn: () => getUserWatchlists(userDetails.id),
    onSuccess: (data) => {
      const watchlistOptionsArr = data.response.map(item => {
        return { label: item.name, value: item.id, group: "My Watchlists" }
      })
      setWatchlistOptions(watchlistOptionsArr);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  })
  

  // USEEFFECT REPLACED WITH USEQUERY ABOVE
  // useEffect(() => {
  //   const fetchUserWatchlists = async () => {
  //     const res = await getUserWatchlists(userDetails.id);
  //     console.log(res.data.response)
  //     if (res.status === 200 || res.statusText === "OK") {
  //       const watchlistOptionsArr = res.data.response.map(item => {
  //         return { label: item.name, value: item.id, groupName: "My Watchlists" }
  //       })
  //       setWatchlistOptions(watchlistOptionsArr);
  //     }
  //   };

  //   fetchUserWatchlists();
  // }, [userDetails.id]);

  return (
    <>
      <div >
        <Stack direction="row" spacing={4} >
        {/* Stock{" "} */}
        {
          <AutoComplete
            options={combination}
            handleDropdownValue={(val, key) => handleCombinationValue(val, key)}
            value="combination"
            currentValue={combinationValue}
            label="Criteria"
            textFieldVariant="outlined"
          />
        }
        {/* of the below filters in{" "} */}
        {
          <AutoComplete
            options={allSegmentOptions}
            value="segment"
            currentValue={segmentValue}
            handleDropdownValue={(val, key) => handleSegmentValue(val)}
            label="Segment / Watchlist"
            textFieldWidth={160}
            textFieldVariant="outlined"
          />
        }
        {/* segment.{" "} */}

        </Stack>
      </div>

      {/* REACT TOAST */}
      <ReactHotToaster />
    </>
  );
};

export default ScanHeader;
