// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaymentFailure_container__t-m4a {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #f7f7f7;\n}\n\n.PaymentFailure_heading__9UkzO {\n  font-size: 3rem;\n  font-weight: bold;\n  color: #f44336;\n  margin-bottom: 1rem;\n}\n\n.PaymentFailure_message__5U363 {\n  font-size: 1.5rem;\n  color: #444;\n  text-align: center;\n  max-width: 500px;\n  margin: 0 auto;\n  line-height: 1.5;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/PaymentFailure/PaymentFailure.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #f7f7f7;\n}\n\n.heading {\n  font-size: 3rem;\n  font-weight: bold;\n  color: #f44336;\n  margin-bottom: 1rem;\n}\n\n.message {\n  font-size: 1.5rem;\n  color: #444;\n  text-align: center;\n  max-width: 500px;\n  margin: 0 auto;\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PaymentFailure_container__t-m4a",
	"heading": "PaymentFailure_heading__9UkzO",
	"message": "PaymentFailure_message__5U363"
};
export default ___CSS_LOADER_EXPORT___;
