// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.HistoricalRecommendationTable_calls_btn_container__cl5Rx {\n  display: flex;\n  \n}\n\n.HistoricalRecommendationTable_buy_calls_btn__k6cIH,\n.HistoricalRecommendationTable_sell_calls_btn__8b3lc {\n  /* border: 2px solid rgb(4, 114, 4); */\n  padding: 0.5rem 1.5rem;\n  background-color: #000;\n\n  color: #fff;\n  cursor: pointer;\n}\n\n.HistoricalRecommendationTable_sell_calls_btn__8b3lc {\n  border-left: none;\n}", "",{"version":3,"sources":["webpack://./src/components/searchResults/HistoricalRecommendationTable/HistoricalRecommendationTable.module.css"],"names":[],"mappings":";AACA;EACE,aAAa;;AAEf;;AAEA;;EAEE,sCAAsC;EACtC,sBAAsB;EACtB,sBAAsB;;EAEtB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["\n.calls_btn_container {\n  display: flex;\n  \n}\n\n.buy_calls_btn,\n.sell_calls_btn {\n  /* border: 2px solid rgb(4, 114, 4); */\n  padding: 0.5rem 1.5rem;\n  background-color: #000;\n\n  color: #fff;\n  cursor: pointer;\n}\n\n.sell_calls_btn {\n  border-left: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calls_btn_container": "HistoricalRecommendationTable_calls_btn_container__cl5Rx",
	"buy_calls_btn": "HistoricalRecommendationTable_buy_calls_btn__k6cIH",
	"sell_calls_btn": "HistoricalRecommendationTable_sell_calls_btn__8b3lc"
};
export default ___CSS_LOADER_EXPORT___;
