// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_links_container__V1rHj {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: 1rem;\n}\n\n\n\n/* MEDIA QUERIES */\n@media only screen and (max-width: 600px) {\n  .Footer_links_container__V1rHj {\n\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,SAAS;AACX;;;;AAIA,kBAAkB;AAClB;EACE;;EAEA;AACF","sourcesContent":[".links_container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: 1rem;\n}\n\n\n\n/* MEDIA QUERIES */\n@media only screen and (max-width: 600px) {\n  .links_container {\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links_container": "Footer_links_container__V1rHj"
};
export default ___CSS_LOADER_EXPORT___;
