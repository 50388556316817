export const  screenerSetOptions = [
  {
    label: "Recommended",
     value: "set_recommended"
  },

  // {
  //   label: "Intraday",
  //   value: "intraday"
  // },

  // {
  //   label: "BTST",
  //   value: "btst"
  // },

  // {
  //   label: "Swing",
  //   value: "swing"
  // },

  // {
  //   label: "STBT",
  //   value: "stbt"
  // }
];

export const  screenerSetDefaultValues = {
  "set_recommended": false,
  // "intraday": false,
  // "btst": false,
  // "swing": false,
  // "stbt": false
}

