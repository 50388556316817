import React, { useState } from "react";
import { axiosConfig } from "../../../axios/axiosConfig";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import ReactHotToaster from "../../common/ReactHotToaster";
import UserRefundsTable from "./UserRefundsTable";

const AdminRefunds = () => {
  const [refundsData, setRefundsData] = useState([]);

  const fetchAllUserRefundsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["user-refunds"], // dependency
    queryFn: () => fetchAllUserRefunds(),
    onSuccess: (data) => {
      setRefundsData(data.response);
    },
    onError: (error) => {
      console.log("refunds error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  return (
    <div>
      <h2>AdminRefunds</h2>

      {/* USER REFUNDS TABLE */}
      <div className="refundsTable">
        <UserRefundsTable
          userRefundsData={refundsData}
          setUserRefundsData={setRefundsData}
        />
      </div>

      {/* react toast */}
      <ReactHotToaster />
    </div>
  );
};

export default AdminRefunds;

export const fetchAllUserRefunds = async () => {
  const res = await axiosConfig({
    method: "get",
    url: "payment/moneyback",
    data: {},
  });

  return res.data;
};
