import React, { useState } from "react";

import axiosConfig from "../../../axios/axiosConfig";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { YEARLY_DISCOUNT } from "../../../utils/globalConstants";
import { subscriptionPlanTiers } from "../../PricingPlans";
import ReactHotToaster from "../../common/ReactHotToaster";
import UserSubscriptionDetails from "./UserSubscriptionDetails";
import { Box, Button, CircularProgress } from "@mui/material";
import { razorpayCheckout } from "../../../utils/razorpay/newRazorpayUtils";
import AddUserTopUp from "./AddUserTopUp";

import styles from "./SupbscriptionPlan.module.css";

const SubscriptionPlan = () => {
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const USERID = currentUser.userDetails.id;

  // active user topup/addon details
  const [activeTopUpDetails, setActiveTopUpDetails] = useState({});
  const [userSubscriptionDetails, setUserSubscriptionDetails] = useState({});
  // const { topup_total_alert, topup_consume_alert, topup_alerts_left } = userSubscriptionDetails;
  // const formattedDate = new Date(subscription_date).toDateString();

  
  const { consumption, created_on } = activeTopUpDetails;
  
  const [selectedPlan, setSelectedPlan] = useState("basic");
  const [planPeriod, setPlanPeriod] = useState("monthly");

  // FETCHING COUPON DETAILS API REQUEST
  const fetchUserSubscriptionQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["user-subscription"],
    queryFn: () => fetchUserSubscription(USERID),
    onSuccess: (data) => {
      console.log("user sub details: ", data.response)
      setUserSubscriptionDetails(data.response);
    },
    onError: (error) => {
      console.log("error: ", error.response);
      // if (error.response.status === 404 || error.response.statusText === "Not Found") {
      //   setUserSubscriptionDetails({ isUserSubscribed: false })
      // }
      toast.error(error?.response?.data?.response || "Something went wrong!");
    },
  });

  // user is subscribed to below plan
  const userSubscribedTier = subscriptionPlanTiers.find((item) => {
    return item.title.toLowerCase() === selectedPlan;
  });

  const formattedTier = {
    price: userSubscribedTier.price,
    title: userSubscribedTier.title,
  };

  const handleRenewPlan = () => {
    // calculating total payable amount without ADD ONS
    const totalBasePlanAmount = calcTotalPrice(userSubscribedTier, planPeriod); // calculate base plan price according to monthly or yearly
    const state = {
      totalBasePlanAmount,
      selectedTier: formattedTier,
      planPeriod,
      subscriptionType: "RENEW_PLAN",
      // subscriptionType: selectedPlan,
    };

    navigate("/cart", { state });
  };

  // Fetching active user top up details
  const fetchActiveUserTopUpsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["active-topup"],
    queryFn: () => fetchActiveTopupDetails(USERID),
    onSuccess: (data) => {
      if (!data?.response[0]) return;

      setActiveTopUpDetails(data?.response[0]);
    },
    onError: (error) => {
      console.log("error: ", error.response);
      // if (error.response.status === 404 || error.response.statusText === "Not Found") {
      //   setUserSubscriptionDetails({ isUserSubscribed: false })
      // }
      toast.error(error?.response?.data?.response || "Something went wrong!");
    },
  });

  return (
    <Box>
      {/* USER SUBSCRIPTION DETAILS COMPONENT */}
      {fetchUserSubscriptionQuery.isLoading ? (
        <Box
          sx={{
            padding: "2rem",
          }}
        >
          <CircularProgress />
        </Box>
      ) : !userSubscriptionDetails?.isSubscribed ? (
        <Box
          sx={{
            padding: "2rem",
          }}
        >
          <Button variant="contained" onClick={() => navigate("/pricing")}>
            Buy New Plan
          </Button>
        </Box>
      ) : (
        <UserSubscriptionDetails
          userSubscriptionDetails={userSubscriptionDetails}
          handleRenewPlan={handleRenewPlan}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          planPeriod={planPeriod}
          setPlanPeriod={setPlanPeriod}
        />
      )}

      {/* ACTIVE TOPUP DETAILS */}
      {/* Checking if 'activeTopUpDetails' object is empty. If there's no top, user can buy from topup list table */}
      {userSubscriptionDetails?.topup_alerts_left === "0" ? (
        <AddUserTopUp userSubscriptionDetails={userSubscriptionDetails} />
      ) : (
        <div className={styles.activeTopDetailsContainer}>
          <h2>Active Alert Add On Details</h2>
          <div className={styles.detailsList}>
            {/* <DetailItem label="Purchased On" value={created_on} />
            <DetailItem label="Alerts Used" value={consumption} />             */}
            <DetailItem label="Total Alerts" value={userSubscriptionDetails?.topup_total_alert} />            
            <DetailItem label="Alerts Consumed" value={userSubscriptionDetails?.topup_consume_alert} />            
            <DetailItem label="Alerts Left" value={userSubscriptionDetails?.topup_alerts_left} />            
          </div>
        </div>
      )}

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </Box>
  );
};

export default SubscriptionPlan;

function DetailItem({ label, value }) {
  return (
    <div className={styles.detailItem}>
      <h3>{label}</h3>
      <p>{value}</p>
    </div>
  );
}


// UTLILITY / API FUNCTIONS
const fetchUserSubscription = async (user_id) => {
  // return;
  if (!user_id) {
    return;
  }

  const res = await axiosConfig({
    method: "get",
    url: `/subscription/user_subscription?userid=${user_id}`,
    data: {},
  });

  return res.data;
};

function calcTotalPrice(selected_tier, plan_period) {
  let total_price = 1;

  if (plan_period === "monthly") {
    total_price = selected_tier.price;
  }

  // if yearly plan is selected, then multiply by 12, and apply the discount
  if (plan_period === "yearly") {
    let yearly_price = selected_tier.price * 12; // yearly price without discount
    total_price = yearly_price - yearly_price * YEARLY_DISCOUNT;
  }

  return total_price;
}

// Utilities
const fetchActiveTopupDetails = async (user_id) => {
  // return;
  if (!user_id) {
    return;
  }

  const res = await axiosConfig({
    method: "get",
    url: `/subscription/user_topup?userid=${user_id}`,
    data: {},
  });

  return res.data;
};
