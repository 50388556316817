import React, { useState } from "react";

import styles from "./SubscriptionPlansTable.module.css";
import ReactHotToaster from "../../../common/ReactHotToaster";
import ColorSignalCircle from "../../../common/ColorSignalCircle";
import MuiModal from "../../../muiComps/MuiModal";
import CreateSubscriptionForm from "../CreateSubscriptionForm";

const SubscriptionPlansTable = ({
  plansData = [],
  setOpenSubscriptionForm,
  setActivePlan,
}) => {
  // const [openSubscriptionForm, setOpenSubscriptionForm] = useState(false);

  return (
    <div className={styles.outerContainer}>
      <h1 className={styles.tableTitle}>List of Subscription Plans</h1>
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Code</th>
            <th>Description</th>
            <th>Features</th>
            <th>Price</th>
            <th>Period</th>
            {/* <th>Created On</th> */}
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {plansData.map((item, index) => {
            // const createdDate = new Date(item.created_at).toDateString();

            return (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.plan_code}</td>
                <td>{item.description}</td>
                <td>{item.plan_description}</td>
                <td>{item.price}</td>
                <td>{`${item.interval} ${item.interval_unit}`}</td>
                {/* <td>{createdDate}</td> */}
                <td>
                  {item.active ? (
                    <ColorSignalCircle bgColor="green" />
                  ) : (
                    <ColorSignalCircle bgColor="red" />
                  )}
                </td>
                <td>
                  <div className={styles.actionBtnContainer}>
                    <button
                      className={styles.btn}
                      onClick={() => {
                        setOpenSubscriptionForm(true);
                        setActivePlan(item);
                      }}
                    >
                      edit
                    </button>
                    {/* <button onClick={() => {}} >delete</button> */}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* subscription form modal */}
      {/* <MuiModal open={openSubscriptionForm} setOpen={setOpenSubscriptionForm} >
        <CreateSubscriptionForm formType="edit" />
      </MuiModal> */}

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default SubscriptionPlansTable;
