import {
  Box,
  Stack,
  Typography,
  Container,
  Paper,
  Card,
  TextField,
  Button,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import toast from "react-hot-toast";
import React, { useState } from "react";
import { useSelector } from "react-redux";
// import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import ReactHotToaster from "../../common/ReactHotToaster";

import axiosConfig from "../../../axios/axiosConfig";
import { useNavigate } from "react-router-dom";
import { curry } from "lodash";
import MuiModal from "../../muiComps/MuiModal";
import UpdateNameEmailModal from "./UpdateNameEmailModal";

const styles = {
  labelContainer: {
    border: "1px solid #ccc",
    p: "8.5px 14px",
    borderRadius: "4px",
    backgroundColor: "#eee",
    color: "#555",
  },
  editIconContainer: {
    // border: "1px solid #ccc",
    // borderRadius: "50%",
    padding: "2px",
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  }
};

// const ROOT_DOMAIN =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:3000"
//     : "https://domainname.com";
// const ROOT_DOMAIN = "https://tnibro.com";
const ROOT_DOMAIN = "https://stockyaari.com";
// http://localhost:3000/register?referral_code=IRD2HMU7G6XY

// https://usehooks-ts.com/react-hook/use-copy-to-clipboard

const UserProfile = () => {
  const navigate = useNavigate();
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const USERID = userDetails?.id;

  const [value, copy] = useCopyToClipboard();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const [openNameEmailEditModal, setOpenNameEmailEditModal] = useState(false);

  const updateFullName = async () => {
    if (!fullName || fullName.trim() === "") return;

    const consent = window.confirm(
      "Are you sure you want to update full name?"
    );

    if (!consent) return;

    try {
      const res = await axiosConfig({
        method: "patch",
        url: `/enroll/user_profile?userid=${USERID}`,
        data: { username: fullName },
      });

      if (res.statusText === "OK" || res.status === 200) {
        const user = JSON.parse(localStorage.getItem("user"));
        const newUser = { ...user, userDetails: res.data.response };
        localStorage.setItem("user", JSON.stringify(newUser));

        toast.success("Name changed successfully!");
      }
    } catch (error) {
      console.log("updateFullName error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    }
  };

  const disableSaveBtn = !fullName || fullName.trim() === "";

  const REFERRAL_LINK_STAGING = `https://faircopy.tnibro.com/register?referral_code=${userDetails.referralcode}`;
  const REFERRAL_LINK = `${ROOT_DOMAIN}/register?referral_code=${userDetails.referralcode}`;

  // SEND EMAIL OTP HANDLER
  const sendEmailOTP = async () => {
    try {
      const res = await axiosConfig({
        method: "patch",
        url: `/enroll/user_profile?userid=${userDetails?.id}`,
        data: { email: email },
      });

      // if success, setVerifyBtnClicked(true)
      if (res.status === 200 || res.statusText === "OK") {
        toast.success("Verification code sent to your email.");
        navigate("/email-update-verify", { state: { email: email } });
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.response || "Something went wrong");
    }
  };

  return (
    <Container>
      <h2>User Profile</h2>

      {/* fullname, email, phone, country, usertype, joined on */}
      {/* Outer Box that contains Inner box user details and Plan card */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Card
          sx={{
            maxWidth: 700,
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            backgroundColor: "white",
            p: 2,
          }}
        >

          {/* NAME & EMAIL CONTAINER/ BOX */}
          <Stack sx={{
            border: "1px solid #ccc",
            padding: '1rem',
            position: "relative",
          }} >
            <div style={styles.editIconContainer} >

              <EditIcon size="small" onClick={() => setOpenNameEmailEditModal(true)} />
            </div>
          <Stack sx={{ m: "1rem 0", minWidth: 300 }}>
            <span style={{ fontSize: "14px" }}>Full Name</span>

            <Box
              sx={{
                border: "1px solid #ccc",
                marginTop: "0.5rem",
                padding: "0.5rem",
                borderRadius: 1,
                backgroundColor: "#eee",
                color: "#555",
              }}
            >
              {userDetails.username}
            </Box>

            
          </Stack>

          <Stack sx={{ m: "1rem 0", minWidth: 300 }}>
            <span style={{ fontSize: "14px" }}>Email</span>
            {/* <Box sx={styles.labelContainer}>
              {userDetails.email ? userDetails.email : "-"}
            </Box> */}

            <Box
              sx={{
                border: "1px solid #ccc",
                marginTop: "0.5rem",
                padding: "0.5rem",
                borderRadius: 1,
                backgroundColor: "#eee",
                color: "#555",
              }}
            >
              {userDetails.email ? userDetails.email : "-"}
            </Box>

            {/* <TextField
              id="email"
              name="email"
              defaultValue={userDetails.email ? userDetails.email : "-"}
              onChange={(e) => setEmail(e.target.value)}
              size="small"
            /> */}
            {/* <Button
              variant="contained"
              size="small"
              sx={{ mt: 1 }}
              onClick={sendEmailOTP}
            >
              Verify Email
            </Button> */}
          </Stack>
          </Stack>

          <Stack sx={{ m: "1rem 0", minWidth: 300 }}>
            <span style={{ fontSize: "14px" }}>Phone</span>
            <Box sx={styles.labelContainer}>
              {userDetails.phoneno ? userDetails.phoneno : "-"}
            </Box>
            {/* <TextField
            // label="Size"
            id="phone"
            defaultValue="Small"
            size="small"
          /> */}
          </Stack>

          <Stack sx={{ m: "1rem 0", minWidth: 300 }}>
            <span style={{ fontSize: "14px" }}>Country</span>
            <Box
              sx={{
                border: "1px solid #ccc",
                p: "8.5px 14px",
                borderRadius: "4px",
                backgroundColor: "#eee",
                color: "#555",
              }}
            >
              {userDetails.country}
            </Box>
          </Stack>

          <Stack sx={{ m: "1rem 0", minWidth: 300 }}>
            <span style={{ fontSize: "14px" }}>User Type</span>
            <Box
              sx={{
                border: "1px solid #ccc",
                p: "8.5px 14px",
                borderRadius: "4px",
                backgroundColor: "#eee",
                color: "#555",
              }}
            >
              {userDetails.user_type}
            </Box>
          </Stack>

          <Stack sx={{ m: "1rem 0", minWidth: 300 }}>
            <span style={{ fontSize: "14px" }}>Joined On:</span>
            <Box
              sx={{
                border: "1px solid #ccc",
                p: "8.5px 14px",
                borderRadius: "4px",
                backgroundColor: "#eee",
                color: "#555",
              }}
            >
              {new Date(userDetails.date_joined).toDateString()}
            </Box>
          </Stack>

          <Stack sx={{ m: "1rem 0", minWidth: 300 }}>
            <span style={{ fontSize: "14px" }}>REFERRAL CODE</span>
            <Box sx={styles.labelContainer}>{userDetails.referralcode}</Box>
          </Stack>

          <Stack sx={{ m: "1rem 0" }}>
            <span style={{ fontSize: "14px" }}>REFERRAL LINK (STAGING)</span>
            <Stack direction="row" spacing={2}>
              <Box sx={styles.labelContainer}>{REFERRAL_LINK_STAGING}</Box>

              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  copy(REFERRAL_LINK_STAGING);
                  toast.success("Referral Link copied to clipboard");
                }}
              >
                Copy Link
              </Button>
            </Stack>
          </Stack>

          <Stack sx={{ m: "1rem 0" }}>
            <span style={{ fontSize: "14px" }}>REFERRAL LINK</span>
            <Stack direction="row" spacing={2}>
              <Box sx={styles.labelContainer}>{REFERRAL_LINK}</Box>

              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  copy(REFERRAL_LINK);
                  toast.success("Referral Link copied to clipboard");
                }}
              >
                Copy Link
              </Button>
            </Stack>
          </Stack>
        </Card>

        {/* SUBSCRIPTION PLAN CARD */}
        <SubscriptionPlanCard />
      </Box>

      <Stack direction="row" spacing={2}>
        <Button
          disabled={disableSaveBtn}
          variant="contained"
          size="small"
          color="secondary"
          sx={{ mt: 4 }}
          onClick={updateFullName}
        >
          save
        </Button>
      </Stack>

      {/* <Stack direction="row" spacing={2}>
        <LoadingButton
          loading
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
        >
          Save
        </LoadingButton>
      </Stack> */}

      {/* EDIT NAME, EMAIL MODAL */}
      <MuiModal open={openNameEmailEditModal} setOpen={setOpenNameEmailEditModal} >
        <UpdateNameEmailModal />
      </MuiModal>

      {/* REACT TOAST */}
      <ReactHotToaster />
    </Container>
  );
};

export default UserProfile;
