import React from "react";
import {
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import styles from "./CreateCouponForm.module.css";

const CreateCouponForm = ({
  couponDetailsInput,
  setCouponDetailsInput,
  isDeactivate,
  setIsDeactivate,
  handleCouponSubmit,
  handleCouponEditSave,
  isEditMode,
  // activeUserCouponDetails,
}) => {
  const {
    coupon_name,
    coupon_desc,
    coupon_code,
    start_date,
    expiry_date,
    // isActive,
    discount_by, // flat or percentage
    discount_value,
    max_redemption,
    isHidden,
  } = couponDetailsInput;

  const CREATE_BTN_DISABLED =
    !coupon_name ||
    coupon_name.trim() === "" ||
    !coupon_desc ||
    coupon_desc.trim() === "" ||
    !coupon_code ||
    coupon_code.trim() === "" ||
    !discount_by ||
    discount_by.trim() === "" ||
    !discount_value ||
    discount_value.trim() === "" ||
    !max_redemption ||
    max_redemption.trim() === "" ||
    !start_date ||
    start_date.trim() === "" ||
    !expiry_date ||
    expiry_date.trim() === "";

  const couponsInputChangeHandler = (event) => {
    // state change for exclusive/hidden toggle only
    if (event.target.name === "isHidden") {
      setCouponDetailsInput((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.checked,
        };
      });

      return;
    }

    setCouponDetailsInput((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  // handler for isActive Input
  const isDeactiveOnChange = (event) => {
    // IMPORTANT: IF INPUT IS TRUE/CHECKED, ADMIN WANTS TO DEACTIVATE
    const input = event.target.checked;

    if (input) {
      const prompt = window.confirm("Are you sure you want to deactivate?");

      // if cancelled / false ()
      if (!prompt) return;
    }

    setIsDeactivate(event.target.checked);
  };

  // LABEL STYLING
  const textLabelStyles = {
    textTransform: "uppercase",
    fontWeight: 500,
    letterSpacing: 1,
  };

  return (
    <div>
      <Typography
        variant="h5"
        sx={{
          // display: 'inline-block',
          margin: "1rem 0",
          padding: "0.5rem 0",
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
          // fontWeight: 500,
        }}
      >
        {isEditMode ? "Edit" : "Create"} Coupon
      </Typography>

      {/* EDIT LIMITATIONS MESSAGE */}
      {isEditMode && (
        <div className={styles.editMsgContainer}>
          <p className={styles.editMsg}>Disabled fields are not editable</p>
        </div>
      )}

      {/* form */}
      <Stack spacing={2} sx={{}}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>
            Coupon Name:{" "}
          </Typography>

          <TextField
            required
            size="small"
            id="coupon_name"
            name="coupon_name"
            label="Coupon Name"
            value={coupon_name}
            onChange={couponsInputChangeHandler}
            // defaultValue="Hello World"
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>
            Description:{" "}
          </Typography>

          <TextField
            required
            size="small"
            id="coupon_desc"
            name="coupon_desc"
            label="Description"
            value={coupon_desc}
            onChange={couponsInputChangeHandler}
            // defaultValue="Hello World"
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>
            COUPON CODE:{" "}
          </Typography>

          <TextField
            disabled={isEditMode}
            required
            size="small"
            id="coupon_code"
            name="coupon_code"
            label="Coupon Code"
            placeholder="eg. DIWALIOFFER"
            value={coupon_code.toUpperCase()}
            onChange={couponsInputChangeHandler}
            // defaultValue="Hello World"
          />
        </Stack>

        {/* UNABLE TO APPLY FLAT DISCOUNT AS GETTING 'CANT APPLY FLAT DISCOUNT IN INCLUSIVE TAX' ERROR */}
        {/* DISCOUNT TYPE (Flat or Percentage)  */}
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>
            Discount Type:
          </Typography>

          <select
            disabled={isEditMode}
            required
            name="discount_by"
            id="discount_by"
            value={discount_by}
            onChange={couponsInputChangeHandler}
          >
            {/* <option value="flat">Flat</option> */}
            <option value="percentage">Percentage</option>
          </select>
        </Stack>

        {/* DISCOUNT VALUE: Flat discount or Percentage Value in Integer */}
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>
            Discount Value(%):{" "}
          </Typography>

          <TextField
            disabled={isEditMode}
            required
            type="number"
            size="small"
            id="discount_value"
            name="discount_value"
            label="Discount Value"
            placeholder="eg. 50"
            value={discount_value}
            onChange={couponsInputChangeHandler}
            // defaultValue="Hello World"
            inputProps={{
              minlength: 0,
              // maxlength: discount_by === 'percentage' ? "100" : null,
              maxLength: 11,
            }}
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>
            Max Redemptions:
          </Typography>

          <TextField
            // disabled={isEditMode}
            required
            type="number"
            size="small"
            id="max_redemption"
            name="max_redemption"
            label="Max Redemptions"
            placeholder="eg. 100"
            value={max_redemption}
            onChange={couponsInputChangeHandler}
            // defaultValue="Hello World"
            inputProps={{}}
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>
            Start Date:{" "}
          </Typography>

          <input
            disabled={isEditMode}
            required
            type="date"
            min={new Date().toISOString().slice(0, -14)}
            name="start_date"
            id="start_date"
            value={start_date}
            onChange={couponsInputChangeHandler}
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>
            Expiry Date:{" "}
          </Typography>

          <input
            required
            type="date"
            name="expiry_date"
            id="expiry_date"
            value={expiry_date}
            onChange={couponsInputChangeHandler}
          />
        </Stack>

        {/* HIDDEN / EXLUSIVE OFFER TOGGLE */}
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>
            Exclusive for Specific Users?
          </Typography>

          <input
            required
            type="checkbox"
            name="isHidden"
            id="isHidden"
            value={isHidden}
            onChange={couponsInputChangeHandler}
          />
        </Stack>

        {/* ONLY SHOW CHECK BOX IF EDIT MODE IS ON */}
        {isEditMode ? (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1">
              Deactivate? (Active if unchecked):
            </Typography>

            <Checkbox
              // checked={isActiveInput}
              checked={isDeactivate}
              // onChange={handleChange}
              onChange={isDeactiveOnChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
        ) : null}

        {/* BUTTONS */}
        <div
          style={{
            margin: "2rem",
          }}
        >
          {isEditMode ? (
            <Stack>
              <Button variant="contained" onClick={handleCouponEditSave}>
                save
              </Button>
            </Stack>
          ) : (
            <Stack>
              <Button
                disabled={CREATE_BTN_DISABLED}
                variant="contained"
                onClick={handleCouponSubmit}
              >
                create
              </Button>
            </Stack>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default CreateCouponForm;
