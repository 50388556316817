import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosConfig from "../../../axios/axiosConfig";

import TableViewIcon from "@mui/icons-material/TableView";

import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';
import ReactHotToaster from "../../common/ReactHotToaster";

// DashboardItem is a component, mapped in DashboardList
const DashboardItem = ({
  id: dashboardID,
  dashboard_name,
  screener_list,
  setUserDashboards,
}) => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const USERID = userDetails.id;

  const navigate = useNavigate();

  const handleDeleteDashboard = async () => {
    const answer = window.confirm(
      "Are you Sure you want to delete this dashboard? This can't be undone."
    );

    if (answer) {
      try {
        const res = await axiosConfig({
          method: "delete",
          url: `/user/dashboard?dashboard_id=${dashboardID}&userid=${USERID}`,
          // data: {},
        });

        // IF dashboard deleted successfully
        if (res.status === 200 || res.statusText === "OK") {
          setUserDashboards((prev) => {
            return prev.filter((dashboard) => dashboard.id !== dashboardID);
          });

          toast.success('Dashboard deleted successfully!');
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      }
    }
  };

  const routeToSingleDashboard = () => {
    navigate(`/dashboards/${dashboardID}`);
  };

  return (
    <Box sx={{ width: "auto", maxWidth: 500, m: 2 }}>
      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              mb: 1
            }}
          >
            <Stack direction="row" gap={1}>
              <TableViewIcon />

              <Link
                to={`/dashboards/${dashboardID}`}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="h6"
                  // component="div"
                  sx={{ textTransform: "uppercase" }}
                >
                  {dashboard_name}
                </Typography>
              </Link>
            </Stack>

            <DeleteIcon
              onClick={handleDeleteDashboard}
              color="error"
              sx={{ cursor: "pointer" }}
            />
          </Box>

          <Typography variant="body2">
            {/* {typeof screener_list === 'string' ? '0' : screener_list.length} Screeners */}
            {screener_list?.length} Screeners
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={routeToSingleDashboard} size="small">
            View
          </Button>
        </CardActions>
      </Card>

      {/* REACT TOAST */}
      <ReactHotToaster />
    </Box>
  );
};

export default DashboardItem;
