import { Box } from "@mui/material";
import React, { useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";

// import styles from "./ImageTextCarousel.module.css";
import Slider from "react-slick";

// import image1 from "../../assets/image-slider/banner.jpg";
import image1 from "../../../assets/image-slider/banner.jpg";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const images = [{ url: image1 }, { url: image1 }, { url: image1 }];

const MainImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    // autoplaySpeed: 2000,
    // centerPadding: "60px",
    nextArrow: <ArrowForwardIosIcon sx={{ color: "#000" }} />,
    prevArrow: <ArrowBackIosIcon sx={{ color: "#000" }} />,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       // initialSlide: 2
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  const styles = {
    image: {
      width: '100%',
    }
    
  }

  return (
    <div>
      <Slider {...settings}>
        {images.map((item, index) => {
          return (
            <div style={{ width: '100%', height: '250px'}} >
              <img style={styles.image} src={item.url} alt=""  />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default MainImageSlider;
