import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import styles from './Footer.module.css';
// OLD FOOTER PAGE, NOT BEING USED

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        // height: "70px",
        backgroundColor: "primary.main",
        // paddingTop: "1rem",
        // paddingBottom: "1rem",
        padding: "0.5rem 0",
        position: "static",
        bottom: 0,
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography color="white" variant="h6">
              TNIBRO &copy; 2023
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <Typography color="textSecondary" variant="subtitle1">
              {`${new Date().getFullYear()} | React | Material UI | React Router`}
            </Typography> */}
            {/* <Stack spacing={4} direction="row" alignItems="center"> */}
            <div className={styles.links_container}>
                
              <Link to="/">
                <span style={{ color: "white" }}>Home</span>
              </Link>
              <Link to="/privacy-policy">
                <span style={{ color: "white" }}>Privacy Policy</span>
              </Link>
              <Link to="/refunds-cancellation">
                <span style={{ color: "white" }}>
                  Refund & Cancellation Policy
                </span>
              </Link>
              <Link to="/terms-conditions">
                <span style={{ color: "white" }}>Terms & Conditions</span>
              </Link>
              <Link to="/contact-us">
                <span style={{ color: "white" }}>Contact Us</span>
              </Link>

              <div>
                <a
                  href="#"
                  onclick="window.open('https://www.sitelock.com/verify.php?site=tnibro.com','SiteLock','width=400,height=400,left=160,top=170');"
                >
                  <img
                    class="img-fluid"
                    alt="SiteLock"
                    title="SiteLock"
                    src="https://shield.sitelock.com/shield/tnibro.com"
                    height={50}
                  />
                </a>
              </div>
              </div>
            {/* </Stack> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
