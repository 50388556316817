import React from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import DashboardLayout from "../components/common/DashboardLayout";

const mainListItemsArray = [
  {
    id: 1,
    label: "Advisory Form",
    link: "/advisor-dashboard/advisory-form",
    icon: <DashboardIcon />,
  },
  {
    id: 2,
    label: "User Support",
    link: "/advisor-dashboard/user-support",
    icon: <SupportAgentIcon />,
  },
  // {
  //   id: 3,
  //   label: "My Subscription",
  //   link: "/advisor/subscription",
  //   icon: <ReceiptIcon />,
  // },
];

export default function AdvisorDashboardLayout() {
  return <DashboardLayout mainListItems={mainListItemsArray} />;
}
