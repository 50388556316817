import React from 'react';

import styles from './UnderConstruction.module.css';
import Footer from '../homepage/Footer';

const UnderConstruction = () => {
  return (
    <>
    <div className={styles.body} >
    <div className={styles.container}>
        <h1 className={styles.title} >This Site is Under Construction</h1>
        <p className={styles.text} >We apologize for the inconvenience, but our website is currently undergoing maintenance.</p>
        <p className={styles.text} >Please check back later for updates.</p>
    </div>
</div>

    <Footer />
</>
  )
}

export default UnderConstruction