import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";

import styles from "./Banner.module.css";
import { useMutation } from "@tanstack/react-query";
import { axiosConfig } from "../../../../axios/axiosConfig";

const Banner = () => {
  const [imgFile, setImgFile] = useState();
  const [imgPreview, setImgPreview] = useState(null);
  
  const [formInput, setFormInput] = useState({
    title: "",
    description: "",
    imgUrl: "",
    targetUrl: "",
  });

  const handleFormInputOnChange = (event) => {
    setFormInput((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });

    // image preview code
    if (event.target.name === "imgUrl") {
      setImgPreview(event.target.value);
    }
  };

  // console.log("formInput: ", formInput)

  function handleImageFileChange(e) {
    setImgPreview(URL.createObjectURL(e.target.files[0]));
    setImgFile(e.target.files[0]);

    setFormInput((prev) => {
      return {
        ...prev,
        imgUrl: URL.createObjectURL(e.target.files[0]),
      };
    });
  }

  // upload home banner
  const uploadHomeBannerMutation = useMutation({
    mutationFn: (variables) => uploadHomeBanner(formInput, imgFile),
    onSuccess: (data) => {
      console.log("data: ", data)
      // setAllUsersTicketsList((prevState) => {
      //   return prevState.filter((item) => item.id !== ticket_id);
      // });

      
    },
    onError: (error) => {
      console.log("del comment error: ", error);
    },
    onSettled: (data) => {},
  });

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    e.preventDefault();

    uploadHomeBannerMutation.mutate();
  };

  return (
    <div>
      <h2>Banner Form</h2>

      {/* Banner form */}
      {/* name?, description?, image url / upload, deeplink / Launch URL */}
      <div className={styles.formPreviewContainer}>
        <form className={styles.bannerForm}>
          {/* <div className={styles.inputBox}>
            <label htmlFor="title">Title</label>
            <input
              className={styles.inputControl}
              type="text"
              name="title"
              id="title"
              value={formInput.title}
              onChange={handleFormInputOnChange}
            />
          </div>

          <div className={styles.inputBox}>
            <label htmlFor="description">description</label>
            <input
              className={styles.inputControl}
              type="text"
              name="description"
              id="description"
              value={formInput.description}
              onChange={handleFormInputOnChange}
            />
          </div> */}

          <div className={styles.inputBox}>
            <label htmlFor="title">Image</label>

            <div className={styles.imgUploadContainer}>
              <input
                className={styles.imgUrlInput}
                type="text"
                name="imgUrl"
                id="imgUrl"
                value={formInput.imgUrl}
                onChange={handleFormInputOnChange}
              />
              <label htmlFor="imageUpload">
                {/* <UploadIcon /> */}
                <span className={styles.uploadBtn}>upload</span>
                <input
                  onChange={handleImageFileChange}
                  className={styles.imgFileInput}
                  type="file"
                  name="imageUpload"
                  id="imageUpload"
                />
              </label>
            </div>
          </div>

          {/* target url */}
          <div className={styles.inputBox}>
            <label htmlFor="targetUrl">Target Link</label>
            <input
              className={styles.inputControl}
              type="text"
              name="targetUrl"
              id="targetUrl"
              value={formInput.targetUrl}
              onChange={handleFormInputOnChange}
            />
          </div>

          <div className="btnContainer">
            <button className="submitBtn" onClick={handleSubmit}>
              submit
            </button>
          </div>
        </form>

        {/* Image Preview Container */}
        <div className={styles.imgPreviewContainer}>
          <div className={styles.imgContainer}>
            <img className={styles.imgPreview} src={imgPreview} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

// utilities
const uploadHomeBanner = async (form_input, img_file) => {
  // if (!ticket_id) return;

  const res = await axiosConfig({
    method: "post",
    url: "/home/banner",
    data: {
      image: img_file,
      image_link: form_input?.imgUrl,
      jump_screen_link: form_input?.targetUrl,
    },
  });

  return res.data;
};
