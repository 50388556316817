// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ThanksForPaying_thanksContainer__Tf\\+Bg {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #f5f5f5;\n}\n\n.ThanksForPaying_thanksIcon__RZcZt {\n  margin-bottom: 30px;\n}\n\n.ThanksForPaying_thanksHeading__sAeiN {\n  font-size: 36px;\n  font-weight: bold;\n  color: #333;\n  margin-bottom: 15px;\n}\n\n.ThanksForPaying_thanksMessage__\\+yBaM {\n  font-size: 16px;\n  color: #666;\n  line-height: 1.5;\n}", "",{"version":3,"sources":["webpack://./src/components/common/ThanksForPaying/ThanksForPaying.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".thanksContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #f5f5f5;\n}\n\n.thanksIcon {\n  margin-bottom: 30px;\n}\n\n.thanksHeading {\n  font-size: 36px;\n  font-weight: bold;\n  color: #333;\n  margin-bottom: 15px;\n}\n\n.thanksMessage {\n  font-size: 16px;\n  color: #666;\n  line-height: 1.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thanksContainer": "ThanksForPaying_thanksContainer__Tf+Bg",
	"thanksIcon": "ThanksForPaying_thanksIcon__RZcZt",
	"thanksHeading": "ThanksForPaying_thanksHeading__sAeiN",
	"thanksMessage": "ThanksForPaying_thanksMessage__+yBaM"
};
export default ___CSS_LOADER_EXPORT___;
