import React, { useState } from "react";

const UpdateUserDetailsForm = () => {
  const [activeScreen, setActiveScreen] = useState("name-update"); // name-update, otp-input, submit-otp

  return (
    <div>
      <h2>UpdateUserDetailsForm</h2>

      <div className="formContainer">
        <div className="inputControl">
          <label htmlFor="firstName">firstName</label>
          <input type="text" name="firstName" id="firstName" />
        </div>

        <div className="inputControl">
          <label htmlFor="lastName">lastName</label>
          <input type="text" name="lastName" id="lastName" />
        </div>

        <div className="inputControl">
          <label htmlFor="email">email</label>
          <input type="email" name="email" id="email" />
        </div>

        <button className="sendOtp">send email code</button>
      </div>

      {/* OTP INPUT */}
      <div className="otpContainer">
        <div className="inputControl">
          <label htmlFor="otp">Verification Code:</label>
          <input type="text" name="otp" id="otp" />
        </div>

        <button className="submitBtn">submit</button>
      </div>
    </div>
  );
};

export default UpdateUserDetailsForm;
