import React from 'react'
import UserData from '../../../User/UserData'

// made this component to simplify the structure
const Users = () => {
  return (
    <UserData />
  )
}

export default Users