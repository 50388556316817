import React, { useEffect, useState, Suspense, lazy } from "react";

import { useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { useSearchParams, useLocation } from "react-router-dom";

import axios from "axios";
import toast from "react-hot-toast";
import Screener from "../Screener/Screener";
import Loader from "../components/Loading/Loading";
import BasicButton from "../components/Button/BasicButton";
import BasicTextFields from "../components/Modal/Input";
import SavedScanWithResult from "./SavedScanWithResult";
import BasicPagination from "../components/Pagination/Pagination";
import { useSelector } from "react-redux";
import SavedScanDetails from "./SavedScanDetails";
import { CircularProgress, Stack, Tooltip } from "@mui/material";
import ReactHotToaster from "../components/common/ReactHotToaster";

import axiosConfig from "../axios/axiosConfig";
import BackTestTiles from "./BackTestTiles";
// import SymbolsListTable from "./SymbolsListTable";
import DenseTable from "../components/Tables/Tables";
import MuiLinearProgressLoader from "../components/muiComps/MuiLinearProgressLoader";
import NewScan from "../components/main/NewScan";

// LAZY LOADING COMPONENTS
const SymbolsListTable = lazy(() => import("./SymbolsListTable"));

const label = { inputProps: { "aria-label": "controlled" } };

const SavedScan = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const { currentPage: globalCurrentPage, rowsPerPage } = useSelector(
    (store) => store.pagination
  );

  // STATE FOR SCREENR TABLE
  // const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedStockCategory, setSelectedStockCategory] = useState("");

  const [savedScan, setSavedScan] = useState({});
  const [scanQuery, setScanquery] = useState([]);
  const [savedScanList, SetSavedScanList] = useState([]);

  const [latestStocks, setLatestStocks] = useState([]);

  // savedScreenerResult stores the list of stocks as a result. Used where screenerQuery is private.
  const [savedScreenerResult, setSavedScreenerResult] = useState(null);
  const [screenerObjDetails, setScreenerObjDetails] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [itemsLimit, setItemsLimit] = useState(10);

  const [isLoading, setIsLoading] = useState(false);
  const [scanSearchText, setScanSearchText] = useState("");

  const [userScreenerAlertDetail, setUserScreenerAlertDetail] = useState({}); // user's screener alert details

  // CHANGES ON 16/08/2023
  const [showBackTestTiles, setShowBackTestTiles] = useState(true);
  const [timeframeDate, setTimeframeDate] = React.useState(null);
  const [symbolsData, setSymbolsData] = React.useState([]);
  const [isSymbolsDataLoading, setIsSymbolsDataLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramId = searchParams.get("id");

  // PAGINATION STATES FOR BACKTESTING
  // const [backtestCurrentPage, setBacktestCurrentPage] = useState(1);
  const [backtestTotalStocks, setBacktestTotalStocks] = useState(0);

  // const fetchBacktestSymbols = async () => {
  //   if (!timeframeDate || timeframeDate === "" || !paramId) {
  //     return;
  //   }

  //   setIsSymbolsDataLoading(true);

  //   try {
  //     const res = await axiosConfig({
  //       method: "post",
  //       url: "/backtest",
  //       // url: `/backtest?page_no=${backtestCurrentPage}&per_page=${rowsPerPage}`,
  //       data: {
  //         screenerid: paramId || savedScan?.id,
  //         timeframedate: timeframeDate,
  //       },
  //     });

  //     if (res.status === 200 || res.statusText === "OK") {
  //       setBacktestTotalStocks(res.data.total_length);
  //       setSymbolsData(res.data.response);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsSymbolsDataLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchBacktestSymbols();
  // }, [timeframeDate, paramId, savedScan?.id]);

  // useEffect(() => {
  //   if (!savedScreenerResult?.response.length || savedScreenerResult?.response.length === 0) return;

  //   const fetchBacktestSymbols = async () => {
  //     if (!timeframeDate || timeframeDate === "") {
  //       return;
  //     }

  //     try {
  //       const res = await axiosConfig({
  //         method: "post",
  //         url: "/backtest",
  //         data: { screenerid: paramId || savedScan?.id, timeframedate: timeframeDate },
  //       });

  //       setSymbolsData(res.data.response);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchBacktestSymbols();
  // }, [timeframeDate, paramId, savedScan?.id]);

  // *****************************************************************************

  const limit = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
  ];

  // THIS FUNCTON IS FIRST RUNNING IN USEEFFECT (when there's no parameter
  // in URL). This means this function is not NEEDED when there's
  // param/ScreenerID in URL.  THEN EVERYTIME WHEN STATE IS UPDATED
  const handleFetchScanList = () => {
    setIsLoading(true);

    const param = {
      limit: itemsLimit,
      offset: (currentPage - 1) * itemsLimit,
    };

    if (scanSearchText !== "") {
      param.search = scanSearchText;
    }

    axios
      .get("/tnibroadmin/scanner", {
        params: {
          userid: userDetails.id,
          ...param,
        },
      })
      .then((response) => {
        // code on 17/08/2023
        // fetchBacktestSymbols();
        if (response.data.status === "success") {
          setSavedScan({});
          setScanquery([]);
          SetSavedScanList(response.data.response);
          // setLatestStocks()

          const totalPage = Math.ceil(response.data.total_count / itemsLimit);
          setTotalPages(totalPage);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      })
      .finally(() => setIsLoading(false));
  };

  const deleteScreener = (scan) => {
    const consent = window.confirm(
      "Are you sure you want to delete this Scan?"
    );
    if (!consent) return;

    axios
      .delete(`/tnibroadmin/scanner?id=${scan.id}`)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success("Scan deleted!");
        }
        handleFetchScanList();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      });
  };

  const editScreener = (scan) => {
    setSavedScan(scan);
    const query = JSON.parse(scan.scanner_query);
    setScanquery(query);

    setSearchParams({ id: scan.id });
  };

  // const handleUpdateScreener = (editedFields, id) => {
  //   axios
  //     .patch(`/tnibroadmin/scanner?id=${id}`, {
  //       ...editedFields,
  //     })
  //     .then((response) => {
  //       const scanList = {
  //         ...response.data.response,
  //         scanner_query: JSON.parse(response.data.response.scanner_query),
  //       };
  //       setSavedScan(scanList);

  //       const query = JSON.parse(response.data.response.scanner_query);
  //       setScanquery(query);
  //       toast.success("Screener details updated!");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error(error?.response?.data?.response || "Something went wrong");
  //     });
  // };

  const handlePublishToApp = (val, id) => {
    axios
      .patch(`/tnibroadmin/scanner?id=${id}`, {
        publish: val,
      })
      .then((response) => {
        toast.success(val ? "Published to app!" : "unplublished from App");
        handleFetchScanList();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      });
  };

  const handleSearchText = (field, text) => {
    setScanSearchText(text);
  };

  const handlePagechange = (val) => {
    setCurrentPage(val);
  };

  const handleItemsLimitChange = (event) => {
    setItemsLimit(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchOnEnter = () => {
    setCurrentPage(1);
    handleFetchScanList();
  };

  // THIS USEEFFECT HAS BEEN COMMENTED OUT , AS SINGLE SCAN MOVED TO NEW PAGE: /SAVEDSCAN/ID
  // useEffect where screener data is being fetched through Params
  // useEffect(() => {
  //   // setSavedScreenerResult(null);
  //   setScanquery([]);
  //   setSavedScan([]);

  //   if (paramId) {
  //     setIsLoading(true);
  //     axios
  //       // .get(`/tnibroadmin/scanner?page_no=${globalCurrentPage}&per_page=${rowsPerPage}`, {
  //       .get('/tnibroadmin/scanner', {
  //         params: {
  //           id: paramId,
  //         },
  //       })
  //       .then((response) => {
  //         // SetSavedScanList(response.data.response); // new code for dense table screener data
  //         setScreenerObjDetails(response.data.scanner_data);
  //         setSavedScreenerResult(response.data);
  //         // fetchBacktestSymbols();

  //         setLatestStocks(response?.data?.latest_stock);
  //         setUserScreenerAlertDetail(
  //           response.data.scanner_data?.screener_alert[0]
  //         );

  //         if (response.data.hidden) {
  //           // setScreenerObjDetails(response.data.scanner_data);
  //           // setSavedScreenerResult(response.data);
  //           // setIsLoading(false);
  //         } else {
  //           // setScreenerObjDetails(response.data.scanner_data);
  //           // const screenerIdResponse = {
  //           //   ...response.data.response,
  //           //   scanner_query: JSON.parse(response.data.response.scanner_query),
  //           // };
  //           const screenerIdResponse = {
  //             ...response.data.scanner_data,
  //             scanner_query: JSON.parse(
  //               response.data.scanner_data.scanner_query
  //             ),
  //           };
  //           setSavedScan(screenerIdResponse);

  //           const query = JSON.parse(response.data.scanner_data.scanner_query);
  //           setScanquery(query);
  //           // setIsLoading(false);
  //         }
  //         setIsLoading(false);

  //         if (response.status === 200 || response.statusText === "OK") {
  //           // setLatestStocks(response?.data?.latest_stock);
  //           // setUserScreenerAlertDetail(
  //           //   response.data.scanner_data
  //           //   ?.screener_alert[0]
  //           // );
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         toast.error(
  //           error?.response?.data?.response || "Something went wrong"
  //         );
  //         setIsLoading(false);
  //       })
  //       .finally(() => {
  //         fetchBacktestSymbols();
  //       });
  //   } else {
  //     handleFetchScanList();
  //   }

  //   // empty/nullify states when component is unmounted
  //   return () => setScreenerObjDetails({});
  // }, [location.search, currentPage, itemsLimit, paramId]);
  // , globalCurrentPage, rowsPerPage removed from dependency

  // IMPORTANT, useEffect when there's NO PARAMID in the URL
  useEffect(() => {
    // if (!paramId) {
    //   handleFetchScanList();
    // }
    handleFetchScanList();
  }, []);

  // SCAN DETAILS LIST TABLE:
  if (!paramId) {
    return (
      <div>
        <span>
          <h2>Scan List</h2>
        </span>
        <span>
          <BasicButton
            title="Add Scan"
            callback={() => navigate("/screener")}
          />
        </span>

        {isLoading && (
          <div style={{ width: "80%", margin: "20px" }}>
            <Loader />
          </div>
        )}

        {savedScanList.length <= 0 ? (
          <>
            <h2>Scan List Empty.</h2>
          </>
        ) : (
          <div style={{ margin: "20px", width: "90%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <BasicTextFields
                label="Search Scans"
                value={scanSearchText}
                callback={handleSearchText}
                onEnter={handleSearchOnEnter}
              />
            </div>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 550 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="left">
                      Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="left">
                      Description
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="left">
                      Recommendation
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="left">
                      Signal
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="left">
                      Hidden?
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="left">
                      Screener Alert?
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {savedScanList.length !== 0 &&
                    savedScanList?.map((row) => (
                      <TableRow
                        key={row.scan_name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell scope="row" align="left">
                          <div onClick={() => editScreener(row)}>
                            {row.scan_name}
                          </div>
                        </TableCell>

                        <TableCell scope="row" align="left">
                          {row.scan_description}
                        </TableCell>

                        {/* RECOMMENDATION */}
                        <TableCell scope="row" align="center">
                          {row.set_recommended ? (
                            <span
                              style={{
                                display: "inline-block",
                                fontWeight: "400",
                                padding: 5,
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                                backgroundColor: "green",
                                color: "white",
                              }}
                            >
                              ON
                            </span>
                          ) : null}
                        </TableCell>

                          {/* SIGNAL */}
                        <TableCell scope="row" align="left">
                          <span style={{
                            textTransform: 'uppercase',
                            // fontWeight: 'bold'
                          }} >
                            {row?.recommended_signal ? row?.recommended_signal : "-"}
                          </span>
                          
                        </TableCell>

                        <TableCell scope="row" align="center">
                          {row?.hide_query ? <VisibilityOffIcon /> : "-"}
                        </TableCell>
                        <TableCell scope="row" align="center">
                          {row?.screener_alert?.length === 0 ? (
                            "-"
                          ) : (
                            <NotificationsActiveIcon />
                          )}
                        </TableCell>

                        <TableCell scope="row" align="center">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{ cursor: "pointer", margin: "0px 10px" }}
                            >
                              {/* <LinkIcon onClick={() => editScreener(row)} /> */}
                              <LinkIcon
                                onClick={() => navigate(`/savedscan/${row.id}`)}
                              />
                            </span>
                            <span
                              style={{ cursor: "pointer", margin: "0px 10px" }}
                            >
                              <DeleteIcon
                                color="error"
                                onClick={() => deleteScreener(row)}
                              />
                            </span>
                            <div>
                              <Tooltip title="Publish Screener">
                                <Switch
                                  checked={row.publish}
                                  onChange={() => {
                                    handlePublishToApp(!row.publish, row.id);
                                  }}
                                  {...label}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div
              style={{
                display: "flex",
                margin: "20px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BasicPagination
                count={totalPages}
                currentPage={currentPage}
                handlePagechange={handlePagechange}
              />

              <div style={{ marginLeft: "20px" }}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={itemsLimit}
                      label="Limit"
                      onChange={handleItemsLimitChange}
                    >
                      {limit.map((num) => (
                        <MenuItem value={num.value}>{num.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
        )}

        {/* PUT OLD RETURN THAT IS COPIED BELOW HERE IF AND WHEN NEEDED */}

        {/* REACT TOAST */}
        <ReactHotToaster />
      </div>
    );
  }
};

export default SavedScan;

// PREVIOUS CODE FOR SINGLESAVEDSCAN

// return (
//   <>
//     {/* SCAN DETAILS (NAME, DESCRIPTION) */}
//     <span>
//       <h2>Saved Scan Details</h2>
//     </span>

//     {screenerObjDetails?.scan_name ? (
//       <SavedScanDetails
//         screenerId={paramId || savedScan?.id}
//         screenerObjDetails={screenerObjDetails}
//         userScreenerAlertDetail={userScreenerAlertDetail}
//       />
//     ) : (
//       <Box sx={{ display: "flex" }}>
//         <CircularProgress />
//       </Box>
//     )}

//     {/* WHEN QUERY IS NOT HIDDEN */}
//     {scanQuery.length > 0 ? (
//       <div>
//         <div>
//           {/* WHEN QUERY IS NOT HIDDEN */}
//           <Screener
//             myScanQuery={scanQuery}
//             // checking if int or string below
//             segmentQuery={
//               isNaN(parseInt(savedScan.segment))
//                 ? savedScan.segment
//                 : parseInt(savedScan.segment)
//             }
//             isPublished={savedScan.publish}
//             queryNameDesc={{
//               scan_name: savedScan.scan_name,
//               scan_description: savedScan.scan_description,
//             }}
//             screenerId={savedScan.id ?? paramId}
//             handleUpdateScreener={handleUpdateScreener}
//             handleScannerPublishToApp={handlePublishToApp}
//             tableMarginTop={0}
//             latestStocks={latestStocks}
//           />
//         </div>
//       </div>
//     ) : null}

//     {/* SECTION 2: QUERY IS HIDDEN */}
//     {/* {savedScreenerResult ? (
//       <div>
//         <SavedScanWithResult
//           tableFeed={savedScreenerResult || []}
//           screenerObjDetails={screenerObjDetails}
//           screenerId={paramId}
//           latestStocks={latestStocks}
//           userScreenerAlertDetail={userScreenerAlertDetail}
//         />
//       </div>
//     ) : null} */}

//     {/* WHEN QUERY IS HIDDEN */}
//     {/* IMP: REPLACE BELOW CODE WITH HIDDEN QUERY LOGIC */}
//     {!scanQuery || scanQuery.length <= 0 ? (
//       savedScreenerResult?.response ? (
//         <DenseTable
//           // tableData={savedScanList}
//           tableData={savedScreenerResult?.response}
//           // setRowsPerPage={setRowsPerPage}
//           // maxHeight="500px"
//           selectedStockCategory={selectedStockCategory}
//           setSelectedStockCategory={setSelectedStockCategory}
//           latest_stocks={latestStocks}
//         />
//       ) : (
//         <>
//           <Stack direction="row" spacing={2}>
//             <h1>Loading...</h1>
//             <CircularProgress />
//           </Stack>
//         </>
//       )
//     ) : null}

//     {/* BACKTEST TABLE AND DATE WISE TABLE DATA */}

//     {isSymbolsDataLoading ? (
//       <Stack direction="row" spacing={2}>
//         <h1>Backtest Data Loading...</h1>
//         <CircularProgress />
//       </Stack>
//     ) : showBackTestTiles ? (
//       <BackTestTiles
//         setTimeframeDate={setTimeframeDate}
//         timeframeDate={timeframeDate}
//         setShowBackTestTiles={setShowBackTestTiles}
//         screenerId={savedScan.id ?? paramId}
//       />
//     ) : (
//       <Suspense fallback={<div>TABLE IS LOADING, please wait...</div>}>
//           <SymbolsListTable
//         symbolsData={symbolsData}
//         setSymbolsData={setSymbolsData}
//         setShowBackTestTiles={setShowBackTestTiles}
//         timeframeDate={timeframeDate}
//         isSymbolsDataLoading={isSymbolsDataLoading}
//         totalStocks={backtestTotalStocks}
//         // currentPage={backtestCurrentPage}
//         // setCurrentPage={setBacktestCurrentPage}
//       />
//         </Suspense>

//     )}

//   </>
// );
