// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BannerImageTable_mainContainer__1pbk6 {\n    margin: 2rem;\n}\n\n.BannerImageTable_tableTitle__5MwV8 {\n    text-align: center;\n    margin-bottom: 1rem;\n  }\n  \n  .BannerImageTable_tableContainer__5IfGh {\n    width: 100%;\n    max-width: 1400px;\n    border-collapse: collapse;\n    border: 1px solid #ccc;\n    /* background-color: red; */\n  }\n  \n  thead th {\n    text-transform: uppercase;\n  }\n  \n  .BannerImageTable_tableContainer__5IfGh th,\n  .BannerImageTable_tableContainer__5IfGh td {\n    padding: 8px;\n    text-align: left;\n    border: 1px solid #ccc;\n  }\n  \n  .BannerImageTable_tableContainer__5IfGh th {\n    background-color: #f2f2f2;\n  }\n  \n  .BannerImageTable_tableBodyContainer__HHJxw tr:nth-child(even) {\n    /* background-color: #f2f2f2; */\n  }", "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/HomeScreenContent/Banner/BannerImageTable/BannerImageTable.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,sBAAsB;IACtB,2BAA2B;EAC7B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;;IAEE,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,+BAA+B;EACjC","sourcesContent":[".mainContainer {\n    margin: 2rem;\n}\n\n.tableTitle {\n    text-align: center;\n    margin-bottom: 1rem;\n  }\n  \n  .tableContainer {\n    width: 100%;\n    max-width: 1400px;\n    border-collapse: collapse;\n    border: 1px solid #ccc;\n    /* background-color: red; */\n  }\n  \n  thead th {\n    text-transform: uppercase;\n  }\n  \n  .tableContainer th,\n  .tableContainer td {\n    padding: 8px;\n    text-align: left;\n    border: 1px solid #ccc;\n  }\n  \n  .tableContainer th {\n    background-color: #f2f2f2;\n  }\n  \n  .tableBodyContainer tr:nth-child(even) {\n    /* background-color: #f2f2f2; */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "BannerImageTable_mainContainer__1pbk6",
	"tableTitle": "BannerImageTable_tableTitle__5MwV8",
	"tableContainer": "BannerImageTable_tableContainer__5IfGh",
	"tableBodyContainer": "BannerImageTable_tableBodyContainer__HHJxw"
};
export default ___CSS_LOADER_EXPORT___;
