import React from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

import styles from './NewFooter.module.css'

const linksArray = [
  { id: 1, label: "Home", link: "/home" },
  { id: 2, label: "Screener", link: "/screener" },
  { id: 3, label: "Saved Scans", link: "/savedscan" },
  { id: 4, label: "Options", link: "/nse-option-chain" },
  {
    id: 5,
    label: "Recommended Stocks",
    link: "/recommended-stocks?tab=nifty50",
  },
  { id: 6, label: "Dashboards", link: "/dashboards" },
  // { id: 7, label: "Watchlists", link: "/watchlist" },
  // { id: 8, label: "Alerts", link: "/alerts" },
  // { id: 9, label: "Pricing", link: "/pricing" },
  
];

const linksArray2 = [
  { id: 7, label: "Watchlists", link: "/watchlist" },
  { id: 8, label: "Alerts", link: "/alerts" },
  { id: 1, label: "Privacy Policy", link: "/privacy-policy" },
  // { id: 2, label: "Refunds & Cancellation Policy", link: "/refunds-cancellation" },
  { id: 3, label: "Terms & Conditions", link: "/terms-conditions" },
  { id: 4, label: "Contact Us", link: "/contact-us" },
]

const NewFooter = () => {
  return (
    <div className={styles.NewFooter}>
      <div className={styles.topContainer}>
        {/* SOCIAL LINKS SECTION */}
        <section className={`${styles.socialLinksContainer} ${styles.footerSection}`}>
          <h1>TNIBRO</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
            mollitia nemo eligendi neque, nobis accusamus eos similique aperiam
            sint odio obcaecati esse consequatur minus? Architecto unde amet
            assumenda perferendis a!
          </p>

          <div className={styles.socialIcons}>
            <FacebookIcon fontSize="large" />
            <InstagramIcon fontSize="large" />
            <TwitterIcon fontSize="large" />
          </div>
        </section>

        {/* USEFULL LINKS SECTION */}
        <section className={`${styles.usefulLinksContainer} ${styles.footerSection}`}>
          <h2 className={styles.sectionTitle}>Useful Links</h2>

          {/* Links */}
          <div className={styles.listWrapper} >
            <ul className={styles.linksList}>
              {linksArray.map((item) => {
                return (
                  <Link className={styles.link} key={item.id} to={item.link}>
                    <li className={styles.linkItem}>{item.label}</li>
                  </Link>
                );
              })}
            </ul>

            <ul className={styles.linksList}>
              {linksArray2.map((item) => {
                return (
                  <Link className={styles.link} key={item.id} to={item.link}>
                    <li className={styles.linkItem}>{item.label}</li>
                  </Link>
                );
              })}
            </ul>
          </div>
          
        </section>

        {/* SUBSCRIBE SECTION */}
        <section className={`${styles.subscribeContainer} ${styles.footerSection}`}>
          <h2 className={styles.sectionTitle}>Subscribe</h2>

          <div className={styles.formContainer}>
            <input className={styles.emailInput} placeholder="Email*" type="email" name="" id="" />
            <button className={styles.subscribeButton}>Subscribe</button>
            <p>Get latest updates via email. You can unsubscribe anytime.</p>
          </div>
          
        </section>
      </div>

      {/* COPYRIGHT */}
      <div className={styles.copyrightContainer}>
        <p>Copyright &copy; TNIBRO</p>
      </div>
      
    </div>
  );
};

export default NewFooter;
