import React, { useState } from "react";

import styles from "./StockAdvisoryForm.module.css";
import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ReactHotToaster from "../../../common/ReactHotToaster";

const advisoryTypeOptions = [
  { value: "SHORT", label: "SHORT" },
  { value: "MEDIUM", label: "MEDIUM" },
  { value: "LONG", label: "LONG" },
];

const StockAdvisoryForm = () => {
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const [symbolsList, setSymbolsList] = useState([]); // all stocks & symbols list

  const [fileInput, setFileInput] = useState(null); // pdf file input

  const DURATION = {
    SHORT: "1-3 MONTHS",
    MEDIUM: "6-12 MONTHS",
    LONG: "12-36 MONTHS",
  };

  const initialAdvisoryFormInput = {
    symbolName: "AVROIND",
    advisoryType: "SHORT",
    priceRange: "₹125-₹150",
    entryPrice: "", // suggested price, NO RUPEE symbols in entry, target and stop loss
    targetPrice: "",
    stopLoss: "",
    description: "",
  };
  const [advisoryFormInput, setAdvisoryFormInput] = useState(
    initialAdvisoryFormInput
  );
  const {
    symbolName,
    advisoryType,
    entryPrice,
    priceRange,
    targetPrice,
    stopLoss,
    description,
  } = advisoryFormInput;

  const ADVISORY_DURATION = DURATION[advisoryFormInput.advisoryType];

  const formInputOnChange = (event) => {
    setAdvisoryFormInput((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const fileInputOnChange = (event) => {
    setFileInput(event.target.files[0]);
  };

  // FETCH SYMBOLS LIST QUERY
  const symbolsListQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["symbols-list"], // dependency
    queryFn: () => fetchSymbolsList(),
    onSuccess: (data) => {
      setSymbolsList(data.response[0].data);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // POST ADVISORY FORM TO BACKEND
  const postAdvisoryFormMutation = useMutation({
    mutationFn: () =>
      postAdvisoryForm(USERID, advisoryFormInput, ADVISORY_DURATION, fileInput),
    onSuccess: (data) => {
      toast.success(data?.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
    onSettled: () => {
      setAdvisoryFormInput(initialAdvisoryFormInput);
      setFileInput(null);
    },
  });

  //   submit advisory form handler
  const handleSubmitForm = () => {
    postAdvisoryFormMutation.mutate();
  };

  const DISABLE_SUBMIT_BTN =
    !entryPrice ||
    entryPrice.trim() === "" ||
    !targetPrice ||
    targetPrice.trim() === "" ||
    !fileInput;

  return (
    <div className={styles.StockAdvisoryForm}>
      {/* <h1>StockAdvisoryForm</h1> */}

      <div className={styles.formWrapper}>
        <h2>Advisory Form</h2>
        {/* SYMBOL NAME */}
        <div className={styles.inputControlBox}>
          <p className={styles.label}>Symbol Name</p>

          <select
            className={styles.selectedSymbolInput}
            name="symbolName"
            value={symbolName}
            onChange={formInputOnChange}
          >
            {symbolsList.map((item) => {
              return (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>

        <fieldset>
          <legend>Select Advisory Type:</legend>

          {advisoryTypeOptions.map((option) => (
            <div>
              <input
                style={{
                  margin: "0.8rem",
                }}
                type="radio"
                id={option.value}
                name="advisoryType"
                value={option.value}
                checked={advisoryType === option.value}
                onChange={formInputOnChange}
              />
              <label for={option.value}>{option.label}</label>
            </div>
          ))}
        </fieldset>

        {/* ADVISORY TYPE */}
        <div className={styles.inputControlBox}>
          <p className={styles.label}>DURATION</p>

          {/* DURATION */}
          <div className={styles.advisoryDuration}>
            <p>{ADVISORY_DURATION}</p>
          </div>
        </div>

        {/* PRICE RANGE */}
        <div className={styles.inputControlBox}>
          <p className={styles.label}>Price Range*</p>
          <input
            className={styles.entryPriceInput}
            required
            type="text"
            name="priceRange"
            id="priceRange"
            placeholder="eg. ₹125-₹150"
            value={priceRange}
            onChange={formInputOnChange}
          />
        </div>

        {/* ENTRY / SUGGESTED PRICE */}
        <div className={styles.inputControlBox}>
          <p className={styles.label}>Suggested Price*</p>
          <input
            className={styles.entryPriceInput}
            required
            type="text"
            name="entryPrice"
            id="entryPrice"
            value={entryPrice}
            onChange={formInputOnChange}
          />
        </div>

        {/* TARGET PRICE */}
        <div className={styles.inputControlBox}>
          <p className={styles.label}>Target Price*</p>
          <input
            type="text"
            required
            name="targetPrice"
            id="targetPrice"
            value={targetPrice}
            onChange={formInputOnChange}
          />
        </div>

        {/* STOP LOSS */}
        <div className={styles.inputControlBox}>
          <p className={styles.label}>Stop Loss</p>
          <input
            type="text"
            name="stopLoss"
            id="stopLoss"
            value={stopLoss}
            onChange={formInputOnChange}
          />
        </div>

        <div className={styles.inputControlBox}>
          <p className={styles.label}>Description</p>
          <textarea
            name="description"
            id="description"
            placeholder="Description for Admin Panel..."
            value={description}
            onChange={formInputOnChange}
          ></textarea>
          {/* <input
            type="text"
            name="description"
            id="description"
            value={description}
            onChange={formInputOnChange}
          /> */}
        </div>

        {/* FILE UPLOAD */}
        <div className={styles.inputControlBox}>
          <p className={styles.label}>File*</p>
          <input
            required
            type="file"
            name="fileInput"
            onChange={fileInputOnChange}
          />
        </div>

        <div className={styles.btnContainer}>
          <button
            disabled={DISABLE_SUBMIT_BTN}
            className={styles.submitBtn}
            onClick={handleSubmitForm}
          >
            submit
          </button>
        </div>
      </div>

      {/* react hot toast */}
      <ReactHotToaster />
    </div>
  );
};

export default StockAdvisoryForm;

// FETCHER FUNCTION SAMPLE
const fetchSymbolsList = async () => {
  const res = await axiosConfig({
    method: "get",
    url: "/home/symbols/list",
    data: {},
  });

  return res.data;
};

const postAdvisoryForm = async (
  user_id,
  advisory_form_input,
  advisory_duration,
  file_input
) => {
  const {
    symbolName,
    advisoryType,
    entryPrice,
    priceRange,
    targetPrice,
    stopLoss,
    description,
  } = advisory_form_input;

  const formData = new FormData();
  formData.append("symbol", symbolName);
  formData.append("entry_price", entryPrice);
  formData.append("price_range", priceRange);
  formData.append("target_price", targetPrice);
  formData.append("stoploss_price", stopLoss);
  formData.append("adviosry_type", advisoryType);
  formData.append("adviosry_duration", advisory_duration);
  formData.append("description", description);
  formData.append("file", file_input);

  
  const res = await axiosConfig({
    method: "post",
    url: `/predictions/stocks/${user_id}/advisory`,
    data: formData,
  });

  return res.data;
};
