import React from "react";

// IMP: HOW TO SEND DATA AND PROPS TO THE TABLE COMP
// const tableRowData = [
//   { id: 1, name: "John Doe", age: 30 },
//   { id: 2, name: "Jane Doe", age: 25 },
// ];

// const columnHeaders = [
//   { col_id: "id", name: "ID" },
//   { col_id: "name", name: "Name" },
//   { col_id: "age", name: "Age" },
// ];

// REACT COMPONENT
// <CustomHTMLTable tableBody={data} columnHeaders={columnHeaders} />

const CustomHTMLTable = ({
  tableBody,
  columnHeaders,
  deleteItem,
  showDeleteCol = false,
}) => {
  console.log("tableBody: ", tableBody);

  return (
    <table>
      <thead>
        <tr>
          {columnHeaders?.map((column) => (
            <th
              key={column.col_id}
              style={{
                width: column?.col_width,
                border: "1px solid red",
              }}
            >
              {column.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableBody?.map((row) => (
          <tr key={row.id}>
            {columnHeaders.map((column) => {
              const dataItem =
                column.col_id === "created_at"
                  ? new Date(row[column.col_id]).toDateString()
                  : row[column.col_id];

              return <td key={column.col_id}>{dataItem}</td>;
            })}

            {showDeleteCol ? (
              <td>
                <button onClick={() => deleteItem(row.id)}>del</button>
              </td>
            ) : null}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomHTMLTable;
