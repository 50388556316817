import React, { useState } from 'react'
import { axiosConfig } from '../../../axios/axiosConfig';
import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import AdvisoryDetailsTable from './AdvisoryDetailsTable';
import StockAdvisoryForm from './StockAdvisoryForm';

import styles from './AdvisorHome.module.css';

const AdvisorHome = () => {
    const [advisoryStocksList, setAdvisoryStocksList] = useState([]);
    

    // FETCHING ADVISORY DETAILS
const advisoryQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["advisory-details"], // dependency
    queryFn: () => fetchAdvisoryDetails(),
    onSuccess: (data) => {
        setAdvisoryStocksList(data.response?.data);
    
    },
    onError: (error) => {
      // console.log(" error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });


  return (
    <div className={styles.AdvisorHome} >
        <h1>Advisory Home</h1>

        {/* stock advisor form */}
        <StockAdvisoryForm />

        {/* advisory details table */}
        <AdvisoryDetailsTable advisoryDetailsList={advisoryStocksList} />

    </div>
  )
}

export default AdvisorHome;

// UTILITIES
const fetchAdvisoryDetails = async () => {
    // if (!watchlistId) return;
  
    const res = await axiosConfig({
      method: "get",
      url: '/predictions/stocks/advisory?status=OPEN',
      data: {},
    });
  
    return res.data;
  };