import React, { useState } from "react";
import CreateSubscriptionForm from "./CreateSubscriptionForm";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import ReactHotToaster from "../../common/ReactHotToaster";
import { axiosConfig } from "../../../axios/axiosConfig";
import SubscriptionPlansTable from "./SubscriptionPlansTable";
import MuiModal from "../../muiComps/MuiModal";
import MuiLoadingSpinner from "../../common/MuiLoadingSpinner";

const SubscriptionsPlans = () => {
  const [allPlanDetails, setAllPlanDetails] = useState([]);
  const [openSubscriptionForm, setOpenSubscriptionForm] = useState(false);
  const [activePlan, setActivePlan] = useState({});

  // fetch all subscription plans
  const fetchAllPlansQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["all-plans"], // dependency
    queryFn: () => fetchAllPlans(),
    onSuccess: (data) => {
      // setAllPlanDetails(data.response);
      setAllPlanDetails(data.response.plans[0].data);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  return (
    <div>
      <h1>Create Subscription Plans</h1>

      {/* Create Subscription Plan Form */}
      <CreateSubscriptionForm
        formType="create"
        plansData={allPlanDetails}
        setAllPlanDetails={setAllPlanDetails}
      />

      {/* Subscription Plans Table */}
      {fetchAllPlansQuery?.isLoading ? (
        <MuiLoadingSpinner />
      ) : (
        <SubscriptionPlansTable
          plansData={allPlanDetails}
          setOpenSubscriptionForm={setOpenSubscriptionForm}
          setActivePlan={setActivePlan}
        />
      )}

      {/* subscription form modal */}
      <MuiModal open={openSubscriptionForm} setOpen={setOpenSubscriptionForm}>
        <CreateSubscriptionForm
          formType="edit"
          activePlan={activePlan}
          setAllPlanDetails={setAllPlanDetails}
          setOpenSubscriptionForm={setOpenSubscriptionForm}
        />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default SubscriptionsPlans;

// UTILITY FUNCTIONS
export const fetchAllPlans = async () => {
  // if (!USERID) return;

  const res = await axiosConfig({
    method: "get",
    url: `/subscription/subscription_plan`,
    data: {},
  });

  return res.data;
};
