import React, { useState, useEffect, useRef } from "react";
import { Box, Button } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";

import axiosConfig from "../../axios/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./SearchBox.module.css";
import { useDispatch, useSelector } from "react-redux";

import {
  setSearchDate,
  setSearchInput,
  setSearchSegment,
  setSearchSymbol,
} from "../../reduxStore/actions/global-search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  // borderRadius: theme.shape.borderRadius,
  // borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  // marginBottom: 100,
  // width: "500px",
  border: "3px solid #4dcb0f",
  borderRadius: 30,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  // width: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    // border: "1px solid #ccc",
    borderRadius: 30,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const SearchBox = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchBoxRef = useRef(null);

  const INITIAL_DATE = new Date().toISOString().slice(0, 10); // Today's Date as compatible with date input eg
  // SEARCH AUTOCOMPLETE STATES
  // const [searchInput, setSearchInput] = useState("");
  // const [searchDate, setSearchDate] = useState(INITIAL_DATE);

  const { searchInput, searchDate, searchSymbol, searchSegment } = useSelector(
    (state) => state.globalSearch
  );

  // STATE FOR SEARCH LIST RESULTS BOX
  const [showSearchListDropdown, setShowSearchListDropdown] = useState(false);
  const [searchResultsList, setSearchResultsList] = useState([]);

  const formattedDate = new Date(searchDate).toISOString().slice(0, 19);

  // onChange function for search Input
  const handleSearch = async (SEARCH_INPUT) => {
    if (!SEARCH_INPUT || SEARCH_INPUT.length < 2) return;

    // setShowSearchListDropdown(true);

    SEARCH_INPUT = SEARCH_INPUT.toUpperCase();

    try {
      const res = await axiosConfig({
        method: "get",
        url: `/home/searching_stocks?stock=${SEARCH_INPUT}`,
        data: {},
      });

      setSearchResultsList(res.data.response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSearch(searchInput);
  }, [searchInput]);
  
  const handleClickSearchItem = (symbol, segment) => {
    if (!symbol || symbol.trim() === "" || !segment) {
      return;
    }

    if (segment === "f&o") {
      segment = "f%26o";
    }
    setShowSearchListDropdown(false);
    cancelSearch();

    navigate(
      `/search-results?symbol=${symbol}&segment=${segment}&date=${formattedDate}`
    );
    
  };

  useEffect(() => {
    // below if check is to prevent useEffect from running when user moves to other pages
    if (location?.pathname !== '/search-results') {
      return;
    }
    // handleClickSearchItem is already being executed when searchSymbol & searchSegment are being changed.
    handleClickSearchItem(searchSymbol, searchSegment);
  }, [searchDate])

  // CANCEL SEARCH, EMPTY SEARCH FIELD
  const cancelSearch = () => {
    setShowSearchListDropdown(false);
    
    // dispatch(setSearchInput(""));
    // setSearchResultsList([]);
  };

  // useEffect to close Search List Component when clicked outside
  useEffect(() => {
    /**
     * CLOSE SEARCH LIST WHEN CLICKED OUTSIDE
     */
    function handleClickOutside(event) {
      if (searchBoxRef?.current && !searchBoxRef?.current.contains(event.target)) {
        setShowSearchListDropdown(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBoxRef]);
  

  return (
    <div ref={searchBoxRef} >
    <Box
      sx={{
        postion: "relative",
        display: "flex",
        justifyContent: "center",
        m: 2,
        // border: "3px solid #4dcb0f",
      }}
    >
      <Box
        sx={{
          display: "flex",
          m: 2,
        }}
      >
        <div className={styles.date_container}>
          <input
            className={styles.date_input}
            type="date"
            name=""
            id="date"
            max={INITIAL_DATE}
            value={searchDate}
            // onChange={(e) => setSearchDate(e.target.value)}
            onChange={(event) => dispatch(setSearchDate(event.target.value))}
          />
        </div>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            value={searchInput}
            // onFocus={handleSearchFocus}
            onFocus={() => {
              setShowSearchListDropdown(true);
              
            }}
            onChange={(event) => {
              // setSearchInput(event.target.value);
              dispatch(setSearchInput(event.target.value));
            }}
          />
        </Search>

        <Box
          onClick={() => {
            dispatch(setSearchInput(""));
            setShowSearchListDropdown(false);
          }}
          sx={{ display: "flex", alignItems: "center", ml: -4 }}
        >
          {searchInput?.length > 0 ? (
            <CancelIcon sx={{ cursor: "pointer", zIndex: 99 }} />
          ) : null}
        </Box>

        {/* <div>
        <Button variant="contained" size="small">
          submit
        </Button>
      </div> */}
      </Box>

      {/* MENU SEARCH LIST / DROPDOWN */}
      <div className={styles.searchListContainer}>
        {showSearchListDropdown ? (
          
          <ul className={styles.searchList}>
            {searchResultsList.map((item) => {
              return (
                <li
                  key={item.id}
                  className={styles.searchListItem}
                  onClick={() => {
                    // Dispatching seach symbol and segment in global state
                    dispatch(setSearchSymbol(item.name));
                    dispatch(setSearchSegment(item.segment));

                    handleClickSearchItem(item.name, item.segment);
                    // setSearchSymbolDetails(prevState => {
                    //   return { symbol: item.name, segment: item.segment }
                    // })
                  }}
                >
                  {item.name}
                  <span style={{
                    fontSize: '0.8rem',
                    color: '#777',
                    marginLeft: '0.2rem'
                  }} >
                    {item?.company_name ? `[${item?.company_name}]` : "" }
                  </span>
                  
                </li>
              );
            })}
          </ul>
          
        ) : null}
      </div>
    </Box>
    </div>
  );
};

export default SearchBox;