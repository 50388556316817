import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { memberDepartmentList } from "../utils/Member-options";
import BasicButton from "../components/Button/BasicButton";
import Dropdown from "../components/Dropdown/Dropdown";
import BasicTextFields from "../components/Modal/Input";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 550,
  bgcolor: "background.paper",
  border: "1px solid white",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const userTypeOptions = [
  { id: 1, label: "Admin", value: "Admin" },
  { id: 1, label: "Premium", value: "Premium" },
  { id: 1, label: "Advisor", value: "Advisor" },
  { id: 1, label: "Trial", value: "Trial" },
  { id: 1, label: "Agent", value: "Agent" },
];

export default function UserEditModal({
  isOpen,
  action,
  userFields,
  onValidate,
}) {
  const [open, setOpen] = useState(isOpen);

  const [userFieldValues, setUserFieldValues] = useState(userFields);

  const [userTypeInput, setUserTypeInput] = useState("Trial");

  // const handleOpen = () => setOpen(true);

  const handleEditFieldValues = (key, val) => {
    const temp = { ...userFieldValues };
    temp[key] = val;
    setUserFieldValues(temp);
  };

  const handleClose = () => {
    // setOpen(false);
    action(false);
  };

  const handleValidate = () => {
    onValidate(userFieldValues);
    handleClose();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setUserFieldValues(userFields);
  }, [userFields]);

  const userTypeChangeHandler = (event) => {};

  return (
    <div>
      <div className="currentUserType">
        <p>Current User Type: Agent</p>
      </div>

      <div className="userTypeSelectContainer">
        <select onChange={(event) => setUserTypeInput(event.target.value)}>
          <option>Please choose one option</option>
          {userTypeOptions.map((item, index) => {
            return <option key={item.id}>{item.label}</option>;
          })}
        </select>
      </div>

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > */}
      {/* <Box sx={style}>
          <div style={{display: "flex"}}>
            <BasicTextFields value={userFieldValues.username} label="UserName" fieldKey="username" callback={handleEditFieldValues} />
            <BasicTextFields value={userFieldValues.phoneno ?? ""} label="Phone" fieldKey="phoneno" callback={handleEditFieldValues} />
          </div>

          <div style={{display: "flex"}}>
            <BasicTextFields value={userFieldValues.email ?? ""} label="Email" fieldKey="email" callback={handleEditFieldValues} />
            <BasicTextFields value={userFieldValues.user_password ?? ""} label="Password" fieldKey="user_password" callback={handleEditFieldValues} />
          </div>

          <div style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "10px"
          }}>
            <BasicButton title="Cancel" callback={handleClose} />
            <BasicButton title="Validate" callback={handleValidate} />
          </div>
        </Box> */}
      {/* </Modal> */}
    </div>
  );
}
