import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import stockCategory from './stockCategory';
import globalSearch from './globalSearch';
import pagination from "./pagination";

export default combineReducers({
  auth,
  message,
  stockCategory,
  globalSearch,
  pagination
});