import React from 'react';

import styles from './PaymentHistoryTable.module.css';
import { useNavigate } from 'react-router-dom';

const PaymentHistoryTable = ({ tableRowData = []}) => {
  const navigate = useNavigate();

  const routeToBillDetails = (payment_id) => {
    navigate(`/user-dashboard/payments/${payment_id}`);
  }

  return (
    <div className={styles.outerContainer} >
      
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Payment ID</th>
            <th>Product</th>
            <th>Amount</th>
            <th>Payment Date</th>
            <th>Payment Status</th>
            <th>Payment Mode</th>
            <th>Details</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {tableRowData.map((item, index) => {
            return (
              <tr key={item.id}>
                <td>{item.payment_id}</td>
                <td className={styles.productCol} >{item.product}</td>
                <td align='right' >{Number(item.amount).toFixed(2)}</td>
                <td>{item.payment_date}</td>
                <td>{item.payment_status ? <PaymentSuccess /> : <PaymentFailed /> }</td>
                <td>{item.payment_mode}</td>
                <td>
                  {item.payment_status ? (
                    <button className={styles.viewBtn} onClick={() => routeToBillDetails(item.id)} >view</button>
                  ) : null}
                  
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

export default PaymentHistoryTable;


const PaymentSuccess = () => {
  return (
    <span className={styles.paid} >paid</span>
  )
}

const PaymentFailed = () => {
  return (
    <span className={styles.failed} >failed</span>
  )
}