import React, { useEffect, useState } from "react";
import SortIcon from "../../assets/sort-numeric-up.png";

import {
  TableHead,
  TableContainer,
  TableFooter,
  TablePagination,
  TableBody,
  Table,
  TableRow,
  TableSortLabel,
  Stack,
  Typography,
  Pagination,
  Box,
  Autocomplete,
  TextField,
  Tooltip,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";

import PaginationTable from "../Pagination/Pagination";

import dateFormat, { masks } from "dateformat";
import usePagination from "../../hooks/usePagination";
import MuiCustomTooltip from "../muiComps/MuiCustomTooltip";

import {
  candlestick_patterns,
  candlestick_patterns_arr,
} from "../../data/patterns";

import styles from "./Tables.module.css";
import MuiSortIcons from "../muiComps/MuiSortIcons";
import HtmlSelectInputFilter from "./HtmlSelectInputFilter";
import { useDispatch, useSelector } from "react-redux";
import MuiModal from "../muiComps/MuiModal";
import ChooseAlertTypeModal from "./ChooseAlertTypeModal";

import CandleStickChartIcon from "../../assets/icons/candlestick-chart.png";
import WatchlistOutlinedIcon from "../../assets/icons/watchlist.png";
import WatchlistFilledIcon from "../../assets/icons/watchlist-filled.png";
import AlertBellFilledIcon from "../../assets/icons/alert-filled.png";
import AlertBellIcon from "../../assets/icons/alert.png";

import SaveToWatchlistModal from "../watchlist/SaveToWatchlistModal";
import { titleCase } from "../../utils/common/commonUtilityFunctions";
import FilterByStockCategories from "../common/FilterByStockCategories";
import HtmlSelectDropdown from "../common/HtmlSelectDropdown";
import { intraDayOptions } from "../../data/selectOptionsList";
import { allOptionSymbols } from "../../data/selectOptionsList";
import Dropdown from "../Dropdown/Dropdown";
import {
  refreshTimeOptions,
  rowsPerPageOptions,
} from "../../utils/screener-dropdown-options";
import NoResultsToDisplay from "../common/NoResultsToDisplay";
import BasicTextFields from "../Modal/Input";
import {
  setCurrentPage,
  setRowsPerPage,
} from "../../reduxStore/actions/pagination";
import { getUserWatchlists } from "../watchlist/watchlist_utils";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import MuiRecomTabs from "../muiComps/MuiRecomTabs";
import { Link } from "react-router-dom";

const user = JSON.parse(localStorage.getItem("user"));
const USERID = user?.userDetails?.id;

const operatorsArray = [
  { id: 6, value: "", label: "" },
  { id: 1, value: "gt", label: ">" },
  { id: 2, value: "lt", label: "<" },
  { id: 3, value: "gte", label: ">=" },
  { id: 4, value: "lte", label: "<=" },
  { id: 5, value: "eq", label: "=" },
];

// import styles from './Tables.module.css'

// BINANCE COLORS
// green: rgb(3, 166, 109), hex: #03A66D
// red: rgb(207, 48, 74), hex: #CF304A

// COLORS FOR TABLE HEADER BACKGROUND #FFE5B4, #FAD5A5, #FBCEB1, #FFF5EE

const StyledTableCell = styled(TableCell)(
  ({ theme, recommended, cellcolor }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: recommended ? "#D8D8D8" : theme.palette.common.black,
      backgroundColor: recommended ? "#D8D8D8" : "#f2f2f2",
      padding: "2px 8px",
      // padding: 0,
      // fontSize: 8,
      fontWeight: 500,
      borderRight: "1px solid #ddd",
      textTransform: "uppercase",
      // color: recommended
      //   ? theme.palette.common.black
      //   : theme.palette.common.white,
      color: "#333",
      "&:first-child": {
        zIndex: "1000 !important",
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: cellcolor && "whiteSmoke",
      padding: 4,
      borderRight: "1px solid #ddd",
    },
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  // },

  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
    fontSize: 16,
  },
}));

export default function DenseTable({
  tableData = [],
  userAlertList,
  setUserAlertList,
  // selectedFilters,
  // setSelectedFilters,
  maxHeight = "800px",
  handleRemoveStock,
  showRemoveStockIcon,
  tableMarginTop = 0,
  // selectedStockCategory = "",
  // setSelectedStockCategory,
  screenerDataMutate,
  // latest_stocks = ["ADANIENT", "TATASTEEL"],
  latest_stocks = [],
  clearFilters = false,
  selectedTimeframe = "1-d",
  disableFiltering = false,
  disableSorting = false,
  selectedSegmentTab = "nifty500",
  handleTabChange, // handler for tabs change
  showRecomTabs = false,
}) {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  // const { currentPage, rowsPerPage, totalStocks } = useSelector((store) => store.pagination);  // pagination global states from redux store
  const { rowsPerPage, totalStocks } = useSelector((store) => store.pagination); // pagination for frontend table
  const dispatch = useDispatch();

  // RETRIEVING FILTERS FROM LOCAL STORAGE
  const local_filters = JSON.parse(
    localStorage.getItem("selectedFiltersLocal")
  );

  // *********** STATES FOR FILTERING TABLE   ********************
  const initialSelectedFiltersState = {
    previous_pattern: local_filters?.previous_pattern || "",
    current_pattern: local_filters?.current_pattern || "",
    close_open_pct_operator: local_filters?.close_open_pct_operator || "",
    close_open_pct_value: local_filters?.close_open_pct_value || "",
    fiftytwo_weeks_high_operator:
      local_filters?.fiftytwo_weeks_high_operator || "",
    fiftytwo_weeks_high_value: local_filters?.fiftytwo_weeks_high_value || "",
    fiftytwo_weeks_low_operator:
      local_filters?.fiftytwo_weeks_low_operator || "",
    fiftytwo_weeks_low_value: local_filters?.fiftytwo_weeks_low_value || "",
    recommendation_filter: clearFilters ? '' : (local_filters?.recommendation_filter || "Buy"), // should be either 'Buy' or 'Sell' or ''
    selected_category: local_filters?.selected_category || "all", // 'psu', 'auto', 'all', etc
  };
  const [selectedFilters, setSelectedFilters] = useState(
    initialSelectedFiltersState
  );
  // const appliedFilters = Object.values(selectedFilters);
  // console.log("selectedFilters: ", selectedFilters);

  const refreshDetails = JSON.parse(localStorage.getItem("refreshDetails")); // { localCache_RefreshTime: 0, localCache_IsAutoRefreshOn: false }
  // const { localCache_RefreshTime, localCache_IsAutoRefreshOn } = refreshDetails;

  const [refreshTime, setRefreshTime] = useState(
    refreshDetails?.localCache_RefreshTime || 300000
  );
  const [isAutoRefreshOn, setIsAutoRefreshOn] = useState(
    refreshDetails?.localCache_IsAutoRefreshOn
  );

  const [alertDetails, setAlertDetails] = useState({
    symbol: "",
    price: "",
    symbol_id: 0,
  });

  const [openAlertTypeModal, setOpenAlertTypeModal] = useState(false);
  const [openSaveToWatchlistModal, setOpenSaveToWatchlistModal] =
    useState(false);
  const [symbolDetails, setSymbolDetails] = useState({
    symbol_name: "",
    added_in_watchlist: false,
  });

  // states for pagination, rowsPerPage moved to Screener component

  const [stockSearchText, setStockSearchText] = useState("");

  // state for Table, Number of List/ rows selection
  // const [rowsPerPage, setRowsPerPage] = useState(50); // Default number of Rows or Symbols

  // Table data State after sorting
  const [sortedTableFeed, setSortedTableFeed] = useState(tableData);
  

  // RETRIEVING SORTING DETAILS FROM LOCAL STORAGE
  const sortingDetailsLocal = JSON.parse(
    localStorage.getItem("sorting_details_local")
  );

  // ascending or descending
  const [sortingOrder, setSortingOrder] = useState(
    sortingDetailsLocal?.sortingOrder || "desc"
  );
  const [sortingBy, setSortingBy] = useState(
    sortingDetailsLocal?.sortingBy || "date"
  );

  const {
    previous_pattern,
    current_pattern,
    close_open_pct_operator,
    close_open_pct_value,
    fiftytwo_weeks_high_operator,
    fiftytwo_weeks_high_value,
    fiftytwo_weeks_low_operator,
    fiftytwo_weeks_low_value,
    recommendation_filter,
    selected_category,
  } = selectedFilters;

  // FILTERING FUNCTION
  const filteredTableFeed = sortedTableFeed?.filter((item) => {
    // if (!item?.open_gap_pcnt || !item?.year_high) {
    //   return true;
    // }
    
    // the below toggle values would be either true or false, it will be true by default.
    // calcOperatorValues takes 3 arguments (value1, operator, value2)
    const closeOpenPct_Toggle = calcOperatorValues(
      item?.open_gap_pcnt,
      close_open_pct_operator,
      close_open_pct_value
    );

    const fiftyTwoWeeksHighPcnt = JSON.parse(
      item?.year_high
    )?.year_high_gap_pcnt;
    const fiftyTwoWeeksHighToggle = calcOperatorValues(
      fiftyTwoWeeksHighPcnt,
      fiftytwo_weeks_high_operator,
      fiftytwo_weeks_high_value
    );
    const fiftyTwoWeeksLowPcnt = JSON.parse(item?.year_low)?.year_low_gap_pcnt;
    const fiftyTwoWeeksLowToggle = calcOperatorValues(
      fiftyTwoWeeksLowPcnt,
      fiftytwo_weeks_low_operator,
      fiftytwo_weeks_low_value
    );

    // ALLPRICE, BUYPRICE & SELL PRICE FILTERING
    const date_toggle =
      recommendation_filter === ""
        ? true
        : item.recommended?.recommendation === recommendation_filter;

    // SELECTED CATEGORY FILTER ('psu', 'auto')
    const categoryFilter = item?.category_indices.includes(selected_category);

    return (
      (item?.previous_pattern === previous_pattern ||
        !previous_pattern ||
        previous_pattern === "") &&
      (item.pattern === current_pattern ||
        !current_pattern ||
        current_pattern === "") &&
      closeOpenPct_Toggle &&
      fiftyTwoWeeksHighToggle &&
      fiftyTwoWeeksLowToggle &&
      date_toggle &&
      categoryFilter
    );
  });

  // OLD CODE FOR PAGINATION
  const { currentPage, getCurrentData, setCurrentPage, pageCount } =
    usePagination(filteredTableFeed, rowsPerPage); // dataFeed was tableData instead of filteredTableFeed
  let paginatedTableData = getCurrentData(); // DATA THAT IS DISPLAYED AFTER PAGINATION

  // CODE / STATES FOR BACKEND API PAGINATION
  // let paginatedTableData = filteredTableFeed;
  // const [currentPage, setCurrentPage] = useState(1);
  // const pageCount = Math.ceil(paginatedTableData?.length / rowsPerPage);
  // const pageCount = Math.ceil(totalStocks / rowsPerPage);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    currentPage > 0
      ? Math.max(0, (1 + currentPage) * rowsPerPage - sortedTableFeed?.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    // commented out for new pagination setup
    // setPage(newPage);
    // PREVIOUS CODE
    setCurrentPage(newPage);

    // REDUX CODE
    // dispatch(setCurrentPage(newPage));
  };

  // COMMON SORT FUNCTION
  const handleListSorting = () => {
    let arrToSort = [...sortedTableFeed];

    if (!sortingBy || sortingBy === "") {
      return;
    }

    // sort by buyprice DATES only
    if (sortingBy === "buyprice_date") {
      // RESETTING arrToSort ARRAY TO FULL DATA ITEMS
      arrToSort = tableData;

      const buypricesArray = arrToSort.filter(
        (item) => item.recommended?.buyprice
      );
      // const sellpricesArray = arrToSort.filter(
      //   (item) => item.recommended?.sellprice
      // );

      buypricesArray.sort(function (a, b) {
        let date1 = new Date(a.recommended?.date);
        let date2 = new Date(b.recommended?.date);

        // sortingOrder: ascending or descending, true or false
        if (sortingOrder === "asc") {
          // return num1 > num2 ? 1 : num1 < num2 ? -1 : 0;
          return date2 - date1;
        } else {
          return date1 - date2;
        }
      });

      // arrToSort = [...buypricesArray, ...sellpricesArray];
      arrToSort = [...buypricesArray];
    }

    // sort by sellprice only
    if (sortingBy === "sellprice_date") {
      // RESETTING arrToSort ARRAY TO FULL DATA ITEMS
      arrToSort = tableData;

      // const buypricesArray = arrToSort.filter(
      //   (item) => item.recommended?.buyprice
      // );
      const sellpricesArray = arrToSort.filter(
        (item) => item.recommended?.sellprice
      );

      sellpricesArray.sort(function (a, b) {
        let date1 = new Date(a.recommended?.date);
        let date2 = new Date(b.recommended?.date);

        // sortingOrder: ascending or descending, true or false
        if (sortingOrder === "asc") {
          // return num1 > num2 ? 1 : num1 < num2 ? -1 : 0;
          return date2 - date1;
        } else {
          return date1 - date2;
        }
      });

      // arrToSort = [...sellpricesArray, ...buypricesArray];
      arrToSort = [...sellpricesArray];
    }

    // SORTING BY DATE IN RECOMMENDATION COLUMN
    if (sortingBy === "date") {
      arrToSort.sort(function (a, b) {
        let date1 = new Date(a.recommended?.date);
        let date2 = new Date(b.recommended?.date);

        if (sortingOrder === "asc") {
          // return num1 > num2 ? 1 : num1 < num2 ? -1 : 0;
          return date2 - date1;
        } else {
          return date1 - date2;
        }
      });
    }

    // sort by date (ALL - SELL + BUY)
    if (sortingBy === "allprice_date") {
      // RESETTING arrToSort ARRAY TO FULL DATA ITEMS
      arrToSort = tableData;

      arrToSort.sort(function (a, b) {
        let date1 = new Date(a.recommended?.date);
        let date2 = new Date(b.recommended?.date);

        if (sortingOrder === "asc") {
          // return num1 > num2 ? 1 : num1 < num2 ? -1 : 0;
          return date2 - date1;
        } else {
          return date1 - date2;
        }
      });
    }

    // sort by gain percentage
    if (sortingBy === "pct") {
      arrToSort.sort(function (a, b) {
        let num1 = parseFloat(a.recommended?.pct);
        let num2 = parseFloat(b.recommended?.pct);

        // sortingOrder: ascending or descending, true or false
        if (sortingOrder === "asc") {
          // return num1 > num2 ? 1 : num1 < num2 ? -1 : 0;
          return num1 - num2;
        } else {
          return num2 - num1;
        }
      });
    }

    // 7 Days Total Volume % SORT (volume_pcnt)
    if (sortingBy === "volume_pcnt") {
      arrToSort.sort(function (a, b) {
        let num1 = parseFloat(JSON.parse(a.week_volume_compare)?.volume_pcnt);
        let num2 = parseFloat(JSON.parse(b.week_volume_compare)?.volume_pcnt);

        // sortingOrder: ascending or descending, true or false
        if (sortingOrder === "asc") {
          // return num1 > num2 ? 1 : num1 < num2 ? -1 : 0;
          return num1 - num2;
        } else {
          return num2 - num1;
        }
      });
    }

    // 52 WEEKS YEAR HIGH PERCENT SORT
    if (sortingBy === "year_high_gap_pcnt") {
      arrToSort.sort(function (a, b) {
        // JSON.parse(row.year_high)?.year_high_gap_pcnt
        let num1 = parseFloat(JSON.parse(a.year_high)?.year_high_gap_pcnt);
        let num2 = parseFloat(JSON.parse(b.year_high)?.year_high_gap_pcnt);

        if (!num1 || !num2 || isNaN(num1) || isNaN(num2)) return 0;

        if (sortingOrder === "asc") {
          return num1 - num2;
        } else {
          return num2 - num1;
        }
      });
    }

    // 52 WEEKS LOW PERCENT SORT
    // JSON.parse(row.year_low)?.year_low_gap_pcnt
    if (sortingBy === "year_low_gap_pcnt") {
      arrToSort.sort(function (a, b) {
        let num1 = parseFloat(JSON.parse(a.year_low)?.year_low_gap_pcnt);
        let num2 = parseFloat(JSON.parse(b.year_low)?.year_low_gap_pcnt);

        if (sortingOrder === "asc") {
          return num1 - num2;
        } else {
          return num2 - num1;
        }
      });
    }

    // Alphabetical sorting
    if (sortingBy === "symbol") {
      arrToSort.sort(function (a, b) {
        if (a[sortingBy] < b[sortingBy])
          return !(sortingOrder === "asc") ? -1 : 1;
        if (a[sortingBy] > b[sortingBy])
          return !(sortingOrder === "asc") ? 1 : -1;
        // return 0;
      });
    } else {
      // FOR ALL NUMERICAL SORTING
      // sortingOrder: ascending or descending, true or false
      // for 'CMP', 'volume', etc.
      // if (sortingOrder) {
      //   arrToSort.sort((a, b) => a[sortingBy] - b[sortingBy]);
      // } else {
      //   arrToSort.sort((a, b) => b[sortingBy] - a[sortingBy]);
      // }
      arrToSort.sort(function (a, b) {
        let num1 = parseFloat(a[sortingBy]);
        let num2 = parseFloat(b[sortingBy]);

        if (sortingOrder === "asc") {
          return num1 - num2;
        } else {
          return num2 - num1;
        }
      });
    }
    setSortedTableFeed(arrToSort);
  };

  // COMMENTED OUT BELOW CODE FOR TESTING
  // useEffect(() => {
  //   setSortedTableFeed(tableData);

  //   if (tableData?.length < rowsPerPage) {
  //     setPage(0);
  //   }
  // }, [tableData, rowsPerPage]);

  // function to filter stocks by categories
  // useEffect(() => {
  //   const filterByCategoriesHandler = () => {
  //     if (!selectedStockCategory || selectedStockCategory === "") {
  //       setSortedTableFeed(tableData);
  //     } else {
  //       const newStockData = tableData.filter((stockItem) => {
  //         return stockItem.category_indices.includes(selectedStockCategory);
  //       });

  //       setSortedTableFeed(newStockData);
  //     }
  //   };

  //   filterByCategoriesHandler();

  //   return () => setSortedTableFeed([]);
  // }, [selectedStockCategory, tableData]);

  // STYLES FOR CHIPS (set alert, option chart)
  const chipStyles = {
    backgroundColor: "#339502",
    color: "#fff",
    // padding: 0,
    // backgroundColor: '#888',
    "&:hover": {
      // backgroundColor: "#005b96",
      backgroundColor: "#336502",
      color: "white",
    },
    // "--Chip-minHeight": "32px",
    // "--Chip-gap": "5px",
    // "--Chip-paddingInline": "8px",
    // "--Chip-decoratorChildHeight": "20px",
    fontSize: "10px",
  };

  // CLICK HANDLER FOR WATCHLIST FLAG CLICK
  const onClickWatchlistFlag = (symbol, addedInWatchlist) => {
    setOpenSaveToWatchlistModal(true);
    setSymbolDetails({
      symbol_name: symbol,
      added_in_watchlist: addedInWatchlist,
    });
  };

  // USEEFFECT TO RUN AUTO REFRESH
  useEffect(() => {
    const newRefreshDetails = {
      ...refreshDetails,
      localCache_RefreshTime: refreshTime,
      localCache_IsAutoRefreshOn: isAutoRefreshOn,
    };
    localStorage.setItem("refreshDetails", JSON.stringify(newRefreshDetails));

    if (isAutoRefreshOn) {
      const interval = setInterval(() => {
        screenerDataMutate();
      }, refreshTime);

      return () => clearInterval(interval);
    }
  }, [isAutoRefreshOn, refreshTime, refreshDetails]);

  // USEEFFECT TO STORE REFRESH DETAILS IN LOCAL STORAGE
  // useEffect(() => {
  //   const newRefreshDetails = { ...refreshDetails, localCache_RefreshTime: refreshTime, localCache_IsAutoRefreshOn: isAutoRefreshOn }
  //   localStorage.setItem('refreshDetails', JSON.stringify(newRefreshDetails))
  // }, [refreshTime, isAutoRefreshOn, refreshDetails]);

  // setSortingBy FUNCTION, sets the field name/item that is to be sorted.
  const setSortField = (sorting_by) => {
    setSortingBy(sorting_by);
    // setSortingOrder((currentState) => !currentState);
    setSortingOrder((currentState) => {
      if (currentState === "asc") {
        return "desc";
      } else {
        return "asc";
      }
    });
  };

  // setSelectedFilters common onChange function, for select open dropdown filters
  const selectedFiltersOnChange = (e, filter_name) => {
    setSelectedFilters((prev) => {
      return {
        ...prev,
        [filter_name]: e.target.value,
      };
    });
  };

  // onChange function for recommendation buttons: "Buy", "Sell", ""
  const recommendationOnClick = (recom_value) => {
    setSelectedFilters((prev) => {
      return {
        ...prev,
        recommendation_filter: recom_value,
      };
    });
  };

  // USEEFFECT TO STORE FILTERS IN LOCAL STORAGE
  useEffect(() => {
    localStorage.setItem(
      "selectedFiltersLocal",
      JSON.stringify(selectedFilters)
    );
  }, [selectedFilters]);

  // CLEAR ALL FILTERS, FROM STATE AND LOCAL STORAGE
  const clearAllFilters = () => {
    const emptyFilters = {
      previous_pattern: "",
      current_pattern: "",
      close_open_pct_operator: "",
      close_open_pct_value: "",
      fiftytwo_weeks_high_operator: "",
      fiftytwo_weeks_high_value: "",
      fiftytwo_weeks_low_operator: "",
      fiftytwo_weeks_low_value: "",
      recommendation_filter: "",
      selected_category: "all"
    };

    setSelectedFilters(emptyFilters);
    // localStorage.setItem('selectedFiltersLocal', JSON.stringify(emptyFilters))
    localStorage.removeItem("selectedFiltersLocal");

    // setSortingOrder(false);
    setSortingBy("");
    // localStorage.setItem('sorting_details_local', JSON.stringify({sortingOrder: false, sortingBy: "" }))
    localStorage.removeItem("sorting_details_local");

    // setSelectedStockCategory(""); // SECTORS
    // localStorage.removeItem("selected_sector");
  };

  // useEffect to sort dates by default
  // useEffect(() => {
  //   // if clearFilters prop is true
  //   if (clearFilters) {
  //     clearAllFilters();
  //   }

  //   const timeout = setTimeout(() => {
  //     handleListSorting();
  //     localStorage.setItem(
  //       "sorting_details_local",
  //       JSON.stringify({ sortingOrder, sortingBy })
  //     );
  //   }, 200);

  //   return () => clearTimeout(timeout);
  // }, []);

  // useEffect for sorting with dependencies, sort when 'sorting order' and 'sortingBy' is changed
  useEffect(() => {
    handleListSorting();
    localStorage.setItem(
      "sorting_details_local",
      JSON.stringify({ sortingOrder, sortingBy })
    );
  }, [sortingOrder, sortingBy]);

  // FUNCTION TO SEARCH STOCK FIELD
  const handleStockSearch = (event) => {
    const query = event.target.value;
    setStockSearchText(query.toUpperCase());

    if (!query || query === "") {
      setSortedTableFeed(tableData);
      return;
    }
    const filteredStockList = tableData?.filter((stock) =>
      stock.symbol.toLowerCase().includes(query.toLowerCase())
    );
    setSortedTableFeed(filteredStockList);
  };

  // No Results to Display.
  // if (!sortedTableFeed || sortedTableFeed.length === 0) {
  //   return (
  //     <>
  //       <h1>No Results to Display</h1>
  //       <p>
  //         Sorry, we couldn't find any results for this query. Please try again.
  //       </p>
  //     </>
  //   );
  // }

  // To make Symbol Column 'Sticky'
  const stickyColumn = {
    position: "sticky",
    left: 0,
    background: "white",
    // boxShadow: "2px 2px 5px grey",
    // borderRight: "2px solid black",
    // zIndex: "9999 !important"
    zIndex: "999 !important",
  };

  const isFilterApplied =
    previous_pattern ||
    current_pattern ||
    close_open_pct_operator ||
    close_open_pct_value ||
    fiftytwo_weeks_high_operator ||
    fiftytwo_weeks_high_value ||
    fiftytwo_weeks_low_operator ||
    fiftytwo_weeks_low_value ||
    recommendation_filter !== "" ||
    selected_category !== "all";

  // IMPORTANT: This code is for adding 'added_recently' toggle to latest/recently added stocks
  const latestStocksFeed = paginatedTableData?.map((dataRow) => {
    if (latest_stocks?.includes(dataRow.symbol)) {
      return { ...dataRow, added_recently: true };
    }
    return dataRow;
  });

  // IMPORTANT: While searching, tableData should be 'filteredTableFeed'
  // for everything else, it should be 'paginatedTableData'
  const finalTableData = stockSearchText ? filteredTableFeed : latestStocksFeed;

  // check if Alert exist for particular symbol
  const checkAlert = (user_alerts_list, symbolID) => {
    const symbolIdList = user_alerts_list?.map((item) => item.symbol_id);
    return symbolIdList?.includes(symbolID);
  };

  // SET ALERT FUNCTION (PUSH NOTIFICATION)
  const setAlertNotification = (rowObj) => {
    setAlertDetails({
      symbol: rowObj.symbol,
      price: rowObj.CMP,
      symbol_id: rowObj.symbol_id,
    });
    setOpenAlertTypeModal(true);
  };

  const [userWatchlistDataRes, setUserWatchlistDataRes] = useState([]);

  // FETCH USER WATCHLISTS USING useQUERY
  const watchlistQuery = useQuery({
    queryKey: ["user-watchlists"],
    queryFn: () => getUserWatchlists(userDetails.id),
    onSuccess: (data) => {
      // console.log("watchlist data: ", data)
      setUserWatchlistDataRes(data.response);
    },
    onError: (error) => {
      // ADD REACT HOT TOAST HERE
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // checking if a symbol is added in any watchlist
  function isSymbolAddedToWatchlist(symbol_name) {
    if (!symbol_name || userWatchlistDataRes?.length === 0) return;

    let isAdded;
    userWatchlistDataRes?.forEach((item) => {
      if (item.symbol.includes(symbol_name)) {
        isAdded = true;
      } else {
        isAdded = false;
      }
    });

    return isAdded;
  }

  // formatted date code for navigating to 'search results page'
  const formattedDate = new Date().toISOString().slice(0, 19);

  return (
    <div
      style={{
        // marginBottom: '4rem',
        paddingBottom: "4rem",
      }}
    >
      {/* TABLE PAGINATION TOP */}
      <div className={styles.table_settings_container}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          // justifyContent="flex-end"
          sx={{
            zIndex: 99,
            gap: 2,
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Stack direction="column" spacing={2}>
            {/* SEARCH INPUT FIELD */}
            {/* <BasicTextFields
              label="Search"
              value={stockSearchText.toUpperCase()}
              callback={handleStockSearch}
            /> */}
            <TextField
              id="search-field"
              size="small"
              label="Search"
              type="search"
              value={stockSearchText?.toUpperCase()}
              onChange={handleStockSearch}
            />
            {/* <Button
              className={isFilterApplied ? styles.blinking_border : null}
              size="small"
              variant="contained"
              color="warning"
              disabled={!isFilterApplied}
              onClick={clearAllFilters}
            >
              clear all filters
            </Button> */}

            <button
              // className={isFilterApplied ? styles.blinking_border : null}
              className={`${styles.clearAllFiltersBtn} ${
                isFilterApplied ? styles.blinking_border : null
              }`}
              disabled={!isFilterApplied}
              onClick={clearAllFilters}
            >
              clear all filters
            </button>
          </Stack>

          {/* Select Refresh time dropdown and Auto Refresh Toggle */}
          <Stack direction="column" spacing={1}>
            <FormControlLabel
              value={isAutoRefreshOn}
              control={
                <Switch
                  checked={isAutoRefreshOn}
                  onChange={() => {
                    setIsAutoRefreshOn((prevState) => !prevState);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  // disabled={!screenerDataMutate}
                />
              }
              label="Auto Refresh"
              labelPlacement="start"
            />

            <Dropdown
              list={refreshTimeOptions}
              value={refreshTime}
              label="Refresh time"
              callback={(value) => {
                setRefreshTime(value);
              }}
              // disabled={!screenerDataMutate || !isAutoRefreshOn}
              disabled={!isAutoRefreshOn}
            />
          </Stack>

          {/* TIMEFRAME SELECT DROPDOWN */}
          {/* <HtmlSelectDropdown
          placeholder="Select Timeframe"
          optionsArray={intraDayOptions}
          selectedOption={selectedTimeframe}
          setSelectedOption={setSelectedTimeframe}
          defaultOptionVisible={false}
        /> */}

          {/* Dropdown for Pagination, number of rows selection */}
          <Stack
            direction="column"
            spacing={1}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Dropdown
              list={rowsPerPageOptions}
              value={rowsPerPage}
              label="Stocks Per Page"
              callback={(newValue) => dispatch(setRowsPerPage(newValue))}
              // disabled={!isLatestOn}
            />

            {/* <Typography>Page: {currentPage}</Typography> */}
            {/* <Pagination count={5} page={page} onChange={handleChange} vaPrevious CandleStick Patternriant="outlined" shape="rounded" /> */}
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              // sx={{ display: { xs: "none", md: "flex" } }}
            />
          </Stack>
        </Stack>
      </div>

      {/* RECOM TABS */}
      {showRecomTabs ? (
        <MuiRecomTabs
          selectedSegmentTab={selectedSegmentTab}
          handleTabChange={handleTabChange}
        />
      ) : null}

      <TableContainer
        component={Paper}
        sx={{
          maxHeight: maxHeight,
          zIndex: 1,
          overflowX: "scroll",
          paddingBottom: 1,
          marginTop: tableMarginTop,
        }}
      >
        <Table
          stickyHeader
          aria-label="stock screener table"
          sx={{ position: "relative" }}
        >
          {/* <TableHead>
          <StyledTableCell align="center" colSpan={4}>Recommended</StyledTableCell>
        </TableHead> */}
          <TableHead>
            <StyledTableRow>
              {/* FIRST COLUMN - SYMBOL */}
              <StyledTableCell align="center" sx={stickyColumn}>
                <Stack direction="row">
                  <Tooltip
                    title="Sort by Symbols in Alphabetical Order"
                    placement="top"
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        gap: "0.2rem",
                        // alignItems: 'center',
                        // justifyContent: 'center'
                      }}
                      className={styles.recom_span}
                      onClick={() => setSortField("symbol")}
                    >
                      Symbol{" "}
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        ({filteredTableFeed?.length})
                      </span>
                    </div>
                  </Tooltip>
                </Stack>

                {/* STOCK CATEGORIES FILTER */}
                <FilterByStockCategories
                  selectedStockCategory={selected_category}
                  disabled={selectedSegmentTab === "mcx"}
                  // setSelectedStockCategory={setSelectedStockCategory}
                  selectedFiltersOnChange={selectedFiltersOnChange}
                  disableInput={disableFiltering}
                />
              </StyledTableCell>

              <StyledTableCell recommended={true} align="right">
                <Stack direction="row">
                  <Stack direction="column" sx={{ width: 100 }}>
                    <p>
                      <Tooltip title="Filter by All Prices" placement="left">
                        <span
                          className={`${styles.recom_span} ${
                            disableFiltering ? styles.disabled : ""
                          }`}
                          // style={{
                          //   backgroundColor:
                          //     recommendation_filter === "" ? "#FAA0A0" : "",
                          // }}
                          // onClick={() => setSortField("allprice_date")}
                          onClick={() => recommendationOnClick("")}
                        >
                          All
                        </span>
                      </Tooltip>
                      <Tooltip
                        title="Filter by Buy Recommendation"
                        placement="right"
                      >
                        <span
                          className={`${styles.recom_span} ${
                            disableFiltering ? styles.disabled : ""
                          } ${
                            recommendation_filter === "Buy"
                              ? styles.blinking_border
                              : ""
                          }`}
                          // style={{ backgroundColor: recommendation_filter === "Buy" ? '#FAA0A0' : ''}}
                          // onClick={() => setSortField("buyprice_date")}
                          onClick={() => recommendationOnClick("Buy")}
                        >
                          Buy
                        </span>
                      </Tooltip>
                    </p>
                    <p>
                      <Tooltip
                        title="Filter by Sell Recommendation"
                        placement="right"
                      >
                        <span
                          className={`${styles.recom_span} ${
                            disableFiltering ? styles.disabled : ""
                          } ${
                            recommendation_filter === "Sell"
                              ? styles.blinking_border
                              : ""
                          }`}
                          // onClick={() => setSortField("sellprice_date")}
                          onClick={() => recommendationOnClick("Sell")}
                        >
                          Sell
                        </span>
                      </Tooltip>
                      (date)
                    </p>

                    <span>
                      {/* Sell (date) */}
                      {/* <MuiCustomTooltip
                      heading="Buy Date"
                      content="We advised you to buy on this date."
                      // content={<h1>Hello world</h1>}
                      videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                      // iconColor="black"
                    /> */}
                    </span>
                  </Stack>

                  <MuiSortIcons
                    sortingByValue="date"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("date")}
                    pointerEvents={disableSorting ? "none" : "auto"}
                  />
                </Stack>
              </StyledTableCell>

              {/* <StyledTableCell recommended={true} align="center">
                <Stack direction="column">
                Sell/Short (date)
                <MuiCustomTooltip
                  heading="Sell Date"
                  content="We advised you to sell on this date."
                  videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                  iconColor="black"
                />
                </Stack>
              </StyledTableCell> */}

              <StyledTableCell recommended={true} align="right">
                <div
                  style={{
                    display: "flex",
                    // flexDirection: "column",
                    // justifyContent: "center",
                    alignItems: "center",
                    // minWidth: 80,
                    // cursor: "pointer",
                  }}
                >
                  <Stack direction="column" sx={{ minWidth: 100 }}>
                    <span>High / Low %</span>
                    <span style={{ fontSize: "14px" }}>
                      Gain / Save
                      <MuiCustomTooltip
                        heading="High / Low % Gain"
                        content="ADD CONTENT"
                        videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                        // iconColor="black"
                      />
                    </span>
                  </Stack>

                  {/* THE SORTING ICONS */}
                  <MuiSortIcons
                    sortingByValue="pct"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("pct")}
                  />
                </div>
              </StyledTableCell>

              {/* Current Price (CMP or close) */}
              <StyledTableCell align="right">
                <Stack direction="row" sx={{ minWidth: 100 }}>
                  <Stack>
                    <span>Current</span>
                    <span>
                      Price
                      <MuiCustomTooltip
                        // heading="Current Price"
                        content="Current Market Price"
                        videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                      />
                    </span>
                  </Stack>

                  <MuiSortIcons
                    sortingByValue="CMP"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("CMP")}
                  />
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="right">
                <Stack direction="row" sx={{ minWidth: 120 }}>
                  <Stack>
                    <span>% Change</span>
                    <MuiCustomTooltip
                      heading="Price % Change"
                      content="(current close - previous close) / previous close %"
                      videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                    />
                  </Stack>

                  <MuiSortIcons
                    sortingByValue="pChange"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("pChange")}
                  />
                </Stack>
              </StyledTableCell>

              {/* "Volume %" */}
              <StyledTableCell align="right">
                {/* <Stack sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> */}
                <Stack direction="row" width={80}>
                  <Stack direction="column">
                    <span>VOL %</span>

                    <MuiCustomTooltip
                      heading="Volume %"
                      content="(current volume - previous day volume) /  previous volume %"
                      videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                    />
                  </Stack>

                  <MuiSortIcons
                    sortingByValue="pvolumeChange"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("pvolumeChange")}
                  />
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="right">
                <Stack direction="row">
                  <Stack sx={{ minWidth: 80 }}>
                    <span>7 days Avg</span>
                    <span>
                      VOL %
                      <MuiCustomTooltip
                        heading=""
                        content="(current volume - average volume of previous 7 days) / current volume"
                        videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                      />
                    </span>
                  </Stack>

                  <MuiSortIcons
                    sortingByValue="volume_pcnt"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("volume_pcnt")}
                  />
                </Stack>
              </StyledTableCell>

              {/* 10 days Avg Volume % */}
              <StyledTableCell align="right">
                <Stack direction="row">
                  <Stack sx={{ minWidth: 80 }}>
                    <span>10 days Avg</span>
                    <span></span>
                    <span>
                      VOL %
                      <MuiCustomTooltip
                        content="(current volume - average volume of previous 10 days - current volume) / current volume"
                        videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                      />
                    </span>
                  </Stack>

                  <MuiSortIcons
                    sortingByValue="ten_days_avg_pChange"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("ten_days_avg_pChange")}
                  />
                </Stack>
              </StyledTableCell>

              {/* Vol Greatest Open days */}
              <StyledTableCell align="left">
                <Stack direction="row">
                  <Stack sx={{ minWidth: 110 }}>
                    <span>Vol Greatest</span>
                    <span></span>
                    <span></span>
                    <span>
                      Open Days
                      <MuiCustomTooltip
                        content="Current volume compared to previous n number of days"
                        videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                      />
                    </span>
                  </Stack>

                  <MuiSortIcons
                    sortingByValue="volume_greatest"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("volume_greatest")}
                  />
                </Stack>
              </StyledTableCell>

              {/* PREVIOUS CANDLESTICK PATTERN */}
              <StyledTableCell align="left">
                <Stack sx={{ minWidth: 70 }}>
                  <span>Previous Day</span>
                  <span>CandleStick</span>
                  <span>
                    Pattern
                    <MuiCustomTooltip
                      content="Patterns based on previous candles"
                      videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                    />
                  </span>

                  {/* PREVIOUS PATTERN FILTER */}
                  {/* <Autocomplete
                    size="small"
                    disablePortal
                    id="previous-pattern"
                    options={candlestick_patterns}
                    value={selectedFilters.previous_pattern}
                    onChange={(event, newValue) => {
                      setSelectedFilters((prev) => {
                        return {
                          ...prev,
                          previous_pattern: newValue,
                        };
                      });
                    }}
                    sx={{ width: 200, backgroundColor: "white", zIndex: 2 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Pattern" />
                    )}
                  /> */}

                  <Stack
                    direction="row"
                    spacing={0.5}
                    className={styles.flexCenter}
                  >
                    <select
                      // className={styles.previous_pattern_select_Input}
                      className={`${styles.previous_pattern_select_Input} ${
                        selectedFilters?.previous_pattern
                          ? styles.blinking_border
                          : ""
                      }`}
                      name="previous-pattern"
                      placeholder="Select Previous Pattern"
                      value={selectedFilters?.previous_pattern}
                      onChange={(e) =>
                        selectedFiltersOnChange(e, "previous_pattern")
                      }
                    >
                      <option value="">Select Any</option>
                      {candlestick_patterns_arr.map((item) => (
                        <option key={item.videoLink} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>

                    {/* COMMENTED OUT CLEAR BTN */}
                    {/* <button
                      className={styles.html_btn}
                      style={{backgroundColor: selectedFilters.previous_pattern ? "red" : ""}}
                      onClick={() =>
                        setSelectedFilters((prev) => {
                          return {
                            ...prev,
                            previous_pattern: "",
                          };
                        })
                      }
                    >
                      clear
                    </button> */}
                  </Stack>
                </Stack>
              </StyledTableCell>

              {/* CURRENT CANDLESTICK PATTERN */}
              <StyledTableCell align="left">
                <Stack sx={{ minWidth: 70 }}>
                  <span>Current</span>
                  <span>CandleStick</span>
                  <span>
                    Pattern
                    <MuiCustomTooltip
                      content="Patterns based on current candles"
                      videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                    />
                  </span>

                  {/* CURRENT PATTERN FILTER */}
                  {/* <Autocomplete
                    size="small"
                    disablePortal
                    id="current-pattern"
                    options={candlestick_patterns}
                    value={selectedFilters.current_pattern}
                    onChange={(event, newValue) => {
                      setSelectedFilters((prev) => {
                        return {
                          ...prev,
                          current_pattern: newValue,
                        };
                      });
                    }}
                    sx={{ width: 200, backgroundColor: "white", zIndex: 2 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Pattern" />
                    )}
                  /> */}

                  <Stack
                    direction="row"
                    spacing={0.5}
                    className={styles.flexCenter}
                  >
                    <select
                      // className={styles.previous_pattern_select_Input}
                      className={`${styles.previous_pattern_select_Input} ${
                        selectedFilters?.current_pattern
                          ? styles.blinking_border
                          : ""
                      }`}
                      name="current-pattern"
                      value={selectedFilters?.current_pattern}
                      onChange={(e) =>
                        selectedFiltersOnChange(e, "current_pattern")
                      }
                    >
                      <option value="">Select Any</option>
                      {candlestick_patterns_arr.map((item) => (
                        <option key={item.id} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>

                    {/* COMMENTED OUT CLEAR BTN AS ADVISED */}
                    {/* <button
                      className={styles.html_btn}
                      style={{backgroundColor: selectedFilters.current_pattern ? "red" : ""}}
                      onClick={() =>
                        setSelectedFilters((prev) => {
                          return {
                            ...prev,
                            current_pattern: "",
                          };
                        })
                      }
                    >
                      clear
                    </button> */}
                  </Stack>
                </Stack>
              </StyledTableCell>

              {/* sortDirection={sortingOrder} removed */}
              {/* OPEN */}
              <StyledTableCell align="right">
                <Stack direction="row" alignItems="center">
                  <Stack sx={{ minWidth: 110 }}>
                    {/* <span>PRE-MARKET</span> */}
                    <span>
                      OPEN
                      <MuiCustomTooltip
                        // content="Previous Day Close / Open % Change"
                        content="(current open - previous close) / previous close"
                        videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                      />
                    </span>

                    {/* Select Input Filter Component */}
                    {/* <HtmlSelectInputFilter
                      selectValue={selectedFilters.close_open_pct_operator}
                      selectOnChange={(e) =>
                        selectedFiltersOnChange(e, "close_open_pct_operator")
                      }
                      setSelectedFilters={setSelectedFilters}
                      inputValue={selectedFilters.close_open_pct_value}
                      inputOnChange={(e) =>
                        selectedFiltersOnChange(e, "close_open_pct_value")
                      }
                      onClick={() =>
                        setSelectedFilters((prev) => {
                          return {
                            ...prev,
                            close_open_pct_operator: "",
                            close_open_pct_value: "",
                          };
                        })
                      }
                    /> */}
                  </Stack>

                  <MuiSortIcons
                    sortingByValue="open"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("open")}
                  />
                </Stack>
              </StyledTableCell>

              <StyledTableCell sx={{ minWidth: 60 }} align="right">
                <span>HIGH</span>
              </StyledTableCell>
              <StyledTableCell sx={{ minWidth: 60 }} align="right">
                <span>LOW</span>
              </StyledTableCell>
              {/* <StyledTableCell sx={{ minWidth: 60 }} align="right">
                <span>PREV CLOSE</span>
              </StyledTableCell> */}

              {/* 52 WEEKS HIGH */}
              <StyledTableCell align="right">
                <Stack direction="row">
                  <Stack>
                    <span>52 Weeks</span>
                    <span>
                      High
                      <MuiCustomTooltip
                        content="Highest Price & Percentage Needed to achieve highest Price in 52 weeks high"
                        videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                      />
                    </span>

                    {/* Select Input Filter */}
                    <HtmlSelectInputFilter
                      selectValue={selectedFilters.fiftytwo_weeks_high_operator}
                      selectOnChange={(e) =>
                        selectedFiltersOnChange(
                          e,
                          "fiftytwo_weeks_high_operator"
                        )
                      }
                      inputValue={selectedFilters.fiftytwo_weeks_high_value}
                      inputOnChange={(e) =>
                        selectedFiltersOnChange(e, "fiftytwo_weeks_high_value")
                      }
                      onClick={() =>
                        setSelectedFilters((prev) => {
                          return {
                            ...prev,
                            fiftytwo_weeks_high_operator: "",
                            fiftytwo_weeks_high_value: "",
                          };
                        })
                      }
                    />
                  </Stack>

                  <MuiSortIcons
                    sortingByValue="year_high_gap_pcnt"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("year_high_gap_pcnt")}
                  />
                </Stack>
              </StyledTableCell>

              {/* 52 WEEKS LOW */}
              <StyledTableCell align="right">
                <Stack direction="row">
                  <Stack>
                    <span>52 Weeks</span>
                    <span>
                      Low
                      <MuiCustomTooltip
                        content="Lowest Price & Percentage Needed to achieve lowest Price in 52 weeks low"
                        videoLink="https://www.youtube.com/watch?v=FRszmU25okE"
                      />
                    </span>

                    {/* <Stack direction="row" spacing={1}>
                      
                      <select
                        className={styles.select_Input_Control}
                        name="operator"
                        
                      >
                        {operatorsArray.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                      </select>

                      <input
                        className={styles.number_Input_Control}
                        type="number"
                        placeholder="0"
                        name=""
                        id=""
                        maxLength={100}
                        style={{ width: 50 }}
                        
                      />

                      <button
                        className={styles.html_btn}
                        
                      >
                        clear
                      </button>
                    </Stack> */}

                    <HtmlSelectInputFilter
                      selectValue={selectedFilters.fiftytwo_weeks_low_operator}
                      selectOnChange={(e) =>
                        selectedFiltersOnChange(
                          e,
                          "fiftytwo_weeks_low_operator"
                        )
                      }
                      inputValue={selectedFilters.fiftytwo_weeks_low_value}
                      inputOnChange={(e) =>
                        selectedFiltersOnChange(e, "fiftytwo_weeks_low_value")
                      }
                      onClick={() =>
                        setSelectedFilters((prev) => {
                          return {
                            ...prev,
                            fiftytwo_weeks_low_operator: "",
                            fiftytwo_weeks_low_value: "",
                          };
                        })
                      }
                    />
                  </Stack>

                  <MuiSortIcons
                    sortingByValue="year_low_gap_pcnt"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("year_low_gap_pcnt")}
                  />
                </Stack>
              </StyledTableCell>

              {/* REMOVE STOCK ROW FOR SINGLE WATCHLIST PAGE */}
              {showRemoveStockIcon ? (
                <StyledTableCell>Remove Symbol</StyledTableCell>
              ) : null}
            </StyledTableRow>
          </TableHead>

          {/* **************************************************************************************************** */}
          {/*                               TABLE BODY, ROWS START HERE */}
          {/* **************************************************************************************************** */}

          <TableBody>
            {finalTableData?.map((row, i) => {
              return (
                <StyledTableRow
                  key={i}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  sx={{}}
                  className={row?.added_recently ? "blink_background" : ""} // for first row only
                >
                  {/* SERIAL NUMBER FIELD */}
                  {/* <StyledTableCell align="center">{i + 1}</StyledTableCell> */}

                  {/* SYMBOL NAME */}
                  <StyledTableCell
                    sx={stickyColumn}
                    align="center"
                    component="th"
                    scope="row"
                    className={row?.added_recently ? "blink_background" : ""}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#666",
                      }}
                    >
                      <Link
                        className={styles.symbolLink}
                        // target="_blank"
                        to={`/search-results?symbol=${row.symbol}&segment=cash&date=${formattedDate}`}
                      >
                        {row.symbol}
                      </Link>
                    </Typography>

                    {/* <Stack direction="row" spacing={2} sx={{ mt: 1 }}> */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "4px",
                        mt: 0.5,
                      }}
                    >
                      <div
                        className={styles.icon_container}
                        target="popup"
                        onClick={() => {
                          const formattedSymbol = row.symbol.replaceAll(
                            "-",
                            "_"
                          ); // replacing '-' with '_' for tradingView
                          const formattedLink = formatTradingViewURL(
                            "NSE",
                            formattedSymbol,
                            selectedTimeframe
                          );
                          window.open(
                            // `https://in.tradingview.com/chart/?symbol=${formattedSymbol}`,
                            formattedLink,
                            "popup",
                            "width=1200,height=800"
                          );
                          return false;
                        }}
                      >
                        <img src={CandleStickChartIcon} width={20} alt="" />
                      </div>

                      {allOptionSymbols.includes(row.symbol) ? (
                        <Chip
                          color="info"
                          size="small"
                          label="Options"
                          disabled={!allOptionSymbols.includes(row.symbol)}
                          component="a"
                          href={`/nse-option-chain?symbol=${row.symbol}`}
                          clickable
                          sx={chipStyles}
                        />
                      ) : null}

                      {/* OLD ALERT ICON CODE */}
                      {/* <AddAlertIcon
                        sx={{ cursor: "pointer" }}
                        color="success"
                        onClick={() => {
                          setAlertDetails({
                            symbol: row.symbol,
                            price: row.CMP,
                            symbol_id: row.symbol_id,
                          });
                          setOpenAlertTypeModal(true);
                        }}
                      /> */}

                      {/* BELL ALERT ICONS */}
                      <div
                        style={{ cursor: "pointer", padding: "0 0.2rem" }}
                        onClick={() => setAlertNotification(row)}
                      >
                        {checkAlert(userAlertList, row.symbol_id) ? (
                          <img src={AlertBellFilledIcon} width={15} alt="" />
                        ) : (
                          <img src={AlertBellIcon} width={15} alt="" />
                        )}
                      </div>

                      {/* WATCHLIST ICONS (ADD WATCHLIST MODAL) */}
                      {isSymbolAddedToWatchlist(row.symbol) ? (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const isWatchlistAdded = isSymbolAddedToWatchlist(
                              row.symbol
                            );
                            onClickWatchlistFlag(
                              row.symbol,
                              isWatchlistAdded
                              // row?.added_in_watchlist
                            );
                          }}
                        >
                          <img src={WatchlistFilledIcon} width={15} alt="" />
                        </div>
                      ) : (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const isWatchlistAdded = isSymbolAddedToWatchlist(
                              row.symbol
                            );
                            onClickWatchlistFlag(
                              row.symbol,
                              isWatchlistAdded
                              // row?.added_in_watchlist
                            );
                          }}
                        >
                          <img src={WatchlistOutlinedIcon} width={15} alt="" />
                        </div>
                      )}
                    </Box>
                    {/* </Stack> */}
                  </StyledTableCell>

                  {/* ALL BUY SELL DATE */}
                  <StyledTableCell
                    cellcolor="palette.secondary.light"
                    align="right"
                    className={row?.added_recently ? "blink_background" : ""}
                  >
                    <Stack>
                      {row.recommended?.recommendation === "Buy" ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "rgb(3, 166, 109)",
                          }}
                        >
                          {/* <span>BUY</span> */}
                          <span>
                            {row.recommended?.recommendation_signal?.toUpperCase() ||
                              row.recommended?.recommendation?.toUpperCase()}
                          </span>
                          <span>{row.recommended?.buyprice}</span>
                        </Box>
                      ) : null}

                      {row.recommended?.recommendation === "Sell" ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "rgb(207, 48, 74)",
                          }}
                        >
                          {/* <span>SELL</span> */}
                          <span>
                            {row.recommended?.recommendation_signal?.toUpperCase()}
                          </span>
                          <span>{row.recommended?.sellprice}</span>
                        </Box>
                      ) : null}

                      {/* <span style={{ fontSize: "12px", fontWeight: "bold", color: getColor(row.recommended?.incremented_pcnt) }}>
                        {row.recommended?.incremented_pcnt
                          ? `+${row.recommended?.incremented_pcnt}%`
                          : null}
                      </span> */}

                      <span className={styles.small_font_date}>
                        {dateFormat(row.recommended?.date, masks.mediumDate)}
                      </span>
                    </Stack>
                  </StyledTableCell>

                  {/* SELL DATE */}
                  {/* <StyledTableCell
                    cellcolor="palette.secondary.light"
                    align="right"
                  >
                    <Stack>
                      <span>{row.recommended?.sellprice}</span>
                      {row.recommended?.color === "red" ? (
                        <span className={styles.small_font_date}>
                          {dateFormat(row.recommended?.date, masks.mediumDate)}
                        </span>
                      ) : null}
                    </Stack>
                  </StyledTableCell> */}

                  {/* HIGH / LOW % GAIN */}
                  <StyledTableCell
                    cellcolor="palette.secondary.light"
                    align="right"
                    className={row?.added_recently ? "blink_background" : ""}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>
                        {row.recommended?.high || row.recommended?.low}
                      </span>
                      <span
                        style={{
                          // backgroundColor: row.recommended?.color,
                          color: "rgb(3, 166, 109)",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {row.recommended?.color === "green" ? (
                          <NorthIcon color="success" fontSize="14px" />
                        ) : (
                          <SouthIcon color="error" fontSize="14px" />
                        )}
                        {row.recommended?.pct}
                      </span>
                      {/* <span
                        style={{
                          backgroundColor: row.recommended?.color,
                          color: "white",
                          fontSize: "12px",
                        }}
                      >
                        {row.recommended?.pct}
                      </span> */}
                    </div>
                  </StyledTableCell>

                  {/* CURRENT PRICE (CLOSE) */}
                  <StyledTableCell align="right">
                    <span>{row.close}</span>
                  </StyledTableCell>

                  {/* Price % Change */}
                  <StyledTableCell align="right">
                    {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: getColor(row.pChange),
                      }}
                    >
                      {`${row.pChange >= 0 ? "+" : ""}${row.pChange}%`}
                    </span>
                    {/* </div> */}
                  </StyledTableCell>

                  {/* Volume % */}
                  <StyledTableCell align="right">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{row.volume}</span>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: getColor(row.pvolumeChange),
                        }}
                      >
                        {row.pvolumeChange
                          ? `${row.pvolumeChange >= 0 ? "+" : ""}${
                              row.pvolumeChange
                            }%`
                          : "-"}
                      </span>
                      {/* the above is put "+" sign for positive numbers, "-" if null / undefined */}
                    </div>
                  </StyledTableCell>

                  {/* 7 Days Avg Volume % */}
                  <StyledTableCell align="right">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{JSON.parse(row.week_volume_compare)?.volume}</span>
                      {/* <span style={{ fontSize: "12px" }}>
                        {JSON.parse(row.week_volume_compare)?.volume_pcnt}%
                      </span> */}
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: getColor(
                            JSON.parse(row.week_volume_compare)?.volume_pcnt
                          ),
                        }}
                      >
                        {getSign(
                          JSON.parse(row.week_volume_compare)?.volume_pcnt
                        )}
                        {JSON.parse(row.week_volume_compare)?.volume_pcnt}%
                      </span>
                    </div>
                  </StyledTableCell>

                  {/* 10 days Avg Volume % */}
                  <StyledTableCell align="right">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{row.ten_days_avgVolume}</span>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: getColor(row.ten_days_avg_pChange),
                        }}
                      >
                        {getSign(row.ten_days_avg_pChange)}
                        {row.ten_days_avg_pChange
                          ? `${row.ten_days_avg_pChange}%`
                          : "-"}
                      </span>
                    </div>
                  </StyledTableCell>

                  {/* Vol Greatest Open days */}
                  <StyledTableCell align="center">
                    {row?.volume_greatest}{" "}
                    {row?.volume_greatest > 1 ? "Days" : "Day"} Ago
                  </StyledTableCell>

                  {/* PREVIOUS CANDLESTICK PATTERN */}
                  <StyledTableCell align="center">
                    <Chip
                      label={
                        !!row.previous_pattern
                          ? titleCase(row.previous_pattern)
                          : "--"
                      }
                      sx={{
                        "--Chip-paddingInline": "8px",
                        fontSize: "12px",
                      }}
                      size="small"
                      // variant="outlined"
                      color="secondary"
                    />
                  </StyledTableCell>

                  {/* CURRENT CANDLESTICK PATTERN */}
                  <StyledTableCell align="center">
                    <Chip
                      label={!!row.pattern ? titleCase(row.pattern) : "--"}
                      sx={{
                        "--Chip-paddingInline": "8px",
                        // "--Chip-decoratorChildHeight": "20px",
                        fontSize: "12px",
                      }}
                      size="small"
                      // variant="outlined"
                      color="secondary"
                    />
                  </StyledTableCell>

                  {/* OPEN */}
                  <StyledTableCell align="right">
                    <Stack>
                      <span>{row.open}</span>

                      {/* COMMENTED OUT AS NOT NEEDED */}
                      {/* <Typography
                        variant="span"
                        sx={{
                          fontSize: 12,
                          fontWeight: "bold",
                          color: getColor(row?.open_gap_pcnt),
                        }}
                      >
                        {getSign(row?.open_gap_pcnt)}
                        {row?.open_gap_pcnt}%
                      </Typography> */}

                      {/* <span
                        style={{ fontSize: "12px", fontWeight: "bold", color: getColor(row.ten_days_avg_pChange) }}
                      >
                        {getSign(row.ten_days_avg_pChange)}
                        {row.ten_days_avg_pChange ? `${row.ten_days_avg_pChange}%` : "-" }
                      </span> */}
                    </Stack>
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <span>{row.high}</span>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <span>{row.low}</span>
                  </StyledTableCell>
                  {/* <StyledTableCell align="right">
                    <span>{row.previous_close}</span>
                  </StyledTableCell> */}

                  {/* 52 WEEKS HIGH */}
                  <StyledTableCell align="right">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{JSON.parse(row.year_high)?.year_high}</span>
                      <span style={{ fontSize: "12px" }}>
                        {JSON.parse(row.year_high)?.year_high_gap_pcnt}
                        {!!JSON.parse(row.year_high)?.year_high_gap_pcnt
                          ? "%"
                          : "N/A"}
                      </span>
                      <span style={{ fontSize: "12px" }}>
                        {JSON.parse(row.year_high)?.year_high_day_ago} days ago
                      </span>
                    </div>
                  </StyledTableCell>

                  {/* 52 WEEKS LOW */}
                  <StyledTableCell align="right">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "2rem",
                      }}
                    >
                      <span>{JSON.parse(row.year_low)?.year_low}</span>
                      <span style={{ fontSize: "12px" }}>
                        {JSON.parse(row.year_low)?.year_low_gap_pcnt}
                        {!!JSON.parse(row.year_low)?.year_low_gap_pcnt
                          ? "%"
                          : "N/A"}
                      </span>
                      <span style={{ fontSize: "12px" }}>
                        {JSON.parse(row.year_low)?.year_low_day_ago} days ago
                      </span>
                    </div>
                  </StyledTableCell>

                  {/* Remove Icon for removing stock symbol from single watchlist page */}
                  {showRemoveStockIcon ? (
                    <StyledTableCell align="center">
                      <HighlightOffIcon
                        onClick={() => handleRemoveStock(row.symbol)}
                        style={{ cursor: "pointer" }}
                      />
                    </StyledTableCell>
                  ) : null}
                </StyledTableRow>
              );
            })}

            {/* {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={6} />
              </StyledTableRow>
            )} */}
          </TableBody>

          {/* <TableFooter>
          <StyledTableRow>
            <PaginationTable
              count={sortedTableFeed.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </StyledTableRow>
        </TableFooter> */}
        </Table>

        {finalTableData?.length === 0 ? <NoResultsToDisplay /> : null}
      </TableContainer>

      {/* {finalTableData?.length === 0 ? <NoResultsToDisplay /> : null} */}

      {/* TABLE PAGINATION BOTTOM */}
      <Stack spacing={2}>
        <Typography>Page: {currentPage}</Typography>
        <Typography>Total Stocks: {filteredTableFeed?.length}</Typography>
        {/* <Pagination count={5} page={page} onChange={handleChange} vaPrevious CandleStick Patternriant="outlined" shape="rounded" /> */}
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>

      {/* CHOOSE ALERT TYPE MODAL */}
      <MuiModal open={openAlertTypeModal} setOpen={setOpenAlertTypeModal}>
        <ChooseAlertTypeModal
          crudType="create"
          alertDetails={alertDetails}
          setUserAlertList={setUserAlertList}
          setOpenAlertTypeModal={setOpenAlertTypeModal}
        />
      </MuiModal>

      {/* SAVE TO WATCHLIST MODAL */}
      <MuiModal
        open={openSaveToWatchlistModal}
        setOpen={setOpenSaveToWatchlistModal}
      >
        <SaveToWatchlistModal
          // selectedSymbol={selectedSymbol}
          symbolDetails={symbolDetails}
          setOpenSaveToWatchlistModal={setOpenSaveToWatchlistModal}
          setSortedTableFeed={setSortedTableFeed}
          userWatchlistDataRes={userWatchlistDataRes}
        />
      </MuiModal>
    </div>
  );
}

// UTILITY FUNCTIONS
function calcOperatorValues(value1, optr, value2) {
  switch (optr) {
    case "gt":
      return value1 > value2;

    case "lt":
      return value1 < value2;

    case "gte":
      return value1 >= value2;

    case "lte":
      return value1 <= value2;

    case "eq":
      return value1 === value2;

    default:
      return true;
  }
}

// UTILITY FUNCTIONS
// if a number is positive, return green, else red
function getColor(num) {
  // if (!num) {
  //   return "";
  // }

  if (parseFloat(num) >= 0) {
    // color green (BINANCE)
    // return '#03A66D';
    return "#339502";
  }
  // color red (BINANCE)
  return "#CF304A";
}

function getSign(num) {
  if (!num) return "";

  if (parseFloat(num) >= 0) {
    return "+";
  }

  return "";
}

const intervals = {
  "1-min": "1",
  "5-min": "5",
  "15-min": "15",
  "30-min": "30",
  "60-min": "60",
  "1-d": "1D",
};

// format Trading View link / URL
function formatTradingViewURL(exchange, symbol, interval) {
  // formatTradingViewURL("NSE", formattedSymbol, selectedTimeframe);
  // https://in.tradingview.com/chart/?symbol=BSE%3ARELIANCE&interval=1D

  // converting interval/timeframe to tradingView compatible
  const convertedInterval = intervals[interval];
  const formattedURL = `https://in.tradingview.com/chart/?symbol=${exchange}%3A${symbol}&interval=${convertedInterval}`;
  return formattedURL;
}

// checking if a symbol is added in any watchlist
// async function isSymbolAddedToWatchlist(symbol_name) {
//   // user watchlists api
//   const res = await getUserWatchlists(USERID);
//   const userWatchlistsArr = res.response;

//   const isAdded = userWatchlistsArr.forEach(item => {
//     if (item.symbol.includes(symbol_name)) {
//       return true;
//     } else {
//       return false;
//     }
//   });

//   return isAdded;
// }
