import React, { useState } from "react";
import MuiModal from "../../components/muiComps/MuiModal";
import AddScreenerAlertModal from "./AddScreenerAlertModal";
import { Button, Stack, Typography } from "@mui/material";
import MonitorIcon from "@mui/icons-material/Monitor";


import axiosConfig from "../../axios/axiosConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// THIS COMPONENT IS SCAN NAME, DESCRIPTION AND 'ADD SCREENER ALERT' BUTTON
const SavedScanDetails = ({
  
  screenerId,
  userScreenerAlertDetail,
  screenerObjDetails,
}) => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  const navigate = useNavigate();
  const [isScreenerAlertModal, setIsScreenerAlertModal] = useState(false);

  // state to store user alert details fetched from server api
  const SCREENER_ALERT_ID = userScreenerAlertDetail?.id;
 

  const deleteAlert = async () => {
    if (!SCREENER_ALERT_ID) {
      // toast
      console.log("screener id is undefined.");
      return;
    }

    if (!window.confirm("Are you sure you want to delete this alert?")) {
      console.log("delete cancelled!!");
      return;
    }

    try {
      const alertRes = await axiosConfig({
        method: "delete",
        url: `/screener_alert/user_screener_alert?id=${SCREENER_ALERT_ID}&userid=${userDetails?.id}`,
        data: {},
      });

      console.log("alert res: ", alertRes);
    } catch (error) {
      console.log(error);
    } finally {
      // closeModal();
    }
  };

  // HANDLE MONITOR TO DASHBOARD CLICK EVENT (route to /dashboards with choose dashboard modal)
  const routeToDashboards = () => {
    if (!screenerId) {
      // setOpenSaveScanModal(true);
      return;
    }

    navigate("/dashboards?openModal=true", {
      state: { screenerId: screenerId },
    });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "#bc8f8f1f",
          borderRadius: "5px",
          padding: "10px",
          margin: "0.5rem 0",
        }}
      >
        <h4>{screenerObjDetails?.scan_name}</h4>
        <p>{screenerObjDetails?.scan_description}</p>

        {screenerObjDetails?.hide_query ? (
          <h3>QUERY IS HIDDEN.</h3>
        ) : null}
        

        <Stack direction="row" gap={2} mt={2}>
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={() => setIsScreenerAlertModal(true)}
          >
            Add Screener Alert
          </Button>

          <Button
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<MonitorIcon />}
            onClick={routeToDashboards}
          >
            MONITOR ON DASHBOARD
          </Button>
        </Stack>

        {/* <p>show if query hidden or not</p> */}

        {/* SCREENER ALERT DETAILS */}
        {SCREENER_ALERT_ID ? (
          <Stack sx={{}}>
            <Typography variant="h6">Screener Alert Active</Typography>
            <Typography>{userScreenerAlertDetail?.alert_name} </Typography>
            <Typography>{userScreenerAlertDetail?.description}</Typography>

            <Stack direction="row" gap={2} mt={2}>
              <Button
                size="small"
                variant="contained"
                color="warning"
                onClick={deleteAlert}
              >
                Delete Alert
              </Button>
            </Stack>
          </Stack>
        ) : null}

        {/* <p>show alert details here (name and desc) if screener alert exist or not, </p> */}
      </div>

      {/* ADD SCREENER ALERT MODAL */}
      <MuiModal open={isScreenerAlertModal} setOpen={setIsScreenerAlertModal}>
        <AddScreenerAlertModal
          closeModal={() => setIsScreenerAlertModal(false)}
          screenerId={screenerId}
        />
      </MuiModal>
    </>
  );
};

export default SavedScanDetails;
