import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomDynamicTable01 from "../CustomDynamicTable01";
import MuiLinearProgressLoader from "../../muiComps/MuiLinearProgressLoader";

import axiosConfig from "../../../axios/axiosConfig";
import { toast } from "react-hot-toast";
import ReactHotToaster from "../ReactHotToaster";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

import AD_BANNER from "../../../assets/ad_banner.png";
import HtmlSelectDropdown from "../HtmlSelectDropdown";
import SocialShare from "../SocialShare";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: "center",
  color: theme.palette.text.secondary,
  // border: '1px solid',
  borderRadius: "4px",
  // boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)",

  // margin: '14px 0',
  // height: "100%",
}));

const TopStocksHomeTables = () => {
  const [recomStocksGainersData, setRecomStocksGainersData] = useState([]);
  // const [recomOptionsData, setRecomOptionsData] = useState([]);
  const [recomStocksLosersData, setRecomStocksLosersData] = useState([]);

  // SELECT SEGMENT DROPDOWN STATES FOR TOP GAINERS/LOSERS TABLES
  const [selectedTopGainerSegment, setSelectedTopGainerSegment] =
    useState("nifty100");
  const [selectedTopLoserSegment, setSelectedTopLoserSegment] =
    useState("nifty100");

  // FETCHING RECOMMENDED STOCKS (TOP 10 GAINERS)
  const topGainersQuery = useQuery({
    queryKey: ["recommended-stocks", selectedTopGainerSegment],
    queryFn: () => fetchRecommendedStocks(selectedTopGainerSegment, "gainer"),
    onSuccess: (data) => {
      const newStocksData = data.response.map((item) => {
        const { buyprice, date } = item.recommendation;
        return {
          id: item.stock_id,
          name: item.symbol,
          suggested_price: buyprice,
          // suggested_date: new Date(date).toDateString(),
          suggested_date: date,
          price_prcnt_gain: item.recommended_pChange,
        };
      });
      setRecomStocksGainersData(newStocksData);
    },
    onError: (error) => {
      console.log("recom stocks error: ", error);
      toast.error(error.message || "Something went wrong");
    },
  });

  // FETCHING RECOMMENDED STOCKS (TOP 10 LOSERS ONLY)
  const topLosersQuery = useQuery({
    queryKey: ["recommended-losers", selectedTopLoserSegment],
    queryFn: () => fetchRecommendedStocks(selectedTopLoserSegment, "loser"),
    onSuccess: (data) => {
      // console.log(data)
      const newLosersData = data.response.map((item) => {
        const { sellprice, pct, date } = item.recommendation;
        return {
          id: item.stock_id,
          name: item.symbol,
          suggested_price: sellprice,
          // suggested_date: new Date(date).toDateString(),
          suggested_date: date,
          price_prcnt_gain: item.recommended_pChange,
        };
      });
      setRecomStocksLosersData(newLosersData);
    },
    onError: (error) => {
      console.log("recom options error: ", error);
      toast.error(error.message || "Something went wrong");
    },
  });

  return (
    <>
      {/* <HtmlSelectDropdown
        title="Select Segment"
        selectName="Segment Options"
        placeholder="Select Segment"
        optionsArray={["cash", "banknifty", "daily", "default", "F&O", "futures", "Midcap 50", "nifty 100", "nifty 200", "nifty 50", "nifty 500", "nifty and banknifty"]}
        selectedOption={selectedSegment}
        setSelectedOption={setSelectedSegment}
        
      /> */}

      <Box
        sx={{
          flexGrow: 1,
          margin: "1rem 0",
          padding: { xs: "0 0.5rem" },
          maxWidth: "1600px",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            sx={{ margin: "8px 0" }}
            // sx={{ margin: '1rem' }}
          >
            <Item
              sx={{
                display: "flex",
                justifyContent: "center",
                // alignItems: 'center',
              }}
            >
              {topGainersQuery.isLoading ? (
                <MuiLinearProgressLoader />
              ) : (
                <Box
                  sx={
                    {
                      // minWidth: '500px',
                      // maxWidth: '800px',
                    }
                  }
                >
                  <CustomDynamicTable01
                    tableTitle="Top Gainers"
                    tableRowsData={recomStocksGainersData}
                    selectedSegment={selectedTopGainerSegment}
                    setSelectedSegment={setSelectedTopGainerSegment}
                    // tableRowsData={cryptoData}
                    linkTo={`/recommended-stocks?tab=${selectedTopGainerSegment}`}
                  />

                  {/* SHARE BUTTONS */}
                  {/* <SocialShare /> */}
                </Box>
              )}
            </Item>
          </Grid>

          <Grid item xs={12} md={12} lg={6} sx={{ margin: "8px 0" }}>
            <Item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {topLosersQuery.isLoading ? (
                <MuiLinearProgressLoader />
              ) : (
                <Box
                  sx={
                    {
                      // minWidth: '600px',
                      // maxWidth: '800px',
                    }
                  }
                >
                  <CustomDynamicTable01
                    // tableTitle="Short / Sell Recommendations"
                    tableTitle="Top Losers"
                    tableRowsData={recomStocksLosersData}
                    selectedSegment={selectedTopLoserSegment}
                    setSelectedSegment={setSelectedTopLoserSegment}
                    linkTo={`/recommended-stocks?tab=${selectedTopLoserSegment}`}
                  />
                </Box>
              )}
            </Item>
          </Grid>

          {/* <Grid
            item
            sm={12}
            md={8}
            lg={4}
            sx={{ margin: "8px 0", maxHeight: "500px" }}
          >
            <Item>
              <img src={AD_BANNER} alt="" />
            </Item>
          </Grid> */}

          {/* <Grid
          item
          sm={12}
          md={8}
          lg={4}
          sx={{ margin: "8px 0", maxHeight: "500px" }}
        >
          <Item>
            {recomOptionsQuery.isLoading ? (
              <MuiLinearProgressLoader />
            ) : (
              <Box>
              <CustomDynamicTable01
                tableTitle="OPTIONS"
                tableRowsData={recomOptionsData}
              />

              <Link to="/recommended-stocks?tab=cash">
                <Box
                  sx={{
                    backgroundColor: "#339502",
                    color: "white",
                    pl: "15px",
                  }}
                >
                  <Typography sx={{ mt: 2 }}>
                    Click here to see all Recommendations
                  </Typography>
                </Box>
              </Link>
              </Box>
            )}
          </Item>
        </Grid> */}
        </Grid>

        {/* SHARE BUTTONS */}
        {/* moved inside topstockstables */}
        {/* <SocialShare /> */}

        {/* HOT TOASTER */}
        <ReactHotToaster />
      </Box>
    </>
  );
};

export default TopStocksHomeTables;

// UTILITY FUNCTIONS
export const fetchRecommendedStocks = async (segmentType, category) => {
  if (!segmentType) return;

  const res = await axiosConfig({
    method: "get",
    url: `/home/gainer?segment=${segmentType}&category=${category}`,
    data: {},
  });

  return res.data;
};

// DEMO CRYPTO DATA
const cryptoData = [
  {
    id: 1,
    name: "RELIANCE",
    suggested_date: "2023-05-19",
    suggested_price: 115.85,
    price_prcnt_gain: "5%",
  },
  {
    id: 2,
    name: "HDFC",
    suggested_date: "23/04/2023",
    suggested_price: 253.39,
    price_prcnt_gain: "2%",
  },
  {
    id: 3,
    name: "TATASTEEL",
    suggested_date: "23/04/2023",
    suggested_price: 566.31,
    price_prcnt_gain: "5%",
  },
  {
    id: 4,
    name: "MAZDOCK",
    suggested_date: "23/04/2023",
    suggested_price: 1526.57,
    price_prcnt_gain: "8%",
  },
  {
    id: 5,
    name: "RELIANCE",
    suggested_date: "23/04/2023",
    suggested_price: 115.85,
    price_prcnt_gain: "5%",
  },
  {
    id: 6,
    name: "HDFC",
    suggested_date: "23/04/2023",
    suggested_price: 253.39,
    price_prcnt_gain: "2%",
  },
  {
    id: 7,
    name: "TATASTEEL",
    suggested_date: "23/04/2023",
    suggested_price: 566.31,
    price_prcnt_gain: "5%",
  },
  {
    id: 8,
    name: "MAZDOCK",
    suggested_date: "23/04/2023",
    suggested_price: 1526.57,
    price_prcnt_gain: "8%",
  },
  {
    id: 9,
    name: "RELIANCE",
    suggested_date: "23/04/2023",
    suggested_price: 115.85,
    price_prcnt_gain: "5%",
  },
  {
    id: 10,
    name: "HDFC",
    suggested_date: "23/04/2023",
    suggested_price: 253.39,
    price_prcnt_gain: "2%",
  },
];
