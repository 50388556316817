import React from "react";
import "./AccessDenied.css";
import { useNavigate } from "react-router-dom";

const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <div className="access-denied">
      <h1 className="access-denied__title">Access Denied</h1>
      <p className="access-denied__message">
        You do not have permission to view this page.
      </p>

      <button className="backBtn" onClick={() => navigate('/home')} >back to home</button>
    </div>
  );
};

export default AccessDenied;
