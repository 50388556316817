import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useQuery } from "@tanstack/react-query";

import dateFormat, { masks } from "dateformat";

import axiosConfig from "../../axios/axiosConfig";
import { Link } from "react-router-dom";
import MuiSortIcons from "../../components/muiComps/MuiSortIcons";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import HelmetSEO from "../../components/common/HelmetSEO";


// CUSTOMIZED MUI BUTTON
export const Button = styled(MuiButton)((props) => ({
  // Custom CSS
  minWidth: 40,
  padding: '0.1rem 0.5rem',
  textTransform: 'uppercase'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: '#e2e2e2',
    // color: theme.palette.common.white,
    color: '#222',
    padding: '0.5rem 0.2rem',
    // fontSize: 16,
    textTransform: 'uppercase'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: '0.5rem 0.2rem'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const OptionChainPage = () => {
  const [optionsData, setOptionsData] = useState([]); // initial / original Data fetched
  const [filterType, setFilterType] = useState("");

  // FILTERING "BUY" OR "SELL"
  const filterOptionsData = optionsData.filter(option => {
    if (!filterType || filterType === "") {
      return true;
    }
    return option.recommendation?.recommendation === filterType
  })
  
  

  // ascending or descending
  const [sortingOrder, setSortingOrder] = useState();
  const [sortingBy, setSortingBy] = useState("");

  // setSortingBy FUNCTION, sets the field name/item that is to be sorted.
  const setSortField = (sorting_by) => {
    setSortingBy(sorting_by);
    setSortingOrder((currentState) => !currentState);
  };

  
  // FETCH USING useQUERY
  const optionsQuery = useQuery({
    queryKey: ["option-symbols"], // dependency
    queryFn: () => fetchOptionsData(),
    onSuccess: (data) => {
      setOptionsData(data.response);

      // cogoToast.success(data.response, { position: "top-right"});
    },
    onError: (error) => {
      console.log("error: ", error);
      // cogoToast.error(error.message || "Something went wrong", { position: "top-right"});
    },
  });

  const handleSorting = () => {
    if (!sortingBy || sortingBy === "") {
      return;
    }

    let arrToSort = [...optionsData];

    // SORTING BY DATE IN RECOMMENDATION COLUMN
    if (sortingBy === "date") {
      arrToSort.sort((a, b) => {
        let date1 = new Date(a.recommendation?.date);
        let date2 = new Date(b.recommendation?.date);

        if (sortingOrder) {
          // return num1 > num2 ? 1 : num1 < num2 ? -1 : 0;
          return date2 - date1;
        } else {
          return date1 - date2;
        }
      });
    }

    // Alphabetical sortinge
    if (sortingBy === "symbol") {
      arrToSort.sort((a, b) => {
        if (a[sortingBy] < b[sortingBy]) return !sortingOrder ? -1 : 1;
        if (a[sortingBy] > b[sortingBy]) return !sortingOrder ? 1 : -1;
        // return 0;
      });
    } else {
      // FOR ALL NUMERICAL SORTING
      // sortingOrder: ascending or descending, true or false
      // for 'CMP', 'volume', etc.
      if (sortingOrder) {
        arrToSort.sort((a, b) => a[sortingBy] - b[sortingBy]);
      } else {
        arrToSort.sort((a, b) => b[sortingBy] - a[sortingBy]);
      }
    }
    setOptionsData(arrToSort);
  };

  useEffect(() => {
    handleSorting();
    // localStorage.setItem(
    //   "sorting_details_local",
    //   JSON.stringify({ sortingOrder, sortingBy })
    // );
  }, [sortingOrder, sortingBy]);


  // REMOVE ALL FILTERS & sorting
  const removeFilters = () => {
    setFilterType("");
    setSortingOrder(null);
    setSortingBy("");
    optionsQuery.mutate();
  }

  // const disabledRemoveAllFiltersBtn = !sortingBy || sortingBy === "" || !filterType || filterType === ""
  const disabledRemoveAllFiltersBtn = !(sortingBy || filterType)
  
  
  return (
    <div>
      <HelmetSEO
          title="Option Chain"
          description="Option Chain Recommendation & Chart"
          pageUrl="https://tnibro.com/nse-option-chain"
        />
      
      <Typography variant="h3" sx={{
        textTransform: 'uppercase',
        border: '2px solid #ccc',
        display: 'inline-block',
        padding: '0.5rem',
        borderRadius: 1,
        margin: '1rem 0',
        textAlign: 'center'
      }} >Option Chain</Typography>

      {/* <button onClick={removeFilters} >remove all filters</button> */}
      

      {/* options table */}
      <TableContainer component={Paper} sx={{  width: '100%', maxWidth: 600}}>
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          mb: 1
        }} >
          <Tooltip title="Remove sorting and filters">
            <span>
            <Button disabled={disabledRemoveAllFiltersBtn} variant="contained" size='small' color="error" onClick={removeFilters} >remove all filters</Button>
            </span>
          </Tooltip>
        </Box>
      
        <Table sx={{width: '100%'}} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Stack direction="row" alignItems="center">
                  <p>Symbol</p>
                  <MuiSortIcons
                    sortingByValue="symbol"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("symbol")}
                  />
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" alignItems="center" justifyContent='center'>
                  <p>Recommendation Date</p>
                  <MuiSortIcons
                    sortingByValue="date"
                    sortingBy={sortingBy}
                    sortingOrder={sortingOrder}
                    onClick={() => setSortField("date")}
                  />
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="left">
                <Stack>
                  <p>Recommendation</p>
                  <Stack direction='row' >
                    <Button variant="contained" size='small' onClick={() => setFilterType("Buy")} sx={{ mr: 1, backgroundColor: "rgb(3, 166, 109)"}} >call</Button>
                    <Button variant="contained" size='small' color="error" onClick={() => setFilterType("Sell")} sx={{ backgroundColor: "rgb(207, 48, 74)"}} >put</Button>
                    {/* <button >call</button>
                    <button >put</button> */}
                  </Stack>
                </Stack>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filterOptionsData?.map((option) => (
              <StyledTableRow key={option.id}>
                <StyledTableCell component="th" scope="row" >
                  <div>
                    <Link to={`/nse-option-chain/${option.symbol}`}>
                      {option.symbol}
                    </Link>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span>
                    {dateFormat(option?.recommendation?.date, masks.mediumDate)}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Link to={`/nse-option-chain/${option.symbol}`}>
                    <div
                      style={{
                        // border: '1px solid',
                        width: '75%',
                        textAlign: "center",
                        textTransform: 'uppercase',
                        fontWeight: 200,
                        padding: "0.3rem 0",
                        borderRadius: 3,
                        backgroundColor:
                          option?.recommendation?.recommendation === "Buy"
                            ? "rgb(3, 166, 109)"
                            : "rgb(207, 48, 74)",
                      }}
                    >
                      <p
                        style={{
                          color: "#eee",
                        }}
                      >
                        {option.options}
                      </p>
                    </div>
                  </Link>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OptionChainPage;

// UTILITY FUNCTIONS
const fetchOptionsData = async () => {
  const res = await axiosConfig({
    method: "get",
    url: "home/option_stocks?option=true",
    data: {},
  });

  return res.data;
};