import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const MuiSelectInput = ({
  label = "",
  selectedInput = '',
  setSelectedInput,
  inputOptions = [],
  defaultOption = ""
}) => {
  // const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setSelectedInput(event.target.value);
  };

  return (
    <FormControl sx={{  minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">{label}</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedInput}
        defaultValue={inputOptions[0]?.value}
        label=""
        onChange={handleChange}
      >
        {/* <MenuItem value="">
          <em>{defaultOption}</em>
        </MenuItem> */}
        {inputOptions?.map(item => {
          return <MenuItem key={item.id} value={item.value}>{item.label}</MenuItem>
        })}
        
      </Select>
    </FormControl>
  )
}

export default MuiSelectInput;