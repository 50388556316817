import React, { useState } from "react";

import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styles from "./SinglePaymentDetails.module.css";
import stockyaariLogo from '../../../../assets/stockyaari-logo.png';

const SinglePaymentDetails = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  const { paymentID } = useParams();

  const [billDetails, setBillDetails] = useState();

  
  const formattedDate = new Date(billDetails?.payment_date).toLocaleString().slice(0, 9)

  // FETCH USER Payments (all transactions) USING useQUERY
  const userBillDetailsQuery = useQuery({
    queryKey: ["user-bill-details"],
    queryFn: () => getUserBillDetails(userDetails.id, paymentID),
    onSuccess: (data) => {
      setBillDetails(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  return (
    <div className={styles.SinglePaymentDetails}>
      <h2>Invoice</h2>

      <button onClick={() => window.history.back()} >back</button>

      {/* STOCKYAARI LOGO */}
      <div className={styles.logoContainer}>
        <img className={styles.logo} src={stockyaariLogo} alt="StockYaari Logo" />
      </div>

      {/* OFFICE ADDRESS */}
      <div className={styles.addressContainer}>
        <ul className={styles.addressList}>
          <li className={styles.addressItem}>
            STOCKYAARI TECHNOLOGIES PVT LTD
          </li>
          <li className={styles.addressItem}>2nd Floor, Plot No. 756</li>
          <li className={styles.addressItem}>Udyog Vihar Phase 5</li>
          <li className={styles.addressItem}>Gurugram Haryana-122016</li>
          <li className={styles.addressItem}>billing@stockyaari.com</li>
          <li className={styles.addressItem}>GSTIN: 06ABMCS2604Q1ZV</li>
        </ul>
      </div>

      {/* USER EMAIL ADDRESS */}
      <div className={styles.userEmailContainer}>
        <p>{userDetails.email}</p>
      </div>

      {/* Invoice Number */}
      <div className={styles.invoiceNumberContainer}>
        <p>Invoice Number: {"inv8569"}</p>
      </div>

      {/* PAYMENT DETAILS */}
      <div className={styles.paymentDetails}>
        <div className={styles.dateContainer}>
          <p>Date</p>
          <p>{formattedDate}</p>
        </div>

        <div className={styles.description}>
          <p>description</p>
          <p>StockYaari Premium</p>
        </div>

        <div className={styles.amount}>
          <p>Amount</p>
          <p>{billDetails?.amount}</p>
        </div>

        <div className={styles.total}>
          <p>TOTAL</p>
          <p>{billDetails?.amount}</p>
        </div>
      </div>

      {/* MISC PAYMENT DETAILS */}
      <div className={styles.miscDetails}>
        <p className={styles.paymentMethod}>
          Payment Method: <span>online</span>
        </p>
        <p className={styles.paymentDate}>
          Payment Date: <span>{formattedDate}</span>
        </p>
        <p className={styles.refID}>
          Reference ID: <span>{billDetails?.payment_id}</span>
        </p>
        <p className={styles.place}>
          Place of Supply: <span>Haryana</span>
        </p>
        <p className={styles.sac}>
          SAC Code: <span>998319</span>
        </p>
      </div>
    </div>
  );
};

export default SinglePaymentDetails;

export const getUserBillDetails = async (USERID, payment_id) => {
  if (!USERID || !payment_id) {
    return;
  }

  const res = await axiosConfig({
    method: "get",
    // url: `/user/userdashboard?section=payment&userid=${USERID}`,
    url: `/payment/user_payment_history?userid=${USERID}&payment_id=${payment_id}`,
    data: {},
  });

  return res.data;
};
