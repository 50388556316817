import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const TextFields = ({ handleTextFieldValue, value, defaultValue, onChangeApplyDefaultValue }) => {
  const [inputValue, setInputValue] = useState(value || null);

  // const handleDefaultValues = () => {

  //   const obj = measureRootOptions.find(option => option.value === defaultValue);
  //   return obj.defaultValue;
  // };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    // if (optionsShowingInputField) {
    //   if (optionsShowingInputField.indexOf(onChangeApplyDefaultValue) !== -1) {
    //     handleTextFieldValue(defaultValue);
    //   }
    // }
    
    if(defaultValue) {
      handleTextFieldValue(defaultValue);
    }

  }, [onChangeApplyDefaultValue]);

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '12ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={inputValue}
        size="small"
        onChange={(e) => handleTextFieldValue(e.target.value)}
      />
    </Box>
  );
};

export default TextFields;
