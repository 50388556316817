import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import axiosConfig from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import MobileOTPInputBox from "../MobileOTPInputBox";
import { logout } from '../../../../reduxStore/actions/auth'

const deactivate_list = [
  {
    id: 1,
    label: "I'm not interested anymore.",
    value: "FB101",
    checked: false,
  },
  { id: 2, label: "I found a better product.", value: "FB102", checked: false },
  {
    id: 3,
    label: "Alerts are delayed & Inaccurate.",
    value: "FB103",
    checked: false,
  },
  { id: 4, label: "Performance Issues.", value: "FB104", checked: false },
  { id: 5, label: "Others.", value: "FB105", checked: false },
];

const DeactivateAccountModal = ({ setOpen }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const [optionsList, setOptionsList] = useState(deactivate_list);
  const selectedOptionsArray = optionsList.filter((item) => item.checked).map(item => item.value)
  const selectedOptions = selectedOptionsArray.join();

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileOTP, setMobileOTP] = useState("");
  const [sessionID, setSessionID] = useState("");

  const handleListChange = (event, checkbox_id) => {
    setOptionsList((prevState) => {
      return prevState.map((item) => {
        if (item.id === checkbox_id) {
          return { ...item, checked: event.target.checked };
        }

        return item;
      });
    });
  };

  // GET OTP HANDLER
  const handleGetOTP = async () => {
    try {
      const res = await axiosConfig({
        method: "post",
        url: "/user/account_deactivation",
        data: {
          userid: USERID,
          phone: `91${mobileNumber}`,
        },
      });

      // If successful
      if (res.status === 200 || res.statusText === "OK") {
        setSessionID(res.data.Details);
        const TOAST_MSG =
          "OTP sent to mobile, please type OTP and click Verify OTP";
        toast.success(TOAST_MSG, {
          duration: 6000,
          position: "top-center",
        });
      }
    } catch (error) {
      console.log(error.response);
      const ERROR_MSG = error.response.data.response;
      toast.error(ERROR_MSG || "Something went wrong", {
        duration: 6000,
        position: "top-center",
      });
    }
  };

  // VERIFY / SUBMIT OTP HANDLER
  const verifyOTP = async () => {
    try {
      const res = await axiosConfig({
        method: "post",
        url: "/user/deactivation_verification",
        data: {
          phone: `91${mobileNumber}`,
          session_id: sessionID,
          otp: parseInt(mobileOTP),
          userid: USERID,
          action: "deactivate",
        },
      });

      if (res.status === 200 || res.statusText === "OK") {
        // Indian User Authorized
        toast.success("OTP verified successfully!", {
          duration: 6000,
          position: "top-center",
        });

        // LOGOUT AND CLEAR LOCAL STORAGE
        dispatch(logout());
        

        // EXECUTE A FUNCTION HERE THAT SENDS VALID DEACTIVATE REASONS TO BACKEND.
        sendFeedbackApi();
      }
    } catch (error) {
      console.log("error: ", error);
      const ERROR_MSG = error.response.data.response;
      toast.error(ERROR_MSG || "Something went wrong", {
        duration: 6000,
        position: "top-center",
      });
    } finally {
      setOpen(false);
      window.location.reload();
    }
  };

  const sendFeedbackApi = async () => {
    try {
      const res = await axiosConfig({
        method: "post",
        url: "/user/deactivation_feedback",
        data: {
          userid: USERID,
          feedback_list: selectedOptions,
        },
      });

      if (res.status === 200 || res.statusText === "OK") {
        // Indian User Authorized
        toast.success("Feedback sent successfully!", {
          duration: 6000,
          position: "top-center",
        });
      }
    } catch (error) {
      console.log("error: ", error);
      const ERROR_MSG = error.response.data.response;
      toast.error(ERROR_MSG || "Something went wrong", {
        duration: 6000,
        position: "top-center",
      });
    }
  }

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          marginBottom: "1rem",
        }}
      >
        Deactivate My Account
      </Typography>

      {/* CHECKBOXES */}
      <Stack
        sx={{
          border: "1px solid #ccc",
          borderRadius: "0.5rem",
          padding: "1rem",
        }}
      >
        <Typography variant="subtitle1">
          Please select valid reasons:
        </Typography>

        {optionsList.map((item) => {
          return (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    key={item.id}
                    // value={item.checked}
                    checked={item.checked}
                    onChange={(event) => handleListChange(event, item.id)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={item.label}
              />
            </FormGroup>
          );
        })}
      </Stack>

      {/* MOBILE PHONE INPUT */}
      <MobileOTPInputBox
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
        mobileOTP={mobileOTP}
        setMobileOTP={setMobileOTP}
        onGetOTP={handleGetOTP}
        onVerifyOTP={verifyOTP}
        actionType="deactivate"
        selectedOptionsArray={selectedOptionsArray}
      />

      <Stack sx={{
        marginTop: '2rem',
        // justifyContent: 'center',
        alignItems: 'center'
      }} >
        <Button onClick={() => setOpen(false)} variant="contained" sx={{
          width: '50%'
        }} >Cancel</Button>
      </Stack>
    </Box>
  );
};

export default DeactivateAccountModal;
