import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { axiosConfig } from "../../../../axios/axiosConfig";

import styles from "./UserCallback.module.css";
import ColorSignalCircle from "../../../common/ColorSignalCircle";
import { Switch } from "@mui/material";

const UserCallback = () => {
  const [isCalledBack, setIsCalledBack] = useState(false);
  const [userCallbacksList, setUserCallbacksList] = useState([]);

  const userCallbacksQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["user-callbacks"],
    queryFn: () => fetchUserCallbacks(),
    onSuccess: (data) => {
      setUserCallbacksList(data.response);
    },
    onError: (error) => {
      console.log("fetch user callbacks: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const handleCallBackChange = (event) => {
    setIsCalledBack(event.target.checked);
  };

  return (
    <div>
      <h2>UserCallback</h2>

      {/* USER CALLBACKS TABLE */}
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>#</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Created On</th>
            <th>Called Back?</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {userCallbacksList.map((item, index) => {
            // const createdDate = new Date(item.created_at).toDateString();

            return (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.contact_number}</td>
                <td>{item.email}</td>
                <td>{item.created_at}</td>
                <td>
                  {item.call_initiated ? (
                    <ColorSignalCircle bgColor="green" />
                  ) : (
                    <ColorSignalCircle bgColor="red" />
                  )}
                </td>
                <td>
                  <div className={styles.actionBtnContainer}>
                    <Switch
                      checked={item.call_initiated}
                      onChange={handleCallBackChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    {/* <button onClick={() => {}} >delete</button> */}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UserCallback;

// UTILITIES
export const fetchUserCallbacks = async () => {
  // if (!watchlistId) return;

  const res = await axiosConfig({
    method: "get",
    url: "/advertisement/callrequest",
    data: {},
  });

  return res.data;
};
