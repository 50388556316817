import React from "react";
import GoogleStoreIcon from "../../../assets/play-store-logo.png";
import AppleStoreIcon from "../../../assets/apple-store-logo.png";

import styles from './IconBadges.module.css';

const IconBadges = ({ justifyContent = 'center'}) => {
  return (
    <div className={styles.badgeContainers} style={{ justifyContent: justifyContent }} >
      <img src={GoogleStoreIcon} alt="Google store badge" width={120} />
      <img src={AppleStoreIcon} alt="Apple Store badge" width={100} />
    </div>
  );
};

export default IconBadges;
