import React from "react";
// import SubscriptionPlan from "../../components/SubscriptionPlan";
import PricingPlans from "../../components/PricingPlans";
import { useSearchParams } from "react-router-dom";

const Pricing = () => {
  const [searchParams] = useSearchParams();
  const planUpgradeType = searchParams.get("planType");
  // console.log("planUpgradeType: ", planUpgradeType)

  return (
    <div>
      <PricingPlans planUpgradeType={planUpgradeType} />
      {/* <SubscriptionPlan /> */}
    </div>
  );
};

export default Pricing;
