import React, { useEffect, useState, Suspense, lazy } from "react";
import Tab from "@mui/material/Tab";
import { grey } from "@mui/material/colors";
import { useSearchParams, useNavigate } from "react-router-dom";

import styles from "./RecommendedStocksPage.module.css";
import { Container, Stack, Typography, styled } from "@mui/material";

// import DenseTable from "../../components/Tables/Tables";

import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import ReactHotToaster from "../../components/common/ReactHotToaster";
// import { fetchAllRecommendedStocks } from "./utils";

import axiosConfig from "../../axios/axiosConfig";
import MuiLinearProgressLoader from "../../components/muiComps/MuiLinearProgressLoader";
// import FilterByStockCategories from "../../components/common/FilterByStockCategories";
import GoogleTranslate from "../../components/common/GoogleTranslate";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import HelmetSEO from "../../components/common/HelmetSEO";
import {
  setCurrentPage,
  setTotalStocks,
} from "../../reduxStore/actions/pagination";

// LAZY LOADING COMPONENTS
const DenseTable = lazy(() => import("../../components/Tables/Tables"));

const tabsList = [
  { index: 0, value: "cash", label: "All Stocks" },
  { index: 1, value: "nifty50", label: "Nifty 50" },
  { index: 2, value: "nifty100", label: "Nifty 100" },
  { index: 3, value: "nifty200", label: "Nifty 200" },
  { index: 4, value: "nifty500", label: "Nifty 500" },
  { index: 5, value: "fo", label: "F&O" },
  { index: 6, value: "mcx", label: "MCX" },
  { index: 7, value: "futures", label: "Futures" },
];

// "nifty100","nifty200","nifty500"

const allStyles = {
  tabs: {
    // border: "1px solid gray",
    // borderRadius: 2,
  },
  tab: {
    // borderTop: '1px solid',
    // borderBottom: '1px solid',
    // borderRadius: 2,
    // margin: 1,
    // borderTop: "2px solid"
    backgroundColor: "#339502",
  },
  tableTitle: {
    margin: "2rem 0 0.5rem",
    padding: "0.5rem",
    border: "1px solid gray",
    borderRadius: 2,
    display: "inline-block",
    textTransform: "uppercase",
    fontWeight: 200,
    // fontSize: '1rem',
    // backgroundColor: "#2196f3",
    // color: "blue"
  },
};

// Custom Styling for Tab
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    // textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    // color: 'rgba(0, 0, 0, 0.85)',
    color: "white",
    margin: 0,
    // backgroundColor: lightBlue[300],

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      // color: '#40a9ff',
      color: "#fff",
      opacity: 1,
      // backgroundColor: "#40a9ff",
      backgroundColor: "#337902",
      // borderTop: "2px solid #40a9ff"
    },
    "&.Mui-selected": {
      // color: '#1890ff',
      color: "#333",
      // backgroundColor: lightBlue[50],
      backgroundColor: "white",
      borderTop: "2px solid #333",
      // borderBottom: '4px solid #333',
      borderBottom: `3px solid ${grey[700]}`,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      // backgroundColor: '#d1eaff',
    },
  })
);

// INTRADAY OPTIONS
const intraDayOptions = [
  { id: 1, value: "1-min", title: "1-min" },
  { id: 2, value: "5-min", title: "5-min" },
  { id: 3, value: "15-min", title: "15-min" },
  { id: 4, value: "30-min", title: "30-min" },
  { id: 6, value: "60-min", title: "60-min" },
  { id: 7, value: "1-d", title: "1-day" },
];

// Object to map intervals for tradingView URL
const intervals = {
  "1-min": "1",
  "5-min": "5",
  "15-min": "15",
  "30-min": "30",
  "60-min": "60",
  "1-d": "1D",
};

const RecommendedStocksPage = () => {
  const navigate = useNavigate();
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  // PAGE NUMBER & NUMBER OF ITEMS/SYMBOLS STATES
  // const [currentPage, setCurrentPage] = useState(1);
  const { currentPage, rowsPerPage } = useSelector((store) => store.pagination);
  // const [rowsPerPage, setRowsPerPage] = useState(50); // Default number of Rows or Symbols
  // const [totalStocks, setTotalStocks] = useState(0);

  let [searchParams, setSearchParams] = useSearchParams();
  const urlTabSegment = searchParams.get("tab"); // getting tab from url, ie '?tab=cash'
  const initialObj = tabsList.find((tab) => tab.value === urlTabSegment); // checking if value put in url is valid.

  // RETRIEVING SELECTED SEGMENT FROM LOCAL STORAGE
  // const local_segment = JSON.parse(localStorage.getItem('selected_segment_local'));
  const [selectedSegmentTab, setSelectedSegmentTab] = useState(
    initialObj?.value || "nifty500"
  ); // string value of the selected Segment tab eg. 'cash', 'nifty_50'. 'cash' by default

  // RETRIEVING 'selectedStockCategory' from localStorage
  const selected_stock_category_local = JSON.parse(
    localStorage.getItem("selected_sector")
  );

  // Stock category filter component
  const [selectedStockCategory, setSelectedStockCategory] = useState(
    selected_stock_category_local?.selectedStockCategory || ""
  );

  // TIMEFRAME
  const [selectedTimeframe, setSelectedTimeframe] = useState("1-d");

  const [userAlertList, setUserAlertList] = useState([]); // list of all applied alerts for user

  // stocksData for the main table
  const [stocksData, setStocksData] = useState([]);
  // console.log("stocksData: ", stocksData)
  // const [isInitialLoading, setIsInitialLoading] = useState(false); // initial fetching from url, useEffect loading state

  // change selectedSegment when URL is changed.
  useEffect(() => {
    setSelectedSegmentTab(initialObj?.value || "nifty50");
  }, [initialObj]);

  // REACT QUERY useMutation() hook to post screener and get stocks data, mutateAllStocksQuery
  const { isLoading, mutate } = useMutation({
    // mutationFn: fetchAllRecommendedStocks(selectedSegmentTab),
    mutationFn: async () => {
      if (!selectedSegmentTab) return;

      const res = await axiosConfig({
        method: "post",
        url: "/home/recommended_stocks",
        // url: `/home/recommended_stocks?page_no=${currentPage}&per_page=${rowsPerPage}`,
        data: {
          segment: selectedSegmentTab,
          timeframe: selectedTimeframe,
          userid: userDetails?.id,
        },
      });
      // console.log(res.data)
      return res.data;
    },
    onSuccess: (data) => {
      // console.log("data.response: ", data.response);
      const sortedByDate = data.response;
      // Sorted by date and time (ascending order)
      sortedByDate.sort(
        (a, b) =>
          new Date(b.timeframe).getTime() - new Date(a.timeframe).getTime()
      );

      setStocksData(sortedByDate);

      setUserAlertList(data.user_alert);
      // setTotalStocks(data?.total_length);
      dispatch(setTotalStocks(data?.total_length));
      // queryClient.invalidateQueries('recommended-all-stocks');
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  // onChange function for 'selectedSegment' ie 'cash', 'nifty50', 'mcx', etc.
  const handleTabChange = (event, newValue) => {
    setSelectedSegmentTab(newValue);
    navigate(`/recommended-stocks?tab=${newValue}`);
    // mutate(); //  this function runs on every tab click, (post segment to api)
  };

  // INITIALLY FETCHING 'CASH' OR SELECTED SEGMENT
  useEffect(() => {
    mutate();
  }, [selectedTimeframe, selectedSegmentTab, rowsPerPage, currentPage]);

  // IMPORTANT: This useEffect is to initialize currentPage to 1, for this recommended stocks component.
  useEffect(() => {
    dispatch(setCurrentPage(1));
  }, []);

  return (
    <Container>
      <HelmetSEO
        title="Recommended Stocks"
        description="Buy & Sell Recommendation"
        pageUrl={`${process.env.REACT_APP_ROOT_URL}/recommended-stocks`}
      />

      <div>
        <GoogleTranslate />
      </div>
      {/* <div className="onesignal-customlink-container"></div> */}
      {/* <button onClick={handlePush}>send push</button> */}

      {/* sx={allStyles.tableTitle} */}
      <div style={{ marginBottom: "1rem" }}>
        <Typography
          variant="h4"
          sx={{
            ...allStyles.tableTitle,
            fontSize: { xs: "1.5rem", md: "2rem" },
          }}
        >
          Recommended Stocks
        </Typography>

        <div className={styles.disclaimerWrapper}>
          <h2>Disclaimer</h2>

          <p className={styles.disclaimerText}>
            The information provided by this recommendation tool is for
            informational purposes only and does not constitute financial
            advice. The tool relies on historical data, algorithms, and other
            relevant factors to generate recommendations, but it cannot predict
            future market movements with certainty.
          </p>
        </div>

        {/* Investing in stocks carries inherent risks, including the potential for loss of capital. Before making any investment decisions, it is essential to conduct 
          thorough research, consider your risk tolerance, financial goals, and consult with a qualified financial advisor.

          The creators of this tool do not guarantee the accuracy, completeness, or reliability of the recommendations, and shall not be held responsible for any 
          losses or damages resulting from the use of this tool or reliance on its recommendations. Always exercise caution and make informed decisions when trading stocks or any other financial instruments. */}
      </div>

      <br />

      <Stack width={100}>
        <Dropdown
          list={intraDayOptions}
          value={selectedTimeframe}
          label="Timeframe"
          callback={(value) => setSelectedTimeframe(value)}
          // disabled={!isLatestOn}
          disabled={true} // temporary disabled
          // sxStyles={{ display: {xs: 'none', md: 'flex'}}}
        />
      </Stack>

      {/* TABS COMPONENT, mb: -25, display none for now. */}
      {/* <Box sx={{ maxWidth: { xs: 480, sm: "100%" }, mt: 4, display: 'none' }}>
        <Tabs
          sx={allStyles.tabs}
          value={selectedSegmentTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs"
        >
          {tabsList.map((tab) => {
            return (
              <AntTab
                sx={allStyles.tab}
                className={styles.tab}
                key={tab.index}
                value={tab.value}
                label={tab.label}
              />
            );
          })}
        </Tabs>
      </Box> */}

      {/* TABLE COMPONENT HERE */}

      {isLoading ? (
        <MuiLinearProgressLoader />
      ) : (
        <div style={{}}>
          <Suspense fallback={<div>TABLE IS LOADING, please wait...</div>}>
            <DenseTable
              tableData={stocksData}
              userAlertList={userAlertList}
              setUserAlertList={setUserAlertList}
              maxHeight="600px"
              selectedStockCategory={selectedStockCategory}
              setSelectedStockCategory={setSelectedStockCategory}
              screenerDataMutate={mutate}
              clearFilters={false}
              selectedTimeframe={selectedTimeframe}
              selectedSegmentTab={selectedSegmentTab}
              handleTabChange={handleTabChange}
              showRecomTabs={true}
            />
          </Suspense>

          {/* <DenseTable
            tableData={stocksData}
            userAlertList={userAlertList}
            setUserAlertList ={setUserAlertList }
            maxHeight="600px"
            
            screenerDataMutate={mutate}
          /> */}
        </div>
      )}

      {/* React Hot Toast */}
      <ReactHotToaster />
    </Container>
  );
};

export default RecommendedStocksPage;
