import React from "react";

import { useNavigate } from "react-router-dom";
import styles from '../PricingPlans.module.css';
import { YEARLY_DISCOUNT } from "../../../utils/globalConstants";


// const YEARLY_DISCOUNT = 0.2; // assuming discount is 10%

// This is new component made on 27th June, 2023
const PricingTile = ({ tier, planPeriod }) => {
  const navigate = useNavigate();
  
  const handleClickNavigate = (selectedTier) => {
    // navigate state refresh error due to sending icon comp in state. (complex state)
    // so not sending <Icon /> in tier object
    const formattedTier = {
      price: selectedTier.price,
      subheader: selectedTier.subheader,
      title: selectedTier.title
    }
    // calculating total payable amount without ADD ONS
    const totalBasePlanAmount = calcTotalPrice(formattedTier, planPeriod);  // calculate base plan price according to monthly or yearly
    const state = { totalBasePlanAmount, selectedTier: formattedTier, planPeriod, subscriptionType: "NEW_PLAN" };
    navigate('/cart', { state })
  };
  
  return (
    <ul key={tier.title} className={styles.free_tier_column}>
      <li className={styles.heading_list_item}>
        <h2 className={styles.plan_title}>{tier.title}</h2>
        <span>{tier.subtitle}</span>
        <h3>
          ${planPeriod === "monthly" ? tier.price : (tier.price * 12 * (1 - YEARLY_DISCOUNT)).toFixed(2) }
          <span className={styles.price_span}>
            {" "}
            /{planPeriod === "monthly" ? "Month" : "Year"}
          </span>
        </h3>
        {/* <button
          className={styles.choose_plan_button}
          onClick={() => handleClickNavigate(tier)}
        >
          choose this plan
        </button> */}
        
      </li>
      
      {tier.features.map((item) => {
        return <li key={item.id}>{item.label}</li>;
      })}

      <li>
        <button
          className={styles.choose_plan_button}
          onClick={() => handleClickNavigate(tier)}
        >
          choose this plan
        </button>
      </li>
    </ul>
  );
};

export default PricingTile;


// UTILITY FUNCTIONS
function calcTotalPrice(selected_tier, plan_period) {
  let total_price = 1;
  

  if (plan_period === "monthly") {
    total_price = selected_tier.price;
  }

  // if yearly plan is selected, then multiply by 12, and apply the discount
  if (plan_period === "yearly") {
    let yearly_price = selected_tier.price * 12; // yearly price without discount
    total_price = yearly_price - (yearly_price * YEARLY_DISCOUNT);
  }

  return total_price;
}