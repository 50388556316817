// New Version 26th March 2024

import React, { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import toast from "react-hot-toast";

import axiosConfig from "../../axios/axiosConfig";

// import addonsData from './addonsData';
// import subscriptionData from './subscriptionData';
import styles from "./CartPage.module.css";
// import { displayRazorpay } from "../../utils/razorpay/checkout";
import { useSelector } from "react-redux";
import PayPalMain from "../../components/payment-gateways/PayPalMain";
import ReactHotToaster from "../../components/common/ReactHotToaster";
import { useMutation, useQuery } from "@tanstack/react-query";
import { razorpayCheckout } from "../../utils/razorpay/newRazorpayUtils";

const addOnsList = [
  {
    id: 1,
    label: "Commodities",
    value: "mcx",
    checked: false,
    price: 5,
  },
  { id: 2, label: "Crypto", value: "crypto", checked: false, price: 10 },
  {
    id: 3,
    label: "Options with Chart & Signals",
    value: "options",
    checked: false,
    price: 5,
  },
  {
    id: 4,
    label: "F&O",
    value: "f&o",
    checked: false,
    price: 5,
  },
  {
    id: 5,
    label: "Cash",
    value: "cash",
    checked: false,
    price: 15,
  },
  {
    id: 6,
    label: "Nifty 50",
    value: "nifty_50",
    checked: false,
    price: 10,
  },
  {
    id: 7,
    label: "FOREX",
    value: "forex",
    checked: false,
    price: 5,
  },
  {
    id: 8,
    label: "Simulator",
    value: "simulator",
    checked: false,
    price: 10,
  },
];

// Taking planPeriod constant as no requirement as of now.
const planPeriod = "yearly";

// NEW  PLAN BY DEFAULT
// const subscriptionType = "NEW_PLAN";

function CartPage() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const USERID = currentUser.userDetails.id;
  const location = useLocation();
  const selectedPlanDetails = location?.state?.selectedPlan || {};
  const planUpgradeType = location?.state?.planType;

  const {
    name: SELECTED_PLAN,
    // plan_description,
    price,
    id: PLAN_ID,
    plan_code,
  } = selectedPlanDetails;

  // const { selectedTier, totalBasePlanAmount } = selectedPlanDetails;
  // const SELECTED_PLAN = location?.state?.selectedTier.title;   // 'basic', 'premium', etc.
  // const SELECTED_PLAN = name; // 'basic', 'premium', etc.

  const [couponDiscountPercent, setCouponDiscountPercent] = useState(0); // DISCOUNT IN PERCENTAGE

  const [couponCodeInput, setCouponCodeInput] = useState("");
  const [couponDetails, setCouponDetails] = useState({});
  const [planAmountDetails, setPlanAmountDetails] = useState({});
  const { discount: coupon_discount, final_amount } = planAmountDetails;

  let NET_PAYABLE_AMOUNT = !couponDetails?.coupon_code ? price : final_amount;
  NET_PAYABLE_AMOUNT = Number(NET_PAYABLE_AMOUNT).toFixed(2);

  // for storing all available / valid coupons
  const [allCouponsData, setAllCouponsData] = useState([]);
  const [couponHelperText, setCouponHelperText] = useState("");

  const [searchParams] = useSearchParams();
  // const planUpgradeType = searchParams.get("planType");

  // const handleAddonChange = (event) => {
  //   const addonName = event.target.name;
  //   const isAddonSelected = event.target.checked;

  //   if (isAddonSelected) {
  //     setSelectedAddons((prevSelectedAddons) => [
  //       ...prevSelectedAddons,
  //       addonsData.find((addon) => addon.name === addonName),
  //     ]);
  //   } else {
  //     setSelectedAddons((prevSelectedAddons) =>
  //       prevSelectedAddons.filter((addon) => addon.name !== addonName)
  //     );
  //   }
  // };

  // const calculateTotalPrice = () => {
  //   const basePrice = subscription.price;
  //   const addonsPrice = selectedAddons.reduce(
  //     (total, addon) => total + addon.price,
  //     0
  //   );
  //   return basePrice + addonsPrice;
  // };

  // const [selectedAddOns, setSelectedAddOns] = useState(addOnsList); // An Array of AddOns
  const [allAddOns, setAllAddOns] = useState(addOnsList); // Total AddOns List
  const selectedAddOns = allAddOns.filter((item) => item.checked);

  const handleAddOnsChange = (event) => {
    const newAddOnsList = allAddOns.map((addOn) => {
      if (addOn.value === event.target.value) {
        return { ...addOn, checked: event.target.checked };
      }
      return addOn;
    });
    // setSelectedAddOns(newAddOnsList);
    setAllAddOns(newAddOnsList);
  };

  // Calculate total amount of Plan + Add Ons
  const calculateTotalAmount = () => {
    // calculate Total Price for Add Ons
    let addOnsTotalPrice = allAddOns
      .filter((item) => item.checked)
      .reduce((total, item) => item.price + total, 0);

    if (planPeriod.toLowerCase() === "yearly") {
      addOnsTotalPrice *= 12;
    }

    // const final_amount = addOnsTotalPrice + (price || 0);
    // return final_amount.toFixed(2);
  };

  // PRICE & GST CALCULATIONS (monthly vs yearly)
  // const BASE_PRICE = location?.state?.selectedTier.price;
  const total_amount = calculateTotalAmount(); // without discount & GST
  const discount_applicable = (
    total_amount *
    (couponDiscountPercent / 100)
  ).toFixed(2);
  // const total_payable_amount = parseFloat(total_amount - discount_applicable);  // after discount if any
  // const total_payable_amount = parseFloat((total_amount - discount_applicable).toFixed(2))  // after discount if any
  // const total_payable_amount = Math.round(total_amount - discount_applicable)  // after discount if any
  const total_payable_amount = price; // after discount if any
  const total_without_gst = total_payable_amount / 1.18;
  const GST = total_without_gst * 0.18;

  // IMP: subscriptionType IS 'NEW_PLAN' OR 'RENEW_PLAN' -> Need for post vs patch payment

  // FETCHING COUPON and payment DETAILS API REQUEST
  const fetchCouponDetailQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["coupon-detail"],
    queryFn: () => getCouponDetail(couponCodeInput, PLAN_ID),
    onSuccess: (data) => {
      setCouponDetails(data.response);
      setPlanAmountDetails(data.final_report);
      setCouponHelperText(`${couponCodeInput} Applied!`);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
    enabled: false,
  });

  // HANDLE APPLY COUPON BUTTON
  const handleApplyCoupon = () => {
    // referral/coupon_validation?coupon_code=
    fetchCouponDetailQuery.refetch();
  };

  // FETCH ALL COUPONS AVAILABLE
  const fetchAllCouponsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["all-coupons"],
    queryFn: () => getAllCoupons(),
    onSuccess: (data) => {
      setAllCouponsData(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // CREATE SUBSCRIPTIONS PLAN
  const createUserSubscription = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "/subscription/user_subscription",
        data: {
          userid: USERID,
          plan_id: PLAN_ID,
          plan_code: plan_code,
          // add_on: "",
          user_coupon: couponDetails?.id, // put coupon id here
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      // const { customer_id, child_invoice_id, subscription_id, plan } = data.subscription_details.subscription;
      const {
        customer_id,
        child_invoice_id,
        subscription_id,
        next_billing_at,
        current_term_starts_at,
        plan,
      } = data.response.subscription;
      const paymentObj = {
        customer_id: customer_id,
        payment_mode: "online",
        amount: NET_PAYABLE_AMOUNT,
        invoice_id: child_invoice_id,
        subscription_id: subscription_id,
        plan_id: PLAN_ID,
        plan_code: plan.plan_code,
        // expiry_date: next_billing_at,
        start_date: current_term_starts_at,
        billing_date: next_billing_at,
        // user_coupon: null, // put coupon id here
      };

      // RAZORPAY DISPLAY AFTER SUBSCRIPTION COMPLETE

      razorpayCheckout(USERID, NET_PAYABLE_AMOUNT, paymentObj);
    },
    onError: (error) => {
      // console.log("create subscription error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const handleCreateSubscription = () => {
    createUserSubscription.mutate();
  };

  // UPDATE CHANGE SUBSCRIPTION
  // update plan mutation.
  const updatePlanMutation = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "/subscription/upgrade_plan",
        data: {
          userid: USERID,
          plan_id: PLAN_ID,
          plan_code: plan_code,
          user_coupon: couponDetails?.id,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      console.log("user sub res: ", data);
    },
    onError: (error) => {
      // console.log("create subscription error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // Update/Change/Upgrade plan handler
  const handleUpdatePlan = () => {
    updatePlanMutation.mutate();
  };

  // IMP: MAKE PAYMENT AFTER INVOICE IS GENERATED, RENEW SUBSCRIPTION
  const renewPlanMutation = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "/subscription/renew_subscription",
        data: {
          userid: USERID,
          plan_id: PLAN_ID,
          plan_code: plan_code,
          user_coupon: couponDetails?.id,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      const {
        customer_id,
        child_invoice_id,
        subscription_id,
        next_billing_at,
        current_term_starts_at,
        plan,
      } = data.response.subscription;
      const paymentObj = {
        customer_id: customer_id,
        payment_mode: "online",
        amount: NET_PAYABLE_AMOUNT,
        invoice_id: child_invoice_id,
        subscription_id: subscription_id,
        plan_id: PLAN_ID,
        plan_code: plan.plan_code,
        // expiry_date: next_billing_at,
        start_date: current_term_starts_at,
        billing_date: next_billing_at,
        // user_coupon: null, // put coupon id here
      };

      // RAZORPAY DISPLAY AFTER SUBSCRIPTION COMPLETE

      razorpayCheckout(USERID, NET_PAYABLE_AMOUNT, paymentObj);
    },
    onError: (error) => {
      // console.log("create subscription error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // Update/Change/Upgrade plan handler
  const handleRenewPlan = () => {
    renewPlanMutation.mutate();
  };

  // return no plan selected component, if accessing cart page directly
  if (!selectedPlanDetails?.id) {
    return (
      <>
        <h1>No Plan Selected...</h1>
      </>
    );
  }

  return (
    <div className={styles.cartPage}>
      <div className={styles.planDetails}>
        <h2>Selected Plan: {SELECTED_PLAN}</h2>
        {/* <p>{subscription.description}</p> */}
        <p>
          Base Price: {price}/- <strong>yearly</strong>
        </p>
      </div>

      {/* <div style={{ display: 'none'}} className={styles.addonDetails}>
        <h3>Add-Ons</h3>
        {addonsData.map((addon) => (
          <div key={addon.name}>
            <label>
              <input
                type="checkbox"
                name={addon.name}
                checked={allAddOns.some(
                  (selectedAddon) => selectedAddon.name === addon.name
                )}
                onChange={handleAddonChange}
              />
              {addon.name} (${addon.price})
            </label>
          </div>
        ))}

        <div className={styles.total}>
          <p>Total: ${calculateTotalPrice()}</p>
        </div>
      </div> */}

      {/* MAIN CONTAINER */}
      <div className={styles.main_Container}>
        {/* CART CONTAINER ON THE LEFT */}
        <div className={styles.cart_Container}>
          <h3>Select Add Ons</h3>

          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // minWidth: 400,
            }}
          >
            <Typography variant="h5">ADD ONs</Typography>
            <Typography variant="h5">Price</Typography>
          </Stack>

          <FormGroup>
            {allAddOns.map((addOn) => {
              return (
                <Stack
                  direction="row"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // minWidth: 400,
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  <FormControlLabel
                    key={addOn.id}
                    control={
                      <Checkbox
                        sx={{ padding: "0.5rem 0", marginRight: "0.5rem" }}
                        value={addOn.value}
                        checked={addOn.checked}
                        onChange={handleAddOnsChange}
                        // inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={addOn.label}
                  />

                  <Typography variant="subtitle">{addOn.price}/-</Typography>
                </Stack>
              );
            })}
          </FormGroup>
        </div>

        {/* PRICE CONTAINER ON THE RIGHT {3 DIVS, TOP, middle, payment} */}
        <div className={styles.right_price_Container}>
          {/* TOP COUPON CONTAINER */}
          <div className={styles.coupon_Container}>
            <p>COUPON ?</p>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                id="coupon-input"
                variant="outlined"
                value={couponCodeInput}
                onChange={(e) => setCouponCodeInput(e.target.value)}
                helperText={couponHelperText}
              />

              <Stack>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleApplyCoupon}
                >
                  Apply
                </Button>
              </Stack>
            </Stack>

            <ul className={styles.couponList}>
              {allCouponsData?.map((item) => {
                return (
                  <li
                    key={item.id}
                    className={styles.couponItem}
                    onClick={() => {
                      // couponHelperText("");
                      setCouponCodeInput(item.coupon_code);
                    }}
                  >
                    {item.coupon_code}
                  </li>
                );
              })}
            </ul>
          </div>

          {/* BOTTOM PRICE CONTAINER - base price, Add Ons, Discount, GST */}
          <div className={styles.bottom_Price_Container}>
            {/* <div className={styles.price_container}>
              <p className={styles.price_text}>Total Price:</p>
              <p className={styles.amount}>${total_amount}</p>
            </div> */}
            <div className={styles.price_container}>
              <p className={styles.price_text}>Base Plan:</p>
              <p className={styles.amount}>{price}</p>
            </div>

            {/* ADD ONS LIST */}
            {selectedAddOns?.map((item) => {
              return (
                <div key={item.id} className={styles.price_container}>
                  <p className={styles.price_text}>{item.label}:</p>
                  {/* <p className={styles.amount}>(+) ${planPeriod === 'yearly' ? item.price.toFixed(2) * 12.00 : item.price.toFixed(2)}</p> */}
                  {/* <p className={styles.amount}>(+) {planPeriod === 'yearly' ? (item.price * 12).toFixed(2) : item.price.toFixed(2)}</p> */}
                  <p className={styles.amount}>
                    (+){" "}
                    {planPeriod === "yearly"
                      ? (item.price * 12).toFixed(2)
                      : item.price.toFixed(2)}
                  </p>
                </div>
              );
            })}

            {/* DISCOUNT */}
            <div className={styles.price_container}>
              <p className={styles.price_text}>Discount:</p>
              <p className={styles.amount}>(-) {coupon_discount}</p>
            </div>

            {/* GST */}
            {/* <div className={styles.price_container}>
              <p className={styles.price_text}>GST (18%):</p>
              <p className={styles.amount}>(+) ${GST}</p>
            </div> */}

            {/* TOTAL PAYABLE */}
            <div
              className={`${styles.price_container} ${styles.total_price_container}`}
            >
              <p className={styles.price_text}>
                TOTAL <span style={{ fontSize: "0.8rem" }}>(inc. 18% GST)</span>
                :
              </p>
              {/* <p className={styles.payable_amount}>{total_payable_amount}</p> */}
              <p className={styles.payable_amount}>{NET_PAYABLE_AMOUNT}</p>
            </div>
          </div>

          {/* CHANGE OR UPDATE PLAN */}
          {planUpgradeType === "update" ? (
            <button onClick={handleUpdatePlan}>update plan</button>
          ) : null}

          {/* PAYMENT AMOUNT, RAZORPAY CONTAINER */}
          <div className={styles.payment_container}>
            {/* there's no such thing as manual renew, user just needs to make payment */}
            {/* {planUpgradeType === "renew" ? (
              <button onClick={handleUpdatePlan} >renew plan</button>
            ) : null} */}

            {/* Getting Location state from SubscriptionPlan ('/pricing') component */}
            <Typography variant="h4">
              {/* Net Payable: <strong>{total_payable_amount}</strong> */}
              Amount Payable: <strong>{NET_PAYABLE_AMOUNT}</strong>
            </Typography>

            <div className="btnContainer">
              {createUserSubscription.isLoading ? (
                <CircularProgress />
              ) : planUpgradeType === "renew" ? (
                <Button
                  disabled={NET_PAYABLE_AMOUNT < 0}
                  variant="contained"
                  onClick={handleRenewPlan}
                  sx={{ mt: 2, mb: 2 }}
                >
                  MAKE PAYMENT (RENEW)
                </Button>
              ) : (
                <Button
                  disabled={NET_PAYABLE_AMOUNT < 0}
                  variant="contained"
                  onClick={handleCreateSubscription}
                  sx={{ mt: 2, mb: 2 }}
                >
                  PROCEED TO PAY
                </Button>
              )}
            </div>

            <PayPalMain total_payable_amount={total_payable_amount} />
          </div>
        </div>
      </div>

      {/* React Toast */}
      <ReactHotToaster />
    </div>
  );
}

export default CartPage;

// UTLILITY / API FUNCTIONS
const getCouponDetail = async (coupon_code, plan_id) => {
  if (!coupon_code || !plan_id) {
    return;
  }

  const res = await axiosConfig({
    method: "get",
    url: `referral/coupon_validation?coupon_code=${coupon_code}&plan_id=${plan_id}`,
    data: {},
  });

  return res.data;
};

// getAllCoupons
const getAllCoupons = async () => {
  // if (!) {
  //   return;
  // }

  const res = await axiosConfig({
    method: "get",
    url: "referral/available_coupons",
    data: {},
  });

  return res.data;
};
