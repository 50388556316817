// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.UserPaymentsDashboardTable_tableTitle__Wjsgj {\n  margin-bottom: 1rem;\n}\n\n.UserPaymentsDashboardTable_tableContainer__Eeph4 {\n  max-width: 800px;\n}\n\n.UserPaymentsDashboardTable_table__tTpoT {\n  border-collapse: collapse;\n  /* width: 100%; */\n}\n\n/* Table Header Styles */\n.UserPaymentsDashboardTable_table__tTpoT thead {\n  background-color: #f2f2f2;\n}\n\n\n/* Table header & body styles */\n.UserPaymentsDashboardTable_table__tTpoT th,\n.UserPaymentsDashboardTable_table__tTpoT td {\n  padding: 6px 12px;\n  text-align: left;\n  border: 1px solid #dddddd;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/PaymentsDashboard/UserPaymentsDashboardTable/UserPaymentsDashboardTable.module.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA,wBAAwB;AACxB;EACE,yBAAyB;AAC3B;;;AAGA,+BAA+B;AAC/B;;EAEE,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":["\n.tableTitle {\n  margin-bottom: 1rem;\n}\n\n.tableContainer {\n  max-width: 800px;\n}\n\n.table {\n  border-collapse: collapse;\n  /* width: 100%; */\n}\n\n/* Table Header Styles */\n.table thead {\n  background-color: #f2f2f2;\n}\n\n\n/* Table header & body styles */\n.table th,\n.table td {\n  padding: 6px 12px;\n  text-align: left;\n  border: 1px solid #dddddd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableTitle": "UserPaymentsDashboardTable_tableTitle__Wjsgj",
	"tableContainer": "UserPaymentsDashboardTable_tableContainer__Eeph4",
	"table": "UserPaymentsDashboardTable_table__tTpoT"
};
export default ___CSS_LOADER_EXPORT___;
