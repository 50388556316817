import React from "react";
import { Outlet } from "react-router-dom";
// import SideBar from "../SideBar/SideBar";
import { Container } from "@mui/material";
// import NavBar from "./NavBar";
import NavbarNEW from "./NavbarNEW";
import { useSelector } from "react-redux";
import LoginScreen from "../components/LoginScreen/LoginScreen";

const user = JSON.parse(localStorage.getItem("user"));

export default function AdminLayout() {
  // const {
  //   user: { userDetails },
  // } = useSelector((store) => store.auth);
  const userState = useSelector((store) => store.auth);

  // IF NOT AUTHENTICATED, ROUTE TO LOGIN PAGE
  if (!userState) {
    return <LoginScreen />;
  }

  return (
    <>
      <NavbarNEW isAuth={!!user} userDetails={userState?.user?.userDetails} />
      
      <Container maxWidth="lg">
        <Outlet />
      </Container>
    </>
  );
}
