import React, { useState } from "react";
import CreateTopUpForm from "./CreateTopUpForm";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import ReactHotToaster from "../../common/ReactHotToaster";
import { axiosConfig } from "../../../axios/axiosConfig";
import TopUpsTable from "./TopUpsTable";
import MuiModal from "../../muiComps/MuiModal";
import { useSelector } from "react-redux";

const CreateTopUp = () => {
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const [allTopUpDetails, setAllTopUpDetails] = useState([]);
  const [openTopUpForm, setOpenTopUpForm] = useState(false);
  const [activeTopUp, setActiveTopUp] = useState({});

  // fetch all subscription plans
  const fetchAllTopUpsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["all-topups"], // dependency
    queryFn: () => fetchAllTopUps(USERID),
    onSuccess: (data) => {
      // console.log(data.response);
      setAllTopUpDetails(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  return (
    <div>
      <h1>Create TopUp</h1>

      {/* Create TopUp Form */}
      <CreateTopUpForm
        formType="create"
        topUpsData={allTopUpDetails}
        setAllTopUpDetails={setAllTopUpDetails}
      />

      {/* TopUps Table */}
      <TopUpsTable
        topUpsData={allTopUpDetails}
        setOpenTopUpForm={setOpenTopUpForm}
        setActiveTopUp={setActiveTopUp}
      />

      {/* TopUp form modal */}
      <MuiModal open={openTopUpForm} setOpen={setOpenTopUpForm}>
        <CreateTopUpForm
          formType="edit"
          activeTopUp={activeTopUp}
          setAllTopUpDetails={setAllTopUpDetails}
          setOpenTopUpForm={setOpenTopUpForm}
        />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default CreateTopUp;

// UTILITY FUNCTIONS
export const fetchAllTopUps = async (USERID) => {
  if (!USERID) return;

  const res = await axiosConfig({
    method: "get",
    url: `/subscription/topup_plan?userid=${USERID}`,
    data: {},
  });

  return res.data;
};
