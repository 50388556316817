import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Tooltip,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import MuiModal from "../../muiComps/MuiModal";
import MuiLinearProgressLoader from "../../muiComps/MuiLinearProgressLoader";
import DenseTable from "../../Tables/Tables";
import AddWatchlistAlertModal from "../AddWatchlistAlertModal";
import {
  addStocksToWatchlist,
  fetchAllSymbols,
  fetchWatchlist,
  getUserWatchlists,
  removeSymbolFromWatchlist,
  setWatchlistAlert,
} from "../watchlist_utils";
import ReactHotToaster from "../../common/ReactHotToaster";

// Route: /watchlist/727110

const SingleWatchlistPage = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const USERID = userDetails.id;

  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedSymbolsArray, setSelectedSymbolsArray] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(""); // Input value that is searched.
  const [allSymbols, setAllSymbols] = useState([]); // fetching all symbols from backend api

  const mappedSymbolsArray = selectedSymbolsArray?.map((symbol) => symbol.name); // MAPPING USER SELECTED SYMBOLS (Array of string symbols)

  const [userWatchlistDetails, setUserWatchlistDetails] = useState();
  const [userStocklist, setUserStocklist] = useState([]); // List of screener table data of watchlist symbols..

  // User's Array of symbol strings that are in the watchlist table
  const watchlistSymbolsArray = userStocklist?.map((item) => item.symbol);
  // filering symbols that are not in user's watchlist. (FOR ALL SYMBOLS AUTOLIST)
  const filteredSymbols = allSymbols?.filter(
    (symbol) => !watchlistSymbolsArray?.includes(symbol?.name)
  ); // filtering symbols that are already in user's watchlist

  // Stock category filter component
  const [selectedStockCategory, setSelectedStockCategory] = useState("");

  const [openAddWatchlistAlertModal, setOpenAddWatchlistAlertModal] =
    useState(false); // state for add watchlist alert Modal

  // *********** STATES FOR FILTERING TABLE   ********************
  const initialSelectedFiltersState = {
    previous_pattern: "",
    current_pattern: "",
    close_open_pct_operator: "",
    close_open_pct_value: "",
    fiftytwo_weeks_high_operator: "",
    fiftytwo_weeks_high_value: "",
    fiftytwo_weeks_low_operator: "",
    fiftytwo_weeks_low_value: "",
  };
  const [selectedFilters, setSelectedFilters] = useState(
    initialSelectedFiltersState
  );

  const { watchlistId } = useParams();

  const [userAlertList, setUserAlertList] = useState([]); // list of all applied alerts for user

  // FETCHING ALL SYMBOLS
  const symbolsQuery = useQuery({
    queryKey: ["watchlist-symbols"],
    queryFn: () => fetchAllSymbols(USERID),
    onSuccess: (data) => {
      setAllSymbols(data.response);
    },
    onError: (error) => {
      // console.log('fetch Symbols error: ', error)
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // FETCH USING useQUERY
  const watchlistQuery = useQuery({
    queryKey: ["watchlist", watchlistId],
    queryFn: () => fetchWatchlist(watchlistId, USERID),
    onSuccess: (data) => {
      // console.log("watchlist data: ", data)
      setUserWatchlistDetails(data.watchlist_data);
      setUserStocklist(data.response);
      setUserAlertList(data.user_alert);
    },
    onError: (error) => {
      // console.log('watchlist error: ', error)
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // Patch / send / ADD stocks array to backend function
  // const handleAddStocks = async () => {
  //   try {
  //     const res = await axiosConfig({
  //       method: "patch",
  //       url: `/watchlist/userwatchlist?watchlist_id=${watchlistId}`,
  //       data: { symbol: mappedSymbolsArray },
  //     });
  //     console.log(res);
  //     if (res.status === 200 || res.statusText === "OK") {
  //       setUserStocklist((prev) => [...res.data.response]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(error?.response?.data?.response || "Something went wrong");
  //   } finally {
  //     setSelectedSymbolsArray([]);
  //   }
  // };

  // Patch / send / ADD stocks array to backend function
  const handleAddStocks = async () => {
    try {
      const res = await addStocksToWatchlist(
        watchlistId,
        mappedSymbolsArray,
        USERID
      );
      if (res.status === 200 || res.statusText === "OK") {
        setUserStocklist((prev) => [...res.data.response]);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    } finally {
      setSelectedSymbolsArray([]);
    }
  };

  // REMOVE STOCK FROM WATCHLIST HANDLER
  const handleRemoveStock = async (symbolItem) => {
    try {
      const res = await removeSymbolFromWatchlist(
        watchlistId,
        symbolItem,
        USERID
      );

      if (res.status === 200 || res.statusText === "OK") {
        setUserStocklist((prev) => {
          return prev.filter((item) => item.symbol !== symbolItem);
        });

        // TOAST MESSAGE HERE
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    }
  };

  return (
    // <Box sx={{ m: "2rem" }}>
    <Box sx={{ margin: "1rem 0" }}>
      {/* <h1>SingleWatchlistPage</h1> */}
      <Typography
        sx={{
          border: "1px solid",
          display: "inline-block",
          padding: "0.5rem",
          borderRadius: 1,
          mb: 2,
        }}
        variant="h5"
      >
        {userWatchlistDetails?.name.toUpperCase()}
      </Typography>

      <Box
        sx={{
          // border: '1px solid',
          maxWidth: 600,
          padding: "0.5rem",
          borderRadius: 1,
        }}
      >
        <Typography variant="subtitle2">
          DESCRIPTION: {userWatchlistDetails?.description}
        </Typography>
      </Box>

      {/* Stocks List Autocomplete */}
      {symbolsQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          sx={{ width: 450, m: "1rem 0" }}
          multiple
          id="tags-outlined"
          // options={allSymbols}
          options={filteredSymbols}
          getOptionLabel={(option) => option.name}
          // defaultValue={[top100Films[2]]}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label="Add Symbols" placeholder="ADD " />
          )}
          value={selectedSymbolsArray}
          onChange={(event, newValue) => {
            setSelectedSymbolsArray(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
        />
      )}

      <Stack direction="row" spacing={1} sx={{ width: 600, margin: "2rem 0" }}>
        <Button
          disabled={mappedSymbolsArray?.length === 0}
          variant="contained"
          size="medium"
          onClick={handleAddStocks}
        >
          add symbols
        </Button>

        <Tooltip title="Add or remove an alert for the whole Watchlist">
          <Button
            variant="contained"
            size="medium"
            // onClick={() => createWatchlistAlert(userWatchlistDetails.id, userWatchlistDetails.name)}
            onClick={() => setOpenAddWatchlistAlertModal(true)}
          >
            add/remove alert
          </Button>
        </Tooltip>

        <Button
          variant="contained"
          size="medium"
          onClick={() => navigate("/watchlist")}
        >
          back
        </Button>
      </Stack>

      {/* MUI ALERT COMP FOR BUY / SELL ALERTS */}
      <Stack direction="row" spacing={1} sx={{ width: 600 }}>
        {userWatchlistDetails?.buy_alert ? (
          <Alert severity="info">
            <strong>Buy Alert</strong> Active!
          </Alert>
        ) : null}

        {userWatchlistDetails?.sell_alert ? (
          <Alert severity="success">
            <strong>Sell Alert</strong> Active!
          </Alert>
        ) : null}
      </Stack>

      {/* stocks table here */}
      {watchlistQuery.isLoading ? (
        <MuiLinearProgressLoader />
      ) : userStocklist.length !== 0 ? (
        <Box
          sx={{
            margin: "1rem 0",
            // overflowX: "auto",
            // minWidth: "2500px",
          }}
        >
          <DenseTable
            tableData={userStocklist}
            userAlertList={userAlertList}
            setUserAlertList={setUserAlertList}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            handleRemoveStock={handleRemoveStock}
            showRemoveStockIcon={true}
            maxHeight="600px"
            selectedStockCategory={selectedStockCategory}
            setSelectedStockCategory={setSelectedStockCategory}
          />
        </Box>
      ) : (
        <Box sx={{ m: "1rem 0" }}>
          <Typography
            variant="span"
            sx={{
              color: "white",
              backgroundColor: "#CB4335",
              padding: "4px 1rem",
              borderRadius: "4px",
            }}
          >
            Watchlist Empty! Please add Symbols from the list.
          </Typography>
        </Box>
      )}

      {/* REACT TOAST */}
      <ReactHotToaster />

      {/* CREATE NEW WATCHLIST ALERT */}
      <MuiModal
        open={openAddWatchlistAlertModal}
        setOpen={setOpenAddWatchlistAlertModal}
      >
        <AddWatchlistAlertModal
          setOpenAlertTypeModal={setOpenAddWatchlistAlertModal}
          watchlistDetails={userWatchlistDetails}
          setOpenAddWatchlistAlertModal={setOpenAddWatchlistAlertModal}
          // setUserWatchlists={setUserWatchlists}  // NOT BEING USED, CHECK FOR BUGS
          setUserWatchlistDetails={setUserWatchlistDetails}
        />
      </MuiModal>
    </Box>
  );
};

export default SingleWatchlistPage;
