// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserRefundsTable_tableContainer__Q6DCB {\n  width: 100%;\n  max-width: 1000px;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n  /* background-color: red; */\n}\n\n.UserRefundsTable_tableContainer__Q6DCB th,\n.UserRefundsTable_tableContainer__Q6DCB td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.UserRefundsTable_tableContainer__Q6DCB th {\n  background-color: #f2f2f2;\n}\n\n.UserRefundsTable_tableBodyContainer__RRGb4 tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/AdminRefunds/UserRefundsTable/UserRefundsTable.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".tableContainer {\n  width: 100%;\n  max-width: 1000px;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n  /* background-color: red; */\n}\n\n.tableContainer th,\n.tableContainer td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.tableContainer th {\n  background-color: #f2f2f2;\n}\n\n.tableBodyContainer tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "UserRefundsTable_tableContainer__Q6DCB",
	"tableBodyContainer": "UserRefundsTable_tableBodyContainer__RRGb4"
};
export default ___CSS_LOADER_EXPORT___;
