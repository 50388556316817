import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { axiosConfig } from "../../../axios/axiosConfig";
import MuiModal from "../../muiComps/MuiModal";
import RaiseTicketModal from "./RaiseTicketModal";
import TicketHistoryTable from "./TicketHistoryTable";

const UserSupport = () => {
  
  const [openRaiseTicketModal, setOpenRaiseTicketModal] = useState(false);
  // const [userContactID, setUserContactID] = useState(null);
  const [userTickets, setUserTickets] = useState([]);


  // function to add new ticketitem to all ticketlist state
  const handleAddTicketItem = (ticketItem) => {
    setUserTickets((prevTicketList) => {
      return [...prevTicketList, ticketItem];
    });
  };

  // FETCHING all user tickets raised
  const userTicketsQuery = useQuery({
    queryKey: ["user-tickets"],
    // enabled: false,
    refetchOnWindowFocus: false,
    queryFn: () => fetchUserTickets(),
    onSuccess: (data) => {
      
      setUserTickets(data.response);
    },
    onError: (error) => {
      console.log("fetch Symbols error: ", error);
    },
  });

  const toggleRaiseTicketModal = () => {
    // trigger an api to backend for fetching contact id, if it exists
    // contactIDQuery.refetch();

    // open raise ticket modal
    setOpenRaiseTicketModal(true);
  };

  return (
    <div>
      <h2>User Support</h2>

      {/* RAISE TICKET TRIGGER BUTTON, zoho version */}
      <button onClick={toggleRaiseTicketModal}>raise ticket</button>

      {/* SUPPORT BOX */}
      <MuiModal open={openRaiseTicketModal} setOpen={setOpenRaiseTicketModal}>
        <RaiseTicketModal
          handleAddTicketItem={handleAddTicketItem}
          setOpen={setOpenRaiseTicketModal}
        />
      </MuiModal>

      {/* SHOW PREVIOUS TICKETS HERE */}
      <h2>previous tickets table</h2>

      <TicketHistoryTable userTickets={userTickets} />
    </div>
  );
};

export default UserSupport;

// UTILITY FUNCTIONS
const fetchUserTickets = async () => {
  // if (!user_id) return;

  const res = await axiosConfig({
    method: "get",
    url: "/support/raise_request",
    data: {},
  });

  return res.data;
};
