import React from "react";
import { useSelector } from "react-redux";
import AccessDenied from "../components/common/AccessDenied";
import LoginScreen from "../components/LoginScreen/LoginScreen";
import VerifyEmailPrompt from "../components/VerifyEmailPrompt";
import UserProfile from "../components/UserDashboard/Profile";
import UserDashboardLayout from "../Layout/UserDashboardLayout";

const PrivateRoute = ({
  auth: { isAuthenticated },
  children,
  allowedRoles = null,
}) => {
  // const {
  //   user: { userDetails },
  // } = useSelector((store) => store.auth);
  const userState = useSelector((store) => store.auth);

  // IF NOT AUTHENTICATED, ROUTE TO LOGIN PAGE  
  if (!isAuthenticated) {
    return <LoginScreen />;
  }

  const USER_DETAILS = userState?.user?.userDetails;
  const USER_NAME = USER_DETAILS?.username;
  
  
  // ADD CODE FOR NON-INDIAN USERS HERE, EMAIL VERIFICATION COMPULSORY FOR NON-INDIAN USERS ONLY
  if (USER_DETAILS?.country !== "India") {
    if (!USER_DETAILS?.email_verified) {
      return <VerifyEmailPrompt userDetails={USER_DETAILS} />;
    }
  }

 // CHECKING IF USERNAME UPDATED OR NOT. 
//  if (!USER_NAME || USER_NAME.trim() === "") {
//   return (
//     <h1>update username modal</h1>
//   )
// }

  // ONLY ALLOW ROLES CONTAINED IN 'ALLOWED_ROLES' ARRAY
  if (allowedRoles?.includes(USER_DETAILS.user_type.toLowerCase())) {
     
    return children;
  } else {
    return <AccessDenied />;
  }

  // return isAuthenticated ? children : <LoginScreen />;
};

export default PrivateRoute;
