import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import BasicTextFields from "../components/Modal/Input";
import BasicButton from "../components/Button/BasicButton";
import ControlledCheckbox from "../components/Checkbox/Checkbox";
import { Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  padding: "20px",
  borderRadius: "5px",
};

export default function SaveScanModal({
  isOpen,
  // action,
  setOpenSaveScanModal,
  scanFields,
  handleSaveScanScreener,

}) {
  const [open, setOpen] = useState(isOpen);
  const [saveQueryDetails, setSaveQueryDetails] = useState(scanFields); // {scan_name: '', scan_description: ''}
  

  const handleOnTextchange = (key, val) => {
    const temp = { ...saveQueryDetails };
    temp[key] = val;
    setSaveQueryDetails(temp);
  };

  const handleClose = () => {
    // action(false);
    setOpenSaveScanModal(false);
  };

  const handleValidate = () => {
    handleSaveScanScreener(saveQueryDetails);
    handleClose();
    // setOpenSaveScanModal(false);
  };

  const handleIsQueryPrivate = (val, key) => {
    const temp = { ...saveQueryDetails };
    temp[key] = val;
    
    setSaveQueryDetails(temp);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setSaveQueryDetails(scanFields);
  }, [scanFields]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" sx={{ textAlign: 'center', mb: 2}} >Save Scan</Typography>
        <BasicTextFields
          value={saveQueryDetails?.scan_name}
          label="Name"
          fieldKey="scan_name"
          callback={handleOnTextchange}
        />
        <BasicTextFields
          value={saveQueryDetails?.scan_description}
          label="Description"
          fieldKey="scan_description"
          callback={handleOnTextchange}
          multiline={true}
          fullwidth={true}
        />
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: 4 }}>
            <BasicButton title="Submit" callback={handleValidate} />
            <BasicButton title="Cancel" callback={handleClose} />
          </div>
          <ControlledCheckbox
            options={[{ label: "Hide queries", value: "hide_query" }]}
            handleOptionChange={handleIsQueryPrivate}
          />
        </div>
      </Box>
    </Modal>
  );
}
