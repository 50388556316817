import React from 'react';
import './NoResultsToDisplay.css';

const NoResultsToDisplay = () => {
  return (
    <div className="no-results-container">
      <p className="no-results-text">No Results, please remove filters or try another query.</p>
    </div>
  );
}

export default NoResultsToDisplay;