import React, { useEffect, useState } from "react";
import UploadBannerForm from "../../common/UploadBannerForm";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { axiosConfig } from "../../../axios/axiosConfig";
import AdBannersTable from "./AdBannersTable";
import MuiModal from "../../muiComps/MuiModal";

const UploadBanners = () => {
  const [openUploadBannerForm, setOpenUploadBannerForm] = useState(false);
  const [activeBannerItem, setActiveBannerItem] = useState({});  // active banner item that is being edited
  // console.log("activeBannerItem: ", activeBannerItem)
  console.log("activeBannerItem?.screen_name", activeBannerItem?.screen_name || "Dashboard")
  const todayDate = new Date().toISOString().slice(0, 10)
  // console.log("todayDate: ", todayDate)

  const [isEditing, setIsEditing] = useState(false);

  const initialFormInput = {
    // title: "",
    // description: "",
    imgUrl: activeBannerItem?.screen_url || "",
    targetUrl: "",
    // selectedScreen: activeBannerItem?.screen_name ?? "Dashboard",
    selectedScreen: "Dashboard",
    // startDate: activeBannerItem?.from_date || "04/22/2024",
    startDate: formatDate(activeBannerItem?.from_date) || todayDate,
    endDate: formatDate(activeBannerItem?.till_date) || "",
  }

  
  const [formInput, setFormInput] = useState(initialFormInput);
  console.log("formInput: ", formInput)
  

  const [imgFile, setImgFile] = useState();
  const [imgPreview, setImgPreview] = useState(null);
  const [allBannersList, setAllBannersList] = useState([])

  
  const handleFormInputOnChange = (event) => {
    setFormInput((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });

    // image preview code
    if (event.target.name === "imgUrl") {
      setImgPreview(event.target.value);
    }
  };

  function handleImageFileChange(e) {
    setImgPreview(URL.createObjectURL(e.target.files[0]));
    setImgFile(e.target.files[0]);

    setFormInput((prev) => {
      return {
        ...prev,
        imgUrl: URL.createObjectURL(e.target.files[0]),
      };
    });
  }


  // post banner data mutation
  const postBannerDataMutation = useMutation({
    mutationFn: () => postBanner(formInput, imgFile),
    onSuccess: (data) => {
      //  console.log("data: ", data.response.data)
       setAllBannersList(prevState => {
        return [...prevState, data.response.data];
       })
      
    },
    onError: (error) => {
      console.log("watchlist error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
    onSettled: () => {
      // setOpenUploadBannerForm(false);
      setFormInput({
        imgUrl: "",
        targetUrl: "",
        selectedScreen: "Dashboard",
        startDate: todayDate,
        endDate: "",
      });
      setImgPreview(null);
      setImgFile(null);
    }
  });
  
  // HANDLE SAVE / UPDATE / PATCH
  const handleUpdate = () => {

  }

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    e.preventDefault();

    postBannerDataMutation.mutate()
  };

  // FETCH ALL BANNERS
const allBannersQuery = useQuery({
  retry: false,
  refetchOnWindowFocus: false, // Prevent refetch on window focus
  refetchInterval: false, // Disable automatic refetching
  queryKey: ["all-banners"], // dependency
  queryFn: () => fetchAllBanners(),
  onSuccess: (data) => {
    setAllBannersList(data.response.data);
  },
  onError: (error) => {
    
    toast.error(error?.response?.data?.response || "Something went wrong");
  },
});

  // EDIT BANNER ITEM FROM TABLE, when admin clicks on the edit button on AdBannersTable
  const handleEditClick = (bannerItem) => {
    setActiveBannerItem(bannerItem);
    setIsEditing(true);

    console.log("bannerItem: ", bannerItem)

    // setFormInput({
    //   imgUrl: activeBannerItem?.screen_url,
    //   targetUrl: "",
    //   selectedScreen: activeBannerItem?.screen_name,
    //   startDate: formatDate(activeBannerItem?.from_date),
    //   endDate: formatDate(activeBannerItem?.till_date),
    // })    
  };

  // useEffect(() => {
  //   setFormInput({
  //     imgUrl: activeBannerItem?.screen_url,
  //     targetUrl: "",
  //     selectedScreen: activeBannerItem?.screen_name,
  //     startDate: formatDate(activeBannerItem?.from_date),
  //     endDate: formatDate(activeBannerItem?.till_date),
  //   })  
  // }, [activeBannerItem])

  return (
    <div>
      <h2>UploadBanners</h2>

      {/* Upload banner form */}
      <UploadBannerForm
        imgPreview={imgPreview}
        formInput={formInput}
        handleFormInputOnChange={handleFormInputOnChange}
        handleImageFileChange={handleImageFileChange}
        handleSubmit={handleSubmit}
        handleUpdate={handleUpdate}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        // activeBannerItem={activeBannerItem}
      />

      {/* BANNER UPLOAD FORM FOR EDITING / UPDATING */}
      {/* <MuiModal open={openUploadBannerForm} setOpen={setOpenUploadBannerForm} >
        <UploadBannerForm
          isEditing={true}
          imgPreview={imgPreview}
          formInput={formInput}
          handleFormInputOnChange={handleFormInputOnChange}
          handleImageFileChange={handleImageFileChange}
          handleSubmit={handleSubmit}
          // activeBannerItem={activeBannerItem}
        />
      </MuiModal> */}

      {/* BANNER DETAILS TABLE */}
      <AdBannersTable allBannersList={allBannersList} setAllBannersList={setAllBannersList} handleEditClick={handleEditClick} />
    </div>
  );
};

export default UploadBanners;


// UTILITIES
const fetchAllBanners = async () => {
  // if (!watchlistId) return;

  const res = await axiosConfig({
    method: "get",
    url: '/advertisement/screens/banner',
    data: {},
  });

  return res.data;
};

// post banner to backend
const postBanner = async (formInput, img_file) => {
  // if (!watchlistId) return;
  console.log("formInput: ", formInput);
  // return;
  
  const formData = new FormData();
  formData.append("banner", img_file);
  formData.append("screen", formInput.selectedScreen);
  formData.append("from_date", formInput.startDate);
  formData.append("till_date", formInput.endDate);

  const res = await axiosConfig({
    method: "post",
    url: 'advertisement/screens/banner',
    data: formData,
  });

  return res.data;
};


// convert date 
const formatDate = (dateString) => {
  if (!dateString || dateString.trim() === '') return;

  return new Date(dateString).toISOString().slice(0, 10);
}