import React, { useEffect, useState } from "react";
import DenseTable from "../components/Tables/Tables";

import BasicTextFields from "../components/Modal/Input";
import BackTestTiles from "./BackTestTiles";
import SymbolsListTable from "./SymbolsListTable";
import axiosConfig from "../axios/axiosConfig";
import SavedScanDetails from "./SavedScanDetails";
import { useSelector } from "react-redux";

// IMPORTANT: THIS COMPONENT IS NOT BEING USED (CHECK)

const SavedScanWithResult = ({
  tableFeed,
  screenerId,
  latestStocks,
  userScreenerAlertDetail,
  screenerObjDetails
}) => {
  // both these states are related to 'Search Stock' and aren't needed anymore
  const [FilterdStockList, setFilteredStockList] = useState([]);
  const [stockSearchText, setStockSearchText] = useState("");
  
  // Show BackTestTable or Symbols List Table toggle
  const [showBackTestTiles, setShowBackTestTiles] = useState(true);
  const [timeframeDate, setTimeframeDate] = React.useState(null);
  const [symbolsData, setSymbolsData] = React.useState(null);

  // STATES FOR PAGINATION
  const { currentPage: globalCurrentPage, rowsPerPage,  } = useSelector((store) => store.pagination);
  

  // Stock category filter component
  const [selectedStockCategory, setSelectedStockCategory] = useState("");

  const handleStockSearch = (field, query) => {
    setStockSearchText(query);

    if (query !== "" || query !== undefined) {
      const filteredStockList = tableFeed.response.filter((stock) =>
        stock.symbol.toLowerCase().includes(query)
      );
      setFilteredStockList(filteredStockList);
      return;
    }
    setFilteredStockList(tableFeed.response);
  };

  // useEffect(() => {
    
  //   setFilteredStockList(tableFeed?.response);
  // }, [tableFeed]);

  useEffect(() => {
    if (!timeframeDate || timeframeDate === "") {
      return;
    }

    const fetchBacktestSymbols = async () => {
      try {
        const res = await axiosConfig({
          method: "post",
          url: "/backtest",
          // url: `/backtest?page_no=${globalCurrentPage}&per_page=${rowsPerPage}`,
          data: { screenerid: screenerId, timeframedate: timeframeDate },
        });

        setSymbolsData(res.data.response);
      } catch (error) {
        console.log(error);
      }
    };

    

    fetchBacktestSymbols();
  }, [screenerId, timeframeDate, globalCurrentPage]);

  return (
    <>
      <SavedScanDetails
        screenerId={screenerId}
        scanName={tableFeed?.name}
        scanDescription={tableFeed?.description}
        screenerObjDetails={screenerObjDetails}
        userScreenerAlertDetail={userScreenerAlertDetail}
      />

      {/* <div
        style={{
          width: "90%",
          backgroundColor: "#bc8f8f1f",
          display: "flex",
          flexDirection: "column",
          borderRadius: "5px",
          margin: "20px",
          padding: "10px",
        }}
                  >
        <span style={{ marginLeft: "20px" }}>Name: {tableFeed?.name} </span>
        <span style={{ marginLeft: "20px" }}>
          Description: {tableFeed?.description}
        </span>
        
      </div> */}

      {/* SCREENER TABLE CONTAINER */}
      <div
        style={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* COMMENTED OUT THE EXTRA SEARCH FIELD  */}
        {/* <span style={{ display: "flex", justifyContent: "flex-end" }}>
          <BasicTextFields
            label="Search"
            value={stockSearchText}
            callback={handleStockSearch}
          />
        </span> */}

        <div
          style={{
            overflowX: "auto",
          }}
        >
          
          <DenseTable
            // tableData={FilterdStockList}
            tableData={tableFeed?.response}
            selectedStockCategory={selectedStockCategory}
            setSelectedStockCategory={setSelectedStockCategory}
            latest_stocks={latestStocks}
          />
        </div>
      </div>

      {/* BACKTEST TABLE AND DATE WISE TABLE DATA */}
      {showBackTestTiles ? (
        <BackTestTiles
          setTimeframeDate={setTimeframeDate}
          timeframeDate={timeframeDate}
          setShowBackTestTiles={setShowBackTestTiles}
          screenerId={screenerId}
        />
      ) : (
        <SymbolsListTable
          symbolsData={symbolsData}
          setSymbolsData={setSymbolsData}
          setShowBackTestTiles={setShowBackTestTiles}
          timeframeDate={timeframeDate}
        />
      )}
    </>
  );
};

export default SavedScanWithResult;
