import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { LOGIN_SUCCESS } from "../../actions/types";
import { LOGIN_SUCCESS } from "../../reduxStore/actions/types";
import axiosConfig from "../../axios/axiosConfig";
import LoginScreen from "../../components/LoginScreen/LoginScreen";

const userLocalStorageDetails = JSON.parse(localStorage.getItem("user"));

// When user clicks on email verification link, they should be routed to this component
// SUCCESS OR FAILURE PAGE...
// IMPORTANT: THIS COMPONENT IS NOT BEING USED ANYWHWERE..

const EmailVerificationPage = () => {
  const dispatch = useDispatch();
  const userState = useSelector((store) => store.auth);
  const userDetails = userState?.user?.userDetails;

  const [URLSearchParams] = useSearchParams();
  const emailToken = URLSearchParams.get("token");
  
  const [loading, setLoading] = useState(true);
  const [emailVerified, setEmailVerified] = useState(userDetails?.email_verified);

  
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!userDetails) {
        return;
      }

      try {
        const res = await axiosConfig({
          method: "post",
          url: "/enroll/emailverify",
          data: { userid: userDetails?.id, otp: '452'},
        });

        if (res.status === 200 || res.statusText === "OK") {
          setEmailVerified(true);
          // state update user
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: res.data.user_details },
          });

          // updte local storage
          const newUserLocalStorage = {
            ...userLocalStorageDetails,
            userDetails: res.data.user_details,
          };

          localStorage.setItem("user", JSON.stringify(newUserLocalStorage));
          // navigate("/");
          window.location.assign("/");
        }
      } catch (error) {
        console.log(error.response);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [emailToken, userDetails?.id]);

  // IF NOT AUTHENTICATED, ROUTE TO LOGIN PAGE
  if (!userDetails) {
    return <LoginScreen />;
  }

  if (loading) {
    return <h1>Loading....</h1>;
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          maxWidth: 700,
          maxHeight: 400,
          mt: 4,
          p: 4,
          bgcolor: "#cfe8fc",
          height: "100vh",
        }}
      >
        {emailVerified ? (
          <>
            <Typography>Your email has been verified Successfully.</Typography>
          </>
        ) : (
          <>
            <Typography>Email Verification Failed.</Typography>
            <button>send new link</button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default EmailVerificationPage;
