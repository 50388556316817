import React from 'react';

import styles from './AdBannersTable.module.css';
import { axiosConfig } from '../../../../axios/axiosConfig';
import { useMutation } from '@tanstack/react-query';

const AdBannersTable = ({ allBannersList, setAllBannersList, handleEditClick }) => {

  

  // post banner data mutation
  const deleteBannerMutation = useMutation({
    mutationFn: (bannerId) => deleteBanner(bannerId),
    onSuccess: (data, bannerId) => {
      
      setAllBannersList(prev => {
        return prev.filter(banner => banner.id !== bannerId);
      });
    },
    onError: (error) => {
      console.log(" error: ", error);
      // toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });
  
  const handleDeleteBanner = (bannerId) => {
    const consent = window.confirm("Are you sure you want to delete this banner?")
    if (!consent) return;

    // Delete banner logic goes here    
    deleteBannerMutation.mutate(bannerId);
  };

 

  return (
    <div>AdBannersTable

      {/* Table for displaying ad banners */}
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Screen</th>
            <th>Created On</th>
            <th>Start</th>
            <th>End</th>
            <th>Image</th>
            

            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {allBannersList.map((item) => {
            // const createdDate = new Date(item.created_at).toDateString();
            // const modifiedDate = new Date(item.modified_at).toDateString();

            return (
              <tr key={item.id}>
                <td>{item.screen_name}</td>
                <td>{item.created_at}</td>
                <td>{item.from_date}</td>
                <td>{item.till_date}</td>
                <td>
                  {/* <a href={item.screen_url}>Image Link</a> */}
                  <div className={styles.imgPreviewBox}>
                    <img className={styles.imgPreview} src={item.screen_url} alt="" />
                  </div>
                  
                </td>
                
                <td>
                  <div className={styles.viewBtnContainer}>
                    <button onClick={() => handleEditClick(item)} >edit</button>
                    <button onClick={() => handleDeleteBanner(item.id)} >delete</button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

export default AdBannersTable;


// UTILITIES
const deleteBanner = async (banner_id) => {
  // if (!watchlistId) return;

  const res = await axiosConfig({
    method: "delete",
    url: 'advertisement/screens/banner',
    data: { id: banner_id},
  });

  return res.data;
};