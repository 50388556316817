import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextFields from '../TextField/TextField';
import BasicButton from '../Button/BasicButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

export default function NtimeFrameModal({isOpen, handleValidate, onChangeApplyDefaultValue}) {
  const [open, setOpen] = useState(isOpen);
  const [nTimeframeValue, setNtimeFrameValue] = useState();

  const handleClose = () => setOpen(false);

  const handleChange = (val) => {
    setNtimeFrameValue(val)
  }

  const handleSubmit = () => {
    handleValidate(nTimeframeValue)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <TextFields
             handleTextFieldValue={(value, key) => handleChange(value)}
             defaultValue="n"
             onChangeApplyDefaultValue={onChangeApplyDefaultValue}
         />
         <BasicButton title="confirm" callback={handleSubmit}/>
        </Box>
      </Modal>
    </div>
  );
}