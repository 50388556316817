import React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const MuiLinearProgressLoader = () => {
  return (
    <>
      <Box sx={{ width: "100%", margin: "10rem 0"}} >
        <Box sx={{ margin: "1rem 0" }}>
          <LinearProgress />
        </Box>
        <Box sx={{ margin: "1rem 0" }}>
          <LinearProgress />
        </Box>
        <Box sx={{ margin: "1rem 0" }}>
          <LinearProgress />
        </Box>
        <Box sx={{ margin: "1rem 0" }}>
          <LinearProgress />
        </Box>
      </Box>
    </>
  );
};

export default MuiLinearProgressLoader;
