
import React from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

import axiosConfig from '../../../axios/axiosConfig';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const initialOptions = {
  "client-id":
    // "AbREbWOUjv29kyQ5RQn9qpv1bZD9ENMn_HLvfd05hR9QGPhLssv5-TSOAuIAQ8kjyhPVj1nuovjTCrgz",
    "AXVICBXNgELKJQpFpUdxO5Zi13J-rbA3Z7bVIXWS9uXUNVvVIYmPSrCjK0Y0SVngysgDpCcYtpIz1g3_",
  currency: "USD",
  // intent: "capture",
  intent: "authorize",
  // "data-client-token": "abc123xyz==",
};

let BACKEND_ORDER_ID = 0;
export default function PayPalMain({ total_payable_amount = 1 }) {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  const navigate = useNavigate();

  const createOrder = async (data) => {
    // console.log('createorder DATA: ', data);

    return axiosConfig({
      method: "post",
      url: "/payment/paypal_payment_order",
      data: { userid: userDetails.id, amount: total_payable_amount, plan_type: 'premium', currency: "USD", paymentmode: 'paypal' },
    }).then((order) => {
      // console.log("order ID: ", order.data.response.id)
      // console.log("whole order: ", order)
      BACKEND_ORDER_ID = order.data.data.id;
      return order.data.response.id;
    });
  };

  const onApprove = async (data) => {
    // console.log("onapprove data: ", data)
    return axiosConfig({
      method: "post",
      url: "/payment/paypal_payment_verify",
      data: { order_id: data.orderID, payment_id: data.payerID, payment_signature: data.facilitatorAccessToken, id: BACKEND_ORDER_ID },
    }).then((order) => {
      console.log("onApprove response: ", order.data)
      if (order.data.response.status === 'COMPLETED') {
        // NAVIGATE TO SUCCESS
        navigate('/payment-success')
      }
      return order.data.response.id
    });
  };

  const onCancel = () => {
    console.log("Order cancelled...")
  }

  return (
    <PayPalScriptProvider options={initialOptions}>
      
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        onCancel={onCancel}
      />
    </PayPalScriptProvider>
  );
}



// ORIGINAL CREATE ORDER AND ONAPPROVE FUNCTIONS
const createOrder = (data) => {
  // Order is created on the server and the order id is returned
  return fetch("/my-server/create-paypal-order", {
    method: "POST",
     headers: {
      "Content-Type": "application/json",
    },
    // use the "body" param to optionally pass additional order information
    // like product skus and quantities
    body: JSON.stringify({
      cart: [
        {
          sku: "YOUR_PRODUCT_STOCK_KEEPING_UNIT",
          quantity: "YOUR_PRODUCT_QUANTITY",
        },
      ],
    }),
  })
  .then((response) => response.json())
  .then((order) => order.id);
};

const onApprove = (data) => {
   // Order is captured on the server and the response is returned to the browser
   return fetch("/my-server/capture-paypal-order", {
    method: "POST",
     headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      orderID: data.orderID
    })
  })
  .then((response) => response.json());
};