// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.YoutubeTutorial_outer_container__k1OuB {\n  min-height: 300px;\n  /* background-color: blueviolet; */\n  /* padding: 2rem; */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.YoutubeTutorial_inner_container__-dXor {\n  display: flex;\n  /* flex-wrap: wrap; */\n}\n\n.YoutubeTutorial_video_caption_container__43Tkc {\n  /* flex: 1; */\n  padding: 2rem;\n  \n}\n.YoutubeTutorial_video_caption__ylc1i {\n  font-size: 24px;\n}\n\n.YoutubeTutorial_youtube_video_container__7exx0 {\n  /* flex: 1; */\n  min-width: 50%;\n  height: 100%;\n  \n  /* padding: 1rem; */\n}\n\n\n\n/* MEDIA QUERIES */\n@media screen and (max-width: 800px) {\n  \n  .YoutubeTutorial_inner_container__-dXor {\n    flex-direction: column;\n    padding: 2rem;\n  }\n}\n\n\n\n", "",{"version":3,"sources":["webpack://./src/components/homepage/YoutubeTutorial/YoutubeTutorial.module.css"],"names":[],"mappings":";AACA;EACE,iBAAiB;EACjB,kCAAkC;EAClC,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,aAAa;;AAEf;AACA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,YAAY;;EAEZ,mBAAmB;AACrB;;;;AAIA,kBAAkB;AAClB;;EAEE;IACE,sBAAsB;IACtB,aAAa;EACf;AACF","sourcesContent":["\n.outer_container {\n  min-height: 300px;\n  /* background-color: blueviolet; */\n  /* padding: 2rem; */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.inner_container {\n  display: flex;\n  /* flex-wrap: wrap; */\n}\n\n.video_caption_container {\n  /* flex: 1; */\n  padding: 2rem;\n  \n}\n.video_caption {\n  font-size: 24px;\n}\n\n.youtube_video_container {\n  /* flex: 1; */\n  min-width: 50%;\n  height: 100%;\n  \n  /* padding: 1rem; */\n}\n\n\n\n/* MEDIA QUERIES */\n@media screen and (max-width: 800px) {\n  \n  .inner_container {\n    flex-direction: column;\n    padding: 2rem;\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outer_container": "YoutubeTutorial_outer_container__k1OuB",
	"inner_container": "YoutubeTutorial_inner_container__-dXor",
	"video_caption_container": "YoutubeTutorial_video_caption_container__43Tkc",
	"video_caption": "YoutubeTutorial_video_caption__ylc1i",
	"youtube_video_container": "YoutubeTutorial_youtube_video_container__7exx0"
};
export default ___CSS_LOADER_EXPORT___;
