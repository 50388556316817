import axiosConfig from '../axios/axiosConfig';

// fetching Symbols for Options for Options Page ('/nse-option-chain')
export const fetchSymbolsArray = async () => {
  const res = await axiosConfig({
    method: "get",
    url: "/optionchain?symbol=true",
    data: {},
  });

  return res.data;
}


// Fetching Symbols data / Nifty data
  // VERY IMPORTANT FUNCTION, FETCHING initial data ie stocks data, initial expiry data.
export const fetchSymbolsData = async (selectedSymbol, stockIndex) => {
  if (selectedSymbol === "" && stockIndex === "") {
    return;
  }

  const res = await axiosConfig({
    method: "post",
    url: "/optionchain",
    data: { symbol: selectedSymbol || stockIndex },
  });

  return res.data;
};